import React, { FC } from 'react'
import { Grid, Tooltip } from '@chakra-ui/react'

interface Props {
  Icon?(props: React.SVGProps<SVGSVGElement>): JSX.Element
  children: React.ReactNode
  tooltip?: React.ReactNode
}

const TableHeader: FC<Props> = ({ children, Icon, tooltip }) => {
  const showComponent = (
    <Grid
      gridAutoFlow="column"
      gridTemplateColumns="max-content 1fr"
      alignItems="center"
      gridGap="8px"
    >
      {Icon ?
        <Icon />
      : <span
          style={{
            display: 'inline-block',
            height: '16px',
            width: '1px',
          }}
        ></span>
      }
      <span>{children}</span>
    </Grid>
  )
  return tooltip ?
      <Tooltip label={tooltip}>{showComponent}</Tooltip>
    : showComponent
}

export default TableHeader

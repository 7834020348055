import FilterIcon from '@/components/Icons/Filter'
import { colors } from '@/theme/colors'
import React, { FC } from 'react'
import { ButtonProps } from '@chakra-ui/react'
import { QFormSubmitButton } from '@/components/QForm/QButtons/QFormSubmitButton'

export const QFormSubmitFiltersButton: FC<ButtonProps> = ({
  children = 'Сохранить',
  ...props
}) => {
  return (
    <QFormSubmitButton
      color={'neutral.100'}
      height="max-content"
      alignSelf="end"
      leftIcon={<FilterIcon color={colors.neutral[100]} />}
      size="l"
      {...props}
    >
      {children}
    </QFormSubmitButton>
  )
}

import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { TextareaProps } from '@chakra-ui/textarea/dist/textarea'
import { QTextCore } from '@/components/QForm/QField/QInput/QText/QTextCore'

export const QTextInner = (props: TextareaProps) => {
  const { name, propsFromYup } = useQFieldContext()
  const [field, meta] = useField(name)
  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QTextCore
        {...{
          ...field,
          isInvalid: Boolean(meta.error),
        }}
      />
    </ComponentPropsDecorator>
  )
}

import React, { ReactNode } from 'react'
import { QNumberInner } from '@/components/QForm/QField/QInput/QNumber/QNumberInner'
import { omit } from 'lodash'
import { QNumberPropsType } from '@/components/QForm/QField/QInput/QNumber/QNumber.types'
import { QForm } from '@/components/QForm/QForm'

type QMoneyProps = QNumberPropsType & {
  currency?: ReactNode
}
export const QMoney = (props: QMoneyProps) => {
  const { name, withWrapper, isHardName, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
        onChangeValue: props.onChangeValue,
      }}
    >
      <QNumberInner
        isHideStepper
        precision={2}
        rightElement={{
          content: '₽',
        }}
        {...omit(otherProps, ['onInvalid'])}
      />
    </QForm.Field>
  )
}

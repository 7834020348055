import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import React, { FC, useCallback, useMemo } from 'react'
import Select from '@/components/atoms/newComponents/Select'
import { colors } from '@/theme/colors'
import { observer } from 'mobx-react-lite'
import ArrowLeft from '@/components/Icons/ArrowLeft'
import ArrowRight from '@/components/Icons/ArrowRight'
import { motion, motion as m } from 'framer-motion'
import { PaginationPageProps } from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import {
  TABLE_QUERIES_DEFAULT_CURRENT_PAGE,
  TABLE_QUERIES_DEFAULT_PER_PAGE,
} from '@/components/moleculs/Table/help/Pagination/TABLE_QUERIES_DEFAULTT_CONFIG'
import { unionArrays } from '@/constants/unionArrays'

function getPageCount(total = 0, perPage: number): number {
  if (total === 0) return 1
  return Math.ceil(total / perPage)
}

const Pagination: FC<{
  pageSettings: PaginationPageProps
  isFetching: boolean | undefined
}> = ({
  isFetching,
  pageSettings: {
    setCurrentPage,
    setPerPage,
    perPage = TABLE_QUERIES_DEFAULT_PER_PAGE,
    currentPage = TABLE_QUERIES_DEFAULT_CURRENT_PAGE,
    count = 0,
    totalPages = 0,
  },
}) => {
  const perPageSelect = useMemo(() => {
    return unionArrays.paginationPerPage
  }, [])

  const pageSwitcherOptions = useMemo(() => {
    if (currentPage && perPage) {
      return new Array(getPageCount(count, perPage))
        .fill('')
        .map((_, i) => i + 1)
    }
    return []
  }, [count, currentPage, perPage])

  const onPageChangeHandler = useCallback(
    (direct: 'next' | 'prev') => {
      if (direct === 'next' && currentPage + 1 <= totalPages) {
        setCurrentPage(currentPage + 1 || 1)
      }

      if (direct === 'prev' && currentPage - 1 !== 0) {
        setCurrentPage(currentPage - 1)
      }
    },
    [currentPage, setCurrentPage, totalPages],
  )

  return (
    <Flex
      className="pagination"
      pt="4"
      pr="6"
      bgColor="white"
      alignItems="center"
      justify="space-between"
    >
      <Grid alignItems="center" gridGap="4" gridAutoFlow="column">
        <Select<number>
          name="perPage"
          options={perPageSelect}
          selected={perPageSelect.find((num) => num === perPage)}
          handleChange={setPerPage}
        />
        <Text color={colors.neutral[500]} textStyle="text4">
          {' '}
          на странице
        </Text>
        <Text color={colors.neutral[500]} textStyle="text4">
          {' '}
          {(perPage || 0) > (count || 0) ? count : perPage} из{' '}
          <Box
            as={motion.span}
            opacity={isFetching ? 0.32 : 1}
            transition={'.12s ease-in'}
          >
            {count}
          </Box>
        </Text>
      </Grid>
      <Grid alignItems="center" gridGap="4" gridAutoFlow="column">
        <Select<number>
          name="currentPage"
          options={pageSwitcherOptions}
          selected={currentPage}
          handleChange={setCurrentPage}
        />
        <Text color={colors.neutral[500]} textStyle="text4">
          {' '}
          страница из{' '}
          <Box
            as={motion.span}
            opacity={isFetching ? 0.32 : 1}
            transition={'.12s ease-in'}
          >
            {totalPages}
          </Box>
        </Text>
        <Flex>
          <Box
            onClick={() => onPageChangeHandler('prev')}
            userSelect="none"
            cursor="pointer"
            pointerEvents={currentPage > 1 ? undefined : 'none'}
            opacity={currentPage > 1 ? undefined : '.2'}
            borderRadius="4px"
            _hover={{
              backgroundColor: colors.accent[100],
              transition: '0.3s ease',
            }}
            px="3"
            py="2"
            as={m.div}
            whileTap={{ scale: 0.9 }}
            flexShrink="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ArrowLeft />
          </Box>
          <Box
            onClick={() => onPageChangeHandler('next')}
            userSelect="none"
            cursor="pointer"
            pointerEvents={currentPage < totalPages ? undefined : 'none'}
            opacity={currentPage < totalPages ? undefined : '.2'}
            borderRadius="4px"
            _hover={{
              backgroundColor: colors.accent[100],
              transition: '0.3s ease',
            }}
            px="3"
            py="2"
            as={m.div}
            whileTap={{ scale: 0.9 }}
            flexShrink="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ArrowRight />
          </Box>
        </Flex>
      </Grid>
    </Flex>
  )
}

export default observer(Pagination)

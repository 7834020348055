import React, { useCallback } from 'react'
import { QFieldContextValueType, QFieldPropsType } from './QField.types'
import { QGroupContext } from '@/components/QForm/QGroup/QGroup.context'
import { QFieldContext } from '@/components/QForm/QField/QField.context'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import { FastField, getIn } from 'formik'
import { QString } from '@/components/QForm/QField/QInput/QString/QString'
import { FastFieldProps } from 'formik/dist/FastField'
import { QNumber } from '@/components/QForm/QField/QInput/QNumber/QNumber'
import { QMoney } from '@/components/QForm/QField/QInput/QMoney/QMoney'
import { QUnit } from '@/components/QForm/QField/QInput/QUnit/QUnit'
import { QSelect } from '@/components/QForm/QField/QSelect/QSelect'
import { QSelectAsync } from '@/components/QForm/QField/QSelectAsync/QSelectAsync'
import { QDate } from '@/components/QForm/QField/QInput/QDate/QDate'
import { QTime } from '@/components/QForm/QField/QInput/QTime/QTime'
import { QText } from '@/components/QForm/QField/QInput/QText/QText'
import isEqual from 'lodash/isEqual'
import { QStringSuggestionsAsync } from '@/components/QForm/QField/QInput/QStringSuggestionsAsyc/QStringSuggestionsAsync'
import { QPhone } from '@/components/QForm/QField/QInput/QPhone/QPhone'
import { QSwitch } from '@/components/QForm/QField/QSwitch/QSwitch'
import * as yup from 'yup'
import { ObjectSchema } from 'yup'
import { QFieldWrapper } from '@/components/QForm/QField/QFieldWrapper'
import { QFile } from '@/components/QForm/QField/QInput/QFile/QFile'
import { QSearch } from '@/components/QForm/QField/QInput/QSearch/QSearch'

type Props = {
  name: string
  dependFrom: unknown
  formik: {
    values: unknown
  }
}

const isolatedReach = (
  yupSchema: ObjectSchema<any> | undefined,
  path: string,
  value: any,
  context: any,
) => {
  if (!yupSchema) return undefined
  try {
    return yup.reach(yupSchema, path, value, context)
  } catch (err) {
    console.error('yupSchema not reached', path, err)
    return undefined
  }
}
export const QFieldFunction = <ValueType,>(
  props: QFieldPropsType<ValueType>,
) => {
  const {
    name,
    isHardName,
    children,
    onChangeValue: propsOnChangeValue,
    dependFrom,
    withWrapper,
  } = props
  const {
    formik,
    validationSchema,
    validationContextValue,
    validationContextValueFunction,
  } = useQFormContext()

  const { setFieldValue } = formik
  const group = React.useContext(QGroupContext)
  const resultName =
    isHardName || !group.hasProvider ? name
    : name ? [group.name, name].join('.')
    : group.name
  const value = getIn(formik.values, resultName)
  const reachedYup = isolatedReach(validationSchema, resultName, value, {
    values: formik.values,
    validationContextValue:
      validationContextValueFunction ?
        validationContextValueFunction(formik.values)
      : validationContextValue,
  })
  const describedYup =
    reachedYup &&
    reachedYup.describe({
      context: {
        values: formik.values,
        validationContextValue:
          validationContextValueFunction ?
            validationContextValueFunction(formik.values)
          : validationContextValue,
      },
    })

  const isOptional = Boolean(
    describedYup && 'optional' in describedYup && describedYup.optional,
  )
  const isDisabled = Boolean(
    describedYup &&
      'meta' in describedYup &&
      describedYup.meta &&
      'isDisabled' in describedYup.meta &&
      describedYup.meta?.isDisabled,
  )
  const tooltipText =
    (describedYup &&
      'meta' in describedYup &&
      describedYup.meta &&
      'tooltipText' in describedYup.meta &&
      describedYup.meta?.tooltipText) ||
    undefined

  const isRequired = reachedYup && !isOptional
  const yupLabel = describedYup && 'label' in describedYup && describedYup.label
  const placeholder =
    String(
      (describedYup &&
        'meta' in describedYup &&
        describedYup.meta &&
        'placeholder' in describedYup.meta &&
        describedYup.meta?.placeholder) ||
        '',
    ) || undefined
  const mask =
    String(
      (describedYup &&
        'meta' in describedYup &&
        describedYup.meta &&
        'mask' in describedYup.meta &&
        describedYup.meta?.mask) ||
        '',
    ) || undefined

  const onChangeValue = useCallback(
    (v: ValueType) => {
      setFieldValue(resultName, v).then()
      propsOnChangeValue && propsOnChangeValue(v)
    },
    [propsOnChangeValue, resultName, setFieldValue],
  )

  const isNotPresentInYupScheme = Boolean(validationSchema && !reachedYup)
  const contextValue: Omit<
    QFieldContextValueType<ValueType>,
    'field' | 'meta'
  > = {
    groupName: group.hasProvider ? group.name : undefined,
    groupValue: group.hasProvider ? group.value : undefined,
    onChangeValue,
    name: resultName,
    value,
    isNotPresentInYupScheme,
    propsFromYup: {
      isRequired,
      isDisabled,
      placeholder,
      mask,
      tooltipText,
    },
  }

  return (
    <>
      <FastField
        name={resultName}
        dependFrom={{
          ...dependFrom,
          ...contextValue.propsFromYup,
          ...contextValue,
        }}
        shouldUpdate={(nextProps: Props, props: Props) => {
          return (
            !isEqual(props, nextProps) ||
            !isEqual(props.dependFrom, nextProps.dependFrom)
          )
        }}
      >
        {({ field, meta }: FastFieldProps<ValueType>) => {
          return (
            <QFieldContext.Provider
              value={{
                ...contextValue,
                field,
                meta,
              }}
            >
              <QFieldWrapper
                {...{
                  tooltipText,
                  resultName,
                  withWrapper,
                  yupLabel,
                  isRequired,
                  errorText: meta.error,
                  isDisabled,
                  isNotPresentInYupScheme,
                }}
              >
                {typeof children === 'function' ?
                  children({
                    ...contextValue,
                    field,
                    meta,
                  })
                : children}
              </QFieldWrapper>
            </QFieldContext.Provider>
          )
        }}
      </FastField>
    </>
  )
}

export class QField<ValueType> extends React.Component<
  QFieldPropsType<ValueType>,
  never
> {
  static String = QString
  static Search = QSearch
  static StringSuggestionsAsync = QStringSuggestionsAsync
  static Money = QMoney
  static Unit = QUnit
  static Number = QNumber
  static Phone = QPhone
  static Date = QDate
  static Time = QTime
  static Text = QText
  static Select = QSelect
  static SelectAsync = QSelectAsync
  static Switch = QSwitch
  static File = QFile

  render() {
    return (
      <QFieldFunction {...this.props}>{this.props.children}</QFieldFunction>
    )
  }
}

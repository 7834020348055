import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_204_15248)">
        <path
          d="M12.667 3.333h-1.4A3.34 3.34 0 008 .666H3.333A3.337 3.337 0 000 3.999v6a2.667 2.667 0 002.04 2.585 2.333 2.333 0 104.6.082h2.722a2.31 2.31 0 00-.027.333A2.333 2.333 0 0014 13c0-.14-.014-.278-.04-.415A2.666 2.666 0 0016 9.999V6.666a3.338 3.338 0 00-3.333-3.333zm2 3.333v.667h-3.334V4.666h1.334a2 2 0 012 2zM1.333 9.999V4a2 2 0 012-2H8a2 2 0 012 2v7.334H2.667a1.334 1.334 0 01-1.334-1.334zm4 3a1 1 0 01-2 0c0-.114.022-.226.062-.333h1.876c.04.107.062.22.062.333zm6.334 1a1 1 0 01-1-1 .94.94 0 01.062-.333h1.876a.94.94 0 01.062.333 1 1 0 01-1 1zm1.666-2.666h-2V8.666h3.334v1.333a1.333 1.333 0 01-1.334 1.334z"
          fill="#475467"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_204_15248">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

import React from 'react'
import { QFormPropsType } from '@/components/QForm/QForm.types'
import { FormikValues } from 'formik'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import { PrintR } from '@/components/PrintR/PrintR'
import { QDateWithTimeRange } from '@/components/QForm/QFormTemplates/DateWithTimeRange/QDateWithTimeRange'
import { QField } from '@/components/QForm/QField/QField'
import { QErrors } from '@/components/QForm/QErrors/QErrors'
import { QFormFunction } from '@/components/QForm/QFormFunction'
import { QFormResponseErrors } from '@/components/QForm/QFormResponseErrors'
import { QFormSubmitButton } from '@/components/QForm/QButtons/QFormSubmitButton'
import { ButtonProps } from '@chakra-ui/react'
import { QFormSubmitFiltersButton } from '@/components/QForm/QButtons/QFormSubmitFiltersButton'

const DebugValues = () => {
  const {
    formik: { values },
  } = useQFormContext()
  return <PrintR object={values} isDefaultCollapsedTree={false} />
}

class Templates {
  static DateWithTimeRange = QDateWithTimeRange
}

class Submit extends React.Component<ButtonProps, any> {
  static Filters = QFormSubmitFiltersButton

  render() {
    return <QFormSubmitButton {...this.props} />
  }
}

class Buttons extends React.Component<ButtonProps, any> {
  static Submit = Submit

  render() {
    return <Buttons.Submit {...this.props} />
  }
}

export class QForm<ValuesType extends FormikValues> extends React.Component<
  QFormPropsType<ValuesType>,
  {
    resetCount: number
  }
> {
  static Button = Buttons
  static DebugValues = DebugValues
  static Errors = QErrors
  static ResponseErrors = QFormResponseErrors
  static Templates = Templates
  static Field = QField

  state = {
    resetCount: 0,
  }

  resetForm = () => {
    this.setState((data) => {
      return {
        ...data,
        resetCount: data.resetCount + 1,
      }
    })
  }

  render() {
    const {
      props: { children },
    } = this

    return (
      <QFormFunction
        key={this.state.resetCount}
        {...this.props}
        onResetForm={this.resetForm}
      >
        {children}
      </QFormFunction>
    )
  }
}

import * as React from 'react'
import { IconProps, Icon } from '@chakra-ui/react'
import { colors } from '../../theme/colors'

function _Icon(props: IconProps) {
  return (
    <Icon
      width="16px"
      height="16px"
      fill="none"
      color={colors.neutral[1000]}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#prefix__clip0_343_16715)">
        <path
          d="M9.333 16a.667.667 0 01-.4-.133l-2.667-2A.667.667 0 016 13.333V9.587L1.322 4.325A2.6 2.6 0 013.266 0h9.467a2.6 2.6 0 011.942 4.325L10 9.587v5.746a.667.667 0 01-.667.667zm-2-3l1.333 1V9.333c0-.163.06-.32.169-.442l4.845-5.452a1.267 1.267 0 00-.947-2.106H3.266A1.267 1.267 0 002.32 3.44L7.165 8.89c.108.122.168.28.168.442V13z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_343_16715">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default _Icon

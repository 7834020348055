import { QFormSubmitErrorsReturn } from '@/components/QForm/QForm.types'
import isArray from 'lodash/isArray'
import { isString } from 'lodash'
import { RTKMutationPhpResult, RTKQUseQueryResult } from '@/types/rtkq.types'
import { AxiosError, AxiosResponse } from 'axios'
import { ApiResponseErrorsType } from '@/types/api.types'
import { FetchBaseQueryError } from '@reduxjs/toolkit/src/query/fetchBaseQuery'

const codeToText = {
  ERR_NETWORK: 'Ошибка сетевого подключения',
}

const serverErrorTitle = 'Ошибка на стороне сервера'
const errorStatuses = [401, 403, 404, 400, 501]

function getErrorByStatus(
  status: number | undefined,
  code?: string,
  message?: string,
) {
  if (status === 500) {
    return {
      message: serverErrorTitle,
      errors: {
        'Необработанная ошибка': 'Текст ошибки отсутствует',
      },
    }
  } else if (!status) {
    return {
      message: 'Необработанная ошибка',
      errors: {
        Ошибка:
          codeToText[code as keyof typeof codeToText] ||
          message ||
          'неизвестно',
      },
    }
  } else if (!errorStatuses.includes(status)) {
    return undefined
  }
}

async function getEgoErrors(data: any) {
  if (data?.type === 'application/json') {
    const t = JSON.parse(await data.text())
    if (!t.errors) {
      return undefined
    }
    return t as QFormSubmitErrorsReturn
  } else {
    if (data?.errors) {
      return data
    }
    if (data?.error) {
      if (isArray(data.error)) {
        const d = data.error as { message: string }[]
        const ers = d
          .map((d) => d.message)
          .reduce((accum, currentValue, currentIndex) => {
            return {
              ...accum,
              [`Ошибка_${currentIndex + 1}`]: currentValue,
            }
          }, {})
        return {
          message: serverErrorTitle,
          errors: ers,
        }
      } else if (isString(data.error)) {
        return {
          message: serverErrorTitle,
          errors: {
            Ошибка: data.error,
          },
        }
      }
    }
  }
}

export type ConsumedErrorsType =
  | RTKMutationPhpResult<any>
  | RTKQUseQueryResult<any, any>
  | AxiosResponse<any, any>
  | AxiosError<any, any>
  | FetchBaseQueryError
export const qFormCollectErrors = async (
  result: ConsumedErrorsType,
): Promise<ApiResponseErrorsType | undefined> => {
  if ('error' in result) {
    const error = result.error
    if (error && typeof error !== 'string' && 'response' in error) {
      const response = error.response
      const data = response?.data
      const errorByStatus = getErrorByStatus(
        response?.status,
        error?.code,
        error?.message,
      )
      if (errorByStatus) {
        return errorByStatus
      }
      const egoErrors = await getEgoErrors(response?.data)

      if (egoErrors) {
        return egoErrors
      }

      return data as ApiResponseErrorsType
    }
  }
}

import styled, { css } from 'styled-components'
import { colors } from '@/theme/colors'

export type LocationTypeSelectButtonPropsType = {
  $isActive?: boolean
  $isDisabled?: boolean
}
export const UniversalLocationTypeSelectButton = styled.button<LocationTypeSelectButtonPropsType>`
  border: 1px solid ${colors.neutral['300']};

  color: ${colors.neutral['500']};
  background: ${colors.accent['200']};

  ${({ $isActive }) =>
    $isActive ?
      css`
        color: ${colors.neutral['900']};
        background: ${colors.neutral['100']};
      `
    : ''}
  &:hover {
    color: ${colors.neutral['900']};
  }

  ${({ $isDisabled }) =>
    $isDisabled ?
      css`
        pointer-events: none;
        opacity: 0.22;
      `
    : ''};
  cursor: ${({ $isActive }) => ($isActive ? 'default' : 'pointer')};
  padding: 2px 6px 2px 6px;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
`

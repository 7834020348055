import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_204_15334)">
        <path
          d="M12.667 2H8.315a.68.68 0 01-.298-.066L5.913.878a2.01 2.01 0 00-.894-.211H3.333A3.337 3.337 0 000 4v8a3.337 3.337 0 003.333 3.334h9.334A3.337 3.337 0 0016 12V5.334A3.337 3.337 0 0012.667 2zM3.333 2H5.02a.68.68 0 01.298.067L7.42 3.12c.277.14.583.212.894.214h4.352a2 2 0 011.852 1.254l-13.186.075V4a2 2 0 012-2zm9.334 12H3.333a2 2 0 01-2-2V5.996l13.334-.075v6.08a2 2 0 01-2 2z"
          fill="#475467"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_204_15334">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.068 8.474a.667.667 0 010-.947l3.06-3.053a.666.666 0 00-.47-1.141.667.667 0 00-.47.194l-3.06 3.06a2 2 0 000 2.827l3.06 3.06a.667.667 0 00.94-.947l-3.06-3.053z"
        fill="#1188D9"
      />
    </svg>
  )
}

export default Icon

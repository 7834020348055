import React from 'react'
import { QDateInner } from '@/components/QForm/QField/QInput/QDate/QDateInner'
import { DatePickerProps } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'

export const QDate = (
  props: DatePickerProps & {
    placeholder?: string
  } & Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, withWrapper, isHardName, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QDateInner {...otherProps} />
    </QForm.Field>
  )
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Input from '@/components/atoms/Input/Input'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import {
  Box,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Kbd,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'
import Search from '@/components/Icons/Search'
import CrossInCircle from '@/components/Icons/CrossInCircle'
import omit from 'lodash/omit'
import Link from '@/components/Link'
import { useNavigate } from 'react-router'
import { QSearchPropsType } from '@/components/QForm/QField/QInput/QSearch/QSearch.types'
import styled from 'styled-components'
import { isId, isShipmentShortId } from '@/extendYup'
import { colors } from '@/theme/colors'

const NoBr = styled.div`
  white-space: nowrap;
`
type Props = QFieldCorePopsType & InputProps & QSearchPropsType
export const QSearchCore = (props: Props) => {
  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined
  const propsValue = props.value
  const innerRef = useRef<NodeJS.Timeout>()
  const [innerValue, setInnerValue] = useState(propsValue || '')
  const [isFocused, setIsFocused] = useState(false)
  const trimmedValue = useMemo(() => {
    return innerValue.trim()
  }, [innerValue])
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || '')
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const propsOnChange = props.onChange
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setInnerValue(v)
      if (onChangeValue || propsOnChange) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(v)
          propsOnChange && propsOnChange(e)
        }, 300)
      }
    },
    [onChangeValue, propsOnChange],
  )

  const setValue = props.helpers?.setValue
  const onClear = useCallback(() => {
    setValue && setValue('')
    clearTimeout(innerRef.current)
    clearTimeout(changeRef.current)
    setInnerValue('')
    if (onChangeValue || propsOnChange) {
      changeRef.current = setTimeout(() => {
        onChangeValue && onChangeValue('')
      }, 300)
    }
  }, [onChangeValue, propsOnChange, setValue])

  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      setIsFocused(false)
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)

      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [propsOnFocus],
  )

  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      clearInterval(innerRef.current)
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])

  const hasIdPattern = useMemo(() => {
    return isShipmentShortId(trimmedValue) || isId(trimmedValue)
  }, [trimmedValue])
  const isActiveSearchIcon = Boolean(isFocused || innerValue)

  const iShowPopover =
    Boolean(props.getShortIdLink) && isFocused && hasIdPattern
  return (
    <Popover placement={'bottom-start'} isOpen={iShowPopover} autoFocus={false}>
      <PopoverTrigger>
        <InputGroup
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (props.getShortIdLink && hasIdPattern) {
                const link = props.getShortIdLink(trimmedValue)
                if (e.ctrlKey) {
                  const w = window?.open(link, `tab_${trimmedValue}`)
                  w?.focus && w.focus()
                } else {
                  navigate(link)
                }
              }
            }
          }}
        >
          <InputLeftElement
            pointerEvents="none"
            children={
              <Box
                style={{
                  transform: `scale(${isFocused ? 1.142 : 1})`,
                  transition: '.2s ease-out',
                }}
              >
                <Search
                  fill={
                    colors[isActiveSearchIcon ? 'accent' : 'neutral'][
                      isActiveSearchIcon ? 600 : 500
                    ]
                  }
                />
              </Box>
            }
          />
          <Input
            data-type={'page-table-search'}
            pr={'2em'}
            autoComplete={'off'}
            placeholder="Поиск"
            pl="38px"
            {...{
              onChange,
              onFocus,
              onBlur,
              value: innerValue,
              ...(props.mask ?
                {
                  maskProps: {
                    mask: props.mask,
                  },
                }
              : {}),
              ...omit(props, [
                'getShortIdLink',
                'mask',
                'onChangeValue',
                'onChange',
                'onBlur',
                'onFocus',
                'value',
                'isRequired',
                'required',
                'tooltipText',
                'helpers',
              ]),
            }}
          />
          <InputRightElement
            title={'Очистить строку поиска'}
            onClick={onClear}
            children={
              <CrossInCircle
                opacity={innerValue ? 0.6 : 0}
                _hover={{
                  transform: 'scale(1.1f)',
                  opacity: 1,
                }}
                transition="0.2s ease"
                pointerEvents={innerValue ? undefined : 'none'}
                cursor="pointer"
                fill="#555"
              />
            }
          />
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>Введён идентификатор!</PopoverHeader>
        <PopoverBody>
          Нажмите клавишу <Kbd>Enter</Kbd> - чтобы{' '}
          <Link
            href={
              (props?.getShortIdLink && props?.getShortIdLink(trimmedValue)) ||
              'wtf'
            }
          >
            <NoBr>открыть в текущем окне</NoBr>
          </Link>{' '}
          либо <Kbd>Ctrl</Kbd>+<Kbd>Enter</Kbd> для открытия{' '}
          <Link
            target={'_blank'}
            href={
              (props?.getShortIdLink && props?.getShortIdLink(trimmedValue)) ||
              'wtf'
            }
          >
            <NoBr>в новом окне</NoBr>
          </Link>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

import { Grid, Stack, Tooltip } from '@chakra-ui/react'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal'
import React, { FC } from 'react'
import { QForm } from '@/components/QForm/QForm'
import { LocationModalFormPropsType } from '@/components/QForm/QField/QSelectAsync/Goods/QSelectAsync.types'
import { goodGoodRequestYup } from '@/components/QForm/QField/QSelectAsync/Goods/Modal/QSelectAsyncGoodsNewGood.yup'
import { QSelectAsyncEndpoints } from '@/components/QForm/QField/QSelectAsync/Goods/QSelectAsync.endpoints'
import { QGroup } from '@/components/QForm/QGroup/QGroup'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import { notificationsStore } from '@/stores/global.store'

export const QSelectAsyncGoodsNewGoodModal: FC<LocationModalFormPropsType> = ({
  modalProps,
}) => {
  const [createGood, { isLoading }] = QSelectAsyncEndpoints.create.useMutation()
  const modalData = modalProps.getData()

  return (
    <QForm
      validationSchema={goodGoodRequestYup}
      initialValues={modalData?.goods}
      onSubmit={async (good) => {
        const response = await createGood(good)
        const errors = await qFormCollectErrors(response)
        if (!errors) {
          if ('data' in response && response.data) {
            if (modalData?.afterSave) {
              modalData.afterSave(response.data)
            }
          }
          notificationsStore.pushSuccess({
            message: 'Товар создан',
          })
          modalProps.closeModal()
        }
        return errors
      }}
    >
      {({ formik }) => {
        return (
          <Modal
            {...modalProps}
            header="Новый товар"
            buttons={
              <Grid gridAutoFlow="column" gridGap="4">
                <Button
                  onClick={() => modalProps.closeModal()}
                  size="l"
                  variant="secondary"
                >
                  Отменить
                </Button>
                <Tooltip
                  label={
                    Object.values(formik.errors).length > 0 && <QForm.Errors />
                  }
                >
                  <div>
                    <Button
                      {...{ isLoading }}
                      size="l"
                      onClick={() => {
                        formik.handleSubmit()
                      }}
                      variant={formik.isValid ? 'accent' : 'disabled'}
                    >
                      Сохранить
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            }
          >
            <QGroup name={'goodStoreRequest'}>
              <Stack spacing={'8px'}>
                <QForm.Field.String name={'vendorCode'} withWrapper />
                <QForm.Field.String name={'name'} withWrapper />
                <QForm.Field.String name={'externalId'} withWrapper />
                <Stack spacing={'16px'}>
                  <QForm.Field.Money name={'price'} withWrapper />
                  <QForm.Field.Select.VatRate name={'vatRate'} withWrapper />
                </Stack>
              </Stack>
            </QGroup>
          </Modal>
        )
      }}
    </QForm>
  )
}

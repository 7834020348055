import React, { DetailedHTMLProps, FC, LegacyRef } from 'react'
import { forwardRef, Input, InputProps } from '@chakra-ui/react'
import InputMask, { Props as MaskProps } from 'react-input-mask'

type DatePickerInputProps = DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { isInvalid?: boolean }

interface Props extends InputProps {
  // variant?:  'disabled' | 'outline'
  datePikerInputProps?: DatePickerInputProps
  ref?: LegacyRef<HTMLInputElement>
  maskProps?: MaskProps
}

const _Input: FC<Props> = forwardRef(
  (
    { variant = 'outline', datePikerInputProps, maskProps, children, ...props },
    ref,
  ) => {
    return (
      // @ts-ignore
      <Input
        {...(maskProps ?
          {
            ...maskProps,
            as: InputMask as React.ElementType,
          }
        : {})}
        ref={ref}
        {...(datePikerInputProps || props)}
        size="md"
        variant={variant}
      />
    )
  },
)

export default _Input

import React from 'react'
import { InputProps } from '@chakra-ui/react'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'
import { QStringSuggestionsAsyncInner } from '@/components/QForm/QField/QInput/QStringSuggestionsAsyc/QStringSuggestionsAsyncInner'
import { InputSuggestionsProps } from '@/components/QForm/QField/QInput/QStringSuggestionsAsyc/QStringSuggestionsAsync.types'

export const QStringSuggestionsAsync = (
  props: InputProps &
    InputSuggestionsProps &
    Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        isHardName,
        withWrapper,
      }}
    >
      <QStringSuggestionsAsyncInner {...otherProps} />
    </QForm.Field>
  )
}

import React, { FC, useCallback, useState } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import { isPromise } from 'formik'

interface Props extends ButtonProps {
  variant:
    | 'accent'
    | 'disabled'
    | 'secondary'
    | 'err'
    | 'red'
    | 'text'
    | 'text-button'
}

const _Button: FC<Props> = ({ children, onClick, ...props }) => {
  const [innerIsLoading, setInnerIsLoading] = useState(false)
  const innerOnClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        const clickResult = onClick(e)
        if (isPromise(clickResult)) {
          setInnerIsLoading(true)

          clickResult.then(() => {
            setInnerIsLoading(false)
          })
        }
      }
    },
    [onClick],
  )

  return (
    <Button
      type={'button'}
      {...{ ...props }}
      onClick={innerOnClick}
      isLoading={props.isLoading || innerIsLoading}
      variant={props.disabled ? 'disabled' : props.variant}
    >
      {children}
    </Button>
  )
}

export default _Button

import { Box, Grid, Stack, Tooltip } from '@chakra-ui/react'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal'
import React, { FC, useCallback } from 'react'
import { QForm } from '@/components/QForm/QForm'
import { QGroup } from '@/components/QForm/QGroup/QGroup'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import {
  LocationModalFormPropsType,
  LocationModalFormTypes,
} from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModalForm.types'

type Props = {
  isLoading: boolean
} & Pick<LocationModalFormPropsType, 'modalProps'>
export const LocationModalInner: FC<Props> = ({ modalProps, isLoading }) => {
  const { formik } =
    useQFormContext<LocationModalFormTypes['ConsumedRequest']>()

  const propsCloseModal = modalProps.closeModal
  const closeModal = useCallback(() => {
    propsCloseModal()
  }, [propsCloseModal])
  const modalData = modalProps.getData()

  return (
    <Modal
      {...modalProps}
      header={
        <Box
          color={
            formik.values.locationStoreRequest.fullName ?
              undefined
            : 'neutral.500'
          }
        >
          {
            //@ts-ignore
            modalData?.location?.id ?
              formik.values.locationStoreRequest.fullName || 'Без названия'
            : 'Новый адрес'
          }
        </Box>
      }
      buttons={
        <Grid gridAutoFlow="column" gridGap="4">
          <Button onClick={closeModal} size="l" variant="secondary">
            Отменить
          </Button>
          <Tooltip
            label={
              Object.values(formik.errors).length > 0 && (
                <QForm.Errors
                  filterFn={(item) => item.path !== 'address.unrestrictedValue'}
                />
              )
            }
          >
            <div>
              <Button
                {...{ isLoading }}
                size="l"
                onClick={() => {
                  formik.handleSubmit()
                }}
                variant={formik.isValid ? 'accent' : 'disabled'}
              >
                {
                  //@ts-ignore
                  modalData?.location?.id ? 'Сохранить' : 'Создать'
                }
              </Button>
            </div>
          </Tooltip>
        </Grid>
      }
    >
      <QGroup name={'locationStoreRequest'}>
        <Box pb={'16px'}>
          <QForm.Field.String withWrapper name={'fullName'} />
        </Box>
        <Grid gridTemplateColumns={'1fr 100px'} gridGap={'16px'} mb={'16px'}>
          <QForm.Field.StringSuggestionsAsync
            withWrapper
            name={'addressString'}
          />
          <QForm.Field.Number withWrapper name={'floor'} />
        </Grid>
        <Grid templateColumns={'300px 416px'} gridGap={'24px'}>
          <Stack spacing={'16px'}>
            <QForm.Field.Phone withWrapper name={'phoneNumber'} />
            <QForm.Field.Phone withWrapper name={'additionalPhoneNumber'} />
            <QForm.Field.Text withWrapper name={'comment'} />
          </Stack>
        </Grid>
      </QGroup>
      <QForm.ResponseErrors />
    </Modal>
  )
}

import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { partnerLegalFormOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { ClientLegalForm } from '@/_app/pages/partners/modals/partner/ClientModalForm.types'

type QSelectLegalFormPropsType = Omit<
  QSelectPropsType<IOption<ClientLegalForm>, ClientLegalForm>,
  'options'
>
export const QSelectPartnerLegalForm = (props: QSelectLegalFormPropsType) => {
  const [t] = useTranslation()
  const options = partnerLegalFormOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import {
  deliveryLocationTypeOptions,
  locationTypeOptions,
  pickupLocationTypeOptions,
} from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { LocationTypes } from '@/api/generated/qubyApiPhp'

type QSelectCargoTypePropsType = Omit<
  QSelectPropsType<IOption<LocationTypes>, LocationTypes>,
  'options'
> & { type?: 'pickup' | 'delivery' }
export const QSelectLocationType = (props: QSelectCargoTypePropsType) => {
  const { type } = props
  const [t] = useTranslation()
  const options = locationTypeOptions(t)
  const pickupOptions = pickupLocationTypeOptions(t)
  const deliveryOptions = deliveryLocationTypeOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options:
          type ?
            type === 'pickup' ?
              pickupOptions
            : deliveryOptions
          : options,
      }}
    />
  )
}

import React, { FC } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { Link, LinkProps } from '@chakra-ui/react'

const _Link: FC<LinkProps> = ({ children, href, ...props }) => {
  return (
    <Link {...props} as={LinkRouter} to={href || '/'}>
      {children}
    </Link>
  )
}

export default _Link

export const QLink = _Link

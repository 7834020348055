import { Box, Stack, StackItem, Text } from '@chakra-ui/react'
import React from 'react'
import { SelectAsyncRenderOptionFunctionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import { QSelectEndpointTypes } from './QSelectAsync.types'

export const locationsRenderOption: SelectAsyncRenderOptionFunctionType<
  QSelectEndpointTypes['ListItem']
> = ({ fullName, addressString }) => (
  <Box>
    <Stack spacing={'10px'}>
      <StackItem>
        <Text textStyle={'text4'}>{fullName}</Text>
      </StackItem>
      <StackItem>
        <Text textStyle={'caption'}>{addressString}</Text>
      </StackItem>
    </Stack>
  </Box>
)

import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QFileCore } from '@/components/QForm/QField/QInput/QFile/QFileCore'
import { InputProps } from '@chakra-ui/react'

export const QFileInner = (
  props: Omit<InputProps, 'value'> & { placeholder?: string },
) => {
  const { name, onChangeValue, propsFromYup } = useQFieldContext()
  const [field, meta] = useField(name)
  return (
    <ComponentPropsDecorator {...props}>
      <QFileCore
        {...{
          ...field,
          ...propsFromYup,
          onChangeValue,
          isInvalid: Boolean(meta.error),
        }}
      />
    </ComponentPropsDecorator>
  )
}

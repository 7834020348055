import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { QGroupListContext } from './QGroupList.context'
import { useDrop } from 'react-dnd'
import { QGroupDragItemType } from '@/components/QForm/QGroup/QGroup.constants'
import { QGroupListDragResultType } from '@/components/QForm/QGroupList/QGroupList.types'

/**
 * Список групп
 * --
 * @see https://formik.org/docs/api/fieldarray
 *
 *
 */
export const QGroupListDroppable: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { name, dropName, arrayHelpers } = useContext(QGroupListContext)
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: QGroupDragItemType + dropName,
      drop: (item, monitor): QGroupListDragResultType => {
        const i = item as { value: unknown; name: string }
        if (Boolean(monitor.isOver()) && name !== i?.name) {
          arrayHelpers?.push(i?.value)
          return {
            moveComplete: true,
          }
        }
        return {
          moveComplete: false,
        }
      },
      collect: (monitor) => {
        const item = (monitor.getItem() as { name: string }) || {}
        return {
          isOver: Boolean(monitor.isOver()) && name !== item?.name,
        }
      },
    }),
    [dropName, name],
  )

  return (
    <div
      ref={drop}
      style={{
        transition: '.1s ease-out',
        outline:
          isOver ? '5px dotted var(--400, rgba(152, 162, 179, 0.30))' : 'none',
        opacity: isOver ? '0.5' : '1',
      }}
    >
      {children}
    </div>
  )
}

import {
  CreateInternalRouteApiArg,
  GetInternalRunRequestsListApiArg,
  qubyApiPhp,
  RouteStoreRequest,
  RouteUpdateRequest,
  RunRequestResource,
} from '@/api/generated/qubyApiPhp'
import { useTableQuerySettings } from '@/hooks/useTableQuerySettings'
import { useClientContext } from '@/providers/Client/useClientContext'
import React, { useEffect, useState } from 'react'
import { dateToString } from '@/utils/dateHelperFns'
import { BaseTablePageContext } from '@/components/BaseTablePage/BaseTablePage.context'
import { useFilter } from '@/hooks/useFilter'
import PageContent from '@/components/PageContent'
import { PlanningRoutesPageHeader } from './components/PlanningRoutesPageHeader'
import { Flex, Text } from '@chakra-ui/react'
import { runRequestQueryArgsYupSchema } from '../runRequest/runRequestQueryArgsYupSchema'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { notificationsStore } from '@/stores/global.store'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import { RunRequestWraper } from './components/RunRequestWraper'
import { RoutesWrapper } from './components/RoutesWrapper'

export const routeStoreRequestSchema: yup.ObjectSchema<RouteStoreRequest> =
  yup.object({
    routeDate: yup.string().required().label('Дата'),
    driverId: yup.string().nullable().required().label('Водитель'),
    vehicleId: yup.string().nullable().required().label('Машина'),
    shiftTimeFrom: yup.string().label('Время начала смены'),
    shiftTimeTo: yup.string().label('Время конца смены'),
    cost: yup.number().optional().default(0).label('Стоимость'),
    runRequestIds: yup.array().of(yup.string().required()).required(),
    deliveryServiceId: yup
      .string()
      .nullable()
      .required()
      .label('Служба доставки'),
  })
export const routeUpdateRequestSchema: yup.ObjectSchema<RouteUpdateRequest> =
  yup.object({
    routeDate: yup.string().label('Дата'),
    driverId: yup.string().label('Водитель'),
    vehicleId: yup.string().label('Машина'),
    deliveryServiceId: yup.string().label('Служба доставки'),
    shiftTimeFrom: yup.string().label('Время начала смены'),
    shiftTimeTo: yup.string().label('Время конца смены'),
    cost: yup.number().default(0).label('Стоимость'),
    runRequestIds: yup.array().of(yup.string().required()).required(),
  })

export const createRouteRequestSchema: yup.ObjectSchema<CreateInternalRouteApiArg> =
  yup.object({
    routeStoreRequest: routeStoreRequestSchema.required(),
  })

export type RouteInitialState = {
  driverId: string
  vehicleId: string
  shiftTimeFrom: string
  shiftTimeTo: string
  cost?: number
  runRequests: RunRequestResource[]
}

const PlanningRoutesPage = () => {
  const { clientId } = useClientContext()
  const [selectedRunRequestIds, setSelectedIds] = useState<string[]>([])
  const localStorageKeyForSaving = `client--${clientId}--ListOfPlanningRoutesTable`
  //
  const {
    page,
    setCurrentPage,
    setPerPage,
    setFilters,
    setOrder,
    filters,
    order,
    searchText,
    onSearch,
    resetFilters,
    appliedFiltersCount,
    isActiveOtherFilterSettings,
  } = useTableQuerySettings<GetInternalRunRequestsListApiArg>({
    storageSettings: {
      localStorageKeyForSaving,
      yupSchema: runRequestQueryArgsYupSchema,
    },
  })
  const [date, setDate] = useState<string>(() => {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate())
    return dateToString(tomorrow)
  })
  const [runRequest, setRunRequest] = useState<RunRequestResource[]>([])
  const [disableButton, setDisableButton] = useState(false)
  const [createInitialState, setCreateInitialState] =
    useState<RouteInitialState>({
      driverId: '',
      vehicleId: '',
      shiftTimeFrom: '',
      shiftTimeTo: '',
      cost: 0,
      runRequests: [],
    })
  const [showCreateRoute, setShowCreateRoute] = useState(false)
  const [createInternalRoute] =
    qubyApiPhp.endpoints.createInternalRoute.useMutation()

  const query = qubyApiPhp.endpoints.getInternalRunRequestsList.useQuery({
    withoutRoutesAtDate: date,
    perPage: 100,
  })
  const queryRoutes = qubyApiPhp.endpoints.getInternalRoutesList.useQuery({
    routeDate: date,
  })

  const formik = useFormik<RouteStoreRequest>({
    // validationSchema: createRouteRequestSchema,

    initialValues: {
      routeDate: date,
      vehicleId: '',
      driverId: '',
      deliveryServiceId: '',
      shiftTimeFrom: '',
      shiftTimeTo: '',
      cost: 0,
      runRequestIds: [],
    },
    onSubmit: async (val) => {
      const result = await createInternalRoute({
        routeStoreRequest: { ...val, routeDate: date },
      })
      const errors = await qFormCollectErrors(result)
      if (!errors) {
        notificationsStore.pushSuccess({
          message: 'Успешно создано',
        })
        formik.setFieldValue('runRequestIds', [])
        setSelectedIds([])
        formik.initialValues = {
          routeDate: date,
          vehicleId: '',
          driverId: '',
          deliveryServiceId: '',
          shiftTimeFrom: '',
          shiftTimeTo: '',
          cost: 0,
          runRequestIds: [],
        }
        setShowCreateRoute(false)
        query?.refetch()
      }
      if (errors) {
        notificationsStore.pushError({
          message: errors?.message,
        })
      }

      return errors
    },
  })
  const { setFieldValue, handleSubmit, resetForm } = formik
  useEffect(() => {
    setRunRequest(query?.data?.data || [])
    setShowCreateRoute && setShowCreateRoute(false)
    setSelectedIds && setSelectedIds([])
    resetForm()
  }, [query?.data?.data, date])

  const filterAction = useFilter()
  const disableNewItem = showCreateRoute || !selectedRunRequestIds?.length

  return (
    <>
      <BaseTablePageContext.Provider
        value={{
          isHasProvider: true,
          page,
          setCurrentPage,
          setPerPage,
          setFilters,
          setOrder,
          filters,
          order,
          searchText,
          onSearch,
          query,
          resetFilters,
          appliedFiltersCount,
          isActiveOtherFilterSettings,
          resetFilterDefaults: {},
          filterAction,
        }}
      >
        <PageContent px="6" py="4">
          <Flex flexDir={'column'}>
            <PlanningRoutesPageHeader {...{ setDate, date }} />

            <RunRequestWraper
              {...{
                setSelectedIds,
                selectedRunRequestIds,
                showItems: query?.data?.data?.length !== 0,
                setShowCreateRoute,
                disableNewItem,
                setCreateInitialState,
                runRequest,
                setRunRequest,
                setFieldValue,
                showCreateRoute,
              }}
            />
            {!query?.data?.data?.length ?
              <Text alignSelf={'center'}>
                Нет свободных рейсов на выбранную дату
              </Text>
            : null}

            <Flex flexDir={'column'} gap={7}>
              {queryRoutes?.data?.data.length ?
                <Flex>
                  <Text textStyle="h4">Маршруты</Text>
                </Flex>
              : null}
              {showCreateRoute && selectedRunRequestIds?.length ?
                <Flex>
                  <RoutesWrapper
                    {...{
                      routeInitialState: createInitialState,
                      queryRoutesIsLoading: queryRoutes?.isLoading,
                      queryRoutesIsFetching: queryRoutes?.isFetching,
                      runRequestRefetch: query?.refetch,
                      type: 'create',
                      setFieldValue,
                      handleSubmit,
                      setShowCreateRoute,
                      setSelectedIds,
                      setRunRequest,
                      resetForm,
                      disableButton,
                      setDisableButton,
                    }}
                  />
                </Flex>
              : null}
              {queryRoutes?.data?.data?.map((el) => {
                return (
                  <Flex key={el?.id}>
                    <RoutesWrapper
                      {...{
                        route: el,
                        queryRoutesIsLoading: queryRoutes?.isLoading,
                        queryRoutesIsFetching: queryRoutes?.isFetching,
                        runRequestRefetch: query?.refetch,
                        routesRefetch: queryRoutes?.refetch,
                        type: 'update',
                        setFieldValue,
                        disableButton,
                        setDisableButton,
                        draggable: true,
                        withGetMenu: true,
                      }}
                    />
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </PageContent>
      </BaseTablePageContext.Provider>
    </>
  )
}
export default PlanningRoutesPage

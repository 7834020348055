import React from 'react'
import { QSelectAsyncInner } from '@/components/QForm/QField/QSelectAsync/QSelectAsyncInner'
import { QSelectAsyncPropsType } from '@/components/QForm/QField/QSelectAsync/QselectAsync.types'
import { SelectAsyncPropsListOptionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import { QSelectAsyncPartnerWarehouses } from '@/components/QForm/QField/QSelectAsync/_DeprecatedPartnerWarehouses/QSelectAsyncPartnerWarehouses'
import { QSelectAsyncMarketplaceWarehouses } from '@/components/QForm/QField/QSelectAsync/_DeprecatedMarketPlaceWarehouses/QSelectAsyncMarketplaceWarehouses'
import { QForm } from '../..'
import { QSelectAsyncPartner } from '@/components/QForm/QField/QSelectAsync/_DeprecatedPartner/QSelectAsyncPartner'
import { QSelectAsyncUniversalLocations } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/QSelectAsyncUniversalLocations'
import {
  ApiAllGetByIdEndpointsType,
  ApiAllGetListEndpointsType,
  ApiEndpointResultType,
} from '@/types/api.types'
import { QSelectAsyncClient } from '@/components/QForm/QField/QSelectAsync/Client/QSelectAsyncClient'
import { QSelectAsyncGoods } from '@/components/QForm/QField/QSelectAsync/Goods/QSelectAsyncGoods'
import { QSelectAsyncDeliveryService } from '@/components/QForm/QField/QSelectAsync/DeliveryService/QSelectAsyncDeliveryService'
import { QSelectAsyncLocations } from '@/components/QForm/QField/QSelectAsync/Locations/QSelectAsyncLocations'
import { QSelectAsyncShipment } from '@/components/QForm/QField/QSelectAsync/Shipment/QSelectAsyncShipment'
import { QSelectAsyncGroup } from '@/components/QForm/QField/QSelectAsync/QSelectAsyncGroup'
import { QSelectAsyncDriver } from './Driver/QSelectAsyncDriver'
import { QSelectAsyncVehicle } from './Vehicle/QSelectAsyncVehicle'
import { QSelectAsyncRunRequest } from './RunRequest/QSelectAsyncRunRequest'
import { QSelectAsyncRunRequestReturn } from './RunRequest/QSelectAsyncRunRequestReturn'

export const QSelectAsyncFunction = <
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = ApiEndpointResultType<SingleQueryEndpoint>,
>(
  props: QSelectAsyncPropsType<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
) => {
  const { name, withWrapper, isHardName, isMultiple, ...otherProps } = props

  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      {isMultiple ?
        <QSelectAsyncGroup<
          SingleQueryEndpoint,
          ListQueryEndpoint,
          ListOptionType,
          SelectedOptionType
        >
          {...{ name, ...otherProps }}
        />
      : <QSelectAsyncInner<
          SingleQueryEndpoint,
          ListQueryEndpoint,
          ListOptionType,
          SelectedOptionType
        >
          {...otherProps}
          // @ts-ignore
          name={name}
        />
      }
    </QForm.Field>
  )
}

export class QSelectAsync<
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = SingleQueryEndpoint['Types']['ResultType'],
> extends React.Component<
  QSelectAsyncPropsType<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
  unknown
> {
  static DeprecatedMarketplaceWarehouses = QSelectAsyncMarketplaceWarehouses
  static DeprecatedPartner = QSelectAsyncPartner
  static DeprecatedPartnerWarehouses = QSelectAsyncPartnerWarehouses
  static Client = QSelectAsyncClient
  static Driver = QSelectAsyncDriver
  static Vehicle = QSelectAsyncVehicle
  static RunRequestForShipments = QSelectAsyncRunRequest
  static RunRequestReturnForShipments = QSelectAsyncRunRequestReturn
  static UniversalLocations = QSelectAsyncUniversalLocations
  static Location = QSelectAsyncLocations
  static Goods = QSelectAsyncGoods
  static DeliveryService = QSelectAsyncDeliveryService
  static Shipment = QSelectAsyncShipment

  render() {
    return <QSelectAsyncFunction {...this.props} />
  }
}

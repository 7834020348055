import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.268 6.587l-3.06-3.06a.667.667 0 10-.94.947l3.066 3.053a.667.667 0 010 .947l-3.066 3.053a.667.667 0 10.94.947l3.06-3.06a2 2 0 000-2.827z"
        fill="#1188D9"
      />
    </svg>
  )
}

export default Icon

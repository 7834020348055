import { ExportDeliveryListType } from '@/containers/ShipmentCard/shipmentCard.utills'
import { IOption } from '@/types/global'
import { TFunction } from 'i18next'
import {
  ArrivalMethod,
  CargoType,
  CargoVolume,
  ExcelReportTemplate,
  PackageType,
  ReceiverDestinationType,
  Role,
  RunRequestStatus,
  RunRequestType,
  SenderDestinationType,
  ShipmentStatus,
  TariffLevelLocalization,
  VatRate,
} from '@/api/generated/qubyApiJava'
import { unionArrays } from '@/constants/unionArrays'
import {
  ClientLegalForm,
  ClientStatus,
} from '@/_app/pages/partners/modals/partner/ClientModalForm.types'
import {
  CargoPackageTypes,
  InvoiceStatusEnum,
  LocationTypes,
  RunRequestStatusEnum,
  RunRequestTypesEnum,
  ShipmentTypesEnum,
} from '@/api/generated/qubyApiPhp'
import {
  DeliveryDlvEnum,
  DlvStatusEnum,
  MonitoringDlvStatus,
  MonitoringErrorTypeWithoutDefault,
  MonitoringStatus,
  MonitoringWarehouse,
  ShipmentsDlvStatusEnum,
} from '@/api/qubyApiEgoDlv'
import { ShipmentStatusNew } from '@/api/qubyApiEgo'

export const cargoPackageTypeOptions: (
  t: TFunction,
) => IOption<PackageType>[] = (t: TFunction) =>
  unionArrays.cargoPackageType
    .filter((type) => type !== 'UNKNOWN')
    .map((val) => ({
      value: val,
      label: t(`constants.cargoPackageType.${val}`),
    }))
export const cargoPackageTypePhpOptions: (
  t: TFunction,
) => IOption<CargoPackageTypes>[] = (t: TFunction) =>
  unionArrays.cargoPackageTypePhp.map((val) => ({
    value: val,
    label: t(`constants.cargoPackageType.${val}`),
  }))

export const RunRequestArrivalMethod: IOption<ArrivalMethod>[] = [
  {
    value: 'QUBY_CAR',
    label: 'Служба доставки',
  },
  {
    value: 'SELF_DELIVERY',
    label: 'Самопривоз',
  },
]
export const RunRequestCargoVolume: IOption<
  CargoVolume,
  {
    text: string
    description: string
  }
>[] = [
  {
    value: 'SMALL',
    label: {
      text: 'До 1 м3',
      description: '~ 1 палеты',
    },
  },
  {
    value: 'MEDIUM',
    label: {
      text: 'До 3 м3',
      description: '~ 2-3 палеты',
    },
  },
  {
    value: 'LARGE',
    label: {
      text: 'Более 3 м3',
      description: '> 3 палет',
    },
  },
]
export const cargoTypeOptionsDeprecated: IOption<
  CargoType,
  {
    text: string
    description: string
  }
>[] = [
  {
    value: 'BOX',
    label: {
      text: 'Коробки',
      description: 'до 30кг • 60х40х40см',
    },
  },
  {
    value: 'BULKY',
    label: {
      text: 'Крупногабарит',
      description: '30 – 150кг',
    },
  },
  {
    value: 'OVERSIZED',
    label: {
      text: 'Негабарит',
      description: 'более 150кг • 200см',
    },
  },
]

export const shipmentStatusOptions: (
  t: TFunction,
  {
    isSimpleUser,
  }: {
    isSimpleUser: boolean
  },
) => IOption<ShipmentStatus>[] = (
  t,
  {
    isSimpleUser,
  }: {
    isSimpleUser: boolean
  },
) =>
  unionArrays.shipmentStatus.map((status) => ({
    value: status,
    label: t(
      `constants.${
        isSimpleUser ? 'shipmentStatusForSimpleUser' : 'shipmentStatus'
      }.${status}`,
    ),
  }))
export const shipmentStatusNewOptions: (
  t: TFunction,
  {
    isSimpleUser,
  }: {
    isSimpleUser: boolean
  },
) => IOption<ShipmentStatusNew>[] = (
  t,
  {
    isSimpleUser,
  }: {
    isSimpleUser: boolean
  },
) =>
  unionArrays.shipmentStatusNew.map((status) => ({
    value: status,
    label: t(
      `constants.${
        isSimpleUser ? 'shipmentStatusNewForSimpleUser' : 'shipmentStatusNew'
      }.${status}`,
    ),
  }))

export const shipmentTypeOptions: (
  t: TFunction,
) => IOption<ShipmentTypesEnum>[] = (t) =>
  unionArrays.shipmentType.map((type) => ({
    value: type,
    label: t(`constants.shipmentTypes.${type}`),
  }))
export const senderDestinationTypeOptions: (
  t: TFunction,
) => IOption<SenderDestinationType>[] = (t) =>
  unionArrays.senderDestinationType.map((type) => ({
    value: type,
    label: t(`constants.senderDestinationType.${type}`),
  }))
export const receiverDestinationTypeOptions: (
  t: TFunction,
) => IOption<ReceiverDestinationType>[] = (t) =>
  unionArrays.receiverDestinationType.map((type) => ({
    value: type,
    label: t(`constants.receiverDestinationType.${type}`),
  }))

export const runRequestStatusOptions: (
  t: TFunction,
) => IOption<RunRequestStatus>[] = (t: TFunction) =>
  unionArrays.runRequestsStatus.map((status) => ({
    value: status,
    label: t(`constants.runRequestStatus.${status}`),
  }))
export const runRequestsStatusOptions: (
  t: TFunction,
) => IOption<RunRequestStatusEnum>[] = (t: TFunction) =>
  unionArrays.runRequestStatus.map((status) => ({
    value: status,
    label: t(`constants.runRequestsStatus.${status}`),
  }))
export const runRequestsTypesOptions: (
  t: TFunction,
) => IOption<RunRequestTypesEnum>[] = (t: TFunction) =>
  unionArrays.runRequestTypes.map((status) => ({
    value: status,
    label: t(`constants.runRequestTypes.${status}`),
  }))
export const monitoringDlvStatusOptions: (
  t: TFunction,
) => IOption<MonitoringDlvStatus>[] = (t: TFunction) =>
  unionArrays.monitoringDlvStatus.map((status) => ({
    value: status,
    label: t(`constants.dlvStatuses.${status}`),
  }))
export const monitoringErrorStatusOptions: (
  t: TFunction,
) => IOption<MonitoringErrorTypeWithoutDefault>[] = (t: TFunction) =>
  unionArrays.monitoringErrorTypeWithoutDefault.map((status) => ({
    value: status,
    label: t(`constants.monitoringErrorTypeWithoutDefault.${status}`),
  }))
export const monitoringWarehouseOptions: (
  t: TFunction,
) => IOption<MonitoringWarehouse>[] = (t: TFunction) =>
  unionArrays.monitoringWarehouse.map((status) => ({
    value: status,
    label: t(`constants.monitoringWarehouseType.${status}`),
  }))
export const monitoringCourierStatusOptions: (
  t: TFunction,
) => IOption<MonitoringStatus>[] = (t: TFunction) =>
  unionArrays.monitoringStatus.map((status) => ({
    value: status,
    label: t(`constants.monitoringStatus.${status}`),
  }))
export const shipmentDlvStatusOptions: (
  t: TFunction,
) => IOption<DlvStatusEnum>[] = (t: TFunction) =>
  unionArrays.dlvStatus.map((status) => ({
    value: status,
    label: t(`constants.dlvStatus.${status}`),
  }))
export const unloadingDlvStatusOptions: (
  t: TFunction,
) => IOption<ShipmentsDlvStatusEnum>[] = (t: TFunction) =>
  unionArrays.shipmentDlvStatus.map((status) => ({
    value: status,
    label: t(`constants.shipmentDlvStatus.${status}`),
  }))
export const shipmentsDlvDeliveryOptions: (
  t: TFunction,
) => IOption<DeliveryDlvEnum>[] = (t: TFunction) =>
  unionArrays.shipmentDlvDelivery.map((status) => ({
    value: status,
    label: t(`constants.shipmentsDlvDelivery.${status}`),
  }))

export const ExportDeliveryListTypeOptions: (
  t: TFunction,
) => IOption<ExportDeliveryListType>[] = (t: TFunction) =>
  unionArrays.exportDeliveryList.map((type) => ({
    value: type,
    label: t(`constants.exportDeliveryListType.${type}`),
  }))

export const RunRequestTypeOptions: (
  t: TFunction,
) => IOption<RunRequestType>[] = (t: TFunction) =>
  unionArrays.runRequestsType.map((type) => ({
    value: type,
    label: t(`constants.runRequestType.${type}`),
  }))

export const userRoleOptions: (t: TFunction) => IOption<Role>[] = (
  t: TFunction,
) =>
  unionArrays.userRole.map((role) => ({
    value: role,
    label: t(`constants.userRole.${role}`),
  }))

export const vatRateOptions: (t: TFunction) => IOption<VatRate>[] = (
  t: TFunction,
) =>
  unionArrays.vatRate.map((val) => ({
    value: val,
    label: t(`constants.vatRate.${val}`),
  }))

export const cargoTypeOptions: (t: TFunction) => IOption<CargoType>[] = (
  t: TFunction,
) =>
  unionArrays.cargoType.map((val) => ({
    value: val,
    label: t(`constants.cargoType.${val}`),
  }))
export const locationTypeOptions: (t: TFunction) => IOption<LocationTypes>[] = (
  t: TFunction,
) =>
  unionArrays.locationType.map((val) => ({
    value: val,
    label: t(`constants.locationType.${val}`),
  }))
export const pickupLocationTypeOptions: (
  t: TFunction,
) => IOption<LocationTypes>[] = (t: TFunction) =>
  unionArrays.pickupLocationType.map((val) => ({
    value: val,
    label: t(`constants.pickupLocationType.${val}`),
  }))
export const deliveryLocationTypeOptions: (
  t: TFunction,
) => IOption<Exclude<LocationTypes, 'QUBY_WAREHOUSE'>>[] = (t: TFunction) =>
  unionArrays.deliveryLocationType.map((val) => ({
    value: val,
    label: t(`constants.deliveryLocationType.${val}`),
  }))

export const excelReportTemplateOptions: (
  t: TFunction,
) => IOption<ExcelReportTemplate>[] = (t: TFunction) =>
  unionArrays.excelReportTemplate.map((val) => ({
    value: val,
    label: t(`constants.excelReportTemplate.${val}`),
  }))

export const tariffLevelLocalizationOptions: (
  t: TFunction,
) => IOption<TariffLevelLocalization>[] = (t: TFunction) =>
  unionArrays.tariffLevelLocalization.map((val) => ({
    value: val,
    label: t(`constants.tariffLevelLocalization.${val}`),
  }))
export const partnerLegalFormOptions: (
  t: TFunction,
) => IOption<ClientLegalForm>[] = (t: TFunction) =>
  unionArrays.partnerLegalForm.map((val) => ({
    value: val,
    label: t(`constants.partnerLegalForm.${val}`),
  }))
export const statusOptions: (t: TFunction) => IOption<ClientStatus>[] = (
  t: TFunction,
) =>
  unionArrays.status.map((val) => ({
    value: val,
    label: t(`constants.status.${val}`),
  }))
export const invoiceStatusOptions: (
  t: TFunction,
) => IOption<InvoiceStatusEnum>[] = (t: TFunction) =>
  unionArrays.invoicesStatus.map((val) => ({
    value: val,
    label: t(`constants.invoicesStatus.${val}`),
  }))

import React, { FC } from 'react'
import { Flex, Grid, Text } from '@chakra-ui/react'
import ContentHeader from '@/components/Header/ContentHeader'
import HeaderTitleByPath from '@/components/Header/HeaderTitleByPath'
import { RefreshQueryListButton } from '@/components/atoms/RefreshQueryListButton/RefreshQueryListButton'
import DatePicker from '@/components/atoms/DatePicker'

import { usePlanningRoutesTableQueryContext } from '../usePlanningRoutesTableQueryContext'
type YandexRoutesHeaderType = {
  setDate: React.Dispatch<React.SetStateAction<string>>
  date: string
}

export const PlanningRoutesPageHeader: FC<YandexRoutesHeaderType> = ({
  setDate,
  date,
}) => {
  const { query } = usePlanningRoutesTableQueryContext()

  return (
    <ContentHeader>
      <Grid gridAutoFlow="column" gridTemplateColumns="max-content">
        <HeaderTitleByPath />
      </Grid>
      <Flex ml={4} justifyContent="flex-end">
        <Grid
          alignItems="center"
          gridGap="4"
          gridTemplateColumns="max-content max-content max-content max-content"
        >
          <RefreshQueryListButton {...{ query: query }} />
          <Text textStyle="h4">Дата:</Text>
          <DatePicker
            value={date}
            handleChange={(newDate) => setDate(newDate)}
          />
        </Grid>
      </Flex>
    </ContentHeader>
  )
}

import React from 'react'
import { QForm } from '../..'
import { CloseButton, Stack } from '@chakra-ui/react'
import { colors } from '@/theme/colors'
import { QGroupListButtons } from '@/components/QForm/QGroupList/QGroupListButtons'
import { QGroupList } from '@/components/QForm/QGroupList/QGroupList'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { IOption } from '@/types/global'

export const QSelectGroup = <
  OptionType extends IOption<ValueType>,
  ValueType extends string = string,
>(
  props: QSelectPropsType<OptionType, ValueType>,
) => {
  const { name: groupName } = props

  return (
    <QGroupList
      name={groupName}
      groupListWrapper={(content, value) => {
        return (
          <div>
            <Stack pb={2} direction={'row'} wrap={'wrap'}>
              {content}
            </Stack>
            <QForm.Field.Select
              filterOptions={({ value: v }) => !value?.includes(v)}
              {...{ ...props }}
              name={`${groupName}[${value?.length}]`}
            />
          </div>
        )
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        background={colors.neutral['300']}
        borderRadius={'var(--chakra-radii-md)'}
        pl={'1em'}
        lineHeight={1}
        spacing={'0.5em'}
      >
        <QForm.Field.Select {...{ ...props }} isOneTimeSelected name={''} />
        <QGroupListButtons.Remove>
          <CloseButton size={'sm'} />
        </QGroupListButtons.Remove>
      </Stack>
    </QGroupList>
  )
}

import { Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Option } from '../../style'

interface Props {
  val: string
  onClick: () => void
}

const CreateOption: FC<Props> = ({ onClick, val }) => {
  return (
    <Option onClick={onClick}>
      <Text textStyle="text4"> Создать "{val}"</Text>
    </Option>
  )
}

export default CreateOption

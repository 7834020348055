import { Card, Grid, Stack, StackItem, Text } from '@chakra-ui/react'
import React from 'react'
import { SelectAsyncRenderSelectedFunctionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import { QSelectEndpointTypes } from './QSelectAsync.types'
import { SelectAsyncComponent } from '@/components/atoms/SelectAsync'
import { colors } from '@/theme/colors'
import PhoneIcon from '@/components/Icons/PhoneIcon'
import { formatPhone } from '@/utils/formatPhone'
import styled from 'styled-components'
import { RegistriesIcon } from '@/components/Icons/RegistriesIcon'

const ButtonsWrapper = styled.div`
  position: relative;
  top: -6px;
  right: -10px;
`
export const DriverRenderSelected: SelectAsyncRenderSelectedFunctionType<
  QSelectEndpointTypes['Item']
> = ({ fullName, phoneNumber, passportData }) => (
  <Card
    maxW={'100%'}
    background={'var(--100, #FFF)'}
    boxShadow={'0px 0px 20px 0px rgba(154, 171, 215, 0.12)'}
  >
    <Stack spacing={'7px'}>
      <div>
        <Grid gridTemplateColumns={'1fr max-content'} alignItems={'center'}>
          <Text textStyle={'h5'}>{fullName}</Text>{' '}
          <ButtonsWrapper>
            <SelectAsyncComponent.EditButton />
            <SelectAsyncComponent.ChangeButton />
            <SelectAsyncComponent.ClearButton />
          </ButtonsWrapper>
        </Grid>
      </div>
      <StackItem>
        {phoneNumber && (
          <Grid
            gridTemplateColumns={'min-content 1fr max-content'}
            gridGap={'5px'}
          >
            <PhoneIcon boxSize={'16px'} color={colors.neutral['500']} />
            <Text textStyle={'text4'} overflowWrap={'normal'}>
              {formatPhone(phoneNumber)}
            </Text>
          </Grid>
        )}
      </StackItem>
      <StackItem>
        {passportData && (
          <Grid
            gridTemplateColumns={'min-content 1fr max-content'}
            gridGap={'5px'}
          >
            <RegistriesIcon boxSize={'16px'} color={colors.neutral['500']} />
            <Text textStyle={'text4'} overflowWrap={'normal'}>
              {passportData}
            </Text>
          </Grid>
        )}
      </StackItem>
    </Stack>
  </Card>
)

import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { motion as m } from 'framer-motion'
import { useClickAway, useDebounce } from 'react-use'
import { Input, Option, OptionsList, Select } from './style'
import variants from './animation'
import SelectIndicator from './help/SelectIndicator'
import { SelectProps } from '@/types/global'
import NotFound from './help/NotFound'
import CreateOption from './help/CreateOption'
import { Grid, Icon, Spinner, Text, Textarea } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { colors } from '@/theme/colors'

export type SelectFieldOptionType = {
  id?: number | string | null
  value?: string
}
const _Select = <T extends SelectFieldOptionType = SelectFieldOptionType>(
  props: SelectProps<T>,
) => {
  const {
    options: opts,
    placeholder,
    mode = 'select',
    disabled = false,
    value,
    styles,
    asTextArea = false,
    loadFn,
    renderOption,
    handleChange,
    onCreateNew,
    onHoverOption,
    onHoverOut,
    getSelectedValueInputString,
    isInvalid,
    helpers,
    isOneTimeSelected,
  } = props
  const isFuzzy = mode === 'fuzzy'
  const isSelect = mode === 'select'
  const isInput = mode === 'input'

  const isDisabled = useMemo(() => disabled, [disabled])
  const selectRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [options, setOptions] = useState(opts || [])

  useEffect(() => {
    if (opts) {
      setOptions(opts)
    }
  }, [opts])

  const [selectedOption, setSelectedOption] = useState<T | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [, setInputMode] = useState<boolean>(false)
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const [direction, setMenuDirection] = useState<'up' | 'down'>('down')
  const [isCreate, setIsCreate] = useState<boolean>(false)

  useMemo(() => {
    if (isInput) {
      setIsCreate(true)
    } else {
      setIsCreate(false)
    }
  }, [isInput])

  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false)

  useEffect(() => {
    if (value === undefined) {
      setSelectedOption(null)
      setInputValue('')
    }
    if (value) {
      if (typeof value === 'object') {
        setSelectedOption(value)

        setInputValue(getSelectedValueInputString(value))
      }

      if (typeof value === 'string') {
        const searchInOptions = options.find((opt) => opt.id === value)

        if (searchInOptions) {
          setSelectedOption(searchInOptions)

          setInputValue(getSelectedValueInputString(searchInOptions))
        } else {
          if (loadFn) {
            setIsLoadingOptions(true)
            loadFn('').then((options) => {
              if (options) {
                const _searchInOptions = options.find((opt) => opt.id === value)
                if (_searchInOptions) {
                  setSelectedOption(_searchInOptions)

                  setInputValue(getSelectedValueInputString(_searchInOptions))
                }
              }
              setIsLoadingOptions(false)
            })
          }
        }
      }
    }
    /**
     * Временный фикс. Когда опции будут получаться из api запросов, ссылка на options перестанет меняться каждый рендер
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (selectedOption && typeof value === 'object') {
      setInputValue(getSelectedValueInputString(selectedOption))
    }
  }, [selectedOption, value, getSelectedValueInputString])

  useDebounce(
    async () => {
      if (loadFn && !isCreate) {
        setIsLoadingOptions(true)
        const opts = await loadFn(inputValue)
        if (opts) setOptions(opts)
        setIsLoadingOptions(false)
      }
    },
    500,
    [loadFn, inputValue],
  )

  useClickAway(selectRef, () => {
    if (selectedOption && !isInput) {
      setInputValue(getSelectedValueInputString(selectedOption))
    }
    if (isMenuOpen) {
      helpers?.setTouched && helpers.setTouched(true)
      setMenuOpen(false)
    }
  })

  const onSelect = (data: T) => () => {
    helpers?.setTouched(true)
    if (handleChange) {
      handleChange(data, inputValue)
    }
  }

  const onClick = () => {
    if (isDisabled) return undefined
    setFocusInput()
    setMenuDirection(
      (
        window.innerHeight -
          (selectRef.current?.getBoundingClientRect().top || 0) >
          300
      ) ?
        'down'
      : 'up',
    )

    setMenuOpen((e) => !e)
  }

  const setFocusInput = () => {
    if ((inputRef.current || textAreaRef.current) && (isFuzzy || isInput)) {
      if (asTextArea && textAreaRef.current) {
        textAreaRef.current.focus()
      }
      if (!asTextArea && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const onInputChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ target: { value } }) => {
    setInputValue(value)
    setInputMode(true)
    // setTouched()
    if (isCreate && handleChange) {
      handleChange(selectedOption as T, value)
    }
    setMenuOpen(true)
  }

  const onCreate = () => {
    setIsCreate(true)
    if (onCreateNew) {
      setSelectedOption(null)
      onCreateNew(inputValue)
    }
  }

  const onMouseEnter = (data: T) => onHoverOption && onHoverOption(data)
  const isSelectedOption = useCallback(
    (data: SelectFieldOptionType) => {
      if (selectedOption === null) {
        return false
      }
      if (data.id) {
        return data.id === selectedOption.id
      } else {
        return data.value === selectedOption.value
      }
    },
    [selectedOption],
  )

  if (isOneTimeSelected && inputValue) {
    return renderOption && selectedOption ?
        renderOption(selectedOption)
      : inputValue
  }

  return (
    <Select
      style={styles}
      $isDisabled={isDisabled}
      $isError={isInvalid}
      data-is-menu-open={isMenuOpen}
      ref={selectRef}
      onClick={onClick}
      data-test-class={'select'}
    >
      {renderOption && selectedOption ?
        renderOption(selectedOption)
      : asTextArea ?
        <Textarea
          autoComplete="none"
          fontSize="14px"
          focusBorderColor="none"
          lineHeight="16px"
          padding="0"
          resize="none"
          bgColor="#fff"
          shadow="noen"
          width="100%"
          outline="0px solid #fff"
          border="none"
          rows={2}
          disabled={isDisabled}
          ref={textAreaRef}
          onChange={onInputChange}
          value={inputValue}
          placeholder={placeholder}
        />
      : <Input
          width="100%"
          disabled={isDisabled}
          autoComplete="none"
          ref={inputRef}
          onChange={onInputChange}
          value={inputValue}
          placeholder={placeholder}
        />
      }
      <Spinner
        size="sm"
        opacity={isLoadingOptions ? 1 : 0}
        color={colors.neutral[400]}
      />
      <Icon
        color={colors.error[600]}
        opacity={isInvalid ? 1 : 0}
        as={WarningIcon}
      />
      {isSelect && <SelectIndicator isOpen={isMenuOpen} />}
      {!isInput && (
        <OptionsList
          as={m.div}
          direction={direction}
          animate={isMenuOpen && !isCreate ? 'visible' : 'hidden'}
          variants={variants.optionList}
          initial={'hidden'}
        >
          {options.length === 0 ?
            onCreateNew && inputValue !== '' ?
              <CreateOption val={inputValue} onClick={onCreate} />
            : <NotFound />
          : options
              .filter((data) => getSelectedValueInputString(data) !== '')
              .map((data, i) => (
                <Option
                  data-test-class={'select_option'}
                  key={i}
                  $isSelected={isSelectedOption(data)}
                  onClick={onSelect(data)}
                  onMouseEnter={() => onMouseEnter(data)}
                  onMouseLeave={onHoverOut}
                >
                  {renderOption ?
                    renderOption(data)
                  : <Grid>
                      <Text textStyle="text4">
                        {getSelectedValueInputString(data)}
                      </Text>
                    </Grid>
                  }
                </Option>
              ))
          }
        </OptionsList>
      )}
    </Select>
  )
}

export default _Select

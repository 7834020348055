import { Tooltip } from '@chakra-ui/react'
import React, { FC } from 'react'
import CommentIcon from '@/components/Icons/CommentTableIcon'
interface Props {
  comment?: string
}

const TableComment: FC<Props> = ({ comment }) => {
  if (!comment) return null
  return (
    <Tooltip label={comment}>
      <div>
        <CommentIcon />
      </div>
    </Tooltip>
  )
}

export default TableComment

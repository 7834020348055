import { useContext } from 'react'
import { QFieldContext } from '@/components/QForm/QField/QField.context'

export const useQFieldContext = () => {
  const context = useContext(QFieldContext)
  if (!context) {
    throw new Error('Не обёрнут контекст QFieldContext')
  }
  return context
}

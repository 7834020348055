import React, { FC, PropsWithChildren, useCallback } from 'react'
import { QForm } from '@/components/QForm'
import { RTKMutationResult } from '@/types/rtkq.types'
import { useClientContext } from '@/providers/Client/useClientContext'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import { VehiclesModalFormEndpoints } from './VehiclesModalForm.endpoints'
import {
  VehicleModalFormPropsType,
  VehicleModalFormTypes,
} from './VehicleModalForm.types'
import {
  vehicleModalFormCreateYup,
  vehicleModalFormUpdateYup,
} from '@/_app/pages/registers/vehicles/modals/car/vehicleModalForm.yup'
import { VehicleModalInner } from './VehicleModalInner'

export const VehicleModal: FC<PropsWithChildren<VehicleModalFormPropsType>> = ({
  modalProps,
}) => {
  const { clientId: partnerId } = useClientContext()
  const [createVehicle, { isLoading: isLoadingCreate }] =
    VehiclesModalFormEndpoints.create.useMutation()
  const [updateVehicle, { isLoading: isLoadingUpdate }] =
    VehiclesModalFormEndpoints.update.useMutation()
  const isLoading = isLoadingUpdate || isLoadingCreate

  const modalData = modalProps.getData()
  const onSubmit = useCallback(
    async (vehicle: VehicleModalFormTypes['ConsumedRequest']) => {
      function handle(
        response: RTKMutationResult<VehicleModalFormTypes['ConsumedResponse']>,
      ) {
        const errors = qFormCollectErrors(response)
        if ('data' in response) {
          if (response.data) {
            modalData?.afterSave && modalData?.afterSave(response.data)
            modalProps.closeModal()
          }
        }
        return errors
      }

      if ('id' in vehicle) {
        const response = await updateVehicle(vehicle)
        return handle(response)
      } else {
        const response = await createVehicle(vehicle)
        return handle(response)
      }
    },
    [createVehicle, updateVehicle, modalProps, modalData],
  )

  return (
    <QForm<VehicleModalFormTypes['ConsumedRequest']>
      requestKey={'vehicleStoreRequest'}
      initialValues={modalData?.vehicle}
      validationSchema={
        modalData?.vehicle && 'id' in modalData?.vehicle ?
          vehicleModalFormUpdateYup
        : vehicleModalFormCreateYup
      }
      {...{ onSubmit }}
    >
      <VehicleModalInner
        {...{
          partnerId,
          modalProps,
          isLoading,
        }}
      />
    </QForm>
  )
}

import styled, { css } from 'styled-components'
import { colors } from '@/theme/colors'

type SelectProps = {
  $isDisabled?: boolean
  $error?: boolean
  $withTopAddon?: boolean
}

export const SelectAsyncTopAddonWrapper = styled.div<SelectProps>`
    z-index: 1;
    position: relative;
    top: ${({ $error }) => ($error ? 0 : 1)}}
`

export const Select = styled.div<SelectProps>`
    padding: 8px 12px;
    border: 1px solid ${colors.neutral[400]};
    border-radius: 10px;
    box-sizing: border-box;
    height: min-content;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr max-content;
    /* justify-content: space-between; */
    position: relative;
    transition: 0.3s ease;
    ${({ $isDisabled = false }) =>
      $isDisabled &&
      css`
        color: ${colors.neutral[500]};
      `}
    ${({ $error = false }) =>
      $error &&
      css`
        border: 1px solid ${colors.error[700]};
        box-shadow: 0 0 0 1px ${colors.error[700]};
      `}
    ${({ $withTopAddon, $error }) =>
      $withTopAddon &&
      css`
        border-top-left-radius: 0;
        ${!$error &&
        css`
          border-top-color: #eaecf0;
        `}
      `}
}

`
type InputProps = { isDisabled?: boolean }
export const Input = styled.input<InputProps>`
  /* width: max-content; */
  min-width: 60px;
  /* max-width: fit-content; */
  font-size: 14px;
  line-height: 14px;
  outline: none;
  padding: 0;
  border: 0;
  text-decoration: none;
  height: max-content;
  user-select: none;
  pointer-events: none;
  ${({ isDisabled = false }) => isDisabled && css``}
`

type IOptionProps = {
  $isSelected?: boolean
  $isKeyboardFocused?: boolean
}
export const Option = styled.div<IOptionProps>`
  padding: 8px 12px;
  transition: 0.1s ease-in-out;
  color: #000;

  &[class] {
    ${({ $isSelected = false }) =>
      $isSelected &&
      css`
        background-color: ${colors.success[200]};
        color: ${colors.success[600]};
      `}
  }

  ${({ $isKeyboardFocused }) => {
    if ($isKeyboardFocused) {
      return css`
        background-color: ${colors.accent[200]};
        color: ${colors.accent[600]};
      `
    }
  }}

  &:hover {
    background-color: ${colors.accent[100]};
    color: ${colors.accent[600]};
  }

  &:active {
    background-color: ${colors.accent[300]};
    color: ${colors.accent[600]};
  }
`
export const OptionsList = styled.div<{
  $direction: 'up' | 'down'
  $isLoading?: boolean
}>`
  position: absolute;
  ${({ $direction }) =>
    $direction === 'up' ? 'bottom' : 'top'}: calc(100% + 4px);
  left: -1px;
  right: -1px;
  border: 1px solid ${colors.neutral[400]};
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 10px;
  overflow-x: hidden;
  background: #fff;
  z-index: 9000;
  max-height: 300px;
  overflow-y: scroll;
  ${({ $isLoading }) => {
    if ($isLoading) {
      return css`
        ${Option} {
          opacity: 0.42 !important;
          pointer-events: none;
        }
      `
    }
  }}
`

export const NotFound = styled.div`
  padding: 12px 12px;
  color: ${colors.neutral[500]};
`

import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import omit from 'lodash/omit'
import { Input, InputProps } from '@chakra-ui/react'

export const QFileCore = ({
  value,
  ...props
}: QFieldCorePopsType<File | undefined> &
  Omit<InputProps, 'value'> & {
    placeholder?: string
  }) => {
  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined

  const changeRef = useRef<NodeJS.Timeout>()

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.files?.item(0)
      clearTimeout(changeRef.current)
      if (onChangeValue) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(v || undefined)
        }, 300)
      }
    },
    [onChangeValue],
  )

  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [propsOnFocus],
  )

  useEffect(() => {
    return () => {
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])
  return (
    <Input
      pb={'2em'}
      type={'file'}
      {...{
        onChange,
        onFocus,
        onBlur,
      }}
      {...omit(props, [
        'onChangeValue',
        'onChange',
        'onBlur',
        'onFocus',
        'value',
      ])}
    />
  )
}

import React, { ReactNode } from 'react'
import { InputProps } from '@chakra-ui/react'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'
import { QSwitchInner } from '@/components/QForm/QField/QSwitch/QSwitchInner'

export const QSwitch = (
  props: InputProps &
    Omit<QFieldPropsType<boolean>, 'children'> & { label?: ReactNode },
) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QSwitchInner {...otherProps} />
    </QForm.Field>
  )
}

import React, { useMemo } from 'react'
import Link from '@/components/Link/Link'
import TableHeader from '@/components/atoms/TableHeader'
import Hashtag from '@/components/Icons/Hashtag'
import User from '@/components/Icons/User'
import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react'
import Truck from '@/components/Icons/Truck'
import Cube from '@/components/Icons/Cube'
import Comment from '@/components/Icons/Comment'
import declOfNum from '@/utils/declOfNmber'
import { RunRequestCargoVolume } from '@/constants/options'
import Box from '@/components/Icons/BoxIcon'
import AttachmentsCell from '@/components/atoms/table_assets/AttachmentsCell'
import Folder from '@/components/Icons/Folder'
import { createColumnHelper } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { TableAddressCell } from '@/components/table/TableAddressCell/TableAddressCell'
import { useAppUserContext } from '@/_app/useAppUserContext'
import { qubyNav } from '@/navigation/qubyNav'

import { RunRequestEndpointTypes } from './RunRequest.types'
import RunRequestStatusLabel from './components/RunRequestStatusLabel/RunRequestStatusLabel'

const columnHelper = createColumnHelper<RunRequestEndpointTypes['ListItem']>()
type IProps = {
  hideAttachments?: boolean
}

export const useRunRequestTableColumns = ({
  hideAttachments = true,
}: IProps) => {
  const [t] = useTranslation()
  const { isQubyEmployee, isSimpleUser } = useAppUserContext()

  //@ts-ignore
  const statusCell: any = ({ row: { original } }) => {
    return (
      <Flex justifyContent="space-between" alignItems="center">
        <RunRequestStatusLabel status={original.status} />
      </Flex>
    )
  }

  return useMemo(
    () => [
      columnHelper.accessor(({ fullOrdinalNumber }) => `${fullOrdinalNumber}`, {
        id: 'ORDINAL_NUMBER',
        enableSorting: true,
        cell: ({
          getValue,
          row: {
            original: { id, internal },
          },
        }) => {
          return (
            <div>
              <div>
                <Link
                  href={qubyNav.runRequest.edit.tab.getPathname({
                    runRequestId: id,
                    tabName: 'collection-address',
                  })}
                  className="link"
                >
                  {getValue()}
                </Link>
              </div>
              {isQubyEmployee && (
                <div>{internal?.orderNumberFromYandexMonitoring}</div>
              )}
            </div>
          )
        },
        header: () => (
          <TableHeader Icon={Hashtag}>
            {t('run_request.table.number')}
          </TableHeader>
        ),
      }),
      ...(isQubyEmployee ?
        [
          columnHelper.accessor('internal.client.fullName', {
            id: 'partner',
            enableSorting: false,
            cell: ({ getValue }) => {
              const partner = getValue()
              return partner || ''
            },
            header: () => (
              <TableHeader Icon={User}>
                {t('run_request.table.partner')}
              </TableHeader>
            ),
          }),
          columnHelper.accessor('destinationAddress', {
            id: 'DESTINATION_ADDRESS',
            enableSorting: false,
            cell: ({
              row: {
                original: { destinationAddress },
              },
            }) => {
              return <TableAddressCell address={destinationAddress?.value} />
            },
            header: () => (
              <TableHeader Icon={User}>
                {t('run_request.table.address')}
              </TableHeader>
            ),
          }),
        ]
      : []),
      columnHelper.accessor('arrivalDate', {
        id: 'ARRIVAL_DATE',
        enableSorting: true,
        cell: ({
          getValue,
          row: {
            original: { arrivalTimeFrom, arrivalTimeTo, arrivalMethod },
          },
        }) => {
          if (!getValue()) return ''
          const date = new Date(getValue() || '')
          const dateString = new Intl.DateTimeFormat(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(date)
          const timeFromString =
            arrivalTimeFrom ?
              ' с ' + arrivalTimeFrom.replace(/:\d{2}$/, '')
            : ''
          const timeToString =
            arrivalTimeTo ? ' до ' + arrivalTimeTo.replace(/:\d{2}$/, '') : ''

          return (
            <Flex>
              <div>
                <div>{dateString}</div>
                <div>
                  <small>
                    {timeFromString}
                    {timeToString}
                  </small>
                </div>
              </div>
              {isSimpleUser && arrivalMethod === 'SELF_DELIVERY' && (
                <div>
                  <Badge ml={2} fontSize={'.64em'}>
                    {t('constants.runRequestArrivalMethod.SELF_DELIVERY')}
                  </Badge>
                </div>
              )}
            </Flex>
          )
        },
        header: () => (
          <TableHeader tooltip={t('run_request.table.arrival')} Icon={Truck}>
            Прибытие
          </TableHeader>
        ),
      }),
      ...(isQubyEmployee ?
        [
          columnHelper.accessor('type', {
            id: 'TYPE',
            enableSorting: true,
            cell: ({
              getValue,
              row: {
                original: { arrivalMethod },
              },
            }) => {
              const arrivalMethodT = t(
                `constants.runRequestArrivalMethod.${arrivalMethod}`,
              )
              const type = getValue()
              return `${t(`constants.runRequestType.${type}`)}${
                type === 'PICKUP' ? ` | ${arrivalMethodT}` : ''
              }`
            },

            header: () => (
              <TableHeader Icon={Cube}>
                {t('run_request.table.type')}
              </TableHeader>
            ),
          }),
        ]
      : []),
      columnHelper.accessor('status', {
        id: 'STATUS',
        enableSorting: true,
        cell: statusCell,
        header: () => (
          <TableHeader Icon={Comment}>
            {t('run_request.table.status')}
          </TableHeader>
        ),
      }),
      ...(isQubyEmployee ?
        [
          columnHelper.accessor('deliveryServiceId', {
            id: 'DELIVERY_SERVICE_ID',
            enableSorting: true,
            cell: ({
              row: {
                original: {
                  deliveryService,
                  deliveryServiceTrackNumber,
                  arrivalMethod,
                },
              },
            }) => {
              if (arrivalMethod === 'SELF_DELIVERY') {
                return <></>
              }

              const deliveryServiceName =
                deliveryService?.id ? deliveryService?.name || '' : 'Quby'
              return (
                <div>
                  <Tooltip label={deliveryServiceName}>
                    <Text
                      maxW={'6em'}
                      style={{ display: 'inline-block' }}
                      noOfLines={1}
                      textStyle="text5"
                    >
                      {deliveryServiceName}
                    </Text>
                  </Tooltip>
                  <div>
                    <Tooltip label={deliveryServiceTrackNumber || ''}>
                      <Text
                        maxW={'6em'}
                        style={{ display: 'inline-block' }}
                        noOfLines={1}
                        textStyle="text6"
                      >
                        {deliveryServiceTrackNumber || '' || ''}
                      </Text>
                    </Tooltip>
                  </div>
                </div>
              )
            },
            header: () => (
              <TableHeader
                tooltip={t('run_request.table.deliveryService')}
                Icon={User}
              >
                СД
              </TableHeader>
            ),
          }),
        ]
      : []),

      columnHelper.display({
        id: 'cargoType',
        enableSorting: false,
        cell: ({
          row: {
            original: {
              shipments,
              cargoVolume,
              cargoNumberOfPallets,
              cargoPackagesAmount,
            },
          },
        }) => {
          const shipmentsCount =
            shipments && shipments.length > 0 ?
              `${shipments.length} ${declOfNum(shipments.length, [
                'отправление',
                'отправления',
                'отправлений',
              ])}\n${cargoPackagesAmount} ${
                cargoPackagesAmount &&
                declOfNum(cargoPackagesAmount, ['место', 'места', 'мест'])
              }`
            : null
          const numberOfPallets = (() => {
            if (cargoNumberOfPallets && cargoVolume === 'LARGE')
              return `${cargoNumberOfPallets} палет`
            const label = RunRequestCargoVolume.find(
              ({ value }) => value === cargoVolume,
            )?.label.text
            if (label) return label
            return null
          })()
          return <pre>{shipmentsCount || numberOfPallets || 'не указано'}</pre>
        },
        header: () => (
          <TableHeader tooltip={t('run_request.table.cargoType')} Icon={Box}>
            Состав
          </TableHeader>
        ),
      }),
      ...(hideAttachments ?
        [
          columnHelper.accessor('attachments', {
            id: 'attachments',
            enableSorting: false,
            cell: ({ getValue }) => (
              <AttachmentsCell atachments={getValue() || []} />
            ),
            header: () => (
              <TableHeader Icon={Folder}>
                {t('shipments.table.attachments')}
              </TableHeader>
            ),
          }),
        ]
      : []),
    ],
    [isQubyEmployee, isSimpleUser, t, hideAttachments],
  )
}

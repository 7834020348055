import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QNumberCore } from './QNumberCore'
import { QNumberPropsType } from './QNumber.types'

export const QNumberInner = (props: Omit<QNumberPropsType, 'name'>) => {
  const { name, propsFromYup } = useQFieldContext()
  const [field, meta] = useField(name)
  const isInvalid = Boolean(meta.error && meta.touched)

  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QNumberCore
        {...{
          ...field,
          isInvalid,
        }}
      />
    </ComponentPropsDecorator>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import {
  QFormContextValueType,
  QFormPropsType,
  QFormSubmitErrorsReturn,
} from '@/components/QForm/QForm.types'
import { QFormContext } from '@/components/QForm/QForm.context'
import {
  FormikHelpers,
  FormikProvider,
  FormikValues,
  prepareDataForValidation,
  useFormik,
  yupToFormErrors,
} from 'formik'
import {
  QFormGroupContextDefaultValue,
  QGroupContext,
} from '@/components/QForm/QGroup/QGroup.context'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { useLocalStorageWithYup } from '@/hooks/useLocalStorageWithYup'
import { ApiResponseErrorsType } from '@/types/api.types'
import { notificationsStore } from '@/stores/global.store'
import { Heading } from '@chakra-ui/react'
import isFunction from 'lodash/isFunction'

const defaultValidationContextValue = {}
export const QFormFunction = <ValuesType extends FormikValues>(
  props: QFormPropsType<ValuesType>,
) => {
  const {
    children,
    onChange,
    onSubmit,
    initialValues = {} as ValuesType,
    validationSchema,
    validationContextValue,
    storageKey,
    onResetForm,
    requestKey,
    externalErrors,
    isPreventRestoreStorage,
    validateOnBlur = true,
    validateOnChange = true,
    validateOnMount = false,
  } = props

  const { savedStorageData, updateStorage } = useLocalStorageWithYup({
    storageKey,
    yupSchema: validationSchema,
  })

  const preparedInitialValues =
    validationSchema ?
      (validationSchema.cast(initialValues, {
        assert: false,
        context: {
          values: initialValues,
          validationContextValue:
            isFunction(validationContextValue) ?
              validationContextValue(initialValues as ValuesType)
            : validationContextValue,
        },
      }) as ValuesType)
    : initialValues

  const [resultErrors, setResultErrors] = useState<
    QFormSubmitErrorsReturn | undefined
  >(undefined)

  const formik = useFormik<ValuesType>({
    initialValues: preparedInitialValues as Required<ValuesType>,
    validateOnBlur,
    validateOnChange,
    validateOnMount,
    onReset: () => {
      updateStorage(null)
      onResetForm && onResetForm()
    },
    onSubmit: async (values, submitProps) => {
      setResultErrors(undefined)
      let resultErrors: QFormSubmitErrorsReturn | undefined

      if (validationSchema) {
        const validationConfig = {
          abortEarly: false,
          stripUnknown: true,
          context: {
            values,
            validationContextValue:
              isFunction(validationContextValue) ?
                validationContextValue(values)
              : validationContextValue,
          },
        }

        const transformedValues = validationSchema.cast(
          values,
          validationConfig,
        ) as ValuesType
        resultErrors = onSubmit && (await onSubmit(transformedValues))
      } else {
        resultErrors = onSubmit && (await onSubmit(values))
      }

      const { setFieldError, setFieldTouched } = formik
      setErrorsFromErrorsByPath({
        resultErrors,
        setFieldError,
        requestKey,
        setFieldTouched,
      })

      function showNotificationsByHeadMessage(
        resultErrors: QFormSubmitErrorsReturn | undefined,
      ) {
        if (resultErrors && 'message' in resultErrors) {
          if (
            'errorsByFieldPath' in resultErrors &&
            resultErrors.errorsByFieldPath &&
            resultErrors.errorsByFieldPath.length
          ) {
            // do nothing
          } else if (
            'errors' in resultErrors &&
            resultErrors.errors &&
            Object.keys(resultErrors.errors).length
          ) {
            // do nothing
          } else {
            notificationsStore.pushError({
              message: (
                <div>
                  <Heading size={'sm'}>{resultErrors.message}</Heading>
                </div>
              ),
            })
          }
        }
      }

      showNotificationsByHeadMessage(resultErrors)

      if (!resultErrors) {
        updateStorage(null)
        submitProps.resetForm({ values })
      }
      setResultErrors(resultErrors)
    },
    validate: (values) => {
      if (validationSchema) {
        const valuesForValidation = prepareDataForValidation(values)

        const validationConfig = {
          abortEarly: false,
          context: {
            values,
            validationContextValue:
              isFunction(validationContextValue) ?
                validationContextValue(valuesForValidation as ValuesType)
              : validationContextValue,
          },
        }
        return validationSchema
          .validate(valuesForValidation, validationConfig)
          .then(
            () => false,
            (errors) => yupToFormErrors(errors),
          )
      }
    },
  })

  const { values, setValues } = formik

  const initRef = useRef(false)
  useEffect(() => {
    if (
      !isPreventRestoreStorage &&
      validationSchema &&
      savedStorageData &&
      !initRef.current
    ) {
      initRef.current = true
      const val = validationSchema.cast(savedStorageData, {
        assert: false,
        context: {
          values: savedStorageData,
          validationContextValue:
            isFunction(validationContextValue) ?
              validationContextValue(savedStorageData as ValuesType)
            : validationContextValue,
        },
      })
      setValues(val as ValuesType)
    }
  }, [
    isPreventRestoreStorage,
    savedStorageData,
    validationSchema,
    setValues,
    validationContextValue,
  ])

  const { setFieldError, setFieldTouched } = formik
  useEffect(() => {
    const f = async () => {
      const err = externalErrors && (await externalErrors)
      if (err) {
        setErrorsFromErrorsByPath({
          resultErrors: err,
          setFieldError,
          requestKey,
          setFieldTouched,
        })
      }
    }
    f()
  }, [externalErrors, requestKey, setFieldError, setFieldTouched])

  useEffect(() => {
    updateStorage(values)
    onChange && onChange(values)
  }, [onChange, updateStorage, values])

  const contextValue: QFormContextValueType<ValuesType> = {
    requestKey,
    resultErrors,
    formik,
    values,
    isValid: formik.isValid,
    validationSchema,
    validationContextValue:
      validationContextValue && isFunction(validationContextValue) ?
        validationContextValue(values as ValuesType)
      : defaultValidationContextValue,
    validationContextValueFunction:
      validationContextValue && isFunction(validationContextValue) ?
        validationContextValue
      : undefined,
  }
  const formContent =
    typeof children === 'function' ? children(contextValue) : children

  return (
    <DndProvider backend={HTML5Backend}>
      <QFormContext.Provider value={contextValue}>
        {/* Зануляем группы полученные выше */}
        <QGroupContext.Provider value={QFormGroupContextDefaultValue}>
          <FormikProvider value={formik}>{formContent}</FormikProvider>
        </QGroupContext.Provider>
      </QFormContext.Provider>
    </DndProvider>
  )
}

function setErrorsFromErrorsByPath(options: {
  resultErrors: ApiResponseErrorsType | undefined | void
  setFieldError: FormikHelpers<any>['setFieldError']
  setFieldTouched: FormikHelpers<any>['setFieldTouched']
  requestKey: string | undefined
}) {
  const { resultErrors, setFieldTouched, setFieldError, requestKey } = options
  if (resultErrors && 'errorsByFieldPath' in resultErrors) {
    const e = resultErrors.errorsByFieldPath
    e.forEach(({ path, errors }) => {
      const message = errors?.[0]?.message

      notificationsStore.pushError({ message })

      const fullPath = (requestKey ? `${requestKey}.` : '') + path
      setFieldTouched(fullPath, true, false)
      setFieldError(fullPath, message)
    })
  }
}

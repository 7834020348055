import React from 'react'
import { InputProps } from '@chakra-ui/react'
import { QStringInner } from '@/components/QForm/QField/QInput/QString/QStringInner'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'

export const QString = (
  props: InputProps & Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QStringInner {...otherProps} />
    </QForm.Field>
  )
}

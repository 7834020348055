import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.473 5.474a.666.666 0 00-.947 0L8.473 8.527a.667.667 0 01-.947 0L4.473 5.474a.667.667 0 10-.947.94l3.06 3.06a2 2 0 002.827 0l3.06-3.06a.667.667 0 000-.94z"
        fill="#1D2939"
      />
    </svg>
  )
}

export default Icon

import * as React from 'react'
import { PropsWithChildren } from 'react'
import FieldWrapper from '@/components/moleculs/fields/Field'
import { FieldWrapperProps } from '@/components/moleculs/fields/Field/FieldWrapper.types'
import * as process from 'process'
import { Box, Tooltip } from '@chakra-ui/react'
import { TooltipInnerSpan } from '@/containers/ShipmentCard/steps/AdditionalServices/Table/components/rows/TooltipInnerSpan'

export type QFieldWrapperProps = {
  withWrapper?: boolean
  wrapperProps?: Omit<FieldWrapperProps, 'children'>
  yupLabel?: string | false
  isRequired?: boolean
  errorText?: string
  isDisabled?: boolean
  tooltipText?: string
  resultName?: string
  isNotPresentInYupScheme?: boolean
}

const isDev = process.env.NODE_ENV === 'development'
export const QFieldWrapper = (props: PropsWithChildren<QFieldWrapperProps>) => {
  const {
    children,
    withWrapper,
    yupLabel,
    isRequired,
    errorText,
    isDisabled,
    resultName,
    isNotPresentInYupScheme,
    tooltipText,
  } = props

  if (!withWrapper) {
    return <>{children}</>
  }
  const title = `${yupLabel || 'Заголовок не найден'}${isRequired ? '*' : ''}`

  return (
    <FieldWrapper
      {...{
        title:
          tooltipText ?
            <>
              {title}
              &thinsp;
              <Tooltip label={tooltipText}>
                <TooltipInnerSpan>?</TooltipInnerSpan>
              </Tooltip>
            </>
          : title,
        errorText,
        isDisabled,
        resultName,
      }}
    >
      {isDev && isNotPresentInYupScheme && (
        <Box color={'error.600'}>
          <small>
            ! Поле <code>{resultName}</code> не найдено в yup-схеме
          </small>
        </Box>
      )}
      {children}
    </FieldWrapper>
  )
}

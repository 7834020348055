import React from 'react'
import Table from '@/components/moleculs/Table/Table'
import { usePlanningRoutesTableQueryContext } from '../usePlanningRoutesTableQueryContext'
import { useRunRequestTableColumns } from '../../runRequest/useRunRequestTableColumns'
import { FormikErrors } from 'formik'
import {
  RouteStoreRequest,
  RunRequestResource,
} from '@/api/generated/qubyApiPhp'
type IProps = {
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
  selectedRunRequestIds: string[]
  runRequest: RunRequestResource[]
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<RouteStoreRequest>>
  showCreateRoute: boolean
}
const PlanningTable = ({
  setSelectedIds,
  selectedRunRequestIds,
  runRequest,
  setFieldValue,
  showCreateRoute,
}: IProps) => {
  const {
    query: { isLoading, isFetching, isError },
  } = usePlanningRoutesTableQueryContext()
  const columns = useRunRequestTableColumns({ hideAttachments: false })

  return (
    <>
      <Table
        skeletonRowHeight={'32px'}
        onRowSelectionChange={(runRequestIds) => {
          if (!showCreateRoute) {
            setSelectedIds(runRequestIds)
            setFieldValue('runRequestIds', runRequestIds)
          }
        }}
        selectedRows={selectedRunRequestIds}
        {...{
          columns,
          isLoading,
          isFetching,
          data:
            isError ?
              []
            : runRequest.map((el) => ({
                ...el,
                id: String(el.id),
              })) || [],
        }}
      />
    </>
  )
}

export default PlanningTable

import React from 'react'
import { Text } from '@chakra-ui/react'
import { NotFound } from '../../style'

const _NotFound = () => {
  return (
    <NotFound>
      <Text textAlign="center" fontSize="10px">
        Ничего не найдено
      </Text>
    </NotFound>
  )
}

export default _NotFound

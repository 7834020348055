import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_204_14994)">
        <path
          d="M15.333 4.666h-3.206l.534-3.91a.667.667 0 00-1.322-.18l-.557 4.09H6.127l.534-3.91a.667.667 0 00-1.322-.18l-.557 4.09H1.333a.667.667 0 000 1.334H4.6l-.545 4H.667a.667.667 0 000 1.333h3.206l-.534 3.91a.667.667 0 101.322.18l.557-4.09h4.655l-.534 3.91a.667.667 0 101.322.18l.557-4.09h3.449a.667.667 0 000-1.333H11.4l.545-4h3.388a.667.667 0 100-1.334zM10.055 10H5.4l.545-4H10.6l-.545 4z"
          fill="#475467"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_204_14994">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

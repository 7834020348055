import React from 'react'
import { QForm } from '@/components/QForm'
import { SelectAsyncQueriesConfig } from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  QSelectAsyncPropsType,
  QSelectEndpointTypes,
} from './QSelectAsync.types'
import { QSelectAsyncEndpoints } from './QSelectAsync.endpoints'
import { useClientContext } from '@/providers/Client/useClientContext'
import { Flex, Text } from '@chakra-ui/react'
import { DriverResource } from '@/api/generated/qubyApiPhp'

export const QSelectAsyncRunRequest = (props: QSelectAsyncPropsType) => {
  const {
    singleEndpointAdditionalArgs,
    listEndpointAdditionalArgs,
    ...otherProps
  } = props
  const { clientId } = useClientContext()
  const queriesConfig: SelectAsyncQueriesConfig<
    QSelectEndpointTypes['SingleEndpoint'],
    QSelectEndpointTypes['ListEndpoint']
  > = {
    single: {
      query: QSelectAsyncEndpoints.getById,
      args: (value: typeof props.value) => {
        return {
          id: String(value),
          ...(singleEndpointAdditionalArgs ?
            singleEndpointAdditionalArgs()
          : {}),
        }
      },
    },
    list: {
      query: QSelectAsyncEndpoints.getList,
      args: () => {
        return {
          perPage: 200,
          statuses: ['NEW'],
          partnerIds: [clientId || ''],
          types: ['PICKUP', 'LOCATION_PICKUP'],
          ...(listEndpointAdditionalArgs ? listEndpointAdditionalArgs() : {}),
        }
      },
    },
  }

  return (
    <>
      <QForm.Field.SelectAsync<
        QSelectEndpointTypes['SingleEndpoint'],
        QSelectEndpointTypes['ListEndpoint'],
        QSelectEndpointTypes['Item']
      >
        renderOption={({
          fullOrdinalNumber,
          destinationAddress,
          driver,
          arrivalDate,
          arrivalTimeFrom,
          arrivalTimeTo,
        }) => {
          const driverInfo = driver as DriverResource
          return (
            <Flex flexDir={'column'} gap={2}>
              <Text fontSize="sm">
                {fullOrdinalNumber} | Прибытие: {arrivalDate} c{' '}
                {arrivalTimeFrom} по {arrivalTimeTo}
              </Text>
              <Text fontSize="sm">Водитель: {driverInfo?.fullName || '-'}</Text>
              <Text fontSize="xs">{destinationAddress?.value}</Text>
            </Flex>
          )
        }}
        {...{
          queriesConfig,
          ...otherProps,
        }}
      />
    </>
  )
}

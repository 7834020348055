import React from 'react'
import { DatePickerProps } from '@/types/global'
import { QTimeInner } from '@/components/QForm/QField/QInput/QTime/QTimeInner'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'

export const QTime = (
  props: Omit<DatePickerProps, 'placeholderText'> & {
    placeholder?: string
    preScrollTo?: string
  } & Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, withWrapper, isHardName, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        withWrapper,
        name,
        isHardName,
      }}
    >
      <QTimeInner {...otherProps} />
    </QForm.Field>
  )
}

import React from 'react'
import { InputProps } from '@chakra-ui/react'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'
import { QSearchInner } from '@/components/QForm/QField/QInput/QSearch/QSearchInner'
import { QSearchPropsType } from '@/components/QForm/QField/QInput/QSearch/QSearch.types'

export const QSearch = (
  props: InputProps &
    Omit<QFieldPropsType<string>, 'children'> &
    QSearchPropsType,
) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QSearchInner {...otherProps} />
    </QForm.Field>
  )
}

import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { cargoPackageTypeOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { PackageType } from '@/api/generated/qubyApiJava'

type QSelectPackageTypePropsType = Omit<
  QSelectPropsType<IOption<PackageType>, PackageType>,
  'options'
>
export const QSelectCargoPackageType = (props: QSelectPackageTypePropsType) => {
  const [t] = useTranslation()
  const options = cargoPackageTypeOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

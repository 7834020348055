import { DlvError, qubyApiDlv } from './generated/qubyApiDlv'
import { PaginationResponse } from './generated/qubyApiPhp'

const convertParamMonitoring = (arg: GetMonitoringApiArg) => {
  if (arg.query) {
    return {
      num: arg.query,
    }
  }

  return {
    dateFrom: arg.dateFrom,
    dateTo: arg.dateTo,
    dlvStatuses:
      //@ts-ignore
      arg.dlvStatuses?.length ? arg.dlvStatuses.join(',') : arg.dlvStatuses,
    courierStatuses:
      arg.courierStatuses?.length ?
        //@ts-ignore
        arg.courierStatuses.join(',')
      : arg.courierStatuses,

    page: arg.currentPage ? arg.currentPage - 1 : 0,
    size: arg.perPage,
    statusDuration: arg.statusDuration,
    filterType: arg.filterType,
    warehouse: arg.warehouse,
    updating: arg.updating,
  }
}
export const qubyApiEgoDlv = qubyApiDlv.injectEndpoints({
  endpoints: (build) => ({
    getMonitoringList: build.query<
      GetMonitoringApiResponse,
      GetMonitoringApiArg
    >({
      query: (queryArg) => {
        const originalParams = convertParamMonitoring(queryArg)
        return {
          url: `https://dlv-app.kak2c.ru/api/monitoring/order${!queryArg.query ? 's' : ''}-info`,
          method: 'GET',
          params: {
            ...originalParams,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getMonitoringById: build.query<
      GetMonitoringByIdApiResponse,
      GetMonitoringByIdApiArg
    >({
      query: (queryArg) => {
        return {
          url: `https://dlv-app.kak2c.ru/api/monitoring/order-info`,
          method: 'GET',
          params: {
            dlvId: queryArg?.dlvId,
            updating: queryArg?.updating,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getMonitoringUploadingToCsById: build.mutation<
      PostMonitoringUploadingToCsByIdApiResponse,
      PostMonitoringUploadingToCsByIdApiArg
    >({
      query: (queryArg) => {
        return {
          url: `https://dlv-app.kak2c.ru/api/commitShipmentRequestList`,
          method: 'POST',
          body: {
            dlvIdList: queryArg?.dlvIdList,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      invalidatesTags: [],
    }),
    getPlanReturnsList: build.query<
      GetPlanReturns2ApiResponse,
      GetPlanReturns2ApiArgEgo
    >({
      query: (queryArg) => ({
        url: `https://dlv-app.kak2c.ru/api/returns/planReturnsAll`,
        method: 'GET',
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          docNumber: queryArg.query || undefined,
          warehouse: queryArg.warehouse,
          courierCode: queryArg.courierCode,
        },
        headers: {
          Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
        },
      }),
      providesTags: ['returns-controller'],
    }),
    getMonitoringLog: build.query<
      GetMonitoringLogApiResponse,
      GetMonitoringLogApiArg
    >({
      query: (queryArg) => {
        return {
          url: `https://dlv-app.kak2c.ru/api/monitoring/exchange`,
          method: 'GET',
          params: {
            type: queryArg.type,
            id: queryArg.id,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
  }),
  overrideExisting: true,
})
export type PostMonitoringUploadingToCsByIdApiArg = {
  dlvIdList?: string[]
}
export type PostMonitoringUploadingToCsByIdApiResponse = {
  success: boolean
  errors: ErrorsUploadingToCsResponse[]
  dlvId: string
  courierNum: string | null
  placeList: PlaceListResponse[]
}
export type PlaceListResponse = {
  placeId: string
  barcode: string
}
export type ErrorsUploadingToCsResponse = {
  code: MonitoringErrorType
  message: string
}
export type GetShipmentDlvApiArg = {
  currentPage?: number
  perPage?: number
  sort?: string
  places?: PlacesDlv
  syncRecord?: SyncRecordDlv
  courierCode?: string
  id?: string
  query?: string
}
export type PlacesDlv = {
  barcode?: string
}
export type SyncRecordDlv = {
  num?: string
  status?: string
  syncState?: string
  courierNum?: string
  createTsFrom?: string
  createTsTo?: string
}

export type ShipmentsDlvStatusEnum =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'ABORTED'
  | 'UPDATING_SUSPENDED'
export type DlvStatusEnum =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'DELIVERED'
  | 'RETURNED'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
  | 'CANCELED'

export type DeliveryDlvEnum =
  | 'A1'
  | 'BAIKAL'
  | 'Boxberry'
  | 'CDEK'
  | 'CDEKV2'
  | 'Cainiao'
  | 'CityExpress'
  | 'CSE'
  | 'DalliService'
  | 'Dellin'
  | 'DHL'
  | 'DOSTAVISTA'
  | 'DPD'
  | 'DPD_LATVIA'
  | 'EBULKY'
  | 'GD'
  | 'Goods'
  | 'Grastin'
  | 'HALVA'
  | 'HALVAV2'
  | 'Hermes'
  | 'IML'
  | 'Kak2cCourier'
  | 'Kaspi'
  | 'Kholodilnik'
  | 'Lamoda'
  | 'LeroyMerlin'
  | 'Logsis'
  | 'MaxiPost'
  | 'Novaposhta'
  | 'OZON'
  | 'OZON_DELIVERY'
  | 'PbmLatvia'
  | 'PEK'
  | 'PickPoint'
  | 'PickUp'
  | 'PODRYGKA_CDEK'
  | 'PODRYGKA_CSE'
  | 'PonyExpress'
  | 'QUBY'
  | 'RussianPost'
  | 'RussianPostApi'
  | 'RussianPostApiEcom'
  | 'SelfPickup'
  | 'SPSR'
  | 'STRIZH'
  | 'TEMPOLINE_RUSSIAN_POST'
  | 'UPS_Latvia'
  | 'Wildberries'
  | 'X5_Post'
  | 'YANDEX_MARKET'
  | 'YandexDelivery'
  | 'YandexDeliveryV2'
  | 'YandexDeliveryV3'

export type GetMonitoringApiArg = {
  statusDuration?: string
  dlvStatuses?: MonitoringDlvStatus[] | string
  courierStatuses?: MonitoringStatus[] | string
  filterType?: string
  num?: string
  currentPage?: number
  perPage?: number
  query?: string
  dateFrom?: string
  dateTo?: string
  year?: number
  month?: string | number
  week?: string | number
  day?: string | number
  hour?: string | number
  minute?: string | number
  second?: string | number
  updating?: string
  warehouse?: string
}
export type GetMonitoringByIdApiArg = {
  query?: string
  dlvId?: string
  updating?: string
}
export type GetMonitoringApiResponse = {
  content: MonitoringContent[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  pageable: Pageable
  sort: MonitoringContentSort
}
export type GetMonitoringByIdApiResponse = {
  content: MonitoringContent[]
}
export type MonitoringContent = {
  courierCode: string
  courierExchangeLog: DlvExchangeLog
  courierNum: string | null
  dlvId: string
  dlvStatus: MonitoringDlvStatus
  num: string
  status: MonitoringStatus
  dlvExchangeLog: DlvExchangeLog
  warehouseName?: string
  commitError?: string
  createTs?: string
}
export type DlvExchangeLog = {
  exchangeLogs: ExchangeLogs[]
}
export type MonitoringWarehouse = 'Внуково' | 'Коледино'
export type ExchangeLogs = {
  id: string
  login: string
  method: string
  methodType: string
  requestDate: string
  responseCode: string
  url: string
}
export type Pageable = {
  sort: PageableSort
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  unpaged: boolean
}
export type PageableSort = {
  empty: boolean
  unsorted: boolean
  sorted: boolean
}
export type MonitoringContentSort = {
  empty: boolean
  unsorted: boolean
  sorted: boolean
}

export type MonitoringContextType = {
  data: MonitoringContent[]
  meta: PaginationResponse
}
export type MonitoringStatus =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
export type MonitoringStatusFull =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
  | 'DELIVERED'
  | 'RETURNED'
  | 'CANCELED'
  | 'ACCEPTED'
  | 'UPDATING'

export type MonitoringDlvStatus =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'UPDATING_SUSPENDED'
export type MonitoringDlvStatusFull =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'UPDATING_SUSPENDED'
  | 'ABORTED'
export type MonitoringLogType = 'COURIER' | 'SHIPMENT'

export type MonitoringErrorType =
  | 'COMMIT_ERROR'
  | 'INACTION'
  | 'DEFAULT'
  | 'ALL_ERRORS'
  | 'WITHOUT_COMMIT'
export type MonitoringErrorTypeWithoutDefault =
  | 'INACTION'
  | 'DEFAULT'
  | 'WITHOUT_COMMIT'

export type GetMonitoringLogApiArg = {
  id: string
  type: MonitoringLogType
}
export type GetMonitoringLogApiResponse = {
  url: string
  request: string
  response: string
  responseCode: string
  login: string
}
export type GetPlanReturns2ApiArgEgo = {
  dateFrom?: string
  dateTo?: string
  docNumber?: string
  query?: string
  warehouse?: string
  courierCode?: string
}
export type GetPlanReturns2ApiResponse = /** status 200 OK */ ReturnsDocsDto
export type ReturnsDocsDto = {
  success?: boolean
  errors?: DlvError[]
  ReturnsDocs?: ReturnDocDto[]
}
export type ReturnDocDto = {
  CourierCode?: DeliveryDlvEnum
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  Shipments?: ReturnDocDtoShipments[]
  Elements: Elements[]
}
export type ReturnDocDtoShipments = {
  DlvId?: string
  Num?: string
  CourierNum?: string
  Client?: string
  Warehouse?: string
  Places?: PlaceDto[]
}
export type PlaceDto = {
  Barcode?: string
  PlaceId?: string
  ShipmentId?: string
  SubPieces?: SubPiecesItem[]
}
export type SubPiecesItem = {
  Barcode?: string
  Dame?: string
  ExternalId?: string
  Qty?: string
  VendorCode?: string
}
export type Elements = {
  description: string
  Qty: number | null
  ExternalId: string
  AdditionalCourierId: string | null
  Num: string
  CourierNum: string | null
  Warehouse: string | null
  Price: number | null
  ShipmentInfo: ShipmentInfo | null
  DlvPlaceInfo: DlvPlaceInfo | null
  DlvSubPieceInfo: DlvSubPieceInfo | null
}
export type DlvSubPieceInfo = {
  Description: string
  InternationalDescription: string
  Price: number
  VatSum: number
  VatType: string
  TnvedCode: string
  ExternalID: string
  VendorCode: string
  MarketPlaceCode: string
  Received: boolean
  ReceivedQuantity: number
  Barcode: string
  CountryOfOrigin: string
  Weight: number
  MarketplaceSkuId: string
  ControlIdMarks: string
  CustomData: string
}
export type DlvPlaceInfo = {
  PlaceId: string
  ShipmentId: string
  Barcode: string
}
export type ShipmentInfo = {
  DlvId: string
  Num: string
  CourierNum: string
  Client: string
  Warehouse: string
}
export type ReturnsDocsDtoEgo = {
  CourierCode?: DeliveryDlvEnum
  ConsDocNum?: string
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  DlvId?: string
  Num?: string
  CourierNum?: string
  Warehouse?: string
  PlaceBarcode?: string
  Client?: string
  PlaceId?: string
  ShipmentId?: string
  Barcode?: string
  Dame?: string
  ExternalId?: string
  Qty?: string
  VendorCode?: string
}
export type ReturnsDocsConvertType = {
  CourierCode?: DeliveryDlvEnum
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  Warehouse?: string | null
  ShipmentWarehouse?: string | null
  Client?: string
  PlaceBarcode?: string
  Barcode?: string
  Price?: number
  Description?: string
  VendorCode?: string
  Error?: boolean
  ExternalId?: string
}

import React, { FC } from 'react'
import { Box, Grid, GridProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { colors } from '@/theme/colors'
import { format } from '@/utils/dateHelperFns'
import { RunRequestStatusEnum } from '@/api/generated/qubyApiPhp'

interface Props {
  status: RunRequestStatusEnum
  style?: GridProps
  changeStatusDate?: string
  onClick?: () => void
}

const colorsCollection = {
  blue: [colors.accent[600], colors.accent[200]],
  orange: [colors.attention[600], colors.attention[200]],
  red: [colors.error[600], colors.error[200]],
  darkRed: [colors.error[1000], colors.error[200]],
  green: [colors.success[600], colors.success[200]],
  purple: ['#C536F6', '#FCF2FF'],
  unset: ['#000', 'E2E2E2'],
}
const statusColor: Partial<Record<RunRequestStatusEnum, string[]>> = {
  NEW: colorsCollection.purple,
  IN_PROGRESS: colorsCollection.blue,
  DONE: colorsCollection.green,
  IDLE_RUN: colorsCollection.darkRed,
  CANCELLED: colorsCollection.red,
  ERROR: colorsCollection.orange,
}

const RunRequestStatusLabel: FC<Props> = ({
  status,
  changeStatusDate,
  style,
  onClick,
}) => {
  const [t] = useTranslation()
  const colors = statusColor[status] || colorsCollection.unset
  const date = changeStatusDate ? ` • ${format(changeStatusDate)}` : null
  return (
    <Grid
      width="max-content"
      gridTemplateColumns="max-content 1fr"
      borderRadius="30px"
      py="0.5"
      px="1"
      alignItems="center"
      {...style}
      backgroundColor={colors[1]}
    >
      <Box
        mr="1"
        width="8px"
        height="8px"
        borderRadius="50%"
        bgColor={colors[0]}
      />
      <Text
        flexShrink="1"
        fontWeight="500"
        textAlign="center"
        fontSize="12px"
        lineHeight="12px"
      >
        <button {...{ onClick }}>
          {t(`constants.runRequestsStatus.${status}`)}
        </button>
        {date}
      </Text>
    </Grid>
  )
}

export default RunRequestStatusLabel

import React, { FC, PropsWithChildren, useCallback } from 'react'
import { QForm } from '@/components/QForm'
import { RTKMutationResult } from '@/types/rtkq.types'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import {
  DriverModalFormPropsType,
  DriverModalFormTypes,
} from './DriverModalForm.types'
import { DriverModalFormEndpoints } from './DriverModalForm.endpoints'
import {
  driverModalFormCreateYup,
  driverModalFormUpdateYup,
} from '@/_app/pages/registers/drivers/modals/driver/driverModalForm.yup'
import { DriverModalInner } from './DriverModalInner'

export const DriverModal: FC<PropsWithChildren<DriverModalFormPropsType>> = ({
  modalProps,
}) => {
  const [createDriver, { isLoading: isLoadingCreate }] =
    DriverModalFormEndpoints.create.useMutation()
  const [updateDriver, { isLoading: isLoadingUpdate }] =
    DriverModalFormEndpoints.update.useMutation()
  const isLoading = isLoadingUpdate || isLoadingCreate

  const modalData = modalProps.getData()
  const onSubmit = useCallback(
    async (driver: DriverModalFormTypes['ConsumedRequest']) => {
      function handle(
        response: RTKMutationResult<DriverModalFormTypes['ConsumedResponse']>,
      ) {
        const errors = qFormCollectErrors(response)
        if ('data' in response) {
          if (response.data) {
            modalData?.afterSave && modalData?.afterSave(response.data)
            modalProps.closeModal()
          }
        }
        return errors
      }

      if ('id' in driver) {
        const response = await updateDriver(driver)
        return handle(response)
      } else {
        const response = await createDriver(driver)
        return handle(response)
      }
    },
    [createDriver, updateDriver, modalProps, modalData],
  )

  return (
    <QForm<DriverModalFormTypes['ConsumedRequest']>
      requestKey={'driverStoreRequest'}
      initialValues={modalData?.driver}
      validationSchema={
        modalData?.driver && 'id' in modalData?.driver ?
          driverModalFormUpdateYup
        : driverModalFormCreateYup
      }
      {...{ onSubmit }}
    >
      <DriverModalInner
        {...{
          modalProps,
          isLoading,
        }}
      />
    </QForm>
  )
}

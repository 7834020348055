import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_204_15079)" fill="#475467">
        <path d="M14 16h-1.333V12.64a1.974 1.974 0 00-1.972-1.972h-5.39a1.973 1.973 0 00-1.972 1.972V16H2v-3.362a3.309 3.309 0 013.305-3.305h5.39A3.308 3.308 0 0114 12.639V16zM8 8a4 4 0 110-8 4 4 0 010 8zm0-6.667a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_204_15079">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

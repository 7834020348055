import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { QGroupListContext } from './QGroupList.context'
import { useDrag } from 'react-dnd'
import { QGroupDragItemType } from '@/components/QForm/QGroup/QGroup.constants'
import { QGroupListDragResultType } from '@/components/QForm/QGroupList/QGroupList.types'
import { QGroupListDraggableContext } from '@/components/QForm/QGroupList/QGroupListDraggable.context'

/**
 * Список групп
 * --
 * @see https://formik.org/docs/api/fieldarray
 *
 *
 */
export const QGroupListDraggable: React.FC<
  PropsWithChildren<{ index: number }>
> = ({ children, index }) => {
  const {
    dropName,
    name,
    value: listValue,
    arrayHelpers,
  } = useContext(QGroupListContext)
  const value = listValue[index]
  const [{ isDragging }, drag, preview] = useDrag(() => {
    return {
      type: QGroupDragItemType + dropName,
      item: {
        name,
        dropName,
        value,
      },
      end: (item, monitor) => {
        const { moveComplete } =
          (monitor.getDropResult() as QGroupListDragResultType) || {}
        if (moveComplete) {
          arrayHelpers?.remove(index)
        }
      },
      canDrag: () => Boolean(dropName),

      collect: (monitor) => ({
        isDragging: Boolean(monitor.isDragging() && dropName),
      }),
    }
  }, [value, dropName, name])

  return (
    <QGroupListDraggableContext.Provider
      value={{
        drag,
        preview,
        isDragging,
      }}
    >
      {children}
    </QGroupListDraggableContext.Provider>
  )
}

import { useCallback, useMemo, useRef, useState } from 'react'
import { ICallback } from '@/types/global'

export type ModalController<T = unknown> = {
  closeModal: (callback?: ICallback) => void
  openModal: (data?: T) => void
  isOpen: boolean
  getData: () => T | undefined
}

export default function useModalState<T>(): ModalController<T> {
  const [isOpen, setIsOpen] = useState(false)
  const data = useRef<T>()

  const closeModal: ModalController<T>['closeModal'] = useCallback(
    (callback) => {
      data.current = undefined
      if (callback) {
        callback()
      }
      setIsOpen(false)
    },
    [],
  )
  const openModal = useCallback((_data?: T) => {
    data.current = _data
    setIsOpen(true)
  }, [])
  const getData = useCallback(() => {
    return data.current
  }, [])

  return useMemo(() => {
    return {
      openModal,
      closeModal,
      isOpen,
      getData,
    }
  }, [closeModal, getData, isOpen, openModal])
}

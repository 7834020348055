import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { LocationTypes } from '@/api/generated/qubyApiPhp'
import { QForm } from '@/components/QForm'
import {
  OnSingleItemLoadedFunctionType,
  SelectAsyncQueriesConfig,
} from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  QSelectAsyncLocationPropsType,
  QSelectAsyncPropsType,
  QSelectEndpointTypes,
} from './QSelectAsync.types'
import { QSelectAsyncEndpoints } from './QSelectAsync.endpoints'
import { QSelectAsyncUniversalLocationTopAddonContent } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/QSelectAsyncUniversalLocationTopAddonContent'
import { universalLocationsRenderSelected } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/universalLocationsRenderSelected'
import { universalLocationsRenderOption } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/universalLocationsRenderOption'
import { useClientContext } from '@/providers/Client/useClientContext'
import { useField } from 'formik'
import { useQGroupContext } from '@/components/QForm/QGroup/useQGroupContext'

export const QSelectAsyncUniversalLocations = (
  props: QSelectAsyncPropsType & QSelectAsyncLocationPropsType,
) => {
  const {
    singleEndpointAdditionalArgs,
    listEndpointAdditionalArgs,
    disabledLocationTypes = [],
    isDisableLocationChange,
    ...otherProps
  } = props

  const locationState = useState<LocationTypes>('ADDRESS')
  const [locationType, setLocationType] = locationState

  const { name: groupName } = useQGroupContext()
  const fieldName = `${groupName ? `${groupName}.` : ''}${props.name}`
  const [, , { setValue }] = useField(fieldName)
  useEffect(() => {
    if (disabledLocationTypes && disabledLocationTypes.includes(locationType)) {
      setValue('')
      setLocationType('ADDRESS')
    }
  }, [disabledLocationTypes, locationType, setLocationType, setValue])

  const onSingleItemLoaded: OnSingleItemLoadedFunctionType<
    QSelectEndpointTypes['SingleEndpoint']
  > = useCallback(
    ({ type }) => {
      setLocationType(type)
    },
    [setLocationType],
  )

  const { clientId } = useClientContext()

  const queriesConfig: SelectAsyncQueriesConfig<
    QSelectEndpointTypes['SingleEndpoint'],
    QSelectEndpointTypes['ListEndpoint']
  > = {
    single: {
      query: QSelectAsyncEndpoints.getById,
      args: (value: typeof props.value) => {
        return {
          id: String(value),
          ...(singleEndpointAdditionalArgs ?
            singleEndpointAdditionalArgs()
          : {}),
        }
      },
    },
    list: {
      query: QSelectAsyncEndpoints.getList,
      args: () => {
        return {
          partnerId: clientId,
          type: locationType,
          ...(listEndpointAdditionalArgs ? listEndpointAdditionalArgs() : {}),
        }
      },
    },
  }

  return (
    <Box>
      <QForm.Field.SelectAsync<
        QSelectEndpointTypes['SingleEndpoint'],
        QSelectEndpointTypes['ListEndpoint'],
        QSelectEndpointTypes['ListItem']
      >
        renderSelected={universalLocationsRenderSelected}
        renderOption={universalLocationsRenderOption}
        isWithoutButtons
        topAddonContent={
          <QSelectAsyncUniversalLocationTopAddonContent
            {...{
              disabledLocationTypes,
              locationType,
              setLocationType,
              isDisableLocationChange,
            }}
          />
        }
        {...{
          queriesConfig,
          onSingleItemLoaded,
          ...otherProps,
        }}
      />
    </Box>
  )
}

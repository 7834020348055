import { useContext } from 'react'
import { BaseTablePageContext } from '@/components/BaseTablePage/BaseTablePage.context'
import { TableQuerySettingsContextType } from '@/containers/RunRequests/table-query-settings/tableQuerySettings.types'
import { ListEndpointType } from './PlanningRoutesPage.types'

export const usePlanningRoutesTableQueryContext = () => {
  const context = useContext(BaseTablePageContext)
  if (!context?.isHasProvider) {
    throw new Error('Компонент не обёрнут контекстом BaseTablePageContext')
  }
  return context as TableQuerySettingsContextType<
    ListEndpointType['Types']['QueryArg'],
    ListEndpointType['Types']['ResultType']
  >
}

import { FieldArray, getIn } from 'formik'
import * as React from 'react'
import { useContext } from 'react'
import { QGroupListContext } from './QGroupList.context'
import { QFormGroupListPropsType } from './QGroupList.types'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import { QGroup } from '@/components/QForm/QGroup/QGroup'
import { QGroupContext } from '@/components/QForm/QGroup/QGroup.context'
import { QGroupListDroppable } from '@/components/QForm/QGroupList/QGroupListDroppable'
import { QGroupListDraggable } from '@/components/QForm/QGroupList/QGroupListDraggable'

/**
 * Список групп
 * --
 * @see https://formik.org/docs/api/fieldarray
 *
 *
 */
export const QGroupList: React.FC<QFormGroupListPropsType> = ({
  children,
  name,
  isHardName,
  dropName,
  groupListWrapper = (content) => content,
}) => {
  const parentGroup = useContext(QGroupContext)
  const { values } = useQFormContext()
  const resultName =
    isHardName || !parentGroup.hasProvider ?
      name
    : [parentGroup.name, name].join('.')

  const value: unknown[] = getIn(values, resultName) || []

  return (
    <FieldArray
      name={resultName}
      render={(arrayHelpers) => (
        <QGroupListContext.Provider
          value={{
            dropName,
            hasProvider: true,
            name: resultName,
            isHardName,
            value,
            arrayHelpers,
          }}
        >
          <QGroupListDroppable>
            {groupListWrapper(
              <>
                {value.map((_, index) => {
                  // @ts-ignore
                  const key =
                    // @ts-ignore
                    (typeof _ === 'string' ? _ : _?.id) || `index-${index}`
                  const groupName = `${name}.[${index}]`
                  return (
                    <QGroupListDraggable key={key} {...{ index }}>
                      <QGroup index={index} name={groupName}>
                        {children}
                      </QGroup>
                    </QGroupListDraggable>
                  )
                })}
              </>,
              value,
            )}
          </QGroupListDroppable>
        </QGroupListContext.Provider>
      )}
    />
  )
}

import * as yup from 'yup'
import { QueryTableArgsSchema } from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import {
  GetInternalRunRequestsListApiArg,
  RunRequestOrderByTypeEnum,
  RunRequestStatusEnum,
  RunRequestTypesEnum,
} from '@/api/generated/qubyApiPhp'
import { unionArrays } from '@/constants/unionArrays'
import { emptySpace } from '@/types/emptySpace'

const runRequestsTypeSchema = yup
  .mixed<RunRequestTypesEnum>()
  .oneOf(unionArrays.runRequestTypes)
const runRequestsStatusSchema = yup
  .mixed<RunRequestStatusEnum>()
  .oneOf(unionArrays.runRequestStatus)
const runRequestsOrderBySchema = yup
  .mixed<RunRequestOrderByTypeEnum>()
  .oneOf(unionArrays.runRequestOrderBy)

export const runRequestQueryArgsYupSchema: QueryTableArgsSchema<GetInternalRunRequestsListApiArg> =
  yup.object({
    ids: yup.array().of(yup.string().required()),
    query: yup.string(),
    partnerIds: yup
      .array()
      .of(yup.string().required().meta({ placeholder: 'Выбрать клиента…' }))
      .label('Клиент'),
    deliveryServiceIds: yup
      .array()
      .of(
        yup
          .string()
          .required()
          .meta({ placeholder: 'Выбрать службу доставки…' }),
      )
      .label('Служба доставки'),
    createdDateTo: yup.string().date().label(emptySpace).meta({
      placeholder: 'По',
    }),
    createdDateFrom: yup.string().date().label('Создано').meta({
      placeholder: 'C',
    }),
    updatedDateFrom: yup.string().date().label('Статус изменен').meta({
      placeholder: 'C',
    }),
    updatedDateTo: yup.string().date().label(emptySpace).meta({
      placeholder: 'По',
    }),
    arrivalDateFrom: yup.string(),
    arrivalDateTo: yup.string(),
    currentPage: yup.number(),
    perPage: yup.number(),
    types: yup.array().of(runRequestsTypeSchema.required()).label('Тип'),
    statuses: yup
      .array()
      .of(
        runRequestsStatusSchema
          .required()
          .meta({ placeholder: 'Выбрать статус…' }),
      )
      .label('Статус'),
    orderBy: runRequestsOrderBySchema,
    withoutRoutesAtDate: yup.string(),
  })

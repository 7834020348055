import React, { useState } from 'react'
import { QForm } from '@/components/QForm'
import { SelectAsyncQueriesConfig } from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  QSelectAsyncLocationPropsType,
  QSelectAsyncPropsType,
  QSelectEndpointTypes,
} from './QSelectAsync.types'
import { QSelectAsyncEndpoints } from './QSelectAsync.endpoints'
import { useClientContext } from '@/providers/Client/useClientContext'
import useModalState from '@/hooks/useModalState'
import { LocationModalStateType } from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModalForm.types'
import { v4 as uuidv4 } from 'uuid'
import { LocationModal } from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModal'
import { locationsRenderSelected } from '@/components/QForm/QField/QSelectAsync/Locations/locationsRenderSelected'
import { locationsRenderOption } from '@/components/QForm/QField/QSelectAsync/Locations/locationsRenderOption'

export const QSelectAsyncLocations = (
  props: QSelectAsyncPropsType & QSelectAsyncLocationPropsType,
) => {
  const {
    singleEndpointAdditionalArgs,
    listEndpointAdditionalArgs,
    type,
    ...otherProps
  } = props

  const { clientId } = useClientContext()

  const queriesConfig: SelectAsyncQueriesConfig<
    QSelectEndpointTypes['SingleEndpoint'],
    QSelectEndpointTypes['ListEndpoint']
  > = {
    single: {
      query: QSelectAsyncEndpoints.getById,
      args: (value: typeof props.value) => {
        return {
          id: String(value),
          ...(singleEndpointAdditionalArgs ?
            singleEndpointAdditionalArgs()
          : {}),
        }
      },
    },
    list: {
      query: QSelectAsyncEndpoints.getList,
      skip: !type,
      args: () => {
        return {
          partnerId: clientId,
          type,
          ...(listEndpointAdditionalArgs ? listEndpointAdditionalArgs() : {}),
        }
      },
    },
  }
  const [uuid, setUuid] = useState(uuidv4())
  const modalProps = useModalState<LocationModalStateType>()
  const { clientId: partnerId } = useClientContext()

  return (
    <>
      <LocationModal key={uuid} {...{ modalProps }} />
      <QForm.Field.SelectAsync<
        QSelectEndpointTypes['SingleEndpoint'],
        QSelectEndpointTypes['ListEndpoint'],
        QSelectEndpointTypes['ListItem']
      >
        renderSelected={locationsRenderSelected}
        renderOption={locationsRenderOption}
        isWithoutButtons
        onCreateNew={
          partnerId && type === 'ADDRESS' ?
            (fullName, afterSave) => {
              setUuid(uuidv4())
              modalProps.openModal({
                location: {
                  partnerId,
                  locationStoreRequest: { fullName: String(fullName) },
                },
                afterSave,
              })
            }
          : undefined
        }
        onEdit={(location, afterSave) => {
          setUuid(uuidv4())
          modalProps.openModal({
            location: {
              id: location.id,
              locationStoreRequest: {
                fullName: String(location.fullName),
                phoneNumber: String(location.phoneNumber),
                addressString: String(location.addressString),
                additionalPhoneNumber: String(location.additionalPhoneNumber),
                comment: String(location.comment),
                // @ts-ignore
                floor: location.floor,
              },
            },
            afterSave,
          })
        }}
        {...{
          queriesConfig,
          ...otherProps,
        }}
      />
    </>
  )
}

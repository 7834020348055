import React, { PropsWithChildren } from 'react'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import Modal from '@/components/Modal'
import { Flex, Grid, Tooltip } from '@chakra-ui/react'
import Button from '@/components/Button/Button'
import { FormikValues } from 'formik'
import { QFormModalPropsType } from '@/components/QForm/QFormModal/QFormModal.types'
import { QForm } from '@/components/QForm'

type Props<T extends FormikValues> = {
  isDirty?: boolean
} & Pick<
  QFormModalPropsType<T>,
  'modalProps' | 'header' | 'submitButtonText' | 'cancelButtonText'
>
export const QFormModalInner = <T extends FormikValues>({
  modalProps,
  header,
  children,
  submitButtonText = 'Отправить',
  cancelButtonText = 'Отменить',
  isDirty = false,
}: PropsWithChildren<Props<T>>) => {
  const {
    isValid,
    formik: { dirty, isSubmitting, isValidating, handleSubmit },
  } = useQFormContext<T>()
  const isLoading = isSubmitting || isValidating
  const withoutDirty = !isDirty ? isValid && dirty : isValid

  return (
    <Modal
      isPreventCloseByClickOutside
      {...{
        ...modalProps,
        header,
      }}
      buttons={
        <Grid gridAutoFlow="column" gridGap="4">
          <Button
            onClick={() => {
              return modalProps.closeModal()
            }}
            size="l"
            variant="secondary"
          >
            {cancelButtonText}
          </Button>
          <Tooltip label={isValid ? undefined : <QForm.Errors />}>
            <Flex alignItems={'stretch'} justifyContent={'stretch'}>
              <Button
                variant={withoutDirty ? 'accent' : 'disabled'}
                isLoading={isLoading}
                size="l"
                onClick={() => handleSubmit()}
              >
                {submitButtonText}
              </Button>
            </Flex>
          </Tooltip>
        </Grid>
      }
    >
      {children}
      <QForm.ResponseErrors />
    </Modal>
  )
}

import React, { useEffect, useState } from 'react'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import { ResponseErrorsRender } from '@/components/QForm/ResponseErrorsRender'
import { ResponseErrorsDlvRender } from './ResponseErrorsDlvRender'

export const QFormResponseErrors = () => {
  const { resultErrors } = useQFormContext()
  const [dlvErrors, setDlvErrors] = useState(false)
  useEffect(() => {
    if (resultErrors) {
      //@ts-ignore
      if ('success' in resultErrors || resultErrors?.success) {
        setDlvErrors(true)
      } else {
        setDlvErrors(false)
      }
    } else {
      setDlvErrors(false)
    }
  }, [resultErrors, setDlvErrors])

  return (
    <>
      {dlvErrors ?
        <ResponseErrorsDlvRender {...{ resultErrors }} />
      : <ResponseErrorsRender {...{ resultErrors }} />}
    </>
  )
}

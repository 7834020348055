import { Box, Grid, Stack, Tooltip } from '@chakra-ui/react'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal'
import React, { FC, useCallback } from 'react'
import { QForm } from '@/components/QForm/QForm'
import { QGroup } from '@/components/QForm/QGroup/QGroup'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import {
  DriverModalFormPropsType,
  DriverModalFormTypes,
} from './DriverModalForm.types'

type Props = {
  isLoading: boolean
} & Pick<DriverModalFormPropsType, 'modalProps'>
export const DriverModalInner: FC<Props> = ({ modalProps, isLoading }) => {
  const { formik } = useQFormContext<DriverModalFormTypes['ConsumedRequest']>()

  const propsCloseModal = modalProps.closeModal
  const closeModal = useCallback(() => {
    propsCloseModal()
  }, [propsCloseModal])
  const modalData = modalProps.getData()
  const yupValue =
    //@ts-ignore
    modalData?.driver?.id ? 'driverUpdateRequest' : 'driverStoreRequest'
  return (
    <Modal
      {...modalProps}
      header={
        <Box>
          {
            //@ts-ignore
            modalData?.driver?.id ?
              //@ts-ignore
              formik.values.driverUpdateRequest.fullName || 'Без названия'
            : 'Новый водитель'
          }
        </Box>
      }
      buttons={
        <Grid gridAutoFlow="column" gridGap="4">
          <Button onClick={closeModal} size="l" variant="secondary">
            Отменить
          </Button>
          <Tooltip
            label={Object.values(formik.errors).length > 0 && <QForm.Errors />}
          >
            <div>
              <Button
                {...{ isLoading }}
                size="l"
                onClick={() => {
                  formik.handleSubmit()
                }}
                variant={formik.isValid ? 'accent' : 'disabled'}
              >
                {
                  //@ts-ignore
                  modalData?.driver?.id ? 'Сохранить' : 'Создать'
                }
              </Button>
            </div>
          </Tooltip>
        </Grid>
      }
    >
      <QGroup name={yupValue}>
        <Stack spacing={3}>
          <QForm.Field.String withWrapper name={'fullName'} />
          <Stack direction={'row'} spacing={3}>
            <QForm.Field.String withWrapper name={'driverLicence'} />
            <QForm.Field.String withWrapper name={'phoneNumber'} />
          </Stack>
          <QForm.Field.String withWrapper name={'passportData'} />
          {
            //@ts-ignore
            modalData?.driver?.id ?
              <QForm.Field.Switch withWrapper name={'fired'} />
            : null
          }
        </Stack>
      </QGroup>
      <QForm.ResponseErrors />
    </Modal>
  )
}

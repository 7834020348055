import { Card, Grid, Stack, StackItem, Text } from '@chakra-ui/react'
import React from 'react'
import { SelectAsyncRenderSelectedFunctionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import { QSelectEndpointTypes } from './QSelectAsync.types'
import { SelectAsyncComponent } from '@/components/atoms/SelectAsync'
import AddressIcon from '@/components/Icons/AddressIcon'
import { colors } from '@/theme/colors'
import PhoneIcon from '@/components/Icons/PhoneIcon'
import { formatPhone } from '@/utils/formatPhone'
import styled from 'styled-components'

const ButtonsWrapper = styled.div`
  position: relative;
  top: -6px;
  right: -10px;
`
export const locationsRenderSelected: SelectAsyncRenderSelectedFunctionType<
  QSelectEndpointTypes['Item']
> = ({ type, fullName, phoneNumber, addressString }) => (
  <Card
    maxW={'100%'}
    background={'var(--100, #FFF)'}
    boxShadow={'0px 0px 20px 0px rgba(154, 171, 215, 0.12)'}
    p={'14px 20px'}
  >
    <Stack spacing={'16px'}>
      <div>
        <Grid gridTemplateColumns={'1fr max-content'} alignItems={'center'}>
          <Text textStyle={'h5'}>{fullName}</Text>{' '}
          <ButtonsWrapper>
            {type === 'ADDRESS' && <SelectAsyncComponent.EditButton />}
            <SelectAsyncComponent.ChangeButton />
            <SelectAsyncComponent.ClearButton />
          </ButtonsWrapper>
        </Grid>
      </div>

      <StackItem>
        <Grid gridTemplateColumns={'min-content 1fr'} gridGap={'10px'}>
          <AddressIcon color={colors.neutral['500']} boxSize={'16px'} />
          <Text textStyle={'text4'}>{addressString}</Text>
        </Grid>
      </StackItem>
      <StackItem>
        <Grid
          gridTemplateColumns={'min-content 1fr max-content'}
          gridGap={'10px'}
        >
          <PhoneIcon boxSize={'16px'} color={colors.neutral['500']} />
          <Text textStyle={'text4'} overflowWrap={'normal'}>
            {formatPhone(phoneNumber)}
          </Text>
        </Grid>
      </StackItem>
    </Stack>
  </Card>
)

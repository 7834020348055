import { Grid, Text, Tooltip } from '@chakra-ui/react'
import React, { FC } from 'react'
import { colors } from '@/theme/colors'
import { FieldWrapperProps } from '@/components/moleculs/fields/Field/FieldWrapper.types'

const FieldWrapper: FC<FieldWrapperProps> = ({
  children,
  title,
  caption,
  subtitle,
  isDisabled,
  errorText,
  resultName,
}) => {
  return (
    <Tooltip placement={'top'} label={Boolean(errorText) && errorText}>
      <Grid gridGap="10px" data-test-id={'field_wrapper'}>
        <>
          {title && (
            <Text
              as={'div'}
              lineHeight="1"
              textStyle="h5"
              data-test-class={'field_title'}
              data-test-result-field-name={resultName}
              {...{ ...(isDisabled ? { color: colors.neutral['500'] } : {}) }}
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <Text
              as={'div'}
              textStyle="field-caption"
              data-test-class={'field_title'}
            >
              {subtitle}
            </Text>
          )}
          {children}
          {caption && (
            <Text as={'div'} textStyle="caption">
              {caption}
            </Text>
          )}
        </>
      </Grid>
    </Tooltip>
  )
}

export default FieldWrapper

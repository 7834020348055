import * as yup from 'yup'
import { VatRate } from '@/api/generated/qubyApiJava'
import { unionArrays } from '@/constants/unionArrays'
import { QSelectEndpointTypes } from '@/components/QForm/QField/QSelectAsync/Goods/QSelectAsync.types'

export const VatRateYup: yup.MixedSchema<VatRate> = yup
  .mixed<VatRate>()
  .oneOf(unionArrays.vatRate)
  .required()
export const goodGoodRequestYup: yup.ObjectSchema<
  QSelectEndpointTypes['ConsumedRequest']
> = yup.object({
  partnerId: yup.string().id().required(),
  goodStoreRequest: yup.object({
    vendorCode: yup.string().label('Код производителя / артикул'),
    name: yup.string().label('Наименование').required(),
    externalId: yup.string().label('Код товара в вашей системе'),
    vatRate: VatRateYup.label('Ставка НДС').optional(),
    price: yup.number().label('Цена').required(),
  }),
})

import * as React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
const id = uuidv4()
export const VehiclesIcon = (props: IconProps) => (
  <Icon viewBox={'0 0 24 24'} {...props}>
    <g clipPath={`url(#${id})`}>
      <path
        d="M19 5h-2.1A5.01 5.01 0 0 0 12 1H5a5.006 5.006 0 0 0-5 5v9a4 4 0 0 0 3.061 3.876 3.5 3.5 0 1 0 6.9.123h4.082a3.36 3.36 0 0 0-.041.5 3.5 3.5 0 1 0 7 0 3.4 3.4 0 0 0-.061-.623A4.001 4.001 0 0 0 24 15v-5a5.006 5.006 0 0 0-5-5Zm3 5v1h-5V7h2a3 3 0 0 1 3 3ZM2 15V6a3 3 0 0 1 3-3h7a3 3 0 0 1 3 3v11H4a2 2 0 0 1-2-2Zm6 4.5a1.5 1.5 0 0 1-3 0c0-.172.032-.34.093-.5h2.814c.06.16.092.328.093.5Zm9.5 1.5a1.5 1.5 0 0 1-1.5-1.5c0-.172.032-.341.093-.5h2.814c.061.159.093.328.093.5a1.5 1.5 0 0 1-1.5 1.5Zm2.5-4h-3v-4h5v2a2 2 0 0 1-2 2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id={id}>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

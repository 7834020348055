import * as React from 'react'
import { QFormGroupListContextValueType } from './QGroupList.types'

const QFormGroupListContextDefaultValue: QFormGroupListContextValueType = {
  hasProvider: false,
  name: 'isFakeName',
  value: ['Нет провайдера группы!'],
  arrayHelpers: undefined,
  dropName: '',
}
export const QGroupListContext =
  React.createContext<QFormGroupListContextValueType>(
    QFormGroupListContextDefaultValue,
  )

import { ObjectSchema } from 'yup'
import { useCallback, useMemo } from 'react'

type Props<StorageDataType extends Record<string, unknown>> = {
  yupSchema?: ObjectSchema<StorageDataType>
  storageKey?: string
}
const useSavedStorageData = <StorageDataType extends Record<string, unknown>>({
  storageKey,
  yupSchema,
}: Props<StorageDataType>) => {
  return useMemo(() => {
    if (!storageKey || !yupSchema) {
      return null
    }
    const savedToLocalStorageJsonString =
      storageKey ? localStorage.getItem(storageKey) : null
    if (!savedToLocalStorageJsonString) {
      return null
    }
    try {
      const savedRequestSettingsInner: StorageDataType = JSON.parse(
        savedToLocalStorageJsonString,
      )
      return yupSchema.cast(savedRequestSettingsInner) as StorageDataType
    } catch (e) {
      console.error(e)
      localStorage.removeItem(storageKey)
      return null
    }
  }, [storageKey, yupSchema])
}
export const useLocalStorageWithYup = <
  StorageDataType extends Record<string, unknown>,
>({
  yupSchema,
  storageKey,
}: Props<StorageDataType>) => {
  const savedStorageData: StorageDataType | null =
    useSavedStorageData<StorageDataType>({
      storageKey,
      yupSchema,
    })

  const updateStorage = useCallback(
    (settings: StorageDataType | null) => {
      if (storageKey) {
        if (settings) {
          const saveData = JSON.stringify(settings)
          localStorage.setItem(storageKey, saveData)
        } else {
          localStorage.removeItem(storageKey)
        }
      }
    },
    [storageKey],
  )

  return {
    updateStorage,
    savedStorageData,
  }
}

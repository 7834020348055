import * as yup from 'yup'

import { VehicleModalFormTypes } from '@/_app/pages/registers/vehicles/modals/car/VehicleModalForm.types'

const vehicleCreateRequest: yup.ObjectSchema<
  VehicleModalFormTypes['CreateRequest']['vehicleStoreRequest']
> = yup.object({
  brand: yup.string().required().label('Марка'),
  plateNumber: yup.string().required().label('Номер'),
  description: yup.string().label('Описание'),
  capacity: yup.object({
    weight: yup.number().default(0).label('Масса, кг'),
    volume: yup.object({
      height: yup.number().default(0).label('Высота, м'),
      width: yup.number().default(0).label('Ширина, м'),
      depth: yup.number().default(0).label('Глубина, м'),
    }),
  }),
  numberSts: yup.string().label('Номер СТС'),
  numberVin: yup.string().label('Номер ВИН'),
  isActive: yup.boolean().label('Активен'),
})

const vehicleUpdateRequest: yup.ObjectSchema<
  VehicleModalFormTypes['UpdateRequest']['vehicleUpdateRequest']
> = yup.object({
  brand: yup.string().required().label('Марка'),
  plateNumber: yup.string().required().label('Номер'),
  description: yup.string().label('Описание'),
  capacity: yup.object({
    weight: yup.number().default(0).label('Масса, кг'),
    volume: yup.object({
      height: yup.number().default(0).label('Высота, м'),
      width: yup.number().default(0).label('Ширина, м'),
      depth: yup.number().default(0).label('Глубина, м'),
    }),
  }),
  numberSts: yup.string().label('Номер СТС'),
  numberVin: yup.string().label('Номер ВИН'),
  isActive: yup.boolean().label('Активен'),
})

export const vehicleModalFormCreateYup: yup.ObjectSchema<
  VehicleModalFormTypes['CreateRequest']
> = yup.object({
  vehicleStoreRequest: vehicleCreateRequest.required(),
})
export const vehicleModalFormUpdateYup: yup.ObjectSchema<
  VehicleModalFormTypes['UpdateRequest']
> = yup.object({
  vehicleUpdateRequest: vehicleUpdateRequest.required(),
  id: yup.string().id().required(),
})

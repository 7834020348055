import React from 'react'
import { QForm } from '@/components/QForm'
import { SelectAsyncQueriesConfig } from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  QSelectAsyncPropsType,
  QSelectEndpointTypes,
} from './QSelectAsync.types'
import { QSelectAsyncEndpoints } from './QSelectAsync.endpoints'

export const QSelectAsyncDeliveryService = (props: QSelectAsyncPropsType) => {
  const {
    singleEndpointAdditionalArgs,
    listEndpointAdditionalArgs,
    ...otherProps
  } = props

  const queriesConfig: SelectAsyncQueriesConfig<
    QSelectEndpointTypes['SingleEndpoint'],
    QSelectEndpointTypes['ListEndpoint']
  > = {
    single: {
      query: QSelectAsyncEndpoints.getById,
      args: (value: typeof props.value) => {
        return {
          id: String(value),
          ...(singleEndpointAdditionalArgs ?
            singleEndpointAdditionalArgs()
          : {}),
        }
      },
    },
    list: {
      query: QSelectAsyncEndpoints.getList,
      args: () => {
        return {
          perPage: 200,
          isActive: true,
          ...(listEndpointAdditionalArgs ? listEndpointAdditionalArgs() : {}),
        }
      },
    },
  }

  return (
    //@ts-ignore
    <QForm.Field.SelectAsync<
      QSelectEndpointTypes['SingleEndpoint'],
      // @ts-ignore
      QSelectEndpointTypes['ListEndpoint'],
      QSelectEndpointTypes['Item']
    >
      //isWithoutButtons
      {...{
        queriesConfig,
        ...otherProps,
      }}
    />
  )
}

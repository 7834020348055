import React, { useCallback } from 'react'
import CrossIcon from '@/components/Icons/CrossInCircle'
import { RunRequestEndpointTypes } from '../../runRequest/RunRequest.types'
import {
  RunRequestResource,
  TransitionTiIdleRunInternalRunRequestApiArg,
} from '@/api/generated/qubyApiPhp'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { ModalController } from '@/hooks/useModalState'

type menuItemsProps = {
  runRequest: RunRequestResource[]
  setRunRequestList: React.Dispatch<React.SetStateAction<RunRequestResource[]>>
  confirmIdleRunModalProps: ModalController<TransitionTiIdleRunInternalRunRequestApiArg>
}
export const useRoutesTableMenuItems = ({
  setRunRequestList,
  runRequest,
  confirmIdleRunModalProps,
}: menuItemsProps) => {
  return useCallback(
    (row: RunRequestEndpointTypes['ListItem']) => {
      return [
        {
          label: 'Удалить',
          icon: <CrossIcon />,
          onClick: async () => {
            setRunRequestList(runRequest?.filter((el) => el?.id !== row?.id))
          },
        },
        ...(row.status !== 'IDLE_RUN' ?
          [
            {
              label: 'Холостой прогон',
              icon: <WarningTwoIcon />,
              onClick: async () => {
                confirmIdleRunModalProps.openModal({ id: row.id })
              },
            },
          ]
        : []),
      ]
    },
    [runRequest, setRunRequestList, confirmIdleRunModalProps],
  )
}

import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { DatePickerProps } from '@/types/global'
import { QTimeCore } from '@/components/QForm/QField/QInput/QTime/QTimeCore'

export const QTimeInner = (
  props: DatePickerProps & { placeholder?: string },
) => {
  const { name, propsFromYup, onChangeValue } = useQFieldContext()
  const [field, meta] = useField(name)
  const isInvalid = Boolean(meta.error && meta.touched)

  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QTimeCore
        {...{
          ...field,
          isInvalid,
          onChangeValue,
        }}
      />
    </ComponentPropsDecorator>
  )
}

// @ts-ignore
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { QFormSubmitErrorsReturn } from '@/components/QForm/QForm.types'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'

export const ResponseErrorsRender = (props: {
  resultErrors: QFormSubmitErrorsReturn | undefined
}) => {
  const { resultErrors } = props
  if (
    !resultErrors ||
    !('errors' in resultErrors) ||
    !('message' in resultErrors) ||
    !resultErrors.errors ||
    !resultErrors.message
  ) {
    return null
  }
  return (
    <ErrorBoundary component={'Вывод ошибок завершился ошибкой 0_о'}>
      <Center>
        <Alert borderRadius={'4px'} status={'error'} mt={12} maxW={'600px'}>
          <AlertIcon />
          <Box>
            <AlertTitle textStyle={'h5'}>{resultErrors.message}</AlertTitle>
            <AlertDescription>
              <Box maxH={'100px'} overflow={'auto'}>
                {resultErrors.errors &&
                  isObject(resultErrors.errors) &&
                  !isArray(resultErrors.errors) &&
                  Object.entries(resultErrors.errors).map(
                    ([title, error], i) => {
                      return (
                        <Box mt={3} key={i}>
                          <Text textStyle={'h6'}>{title}</Text>
                          {
                            typeof error === 'string' ?
                              <Text textStyle={'caption'}>{error}</Text>
                              // @ts-ignore
                            : error.map((text, index) => {
                                return (
                                  <Text key={index} textStyle={'caption'}>
                                    {text}
                                  </Text>
                                )
                              })

                          }
                        </Box>
                      )
                    },
                  )}
              </Box>
            </AlertDescription>
          </Box>
        </Alert>
      </Center>
    </ErrorBoundary>
  )
}

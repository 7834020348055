import { Box } from '@chakra-ui/react'
import React, { forwardRef, PropsWithChildren } from 'react'

export const TooltipInnerSpan = forwardRef<HTMLDivElement, PropsWithChildren>(
  (props, ref) => {
    return (
      <Box
        ref={ref}
        as={'span'}
        borderBottom={'1px dashed'}
        color={'accent.600'}
        cursor={'help'}
        position={'relative'}
        {...props}
      />
    )
  },
)

import React, { FC } from 'react'
import SelectArrow from '@/components/Icons/SelectArrow'
import { motion as m } from 'framer-motion'

interface Porps {
  isOpen: boolean
}

const SelectIndicator: FC<Porps> = ({ isOpen }) => {
  return (
    <m.div animate={{ rotateZ: isOpen ? 180 : 0 }}>
      <SelectArrow />
    </m.div>
  )
}

export default SelectIndicator

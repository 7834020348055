import React, { useCallback, useEffect, useRef, useState } from 'react'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { IOption } from '@/types/global'
import Select from '@/components/atoms/Select'
import { QSelectBaseProps } from '@/components/QForm/QField/QSelect/Qselect.types'

export const QSelectCore = <
  OptionType extends IOption<ValueType>,
  ValueType extends string = string,
>(
  props: QSelectBaseProps<OptionType, ValueType> & QFieldCorePopsType,
) => {
  const {
    options = [],
    getValue = (v) => v.value,
    placeholder = 'Выберите...',
    renderOption = ({ label }) => label,
    getSelectedValueInputString = (selectedOption) => selectedOption.label,
    filterOptions = () => true,
    isInvalid,
    helpers,
    disabled,
  } = props

  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined
  const propsValue = props.value

  const innerRef = useRef<NodeJS.Timeout>()

  const [innerValue, setInnerValue] = useState(propsValue || '')
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || '')
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const handleChange = useCallback(
    ({ value: v }: OptionType) => {
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setInnerValue(String(v))
      if (onChangeValue) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(String(v))
        }, 300)
      }
    },
    [onChangeValue],
  )
  const optionValue = options.find((v) => getValue(v) === innerValue)

  return (
    <ComponentPropsDecorator {...props}>
      <Select
        {...{
          disabled,
          helpers,
          isInvalid,
          getSelectedValueInputString,
          options: options.filter(filterOptions),
          placeholder,
          renderOption,
        }}
        value={optionValue}
        handleChange={handleChange}
      />
    </ComponentPropsDecorator>
  )
}

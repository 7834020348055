import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { IOption } from '@/types/global'
import { Text } from '@chakra-ui/react'
import { OptionsList, SelectContainer } from './style'
import { colors } from '@/theme/colors'
import SelectIndicator from './SelectIndicator'
import RenderOption from './RenderOption'
import { useClickAway, useWindowSize } from 'react-use'

type OptionType<T extends Option> =
  T extends string | number ?
    T extends string ?
      string
    : number
  : IOption

type Option = IOption | string | number

interface SelectProps<T extends Option> {
  name: string
  selected?: OptionType<T>
  options: readonly OptionType<T>[]
  placeholder?: string
  getLabel?: (option: OptionType<T>) => string | ReactNode
  renderOption?: (option: OptionType<T>) => ReactNode
  handleChange?: (selected: OptionType<T>) => void
  isDisabled?: boolean
}

const _Select = <T extends Option>(props: SelectProps<T>) => {
  const {
    selected,
    placeholder,
    options: _opts,
    isDisabled = false,
    handleChange,
    getLabel = (opt) =>
      typeof opt === 'string' || typeof opt === 'number' ? opt : opt.label,
    renderOption = (opt) => (
      <Text textStyle="text4">
        {typeof opt === 'string' || typeof opt === 'number' ? opt : opt.label}
      </Text>
    ),
  } = props

  const { height: innerHeight } = useWindowSize()
  const containerRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const [selectedOption, setSelectedOption] = useState<
    OptionType<T> | undefined
  >()
  const [options, setOptions] = useState<SelectProps<T>['options']>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useClickAway(listRef, () => {
    if (isOpen) setIsOpen(false)
  })

  useEffect(() => {
    setOptions(_opts)
  }, [_opts])

  const isSelected = useCallback(
    (val: OptionType<T>) => {
      if (
        (typeof selectedOption === 'string' && typeof val === 'string') ||
        (typeof selectedOption === 'number' && typeof val === 'number')
      ) {
        return selectedOption === val
      }
      if (selectedOption && val) {
        return (selectedOption as IOption).value === (val as IOption).value
      }

      return false
    },
    [selectedOption],
  )

  const containerRefElement = containerRef.current
  const openDirection = useMemo(() => {
    if (containerRefElement) {
      return (
          containerRefElement.getClientRects()[0].bottom - innerHeight + 200 > 0
        ) ?
          'top'
        : 'bottom'
    }
    return 'bottom'
  }, [innerHeight, containerRefElement])

  useEffect(() => {
    if (selectedOption || selected) {
      setSelectedOption(selected)
    } else {
      setSelectedOption(undefined)
    }
  }, [selected, selectedOption])

  const value = useMemo(() => {
    if (selectedOption) return getLabel(selectedOption)
    return null
  }, [selectedOption, getLabel])

  const setSelect = useCallback(
    (data: OptionType<T>) => {
      setSelectedOption(data)
      if (handleChange) {
        handleChange(data)
      }
    },
    [handleChange],
  )

  const onClickSelect = () => {
    if (!isDisabled) {
      setIsOpen((e) => !e)
    }
  }

  return (
    <SelectContainer
      $isDisabled={isDisabled}
      ref={containerRef}
      onClick={onClickSelect}
    >
      <Text
        textStyle="text4"
        color={value ? colors.neutral[900] : colors.neutral[500]}
      >
        {value || placeholder}
      </Text>
      <SelectIndicator isOpen={isOpen} />

      {isOpen && (
        <OptionsList ref={listRef} data-open-direction={openDirection}>
          {options.map((data, i) => (
            <RenderOption
              key={i}
              isSelected={isSelected(data)}
              onSelectOption={() => setSelect(data)}
            >
              {renderOption(data)}
            </RenderOption>
          ))}
        </OptionsList>
      )}
    </SelectContainer>
  )
}

export default _Select

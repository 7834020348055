import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { shipmentStatusNewOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { useAppUserContext } from '@/_app/useAppUserContext'
import { ShipmentStatusLabelInner } from '@/components/atoms/table_assets/ShipmentStatusLabel/ShipmentStatusLabelInner'
import { ShipmentStatusNew } from '@/api/qubyApiEgo'

type QSelectShipmentStatusPropsType = Omit<
  QSelectPropsType<IOption<ShipmentStatusNew>, ShipmentStatusNew>,
  'options'
>
export const QSelectShipmentStatusNew = (
  props: QSelectShipmentStatusPropsType,
) => {
  const [t] = useTranslation()
  const { isSimpleUser } = useAppUserContext()
  const options = shipmentStatusNewOptions(t, { isSimpleUser })

  return (
    <QForm.Field.Select
      renderOption={({ value: status }) => {
        return <ShipmentStatusLabelInner {...{ status }} />
      }}
      {...{
        ...props,
        options,
      }}
    />
  )
}

import {
  qubyApiPhp,
  TransitionTiIdleRunInternalRunRequestApiArg,
} from '@/api/generated/qubyApiPhp'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import { QFormModal } from '@/components/QForm/QFormModal/QFormModal'
import { ModalController } from '@/hooks/useModalState'
import { notificationsStore } from '@/stores/global.store'
import { Text } from '@chakra-ui/react'
import React from 'react'
import * as yup from 'yup'

type IModalProps = {
  confirmIdleRunModalProps: ModalController<TransitionTiIdleRunInternalRunRequestApiArg>
  runRequestRefetch: () => void
  routesRefetch: () => void
}
const idRouteYup: yup.ObjectSchema<TransitionTiIdleRunInternalRunRequestApiArg> =
  yup.object({
    id: yup.string().id().required(),
  })
export const ConfirmIdleRunModal = ({
  confirmIdleRunModalProps,
  runRequestRefetch = () => {},
  routesRefetch = () => {},
}: IModalProps) => {
  const [addIdleRunRoute] =
    qubyApiPhp.endpoints.transitionTiIdleRunInternalRunRequest.useMutation()
  return (
    <QFormModal
      isDirty
      validationSchema={idRouteYup}
      onSubmit={async (values) => {
        const result = await addIdleRunRoute(values)
        const errors = await qFormCollectErrors(result)
        if (!errors) {
          notificationsStore.pushSuccess({
            message: 'Холостой прогон добавлен',
          })
          confirmIdleRunModalProps.closeModal()
          runRequestRefetch()
          routesRefetch()
        }
        return errors
      }}
      modalProps={confirmIdleRunModalProps}
      header="Добавить холостой прогон"
      submitButtonText={'Да'}
      cancelButtonText={'Нет'}
    >
      <Text>
        Вы действительно хотите добавить холостой прогон к данному рейсу?
      </Text>
    </QFormModal>
  )
}

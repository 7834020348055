import React from 'react'
import { QTextInner } from '@/components/QForm/QField/QInput/QText/QTextInner'
import { TextareaProps } from '@chakra-ui/textarea/dist/textarea'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'

export const QText = (
  props: TextareaProps & Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QTextInner {...otherProps} />
    </QForm.Field>
  )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  QueryFiltersFromApiArg,
  QueryOrderTypesFromApiArg,
  QueryPageTypes,
  QuerySearchTextTypes,
} from '@/types/api.types'
import { useAppUserContext } from '@/_app/useAppUserContext'
import {
  TABLE_QUERIES_DEFAULT_CURRENT_PAGE,
  TABLE_QUERIES_DEFAULT_PER_PAGE,
} from '@/components/moleculs/Table/help/Pagination/TABLE_QUERIES_DEFAULTT_CONFIG'
import * as yup from 'yup'
import { unionArrays } from '@/constants/unionArrays'
import {
  PaginationRequestType,
  TableQueriesLocalCheckingPropsType,
} from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import { BaseOrderTypeEnum } from '@/api/generated/qubyApiPhp'
import { useClientContext } from '@/providers/Client/useClientContext'
import { registersCommonFiltersYup } from '@/_app/pages/registers/registersCommonFilters.yup'
import { omitBy } from 'lodash'

type RequestSettings<ApiArg extends Record<string, unknown>> = {
  page?: QueryPageTypes
  filters?: QueryFiltersFromApiArg<ApiArg>
  order?: QueryOrderTypesFromApiArg<ApiArg>
  searchText?: QuerySearchTextTypes
}

const orderDirectionSchema = yup
  .mixed<BaseOrderTypeEnum>()
  .oneOf(unionArrays.orderDirection)

const localCheckinPropsSchema = yup.object<
  PaginationRequestType & {
    order?: BaseOrderTypeEnum
    query?: string
  }
>({
  perPage: yup
    .mixed()
    .oneOf(unionArrays.paginationPerPage)
    .default(20)
    .required(),
  currentPage: yup.number(),
  order: orderDirectionSchema,
  query: yup.string(),
})

type UseTableQuerySettingsType<ApiArg extends Record<string, unknown>> = {
  defaults?: Partial<RequestSettings<ApiArg>>
  storageSettings?: {
    localStorageKeyForSaving: string
    yupSchema: yup.Schema<Omit<ApiArg, TableQueriesLocalCheckingPropsType>>
  }
}

export const useTableQuerySettings = <ApiArg extends Record<string, unknown>>(
  settings?: UseTableQuerySettingsType<ApiArg>,
) => {
  const isMountedRef = useRef<boolean>(false)
  const { id } = useAppUserContext()
  const { clientId } = useClientContext()
  const localStorageKeyForSaving =
    settings?.storageSettings?.localStorageKeyForSaving
  const localYupSchema = settings?.storageSettings?.yupSchema
  const resultSavingKey = `user--${id}--client--${clientId}--${localStorageKeyForSaving}`

  /**
   * TODO: переписать на useLocalStorageWithYup
   */
  const savedRequestSettings: RequestSettings<ApiArg> = useMemo(() => {
    if (localYupSchema) {
      const savedToLocalStorageJsonString =
        localStorageKeyForSaving ? localStorage.getItem(resultSavingKey) : null
      try {
        const savedRequestSettingsInner: RequestSettings<ApiArg> =
          savedToLocalStorageJsonString ?
            JSON.parse(savedToLocalStorageJsonString)
          : {}
        registersCommonFiltersYup
          .concat(localYupSchema.required())
          .concat(localCheckinPropsSchema.required())
          .validateSync({
            ...savedRequestSettingsInner.searchText,
            ...savedRequestSettingsInner.page,
            ...savedRequestSettingsInner.order,
            ...savedRequestSettingsInner.filters,
          })
        return savedRequestSettingsInner
      } catch (e) {
        console.log(
          `restore localstorage settings error in ${resultSavingKey}`,
          e,
        )
        localStorage.removeItem(resultSavingKey)
        return {}
      }
    }
    return {}
  }, [localStorageKeyForSaving, localYupSchema, resultSavingKey])

  const [page, setPage] = useState<QueryPageTypes>(
    savedRequestSettings.page ||
      settings?.defaults?.page || {
        perPage: TABLE_QUERIES_DEFAULT_PER_PAGE,
        currentPage: TABLE_QUERIES_DEFAULT_CURRENT_PAGE,
      },
  )
  const [filters, setFiltersState] = useState<QueryFiltersFromApiArg<ApiArg>>(
    savedRequestSettings.filters ||
      settings?.defaults?.filters ||
      Object.create({}),
  )

  const setFilters = useCallback((filters: QueryFiltersFromApiArg<ApiArg>) => {
    const clearedFilters = omitBy(
      filters,
      (value) =>
        typeof value === 'undefined' || (typeof value === 'string' && !value),
    ) as QueryFiltersFromApiArg<ApiArg>
    return setFiltersState(clearedFilters)
  }, [])
  const [order, setOrder] = useState<QueryOrderTypesFromApiArg<ApiArg>>(
    savedRequestSettings.order ||
      settings?.defaults?.order ||
      Object.create({}),
  )
  const [searchText, setSearchText] = useState<QuerySearchTextTypes>(
    savedRequestSettings.searchText || settings?.defaults?.searchText || {},
  )
  const resetFilters = useCallback(() => {
    setFilters(Object.create({}))
    setOrder(Object.create({}))
    setSearchText({ query: '' })
  }, [setFilters])

  const appliedFiltersCount =
    Object.values(filters).filter((i) => {
      if (Array.isArray(i)) {
        return i.length > 0
      }
      if (typeof i === 'string') {
        return Boolean(i)
      }
      return true
    }).length || 0

  const isActiveOtherFilterSettings = Boolean(
    order.order || order.orderBy || searchText.query,
  )

  useEffect(() => {
    if (localStorageKeyForSaving) {
      const querySettingsForSave = {
        page,
        filters,
        order,
        searchText,
      }
      localStorage.setItem(
        resultSavingKey,
        JSON.stringify(querySettingsForSave),
      )
    }
  }, [
    filters,
    localStorageKeyForSaving,
    order,
    page,
    resultSavingKey,
    searchText,
  ])

  const onSearch = useCallback(
    (text: string) => {
      setSearchText({ query: text })
    },
    [setSearchText],
  )

  const setCurrentPage = useCallback(
    (currentPage: number) => {
      setPage((page) => ({
        ...page,
        currentPage,
      }))
    },
    [setPage],
  )
  const setPerPage = useCallback(
    (perPage: number) => {
      setPage((page) => ({
        ...page,
        perPage,
      }))
    },
    [setPage],
  )

  useEffect(() => {
    if (isMountedRef.current) {
      setPage((page) => ({
        ...page,
        currentPage: 1,
      }))
    } else {
      isMountedRef.current = true
    }
  }, [filters, order, searchText, page.perPage])

  return useMemo(() => {
    return {
      page,
      setCurrentPage,
      setPerPage,
      filters,
      setFilters,
      order,
      setOrder,
      searchText,
      onSearch,
      resetFilters,
      appliedFiltersCount,
      isActiveOtherFilterSettings,
    }
  }, [
    appliedFiltersCount,
    filters,
    isActiveOtherFilterSettings,
    onSearch,
    order,
    page,
    resetFilters,
    setFilters,
    searchText,
    setCurrentPage,
    setPerPage,
  ])
}

import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { InputProps } from '@chakra-ui/react'
import { QStringCore } from '@/components/QForm/QField/QInput/QString/QStringCore'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'

export const QStringInner = (props: InputProps) => {
  const { name, propsFromYup } = useQFieldContext()
  const [field, meta, helpers] = useField(name)

  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QStringCore
        {...{
          ...field,
          isInvalid: Boolean(meta.error && meta.touched),
          meta,
          helpers,
        }}
      />
    </ComponentPropsDecorator>
  )
}

import React, { FC, ReactElement } from 'react'
import { Box, Grid, GridProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useAppUserContext } from '@/_app/useAppUserContext'
import { ShipmentsPageEndpointTypes } from '@/containers/Shipments/ShipmentsPage.types'
import {
  shipmentStatusColors,
  shipmentStatusColorsCollection,
} from '@/components/atoms/table_assets/ShipmentStatusLabel/shipmentStatusColors'

interface Props {
  status: ShipmentsPageEndpointTypes['ListItem']['status'] | undefined
  style?: GridProps
  onClick?: () => void
}

export const ShipmentStatusLabelInner: FC<Props> = ({
  style,
  onClick,
  status,
}): ReactElement => {
  const [t] = useTranslation()
  const colors =
    (status && shipmentStatusColors[status]) ||
    shipmentStatusColorsCollection.unset
  const { isSimpleUser } = useAppUserContext()

  return (
    <Grid
      width="max-content"
      gridTemplateColumns="max-content 1fr"
      borderRadius="30px"
      alignItems="center"
      {...style}
      backgroundColor={colors[1]}
      {...{ onClick }}
    >
      <Box
        mr="1"
        width="8px"
        height="8px"
        borderRadius="50%"
        bgColor={colors[0]}
      />
      <Text
        flexShrink="1"
        fontWeight="500"
        textAlign="center"
        fontSize="12px"
        lineHeight="12px"
      >
        {status ?
          t(
            `constants.${
              isSimpleUser ? 'shipmentStatusForSimpleUser' : 'shipmentStatus'
            }.${status}`,
          )
        : null}
      </Text>
    </Grid>
  )
}

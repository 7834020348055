import React, { FC, ReactNode } from 'react'
import { GridItem, GridItemProps } from '@chakra-ui/react'
import { motion as m } from 'framer-motion'
import { FullWidthSpinner } from '../FullWidthSpiner'

interface Props extends GridItemProps {
  children: ReactNode
  isLoading?: boolean
  loadingLabel?: ReactNode
}

const AnimationCong = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const PageContent: FC<Props> = ({
  children,
  isLoading = false,
  loadingLabel,
  ...props
}) => {
  return (
    <GridItem
      as={m.div}
      initial={AnimationCong.exit}
      animate={AnimationCong.enter}
      exit={AnimationCong.enter}
      variants={AnimationCong}
      display="grid"
      position="relative"
      gridColumn="2/3"
      gridRow="2/3"
      boxSizing="border-box"
      gridTemplateRows="1fr max-content"
      gridTemplateColumns="1fr"
      height="calc(100vh - 64px)"
      overflowX="hidden"
      {...props}
    >
      {isLoading ?
        <FullWidthSpinner label={loadingLabel} gridRow="1/3" />
      : children}
    </GridItem>
  )
}

export default PageContent

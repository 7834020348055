import * as yup from 'yup'
import { DriverModalFormTypes } from '@/_app/pages/registers/drivers/modals/driver/DriverModalForm.types'

const driverCreateRequest: yup.ObjectSchema<
  DriverModalFormTypes['CreateRequest']['driverStoreRequest']
> = yup.object({
  fullName: yup.string().required().label('ФИО'),
  driverLicence: yup.string().label('Права'),
  passportData: yup.string().label('Паспортные данные'),
  phoneNumber: yup.string().phone().required().label('Телефон'),
})

const driverUpdateRequest: yup.ObjectSchema<
  DriverModalFormTypes['UpdateRequest']['driverUpdateRequest']
> = yup.object({
  fullName: yup.string().required().label('ФИО'),
  driverLicence: yup.string().label('Права'),
  phoneNumber: yup.string().phone().required().label('Телефон'),
  passportData: yup.string().label('Паспортные данные'),
  fired: yup.boolean().label('Уволен'),
})

export const driverModalFormCreateYup: yup.ObjectSchema<
  DriverModalFormTypes['CreateRequest']
> = yup.object({
  driverStoreRequest: driverCreateRequest.required(),
})
export const driverModalFormUpdateYup: yup.ObjectSchema<
  DriverModalFormTypes['UpdateRequest']
> = yup.object({
  driverUpdateRequest: driverUpdateRequest.required(),
  id: yup.string().id().required(),
})

import {
  ArrivalMethod,
  CargoType,
  CargoVolume,
  ExcelReportTemplate,
  GoodOrderBy,
  LocationOrderBy,
  MarketplaceOrderBy,
  PackageType,
  ReceiverDestinationType,
  Role,
  RunRequestOrderBy,
  RunRequestStatus,
  RunRequestType,
  SenderDestinationType,
  ShipmentAdditionalServices,
  ShipmentStatus,
  TariffLevelLocalization,
  VatRate,
} from '@/api/generated/qubyApiJava'
import { PaginationPerPageType } from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import ru from '@/locale/ru'
import { ExportDeliveryListType } from '@/containers/ShipmentCard/shipmentCard.utills'
import {
  BaseOrderTypeEnum,
  CargoPackageTypes,
  InvoiceOrderByEnum,
  InvoiceStatusEnum,
  LocationTypes,
  RunRequestOrderByTypeEnum,
  RunRequestStatusEnum,
  RunRequestTypesEnum,
  ShipmentAdditionalServicesEnum,
  ShipmentRelationsEnum,
  ShipmentTypesEnum,
  StatisticsDeliveryServicesTabsEnum,
  StatisticsRevenueTabsEnum,
} from '@/api/generated/qubyApiPhp'
import {
  ClientLegalForm,
  ClientStatus,
} from '@/_app/pages/partners/modals/partner/ClientModalForm.types'

import {
  DeliveryDlvEnum,
  DlvStatusEnum,
  MonitoringDlvStatus,
  MonitoringErrorType,
  MonitoringErrorTypeWithoutDefault,
  MonitoringStatus,
  MonitoringWarehouse,
  ShipmentsDlvStatusEnum,
} from '@/api/qubyApiEgoDlv'
import {
  RunRequestArrivalMethodsEnum,
  ShipmentStatusNew,
} from '@/api/qubyApiEgo'

const createArrayFromRecord = <T extends string | number>(
  record: Record<T, string>,
): readonly T[] => {
  return Object.keys(record) as T[]
}

const invoicesStatus = createArrayFromRecord(ru.constants.invoicesStatus)
const invoicesOrderBy = createArrayFromRecord(ru.constants.invoicesOrderBy)
const tariffLevelLocalization = createArrayFromRecord(
  ru.constants.tariffLevelLocalization,
)
const shipmentRelations = createArrayFromRecord(
  ru.constants.shipmentRelationsType,
)
const excelReportTemplate = createArrayFromRecord(
  ru.constants.excelReportTemplate,
)

const cargoType = createArrayFromRecord(ru.constants.cargoType)
const locationType = createArrayFromRecord(ru.constants.locationType)
const runRequestArrivalMethod = createArrayFromRecord(
  ru.constants.runRequestArrivalMethod,
)
const runRequestArrivalMethodPhp = createArrayFromRecord(
  ru.constants.runRequestArrivalMethodPhp,
)
const pickupLocationType = createArrayFromRecord(
  ru.constants.pickupLocationType,
)
const deliveryLocationType = createArrayFromRecord(
  ru.constants.deliveryLocationType,
)

const senderDestinationType = createArrayFromRecord(
  ru.constants.senderDestinationType,
)
const partnerLegalForm = createArrayFromRecord(ru.constants.partnerLegalForm)
const userRole = createArrayFromRecord(ru.constants.userRole)

const receiverDestinationType = createArrayFromRecord(
  ru.constants.receiverDestinationType,
)
const shipmentStatus = createArrayFromRecord(ru.constants.shipmentStatus)
const shipmentStatusNew = createArrayFromRecord(ru.constants.shipmentStatusNew)
const shipmentType = createArrayFromRecord(ru.constants.shipmentTypes)
const shipmentAdditionalServices = createArrayFromRecord(
  ru.constants.shipmentAdditionalServices,
)
const shipmentAdditionalServicesEnum = createArrayFromRecord(
  ru.constants.shipmentAdditionalServicesEnum,
)
const runRequestsStatus = createArrayFromRecord(ru.constants.runRequestStatus)
const runRequestStatus = createArrayFromRecord(ru.constants.runRequestsStatus)
const runRequestsType = createArrayFromRecord(ru.constants.runRequestType)
const runRequestTypes = createArrayFromRecord(ru.constants.runRequestTypes)
const runRequestsOrderBy = createArrayFromRecord(
  ru.constants.runRequestsOrderBy,
)
const runRequestOrderBy = createArrayFromRecord(ru.constants.runRequestOrderBy)
const receiversOrderBy = createArrayFromRecord(ru.constants.receiversOrderBy)
const receiversType = createArrayFromRecord(ru.constants.receiversType)
const goodsOrderBy = createArrayFromRecord(ru.constants.goodsOrderBy)
const marketplaceOrderBy = createArrayFromRecord(
  ru.constants.marketplaceOrderBy,
)
const orderDirection = createArrayFromRecord(ru.constants.orderDirection)
const cargoVolume = createArrayFromRecord(ru.constants.cargoVolume)
const exportDeliveryList = createArrayFromRecord(
  ru.constants.exportDeliveryListType,
)
const vatRate = createArrayFromRecord(ru.constants.vatRate)
const cargoPackageType = createArrayFromRecord(ru.constants.cargoPackageType)
const cargoPackageTypePhp = createArrayFromRecord(
  ru.constants.cargoPackageTypePhp,
)

const monitoringDlvStatus = createArrayFromRecord(ru.constants.dlvStatuses)
const monitoringStatus = createArrayFromRecord(ru.constants.monitoringStatus)
const monitoringErrorType = createArrayFromRecord(
  ru.constants.monitoringErrorType,
)
const monitoringErrorTypeWithoutDefault = createArrayFromRecord(
  ru.constants.monitoringErrorTypeWithoutDefault,
)
const monitoringWarehouse = createArrayFromRecord(
  ru.constants.monitoringWarehouseType,
)

const dlvStatus = createArrayFromRecord(ru.constants.dlvStatus)
const shipmentDlvStatus = createArrayFromRecord(ru.constants.shipmentDlvStatus)
const shipmentDlvDelivery = createArrayFromRecord(
  ru.constants.shipmentsDlvDelivery,
)
const statisticsRevenueTabs = createArrayFromRecord(ru.constants.statisticsTabs)
const statisticsSDTabs = createArrayFromRecord(ru.constants.statisticsSDTabs)

const paginationPerPage = createArrayFromRecord(
  ru.constants.paginationPerPage,
).map((i) => Number(i)) as PaginationPerPageType[]

const status = createArrayFromRecord(ru.constants.status)

type UnionArrayType<T> = readonly T[]
type UnionArraysRecordType = {
  invoicesStatus: UnionArrayType<InvoiceStatusEnum>
  shipmentStatus: UnionArrayType<ShipmentStatus>
  shipmentStatusNew: UnionArrayType<ShipmentStatusNew>
  cargoPackageType: UnionArrayType<PackageType>
  cargoPackageTypePhp: UnionArrayType<CargoPackageTypes>
  cargoVolume: UnionArrayType<CargoVolume>
  shipmentType: UnionArrayType<ShipmentTypesEnum>
  paginationPerPage: UnionArrayType<PaginationPerPageType>
  orderDirection: UnionArrayType<BaseOrderTypeEnum>
  runRequestsStatus: UnionArrayType<RunRequestStatus>
  runRequestStatus: UnionArrayType<RunRequestStatusEnum>
  monitoringDlvStatus: UnionArrayType<MonitoringDlvStatus>
  monitoringErrorType: UnionArrayType<MonitoringErrorType>
  monitoringErrorTypeWithoutDefault: UnionArrayType<MonitoringErrorTypeWithoutDefault>
  statisticsRevenueTabs: UnionArrayType<StatisticsRevenueTabsEnum>
  statisticsSDTabs: UnionArrayType<StatisticsDeliveryServicesTabsEnum>
  dlvStatus: UnionArrayType<DlvStatusEnum>
  shipmentDlvDelivery: UnionArrayType<DeliveryDlvEnum>
  shipmentDlvStatus: UnionArrayType<ShipmentsDlvStatusEnum>
  monitoringStatus: UnionArrayType<MonitoringStatus>
  monitoringWarehouse: UnionArrayType<MonitoringWarehouse>
  runRequestsType: UnionArrayType<RunRequestType>
  runRequestTypes: UnionArrayType<RunRequestTypesEnum>
  runRequestsOrderBy: UnionArrayType<RunRequestOrderBy>
  runRequestOrderBy: UnionArrayType<RunRequestOrderByTypeEnum>
  receiversOrderBy: UnionArrayType<LocationOrderBy>
  receiversType: UnionArrayType<LocationTypes>
  goodsOrderBy: UnionArrayType<GoodOrderBy>
  marketplaceOrderBy: UnionArrayType<MarketplaceOrderBy>
  exportDeliveryList: UnionArrayType<ExportDeliveryListType>
  vatRate: UnionArrayType<VatRate>
  senderDestinationType: UnionArrayType<SenderDestinationType>
  receiverDestinationType: UnionArrayType<ReceiverDestinationType>
  shipmentAdditionalServices: UnionArrayType<ShipmentAdditionalServices>
  shipmentAdditionalServicesEnum: UnionArrayType<ShipmentAdditionalServicesEnum>
  shipmentRelations: UnionArrayType<ShipmentRelationsEnum>
  partnerLegalForm: UnionArrayType<ClientLegalForm>
  userRole: UnionArrayType<Role>
  excelReportTemplate: UnionArrayType<ExcelReportTemplate>
  cargoType: UnionArrayType<CargoType>
  tariffLevelLocalization: UnionArrayType<TariffLevelLocalization>
  status: UnionArrayType<ClientStatus>
  invoicesOrderBy: UnionArrayType<InvoiceOrderByEnum>
  locationType: UnionArrayType<LocationTypes>
  pickupLocationType: UnionArrayType<LocationTypes>
  deliveryLocationType: UnionArrayType<Exclude<LocationTypes, 'QUBY_WAREHOUSE'>>
  runRequestArrivalMethod: UnionArrayType<ArrivalMethod>
  runRequestArrivalMethodPhp: UnionArrayType<RunRequestArrivalMethodsEnum>
}

export const unionArrays: UnionArraysRecordType = {
  runRequestArrivalMethod,
  shipmentStatusNew,
  statisticsRevenueTabs,
  dlvStatus,
  statisticsSDTabs,
  shipmentDlvDelivery,
  shipmentDlvStatus,
  monitoringDlvStatus,
  monitoringStatus,
  locationType,
  pickupLocationType,
  cargoVolume,
  deliveryLocationType,
  tariffLevelLocalization,
  cargoType,
  userRole,
  partnerLegalForm,
  invoicesStatus,
  invoicesOrderBy,
  cargoPackageType,
  cargoPackageTypePhp,
  marketplaceOrderBy,
  goodsOrderBy,
  receiversOrderBy,
  receiversType,
  runRequestsOrderBy,
  runRequestsStatus,
  runRequestsType,
  orderDirection,
  paginationPerPage,
  shipmentStatus,
  shipmentType,
  shipmentRelations,
  exportDeliveryList,
  vatRate,
  receiverDestinationType,
  senderDestinationType,
  shipmentAdditionalServices,
  shipmentAdditionalServicesEnum,
  excelReportTemplate,
  status,
  monitoringErrorType,
  monitoringErrorTypeWithoutDefault,
  runRequestArrivalMethodPhp,
  runRequestTypes,
  runRequestStatus,
  runRequestOrderBy,
  monitoringWarehouse,
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { SelectAsyncComponent } from '@/components/atoms/SelectAsync'
import { QSelectCorePropsType } from '@/components/QForm/QField/QSelectAsync/QselectAsync.types'
import { SelectAsyncPropsListOptionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  ApiAllGetByIdEndpointsType,
  ApiAllGetListEndpointsType,
  ApiEndpointResultType,
} from '@/types/api.types'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useQFormContext } from '@/components/QForm/useQFormContext'

const dummy = () => undefined
export const QSelectAsyncCore = <
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = ApiEndpointResultType<SingleQueryEndpoint>,
>(
  props: QSelectCorePropsType<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
) => {
  const onChangeValue = 'onChangeValue' in props ? props.onChangeValue : dummy
  const propsValue = props.value
  const {
    formik: { setFieldTouched },
  } = useQFormContext()
  const { name } = useQFieldContext()

  const innerRef = useRef<NodeJS.Timeout>()

  const [innerValue, setInnerValue] = useState(propsValue || '')
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || '')
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const handleChange = useCallback(
    (v: string) => {
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setFieldTouched(name)
      setInnerValue(v)
      if (onChangeValue) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(v)
        }, 300)
      }
    },
    [setFieldTouched, onChangeValue, name],
  )

  return (
    <ComponentPropsDecorator {...{ handleChange }}>
      <SelectAsyncComponent {...{ ...props }} value={innerValue} />
    </ComponentPropsDecorator>
  )
}

import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { monitoringErrorStatusOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { MonitoringErrorTypeWithoutDefault } from '@/api/qubyApiEgoDlv'

type QSelectMonitoringErrorPropsType = Omit<
  QSelectPropsType<
    IOption<MonitoringErrorTypeWithoutDefault>,
    MonitoringErrorTypeWithoutDefault
  >,
  'options'
>
export const QSelectMonitoringErrorStatus = (
  props: QSelectMonitoringErrorPropsType,
) => {
  const [t] = useTranslation()
  const options = monitoringErrorStatusOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

import React from 'react'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'

function SelectCell({
  indeterminate,
  className = '',
  checked,
  onChange,
}: { indeterminate?: boolean } & CheckboxProps) {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      if (ref.current) {
        ref.current.indeterminate = !checked && indeterminate
      }
    }
  }, [ref, indeterminate, checked])

  return (
    <Checkbox
      onChange={onChange}
      checked={checked}
      isChecked={checked}
      ref={ref}
      size="sm"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={className + ' cursor-pointer'}
    />
  )
}

export default SelectCell

import { useQFormContext } from '@/components/QForm/useQFormContext'
import React, { FC } from 'react'
import { Button, ButtonProps, Tooltip } from '@chakra-ui/react'
import { QForm } from '@/components/QForm'

export const QFormSubmitButton: FC<ButtonProps> = ({
  children = 'Сохранить',
  ...props
}) => {
  const {
    isValid,
    formik: { handleSubmit, isSubmitting, isValidating, dirty },
  } = useQFormContext()

  return (
    <Tooltip label={isValid ? undefined : <QForm.Errors />}>
      <Button
        onClick={() => {
          handleSubmit()
        }}
        isLoading={isSubmitting || isValidating}
        variant={dirty ? 'accent' : 'secondary'}
        isDisabled={!isValid}
        {...{ ...props }}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

import React, { FC } from 'react'
import { Box, Text, Tooltip } from '@chakra-ui/react'
import { parsePhoneNumber } from 'awesome-phonenumber'
import TableComment from '@/components/atoms/table_assets/TableComment'

export const TableAddressCell: FC<{
  name?: string
  phoneNumber?: string
  address?: string
  comment?: string
}> = ({ name, phoneNumber, address, comment }) => {
  const parsed = parsePhoneNumber(phoneNumber || '')
  const formattedPhoneNumber = parsed.number?.international
  return (
    <div>
      {name && (
        <div>
          <Tooltip label={name}>
            <Text
              maxW={'22em'}
              style={{ display: 'inline-block' }}
              noOfLines={1}
              textStyle="text5"
            >
              {name}
            </Text>
          </Tooltip>
        </div>
      )}
      {phoneNumber && (
        <div>
          <Tooltip label={formattedPhoneNumber}>
            <Text
              maxW={'22em'}
              style={{ display: 'inline-block' }}
              noOfLines={1}
              textStyle="text6"
            >
              {formattedPhoneNumber}
            </Text>
          </Tooltip>
        </div>
      )}
      {address && (
        <div>
          <Tooltip label={address}>
            <Text
              maxW={'22em'}
              style={{ display: 'inline-block' }}
              noOfLines={1}
              textStyle="text6"
            >
              {address}
            </Text>
          </Tooltip>
        </div>
      )}
      <Box>
        <TableComment {...{ comment }} />
      </Box>
    </div>
  )
}

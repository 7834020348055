import { Box, Grid, Stack, Tooltip } from '@chakra-ui/react'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal'
import React, { FC, useCallback } from 'react'
import { QForm } from '@/components/QForm/QForm'
import { QGroup } from '@/components/QForm/QGroup/QGroup'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import {
  VehicleModalFormPropsType,
  VehicleModalFormTypes,
} from './VehicleModalForm.types'

type Props = {
  isLoading: boolean
} & Pick<VehicleModalFormPropsType, 'modalProps'>
export const VehicleModalInner: FC<Props> = ({ modalProps, isLoading }) => {
  const { formik } = useQFormContext<VehicleModalFormTypes['ConsumedRequest']>()

  const propsCloseModal = modalProps.closeModal
  const closeModal = useCallback(() => {
    propsCloseModal()
  }, [propsCloseModal])
  const modalData = modalProps.getData()

  const yupValue =
    //@ts-ignore
    modalData?.vehicle?.id ? 'vehicleUpdateRequest' : 'vehicleStoreRequest'
  return (
    <Modal
      {...modalProps}
      header={
        <Box>
          {
            //@ts-ignore
            modalData?.vehicle?.id ?
              //@ts-ignore
              formik.values.vehicleUpdateRequest.brand || 'Без названия'
            : 'Новый автомобиль'
          }
        </Box>
      }
      buttons={
        <Grid gridAutoFlow="column" gridGap="4">
          <Button onClick={closeModal} size="l" variant="secondary">
            Отменить
          </Button>
          <Tooltip
            label={Object.values(formik.errors).length > 0 && <QForm.Errors />}
          >
            <div>
              <Button
                {...{ isLoading }}
                size="l"
                onClick={() => {
                  formik.handleSubmit()
                }}
                variant={formik.isValid ? 'accent' : 'disabled'}
              >
                {
                  //@ts-ignore
                  modalData?.vehicle?.id ? 'Сохранить' : 'Создать'
                }
              </Button>
            </div>
          </Tooltip>
        </Grid>
      }
    >
      <QGroup name={yupValue}>
        <Stack spacing={3}>
          <Stack direction={'row'} spacing={3}>
            <QForm.Field.String withWrapper name={'brand'} />
            <QForm.Field.String withWrapper name={'plateNumber'} />
            <QForm.Field.Switch withWrapper name={'isActive'} />
          </Stack>
          <QForm.Field.String withWrapper name={'description'} />
          <Grid gridTemplateColumns={'1fr 1fr 1fr'} gap={3}>
            <QForm.Field.String withWrapper name={'numberSts'} />
            <QForm.Field.String withWrapper name={'numberVin'} />
            <QGroup name={'capacity'}>
              <QForm.Field.Number withWrapper name={'weight'} />
            </QGroup>
          </Grid>
          <QGroup name={'capacity'}>
            <QGroup name={'volume'}>
              <Grid gridTemplateColumns={'1fr 1fr 1fr'} gap={3}>
                <QForm.Field.Number withWrapper name={'height'} />
                <QForm.Field.Number withWrapper name={'width'} />
                <QForm.Field.Number withWrapper name={'depth'} />
              </Grid>
            </QGroup>
          </QGroup>
        </Stack>
      </QGroup>
      <QForm.ResponseErrors />
    </Modal>
  )
}

import {
  AdditionalServicesEgoResponse,
  AdditionalServicesRequest,
} from '@/containers/ShipmentCard/steps/AdditionalServices/tariffsYup.types'
import omit from 'lodash/omit'
import {
  AddressRequest,
  AddressResponse,
  CreateWarehouseApiResponse,
  GetListOfMarketplaceWarehousesApiArg,
  GetListOfMarketplaceWarehousesApiResponse,
  RunRequestStatus,
  UpdateStatusOfRunRequestApiResponse,
  UpdateWarehouseApiResponse,
} from '@/api/generated/qubyApiJava'
import {
  CreateInternalRunRequestsListApiArg,
  CreateInternalRunRequestsListApiResponse,
  GetInternalLocationsListApiResponse,
  GetInternalVehiclesListApiResponse,
  LocationStoreRequest,
  PaginationResponse,
  PatchInternalRunRequestApiArg,
  PatchInternalRunRequestApiResponse,
  PatchInternalShipmentsApiArg,
  PatchInternalShipmentsApiResponse,
  PostInternalLocationsApiResponse,
  PostInternalShipmentsApiArg,
  PostInternalShipmentsApiResponse,
  qubyApiPhp,
} from '@/api/generated/qubyApiPhp'

const envVariables = require('@/constants/env')

export const qubyApiEgo = qubyApiPhp.injectEndpoints({
  endpoints: (build) => ({
    updateStatusOfRunRequest: build.mutation<
      UpdateStatusOfRunRequestApiResponse,
      EgoUpdateStatusOfRunRequestApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.PHP_API_URL}v1.1/internal/run-requests/${queryArg['request-id']}`,
        method: 'PATCH',
        body: queryArg.runRequestPatch,
      }),
      invalidatesTags: [],
    }),
    loginDlv: build.mutation<any, LoginDlvApiArg>({
      query: (queryArg) => ({
        url: `https://dlv-app.kak2c.ru/api/login`,
        method: 'POST',
        params: {
          eternal: true,
        },
        body: queryArg.userLoginRequest,
        headers: {
          Authorization: ``,
        },
      }),
      invalidatesTags: [],
    }),
    putInternalLocations: build.mutation<
      PostInternalLocationsApiResponse,
      PutInternalLocationsApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/locations/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.locationStoreRequest,
        }
      },
      invalidatesTags: ['Locations'],
    }),
    getInternalDeliveryServicesList: build.query<
      GetInternalDeliveryServicesListApiResponse,
      GetInternalDeliveryServicesListApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/internal/delivery-services`,
        params: {
          order: queryArg.order,
          orderBy: queryArg.orderBy,
          query: queryArg.query,
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
          isActive: queryArg.isActive,
        },
      }),
      providesTags: ['DeliveryServices'],
    }),
    getInternalDeliveryServicesById: build.query<
      GetInternalDeliveryServicesByIdApiResponse,
      GetInternalDeliveryServicesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/internal/delivery-services/${queryArg.id}`,
      }),
      providesTags: ['DeliveryServices'],
    }),
    getInternalVehiclesList: build.query<
      GetInternalVehiclesListApiResponse,
      GetInternalVehiclesListApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/internal/vehicles`,
        params: {
          order: queryArg.order,
          orderBy: queryArg.orderBy,
          query: queryArg.query,
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
          isActive: queryArg.isActive,
        },
      }),
      providesTags: ['Vehicles'],
    }),
    getShipmentTariffsEgo: build.query<
      AdditionalServicesEgoResponse,
      AdditionalServicesRequest
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/client-tariff`,
          method: 'POST',
          params: omit(queryArg, ['cargoPackages']),
          body: {
            cargoPackages: queryArg.cargoPackages,
          },
        }
      },
      providesTags: ['Locations'],
    }),
    updatePartnerWarehouse: build.mutation<
      UpdateWarehouseApiResponse,
      UpdateWarehouseApiArgEgo
    >({
      query: (queryArg) => ({
        url: `${envVariables.JAVA_API_URL}v1/warehouse/${queryArg['warehouse-id']}`,
        method: 'PUT',
        body: queryArg.partnerWarehouseRequest,
      }),
      invalidatesTags: ['Locations'],
    }),
    createPartnerWarehouse: build.mutation<
      CreateWarehouseApiResponse,
      CreatePartnerWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.JAVA_API_URL}v1/warehouse`,
        method: 'POST',
        body: queryArg.partnerWarehouseRequest,
        params: { partnerId: queryArg.partnerId },
      }),
      invalidatesTags: ['Locations'],
    }),
    createInternalRunRequestsList: build.mutation<
      CreateInternalRunRequestsListApiResponse,
      CreateInternalRunRequestsListApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/run-requests`,
        method: 'POST',
        body: queryArg.runRequestStoreRequest,
        params: { partnerId: queryArg.partnerId },
      }),
      invalidatesTags: ['RunRequests'],
    }),
    patchInternalRunRequest: build.mutation<
      PatchInternalRunRequestApiResponse,
      PatchInternalRunRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/internal/run-requests/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.runRequestUpdateRequest,
      }),
      invalidatesTags: ['RunRequests'],
    }),
    postInternalShipments: build.mutation<
      PostInternalShipmentsApiResponse,
      PostInternalShipmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/shipments`,
        method: 'POST',
        body: queryArg.shipmentStoreRequest,
        params: { partnerId: queryArg.partnerId },
      }),
      invalidatesTags: [],
    }),
    patchInternalShipments: build.mutation<
      PatchInternalShipmentsApiResponse,
      PatchInternalShipmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.1/shipments/${queryArg.shipmentId}`,
        method: 'PATCH',
        body: queryArg.shipmentUpdateRequest,
      }),
      invalidatesTags: [],
    }),
    getListOfMarketplaceWarehousesEgo: build.query<
      GetListOfMarketplaceWarehousesApiResponse,
      GetListOfMarketplaceWarehousesApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.PHP_API_URL}v1.1/locations?type=MARKETPLACE_WAREHOUSE`,
        params: {
          query: queryArg.query,
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
          order: queryArg.order,
          orderBy: queryArg.orderBy,
        },
      }),
      providesTags: ['Locations'],
      transformResponse: (response: GetInternalLocationsListApiResponse) => {
        return {
          meta: {
            ...response.meta,
            orderBy: 'temp_fix' as unknown as {},
          },
          data: response.data?.map(
            ({
              addressId,
              addressString,
              id,
              name,
              companyName,
              //marketplaceName,
              workingTimeFrom,
              workingTimeTo,
            }) => {
              return {
                address: {
                  value: addressString,
                  id: addressId,
                } as AddressResponse,
                id,
                // TODO: убрать это.
                name: name,
                marketplaceName: 'Java :(',
                workingTimeFrom,
                workingTimeTo,
                companyName,
              }
            },
          ),
        }
      },
    }),
  }),
  overrideExisting: true,
})

export type EgoUpdateStatusOfRunRequestApiArg = {
  /** Concrete run request ID */
  'request-id': string
  runRequestPatch: EgoRunRequestPatch
}
export type EgoRunRequestPatch = {
  status: RunRequestStatus
  comment: string
  closedAt?: string
}
export type PutInternalLocationsApiArg = {
  id: string
  locationStoreRequest: LocationStoreRequest
}
export type GetMonitoringApiArg = {
  statusDuration?: string
  dlvStatuses?: MonitoringDlvStatus[] | string
  courierStatuses?: MonitoringStatus[] | string
  num?: string
  currentPage?: number
  perPage?: number
  query?: string
  dateFrom?: string
  dateTo?: string
  year?: number
  month?: string | number
  week?: string | number
  day?: string | number
  hour?: string | number
  minute?: string | number
  second?: string | number
}
export type GetMonitoringApiResponse = {
  content: MonitoringContent[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  pageable: Pageable
  sort: MonitoringContentSort
}
export type MonitoringContent = {
  courierCode: string
  courierExchangeLog: DlvExchangeLog
  courierNum: string | null
  dlvId: string
  dlvStatus: MonitoringDlvStatus
  num: string
  status: MonitoringStatus
  dlvExchangeLog: DlvExchangeLog
}

export type LoginDlvApiArg = {
  userLoginRequest: UserLoginRequestDlv
}
export type UserLoginRequestDlv = {
  username: string
  password: string
}

export type DlvExchangeLog = {
  exchangeLogs: ExchangeLogs[]
}
export type ExchangeLogs = {
  id: string
  login: string
  method: string
  methodType: string
  requestDate: string
  responseCode: string
  url: string
}
export type Pageable = {
  sort: PageableSort
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  unpaged: boolean
}
export type PageableSort = {
  empty: boolean
  unsorted: boolean
  sorted: boolean
}
export type MonitoringContentSort = {
  empty: boolean
  unsorted: boolean
  sorted: boolean
}

export type MonitoringContextType = {
  data: MonitoringContent[]
  meta: PaginationResponse
}
export type MonitoringStatus =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'DELIVERED'
  | 'RETURNED'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
  | 'CANCELED'

export type MonitoringDlvStatus =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'ABORTED'
  | 'UPDATING_SUSPENDED'

export type CreatePartnerWarehouseApiArg = {
  partnerId?: string
  partnerWarehouseRequest: PartnerWarehouseRequestEgo
}

export type PartnerWarehouseRequestEgo = {
  phoneNumber?: string
  warehouseName: string
  /** Left border of arrival time interval of the working time of warehouse */
  workingTimeFrom: string
  workingTimeTo: string
  address: string | AddressRequest
}

export type UpdateWarehouseApiArgEgo = {
  'warehouse-id': string
  partnerWarehouseRequest: PartnerWarehouseRequestEgo
}
export type MonitoringLogType = 'COURIER' | 'SHIPMENT'

export type GetMonitoringLogApiArg = {
  id: string
  type: MonitoringLogType
}
export type GetMonitoringLogApiResponse = {
  url: string
  request: string
  response: string
  responseCode: string
  login: string
}
export type DeliveryServiceResource = {
  id: string
  /** Delivery service name */
  name: string
  legalName: string
  /** Delivery service phone */
  phoneNumber: string
  /** Delivery service's url */
  url: string
  dlvContractId: string
  isActive: boolean
  createdAt?: string
}
export type GetInternalDeliveryServicesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: DeliveryServiceResource[]
  }

export type GetInternalDeliveryServicesByIdApiResponse =
  /** status 200 Success */ DeliveryServiceResource
type GetInternalDeliveryServicesListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  isActive?: boolean
}

export type GetInternalVehiclesListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number

  isActive?: boolean
}

export type GetInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}

export type GetInternalDriversListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  isActive?: boolean
}
export type RunRequestArrivalMethodsEnum = 'QUBY_CAR' | 'SELF_DELIVERY'

export type ShipmentStatusNew =
  | 'AT_SENDER_PARSE_ERROR'
  | 'AT_AGENT'
  | 'AT_SENDER_NEW'
  | 'AT_SENDER_PACKED'
  | 'AT_SENDER_AWAITING_SELF_PICKUP'
  | 'AT_SENDER_AWAITING_QUBY_PICKUP'
  | 'QUBY_VEHICLE_2_QUBY'
  | 'AT_QUBY_AWAITING_CONFIRMATION'
  | 'AT_QUBY_AWAITING_CORRECTION'
  | 'AT_QUBY_AWAITING_ROUTING'
  | 'AT_QUBY_AWAITING_DELIVERY'
  | 'QUBY_VEHICLE_2_RECEIVER'
  | 'RETURN_AT_QUBY_AWAITING_CONFIRMATION'
  | 'RETURN_AT_QUBY_AWAITING_RETURN'
  | 'RETURN_QUBY_VEHICLE_2_QUBY'
  | 'AT_SENDER_CANCELLED'
  | 'AGENT_VEHICLE_2_AGENT'
  | 'AGENT_ERROR'
  | 'AT_AGENT_LOST'
  | 'RETURN_AGENT_VEHICLE_2_QUBY'

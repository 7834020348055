import {
  DeleteInternalRouteByIdApiArg,
  qubyApiPhp,
} from '@/api/generated/qubyApiPhp'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'
import { QFormModal } from '@/components/QForm/QFormModal/QFormModal'
import { ModalController } from '@/hooks/useModalState'
import { notificationsStore } from '@/stores/global.store'
import { Text } from '@chakra-ui/react'
import React from 'react'
import * as yup from 'yup'

type IModalProps = {
  confirmModalProps: ModalController<DeleteInternalRouteByIdApiArg>
  runRequestRefetch: () => void
  routesRefetch: () => void
}
const deleteRouteYup: yup.ObjectSchema<DeleteInternalRouteByIdApiArg> =
  yup.object({
    id: yup.string().id().required(),
  })
export const ConfirmRouteModal = ({
  confirmModalProps,
  runRequestRefetch = () => {},
  routesRefetch = () => {},
}: IModalProps) => {
  const [deleteRoute] =
    qubyApiPhp.endpoints.deleteInternalRouteById.useMutation()
  return (
    <QFormModal
      isDirty
      validationSchema={deleteRouteYup}
      onSubmit={async (values) => {
        const result = await deleteRoute(values)
        const errors = await qFormCollectErrors(result)
        if (!errors) {
          notificationsStore.pushSuccess({
            message: 'Маршрут удален',
          })
          confirmModalProps.closeModal()
          runRequestRefetch()
          routesRefetch()
        }
        return errors
      }}
      modalProps={confirmModalProps}
      header="Вы удалили все рейсы из маршрута!"
      submitButtonText={'Подтвердить'}
    >
      <Text>Вы хотите удалить маршрут?</Text>
    </QFormModal>
  )
}

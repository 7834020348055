import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QDateCore } from '@/components/QForm/QField/QInput/QDate/QDateCore'
import { DatePickerProps } from '@/types/global'

export const QDateInner = (
  props: DatePickerProps & { placeholder?: string },
) => {
  const { name, onChangeValue, propsFromYup } = useQFieldContext()
  const [field, meta] = useField(name)
  const isInvalid = Boolean(meta.error && meta.touched)
  return (
    <ComponentPropsDecorator {...props}>
      <QDateCore
        {...{
          ...field,
          ...propsFromYup,
          onChangeValue,
          isInvalid,
        }}
      />
    </ComponentPropsDecorator>
  )
}

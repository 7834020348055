import { Icon, IconProps } from '@chakra-ui/react'
import * as React from 'react'

function _Icon(props: IconProps) {
  return (
    <Icon
      width="4"
      height="4"
      fill="#1D2939"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_410_16629)" fill="current">
        <path d="M10.667 5.333a.667.667 0 00-.943 0L8 7.057 6.276 5.333a.667.667 0 00-.942.943L7.058 8 5.334 9.724a.667.667 0 00.942.943L8 8.943l1.724 1.724a.667.667 0 00.943-.943L8.943 8l1.724-1.724a.667.667 0 000-.943z" />
        <path d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 14.667A6.666 6.666 0 1114.667 8 6.674 6.674 0 018 14.667z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_410_16629">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default _Icon

import { Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Option } from '../../style'

interface Props {
  val: string
  onClick: () => void
  isSelected: boolean
}

const CreateOption: FC<Props> = ({ onClick, val, isSelected }) => {
  return (
    <Option onClick={onClick} $isSelected={isSelected}>
      <Text textStyle="text4"> Создать "{val}"</Text>
    </Option>
  )
}

export default CreateOption

import React, { PropsWithChildren } from 'react'
import { QFormModalInner } from '@/components/QForm/QFormModal/QFormModalInner'
import { FormikValues } from 'formik'
import { QFormModalPropsType } from '@/components/QForm/QFormModal/QFormModal.types'
import { QForm } from '@/components/QForm'

export const QFormModal = <T extends FormikValues>({
  modalProps,
  validationSchema,
  validationContextValue,
  onSubmit,
  header,
  children,
  submitButtonText,
  cancelButtonText,
  requestKey,
  isDirty = false,
}: PropsWithChildren<QFormModalPropsType<T>>) => {
  if (!modalProps.isOpen) {
    return null
  }
  const modalData = modalProps.getData()
  return (
    <QForm<T>
      {...{
        validationSchema,
        validationContextValue,
        requestKey,
      }}
      initialValues={modalData}
      onSubmit={async (formValues) => {
        if (onSubmit) {
          return onSubmit(formValues)
        }
      }}
    >
      <QFormModalInner
        {...{
          header,
          modalProps,
          submitButtonText,
          cancelButtonText,
          isDirty,
        }}
      >
        {children}
      </QFormModalInner>
    </QForm>
  )
}

import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { InputProps } from '@chakra-ui/react'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QSelectCore } from '@/components/QForm/QField/QSelect/QSelectCore'

export const QSelectInner = (props: InputProps) => {
  const { name, onChangeValue, value, propsFromYup } = useQFieldContext()
  const [field, meta, helpers] = useField(name)
  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QSelectCore
        {...{
          ...field,
          onChangeValue,
          value,
          meta,
          helpers,
          disabled: propsFromYup.isDisabled,
          isInvalid: Boolean(meta.error && meta.touched),
        }}
      />
    </ComponentPropsDecorator>
  )
}

import * as React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
const id = uuidv4()
export const RegistriesIcon = (props: IconProps) => (
  <Icon viewBox={'0 0 16 16'} {...props}>
    <g clipPath={`url(#${id})`}>
      <path
        d="M11.333 0H4.667a3.337 3.337 0 0 0-3.334 3.333v10A2.667 2.667 0 0 0 4 16h7.333a3.337 3.337 0 0 0 3.334-3.333V3.333A3.337 3.337 0 0 0 11.333 0Zm2 3.333v7.334h-8V1.333h6a2 2 0 0 1 2 2ZM4 1.448v9.219c-.468 0-.928.123-1.333.358V3.333A2 2 0 0 1 4 1.448Zm7.333 13.219H4A1.333 1.333 0 0 1 4 12h9.333v.667a2 2 0 0 1-2 2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id={id}>
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </Icon>
)

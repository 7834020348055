import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_311_16565)" fill="#475467">
        <path d="M5.999 9.333h4a.667.667 0 100-1.333h-4a.667.667 0 000 1.333z" />
        <path d="M12.667 0H3.333A3.337 3.337 0 000 3.333V4a2 2 0 00.667 1.49v7.177A3.337 3.337 0 004 16h8a3.338 3.338 0 003.333-3.333V5.489A2 2 0 0016 4v-.667A3.337 3.337 0 0012.667 0zM1.333 3.333a2 2 0 012-2h9.334a2 2 0 012 2V4a.667.667 0 01-.667.667H2A.667.667 0 011.333 4v-.667zM14 12.667a2 2 0 01-2 2H4a2 2 0 01-2-2V6h12v6.667z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_311_16565">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

import { Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
const HeaderTitleByPath: FC = () => {
  const [t] = useTranslation()
  const location = useLocation()
  const title = t(`header.title.${location.pathname}`)
  return (
    <>
      <Text textStyle="h4">{title}&nbsp;</Text>
    </>
  )
}

export default HeaderTitleByPath

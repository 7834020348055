import React, { useState } from 'react'
import { QForm } from '@/components/QForm'
import { SelectAsyncQueriesConfig } from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  QSelectAsyncPropsType,
  QSelectEndpointTypes,
} from './QSelectAsync.types'
import { QSelectAsyncEndpoints } from './QSelectAsync.endpoints'
import { v4 as uuidv4 } from 'uuid'
import { VehiclesRenderSelected } from './VehiclesRenderSelected'
import useModalState from '@/hooks/useModalState'
import { VehicleModalStateType } from './modal/VehicleModalForm.types'
import { VehicleModal } from './modal/VehicleModal'
export const QSelectAsyncVehicle = (props: QSelectAsyncPropsType) => {
  const {
    singleEndpointAdditionalArgs,
    listEndpointAdditionalArgs,
    ...otherProps
  } = props

  const queriesConfig: SelectAsyncQueriesConfig<
    QSelectEndpointTypes['SingleEndpoint'],
    QSelectEndpointTypes['ListEndpoint']
  > = {
    single: {
      query: QSelectAsyncEndpoints.getById,
      args: (value: typeof props.value) => {
        return {
          id: String(value),
          ...(singleEndpointAdditionalArgs ?
            singleEndpointAdditionalArgs()
          : {}),
        }
      },
    },
    list: {
      query: QSelectAsyncEndpoints.getList,
      args: () => {
        return {
          perPage: 200,
          ...(listEndpointAdditionalArgs ? listEndpointAdditionalArgs() : {}),
        }
      },
    },
  }
  const [uuid, setUuid] = useState(uuidv4())
  const modalProps = useModalState<VehicleModalStateType>()
  return (
    <>
      <VehicleModal key={uuid} {...{ modalProps }} />
      <QForm.Field.SelectAsync<
        QSelectEndpointTypes['SingleEndpoint'],
        QSelectEndpointTypes['ListEndpoint'],
        QSelectEndpointTypes['Item']
      >
        isWithoutButtons
        renderSelected={VehiclesRenderSelected}
        renderOption={({ brand, plateNumber }) => {
          return (
            <div>
              {brand} | {plateNumber}
            </div>
          )
        }}
        onCreateNew={(brand, afterSave) => {
          setUuid(uuidv4())
          modalProps.openModal({
            vehicle: {
              vehicleStoreRequest: {
                brand: String(brand),
                plateNumber: '',
                description: '',
                numberSts: '',
                numberVin: '',
                isActive: true,
                capacity: {
                  weight: 0,
                  volume: {
                    height: 0,
                    width: 0,
                    depth: 0,
                  },
                },
              },
            },
            afterSave,
          })
        }}
        onEdit={(vehicle, afterSave) => {
          setUuid(uuidv4())
          modalProps.openModal({
            vehicle: {
              id: vehicle.id,
              vehicleUpdateRequest: {
                brand: String(vehicle?.brand),
                plateNumber: String(vehicle?.plateNumber),
                description: vehicle?.description,
                numberSts: vehicle?.numberSts,
                numberVin: vehicle?.numberVin,
                isActive: vehicle?.isActive,
                capacity: {
                  weight: vehicle?.capacity?.weight,
                  volume: {
                    height: vehicle?.capacity?.volume?.height,
                    width: vehicle?.capacity?.volume?.width,
                    depth: vehicle?.capacity?.volume?.depth,
                  },
                },
              },
            },
            afterSave,
          })
        }}
        {...{
          queriesConfig,
          ...otherProps,
        }}
      />
    </>
  )
}

import React, {
  ChangeEventHandler,
  createContext,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import { motion as m } from 'framer-motion'
import { useClickAway } from 'react-use'
import {
  Input,
  Option,
  OptionsList,
  Select,
  SelectAsyncTopAddonWrapper,
} from './style'
import variants from './animation'
import SelectIndicator from './help/SelectIndicator'
import NotFound from './help/NotFound'
import CreateOption from './help/CreateOption'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Spinner,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { colors } from '@/theme/colors'
import {
  SelectAsyncProps,
  SelectAsyncPropsListOptionType,
} from '@/components/atoms/SelectAsync/SelectAsync.types'
import { EditIcon, HamburgerIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { SelectAsyncComponentContext } from '@/components/atoms/SelectAsync/SelectAsyncComponent.context'
import {
  ApiAllGetByIdEndpointsType,
  ApiAllGetListEndpointsType,
  ApiEndpointResultType,
} from '@/types/api.types'
import { formatNumber } from '@/utils/formatNumbers'

const SelectAsyncOptionContext = createContext<{
  option: unknown
  onClickEdit: (option: unknown) => void
}>({
  onClickEdit: () => undefined,
  option: Object,
})

const ScrollIntoView: FC<
  PropsWithChildren<{
    isActive: boolean
  }>
> = ({ children, isActive }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ block: 'center' })
    }
  }, [isActive])

  return <div ref={ref}>{children}</div>
}

const ChangeButton = () => {
  const { onClickChange } = useContext(SelectAsyncComponentContext)
  return (
    <IconButton
      aria-label={'edit'}
      color={'neutral.700'}
      size={'xs'}
      variant={'text'}
      icon={
        <Tooltip label={'Выбрать'}>
          <HamburgerIcon />
        </Tooltip>
      }
      onClick={onClickChange}
    />
  )
}

const EditButton = () => {
  const { onClickEdit, option } = useContext(SelectAsyncOptionContext)
  return (
    <IconButton
      aria-label={'edit'}
      size={'xs'}
      variant={'text'}
      color={'neutral.700'}
      icon={
        <Tooltip label={'Редактировать'}>
          <EditIcon />
        </Tooltip>
      }
      onClick={() => {
        onClickEdit(option)
      }}
    />
  )
}

const ClearButton = () => {
  const { onClickClear } = useContext(SelectAsyncComponentContext)
  return (
    <IconButton
      aria-label={'delete'}
      size={'xs'}
      variant={'text'}
      color={'neutral.700'}
      _hover={{
        color: colors.error['700'],
      }}
      _active={{
        color: colors.error['900'],
      }}
      icon={
        <Tooltip label={'Сбросить'}>
          <SmallCloseIcon />
        </Tooltip>
      }
      onClick={onClickClear}
    />
  )
}

export const SelectAsyncComponentFunction = <
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = ApiEndpointResultType<SingleQueryEndpoint>,
>(
  props: SelectAsyncProps<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
) => {
  const {
    filterOptions = () => true,
    placeholder = 'Выберите...',
    isDisabled = false,
    value,
    styles,
    asTextArea = false,
    topAddonContent,
    isWithoutButtons = false,
    renderOption = (option: ListOptionType) => {
      return option && 'name' in option ?
          (option?.name as string)
        : '!!! нет name передай renderOption'
    },
    renderSelected = renderOption,
    handleChange,
    onCreateNew,
    onHoverOption,
    onHoverOut,
    getValue = ({ id }) => id,
    queriesConfig,
    onOptionChange,
    onEdit,
    onSingleItemLoaded,
  } = props
  const [isHovered, setIsHovered] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [fastData, setFastData] = useState<ListOptionType>()
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState(0)

  const deferredValue = useDeferredValue(inputValue)
  const listQueryResult = queriesConfig.list.query.useQuery(
    {
      ...queriesConfig.list.args(),
      query: deferredValue,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    {
      skip: (!isMenuOpen && !isHovered) || queriesConfig.list.skip,
    },
  )
  const resultData = listQueryResult?.data as unknown as {
    data: ListOptionType[]
  }
  const options: ListOptionType[] = resultData?.data || []
  const filtredOptions = options.filter(filterOptions)
  const isLoadingOptions = listQueryResult?.isFetching
  const singleQueryResult = queriesConfig.single.query.useQuery(
    {
      ...queriesConfig.single.args(value || ''),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    {
      skip: !value,
    },
  )

  useEffect(() => {
    if (
      onSingleItemLoaded &&
      singleQueryResult.isSuccess &&
      singleQueryResult.data
    ) {
      onSingleItemLoaded(singleQueryResult.data)
    }
  }, [onSingleItemLoaded, singleQueryResult.data, singleQueryResult.isSuccess])

  const selectRef = useRef<HTMLDivElement>(null)

  const selectWrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const topAddonElementRef = useRef<HTMLDivElement>(null)
  const [direction, setMenuDirection] = useState<'up' | 'down'>('down')

  useClickAway(selectWrapperRef, () => {
    if (isMenuOpen) {
      if (isEdit) {
        setIsEdit(false)
      }
      setMenuOpen(false)
    }
  })
  useEffect(() => {
    if (!listQueryResult.isSuccess) {
      setSelectedIndex(0)
    }
  }, [listQueryResult.isSuccess])

  const onSelect = useCallback(
    (data: ListOptionType) => {
      setFastData(data)
      if (handleChange || onOptionChange) {
        setInputValue('')
        setMenuOpen(false)
        handleChange && handleChange(getValue(data))
        onOptionChange && onOptionChange(data)
      }
      if (isEdit) {
        setIsEdit(false)
      }
    },
    [getValue, handleChange, isEdit, onOptionChange],
  )

  const onKeyUpInput: KeyboardEventHandler = useCallback(
    (event) => {
      const { shiftKey, key } = event

      function moveOptionsCursor(offset: 1 | -1) {
        if (listQueryResult.isSuccess) {
          const optionsLength = Number(listQueryResult.data?.data?.length)
          const newVal = offset * (shiftKey ? paginationMultiplier : 1)
          setSelectedIndex((currentIndex) => {
            const resultIndex = currentIndex + newVal
            if (resultIndex > optionsLength - 1 || resultIndex < 0) {
              return currentIndex
            }
            return resultIndex
          })
        }
      }

      const paginationMultiplier = 10
      if (key === 'ArrowDown') {
        moveOptionsCursor(1)
      } else if (key === 'ArrowUp') {
        moveOptionsCursor(-1)
      } else if (key === 'Enter') {
        const data = listQueryResult.data?.data?.[selectedIndex]
        if (data) {
          // TODO: убрать это
          //@ts-ignore
          onSelect(data)
        }
      }
    },
    [
      listQueryResult.data?.data,
      listQueryResult.isSuccess,
      onSelect,
      selectedIndex,
    ],
  )
  const onFocusInput = () => {
    clearTimeout(onInputBlurTimeoutRef.current)
    if (isDisabled) return undefined
    setFocusInput()
    setMenuDirection(
      (
        window.innerHeight -
          (selectRef.current?.getBoundingClientRect().top || 0) >
          300
      ) ?
        'down'
      : 'up',
    )
    setMenuOpen(true)
  }

  const setFocusInput = useCallback(() => {
    if (inputRef.current || textAreaRef.current) {
      if (asTextArea && textAreaRef.current) {
        textAreaRef.current.focus()
      }
      if (!asTextArea && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }, [asTextArea])

  useEffect(() => {
    if (isEdit) {
      setFocusInput()
    }
  }, [isEdit, setFocusInput])
  const onInputChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ target: { value } }) => {
    setInputValue(value)
    setMenuOpen(true)
  }

  const onCreate = () => {
    if (onCreateNew) {
      onCreateNew(inputValue, (data) => {
        setFastData(data)
        if (handleChange || onOptionChange) {
          setMenuOpen(false)
          handleChange && handleChange(getValue(data))
          onOptionChange && onOptionChange(data)
        }
        if (isEdit) {
          setIsEdit(false)
        }
      })
    }
  }

  const onInputBlurTimeoutRef = useRef<NodeJS.Timeout>()
  const onInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    clearTimeout(onInputBlurTimeoutRef.current)
    onInputBlurTimeoutRef.current = setTimeout(() => {
      setInputValue('')
      setIsEdit(false)
      return () => {
        clearTimeout(onInputBlurTimeoutRef.current)
      }
    }, 308)
  }, [])

  useEffect(() => {
    if (isMenuOpen && !value) {
      inputRef.current && inputRef.current.focus()
    }
  }, [isMenuOpen, value])
  const onMouseEnter = (data: ListOptionType) =>
    onHoverOption && onHoverOption(data)

  const onClickEdit = useCallback(() => {
    setIsEdit(true)
    setInputValue('')
    setMenuOpen(true)
  }, [])

  const onClickDelete = useCallback(() => {
    setFastData(undefined)
    setIsEdit(false)
    setMenuOpen(false)
    handleChange && handleChange(undefined)
  }, [handleChange])

  const moreResultsCount =
    (listQueryResult?.data?.meta?.count || 0) - (options?.length || 0)

  if (value && !isEdit) {
    return (
      <SelectAsyncComponentContext.Provider
        value={{
          isHasProvider: true,
          onClickClear: onClickDelete,
          onClickChange: onClickEdit,
        }}
      >
        <Grid
          data-test-class={'select'}
          gridTemplateColumns={'1fr auto'}
          gridGap={'12px'}
          onMouseEnter={() => {
            setIsHovered(true)
          }}
        >
          <div>
            <SelectAsyncOptionContext.Provider
              value={{
                option:
                  singleQueryResult.isFetching ? fastData : (
                    singleQueryResult.data
                  ),
                onClickEdit: (o) => {
                  const option = o as ListOptionType
                  onEdit &&
                    onEdit(option, () => {
                      setFastData(option)
                      if (handleChange || onOptionChange) {
                        setMenuOpen(false)
                        handleChange && handleChange(getValue(option))
                        onOptionChange && onOptionChange(option)
                      }
                      if (isEdit) {
                        setIsEdit(false)
                      }
                    })
                },
              }}
            >
              {singleQueryResult.isFetching ?
                fastData ?
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  renderSelected(fastData, () => {
                    setFastData(fastData)
                    if (handleChange || onOptionChange) {
                      setMenuOpen(false)
                      handleChange && handleChange(getValue(fastData))
                      onOptionChange && onOptionChange(fastData)
                    }
                    if (isEdit) {
                      setIsEdit(false)
                    }
                  })
                : <Spinner size={'sm'} />
              : singleQueryResult.data ?
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                renderSelected(singleQueryResult.data, () => {
                  const data = singleQueryResult.data
                  if (data) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setFastData(data)
                    if (handleChange || onOptionChange) {
                      setMenuOpen(false)
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      handleChange && handleChange(getValue(data))
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onOptionChange && onOptionChange(data)
                    }
                    if (isEdit) {
                      setIsEdit(false)
                    }
                  }
                })
              : <Stack direction={'row'} spacing={4}>
                  <div>
                    Ошибка получения данных:
                    <br />
                    {
                      // @ts-ignore
                      singleQueryResult?.error?.response?.data?.message
                    }
                  </div>
                  <Button
                    size={'sm'}
                    onClick={() => {
                      handleChange && handleChange('')
                    }}
                  >
                    Сбросить
                  </Button>
                </Stack>
              }
            </SelectAsyncOptionContext.Provider>
          </div>
          {!isWithoutButtons && !isDisabled && (
            <Grid gridTemplateColumns={'min-content min-content'}>
              {onEdit && <SelectAsyncComponent.EditButton />}
              <ChangeButton />
              <ClearButton />
            </Grid>
          )}
        </Grid>
      </SelectAsyncComponentContext.Provider>
    )
  }

  return (
    <Box
      ref={selectWrapperRef}
      onClick={() => {
        setFocusInput()
      }}
    >
      {topAddonContent && (
        <SelectAsyncTopAddonWrapper
          ref={topAddonElementRef}
          $error={props.isInvalid}
        >
          {topAddonContent}
        </SelectAsyncTopAddonWrapper>
      )}
      <Select
        $error={props.isInvalid}
        data-test-class={'select'}
        style={styles}
        $withTopAddon={Boolean(topAddonContent)}
        $isDisabled={isDisabled}
        data-is-menu-open={isMenuOpen}
        ref={selectRef}
        onClick={() => {
          inputRef.current?.focus()
        }}
        onMouseEnter={() => {
          setIsHovered(true)
        }}
      >
        {asTextArea ?
          <Textarea
            autoComplete="none"
            fontSize="14px"
            focusBorderColor="none"
            lineHeight="16px"
            padding="0"
            resize="none"
            bgColor="#fff"
            shadow="noen"
            width="100%"
            outline="0px solid #fff"
            border="none"
            rows={2}
            disabled={isDisabled}
            ref={textAreaRef}
            onChange={onInputChange}
            value={inputValue}
            placeholder={placeholder}
          />
        : <Input
            onFocus={onFocusInput}
            onBlur={onInputBlur}
            onKeyUp={onKeyUpInput}
            width="100%"
            disabled={isDisabled}
            autoComplete="none"
            ref={inputRef}
            onChange={onInputChange}
            value={inputValue}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onCreate()
              }
            }}
          />
        }
        <Spinner
          size="sm"
          opacity={isLoadingOptions ? 1 : 0}
          color={colors.neutral[400]}
        />
        <SelectIndicator isOpen={isMenuOpen} />
        <OptionsList
          as={m.div}
          $isLoading={isLoadingOptions}
          $direction={direction}
          animate={isMenuOpen ? 'visible' : 'hidden'}
          variants={variants.optionList}
          initial={'hidden'}
        >
          {filtredOptions.length === 0 ?
            onCreateNew && inputValue !== '' ?
              <CreateOption
                isSelected={!isLoadingOptions}
                key={'createOption'}
                val={inputValue}
                onClick={onCreate}
              />
            : <NotFound
                key={'notFound'}
                isLoading={Boolean(isLoadingOptions)}
              />

          : <>
              {filtredOptions.map((data, i) => (
                <SelectAsyncOptionContext.Provider
                  key={i}
                  value={{
                    option: data,
                    onClickEdit: (o) => {
                      const option = o as ListOptionType
                      onEdit &&
                        onEdit(option, () => {
                          setFastData(option)
                          if (handleChange || onOptionChange) {
                            setMenuOpen(false)
                            handleChange && handleChange(getValue(option))
                            onOptionChange && onOptionChange(option)
                          }
                          if (isEdit) {
                            setIsEdit(false)
                          }
                        })
                    },
                  }}
                >
                  <Option
                    $isSelected={getValue(data) === value ? true : undefined}
                    $isKeyboardFocused={i === selectedIndex}
                    onClick={() => {
                      onSelect(data)
                    }}
                    onMouseEnter={() => onMouseEnter(data)}
                    onMouseLeave={onHoverOut}
                    data-test-class={'select_option'}
                  >
                    <ScrollIntoView isActive={i === selectedIndex}>
                      {renderOption(data, () => {
                        setFastData(data)
                        if (handleChange || onOptionChange) {
                          setMenuOpen(false)
                          handleChange && handleChange(getValue(data))
                          onOptionChange && onOptionChange(data)
                        }
                        if (isEdit) {
                          setIsEdit(false)
                        }
                      })}
                    </ScrollIntoView>
                  </Option>
                </SelectAsyncOptionContext.Provider>
              ))}
              {moreResultsCount ?
                <Option
                  style={{
                    pointerEvents: 'none',
                    cursor: 'pointer',
                    color: colors.neutral['600'],
                    background: colors.neutral['200'],
                  }}
                >
                  Также найдено: {formatNumber(moreResultsCount)}.
                  <br />
                  Уточните критерии поиска для выбора.
                </Option>
              : null}
            </>
          }
        </OptionsList>
      </Select>
    </Box>
  )
}

export class SelectAsyncComponent<
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = ApiEndpointResultType<SingleQueryEndpoint>,
> extends React.Component<
  SelectAsyncProps<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
  never
> {
  static ChangeButton = ChangeButton
  static EditButton = EditButton
  static ClearButton = ClearButton

  render() {
    return <SelectAsyncComponentFunction {...this.props} />
  }
}

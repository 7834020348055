import React from 'react'
import { QSelectInner } from '@/components/QForm/QField/QSelect/QSelectInner'
import { IOption } from '@/types/global'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { QSelectReceiverDestinationType } from '@/components/QForm/QField/QSelect/ReceiverDestinationType/QSelectReceiverDestinationType'
import { QSelectSenderDestinationType } from '@/components/QForm/QField/QSelect/SenderDestinationType/QSelectSenderDestinationType'
import { QForm } from '../../QForm'
import { QSelectVatRate } from '@/components/QForm/QField/QSelect/QSelectVatRate/QSelectVatRate'
import { QSelectShipmentStatus } from '@/components/QForm/QField/QSelect/ShipmentStatus/QSelectShipmentStatus'
import { QSelectRunRequestStatus } from '@/components/QForm/QField/QSelect/RunRequestStatus/QSelectRunRequestStatus'
import { QSelectUserRole } from '@/components/QForm/QField/QSelect/QSelectUserRole/QSelectUserRole'
import { QSelectCargoType } from '@/components/QForm/QField/QSelect/QSelectCargoType/QSelectCargoType'
import { QSelectCargoPackageType } from '@/components/QForm/QField/QSelect/QSelectCargoPackageType/QSelectCargoPackageType'
import { QSelectExcelReportTemplate } from '@/components/QForm/QField/QSelect/QSelectExcelReportTemplate/QSelectExcelReportTemplate'
import { QSelectTariffLevelLocalization } from './QSelectTariffLevelLocalization/QSelectTariffLevelLocalization'
import { QSelectPartnerLegalForm } from '@/components/QForm/QField/QSelect/QSelectPartnerLegalForm/QSelectPartnerLegalForm'
import { QSelectClientStatus } from './QSelectClientStatus/QSelectClientStatus'
import { QSelectInvoiceStatus } from './QSelectInvoiceStatus/QSelectInvoiceStatus'
import { QSelectLocationType } from '@/components/QForm/QField/QSelect/QSelectLocationType/QSelectLocationType'
import { QSelectGroup } from '@/components/QForm/QField/QSelect/QSelectGroup'
import { QSelectShipmentType } from '@/components/QForm/QField/QSelect/ShipmentType/QSelectShipmentType'
import { QSelectDlvStatus } from './QSelectDlvStatus/QSelectDlvStatus'
import { QSelectUnloadingDlvStatus } from './QSelectUnloadingDlvStatus/QSelectUnloadingDlvStatus'
import { QSelectShipmentsDlvDelivery } from './QSelectShipmentsDlvDelivery/QSelectShipmentsDlvDelivery'
import { QSelectMonitoringStatusDlv } from './QSelectMonitoringStatusDlv/QSelectMonitoringStatusDlv'
import { QSelectMonitoringStatusCs } from './QSelectMonitoringStatusCs/QSelectMonitoringStatusCs'
import { QSelectMonitoringErrorStatus } from './QSelectMonitoringErrorStatus/QSelectMonitoringErrorStatus'
import { QSelectRunRequestsStatus } from './RunRequestsStatus/QSelectRunRequestsStatus'
import { QSelectRunRequestsTypes } from './QSelectRunRequestsTypes/QSelectRunRequestsTypes'
import { QSelectMonitoringWarehouse } from './QSelectMonitoringWarehouse/QSelectMonitoringWarehouse'
import { QSelectShipmentStatusNew } from './ShipmentStatus/QSelectShipmentStatusNew'

export const QSelectFunction = <
  OptionType extends IOption<ValueType>,
  ValueType extends string = string,
>(
  props: QSelectPropsType<OptionType, ValueType>,
) => {
  const { name, isMultiple, withWrapper, isHardName, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      {isMultiple ?
        <QSelectGroup<OptionType, ValueType> {...{ name, ...otherProps }} />
      : <QSelectInner {...otherProps} />}
    </QForm.Field>
  )
}

export class QSelect<
  OptionType extends IOption<ValueType>,
  ValueType extends string = string,
> extends React.Component<QSelectPropsType<OptionType, ValueType>, unknown> {
  static ReceiverDestinationType = QSelectReceiverDestinationType
  static SenderDestinationType = QSelectSenderDestinationType
  static VatRate = QSelectVatRate
  static UserRole = QSelectUserRole
  static LocationType = QSelectLocationType
  static CargoType = QSelectCargoType
  static PartnerLegalForm = QSelectPartnerLegalForm
  static ExcelReportTemplate = QSelectExcelReportTemplate
  static TariffLevelLocalization = QSelectTariffLevelLocalization
  static CargoPackageType = QSelectCargoPackageType
  static ShipmentStatus = QSelectShipmentStatus
  static ShipmentStatusNew = QSelectShipmentStatusNew
  static ShipmentType = QSelectShipmentType
  static RunRequestStatus = QSelectRunRequestStatus
  static RunRequestsStatus = QSelectRunRequestsStatus
  static RunRequestsTypes = QSelectRunRequestsTypes
  static ClientStatus = QSelectClientStatus
  static InvoiceStatus = QSelectInvoiceStatus
  static DlvStatus = QSelectDlvStatus
  static MonitoringStatusDlv = QSelectMonitoringStatusDlv
  static MonitoringErrorStatus = QSelectMonitoringErrorStatus
  static MonitoringWarehouse = QSelectMonitoringWarehouse
  static MonitoringStatusCs = QSelectMonitoringStatusCs
  static UnloadingDlvStatus = QSelectUnloadingDlvStatus
  static ShipmentsDelivery = QSelectShipmentsDlvDelivery

  render() {
    return <QSelectFunction {...this.props} />
  }
}

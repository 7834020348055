import React, { ReactElement, ReactHTML } from 'react'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'
import trim from 'lodash/trim'
import each from 'lodash/each'

type DecoratorPropsType = {
  defaultElementType?: keyof ReactHTML
  children: ReactElement
} & Record<string, unknown>
export const ComponentPropsDecorator = (decoratorProps: DecoratorPropsType) => {
  const Cook = new CookProps(decoratorProps)
  return Cook.please()
}

type Props = {
  children?: ReactElement
  className?: string
  style?: Record<string, unknown>
  defaultElementType?: keyof ReactHTML
} & Record<string, unknown | string | ((...params: unknown[]) => void)>

class CookProps {
  constructor(props: DecoratorPropsType) {
    this.props = props

    this._saveChildrenProps()
      ._startFillResultProps()
      ._mergeClassNames()
      ._mergeStyles()
      ._mergeMethods()
  }

  childrenIsElement = () => {
    const {
      props: { children },
    } = this
    return React.isValidElement(children)
  }

  childrenProps: Props = {}
  props: Props = {}

  resultProps: Props = {}

  getChildren = (): ReactElement | false => {
    if (this.childrenIsElement()) {
      return React.Children.only(this.props.children) as ReactElement
    } else {
      return false
    }
  }

  _mergeClassNames() {
    const {
      props: { className },
      childrenProps: { className: childrenClassName = '' } = {},
    } = this
    if (className || childrenClassName) {
      this.resultProps.className = trim(
        [className || '', childrenClassName || ''].join(' '),
      )
    }
    return this
  }

  _mergeMethods() {
    each(this.childrenProps, (childrenMethod, methodName) => {
      if (isFunction(childrenMethod)) {
        if (isFunction(get(this.props, methodName))) {
          this.resultProps[methodName] = (...params: unknown[]) => {
            const propsMethod = this.props[methodName] as (
              ...params: unknown[]
            ) => unknown
            propsMethod(...params)
            return childrenMethod(...params)
          }
        }
      }
    })

    return this
  }

  _mergeStyles() {
    const {
      props: { style = {} },
      childrenIsElement,
      childrenProps,
    } = this

    if (childrenIsElement()) {
      const { style: childrenStyle = {} } = childrenProps
      this.resultProps = {
        ...this.resultProps,
        style: {
          ...style,
          ...childrenStyle,
        },
      }
    } else {
      this.resultProps = {
        ...this.resultProps,
        style,
      }
    }

    return this
  }

  _saveChildrenProps() {
    const children = this.getChildren()

    if (children) {
      this.childrenProps = children.props
    }
    return this
  }

  _startFillResultProps() {
    const { defaultElementType, ...propsWithoutDecoratorOptions } = this.props
    const thisPropsWithoutChildren = {
      ...propsWithoutDecoratorOptions,
      children: undefined,
    }

    this.resultProps = {
      ...thisPropsWithoutChildren,
      ...this.childrenProps,
    }

    return this
  }

  please() {
    const {
      props: { children, defaultElementType },
      resultProps,
    } = this

    if (this.childrenIsElement() && children) {
      return React.cloneElement(children, { ...resultProps })
    } else {
      return React.createElement(
        defaultElementType || 'span',
        { ...resultProps },
        children,
      )
    }
  }
}

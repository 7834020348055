import { colors } from '@/theme/colors'
import { ShipmentStatus } from '@/api/generated/qubyApiJava'

export const shipmentStatusColorsCollection = {
  blue: [colors.accent[600], colors.accent[200]],
  orange: [colors.attention[600], colors.attention[200]],
  red: [colors.error[600], colors.error[200]],
  green: [colors.success[600], colors.success[200]],
  purple: ['#C536F6', '#FCF2FF'],

  unset: ['#000', 'E2E2E2'],
}

export const shipmentStatusColors: Partial<Record<ShipmentStatus, string[]>> = {
  AT_SENDER_PARSE_ERROR: shipmentStatusColorsCollection.red,
  AT_SENDER_NEW: shipmentStatusColorsCollection.purple,
  AT_SENDER_PACKED: shipmentStatusColorsCollection.purple,
  QUBY_VEHICLE_2_QUBY: shipmentStatusColorsCollection.blue,
  AT_QUBY_AWAITING_CONFIRMATION: shipmentStatusColorsCollection.orange,
  AT_QUBY_AWAITING_CORRECTION: shipmentStatusColorsCollection.orange,
  AGENT_VEHICLE_2_AGENT: shipmentStatusColorsCollection.blue,
  AT_AGENT: shipmentStatusColorsCollection.blue,
  AGENT_VEHICLE_2_RECEIVER: shipmentStatusColorsCollection.blue,
  AGENT_ERROR: shipmentStatusColorsCollection.red,
  RETURN_AGENT_VEHICLE_2_QUBY: shipmentStatusColorsCollection.blue,
  RETURN_AGENT_VEHICLE_2_SENDER: shipmentStatusColorsCollection.blue,
  AT_AGENT_LOST: shipmentStatusColorsCollection.red,
  QUBY_VEHICLE_2_RECEIVER: shipmentStatusColorsCollection.blue,
  AT_RECEIVER_DONE: shipmentStatusColorsCollection.green,
  RETURN_QUBY_VEHICLE_2_QUBY: shipmentStatusColorsCollection.blue,
  RETURN_AT_QUBY_AWAITING_CONFIRMATION: shipmentStatusColorsCollection.orange,
  RETURN_QUBY_VEHICLE_2_SENDER: shipmentStatusColorsCollection.blue,
  AT_SENDER_RETURNED: shipmentStatusColorsCollection.green,
  AT_SENDER_CANCELLED: shipmentStatusColorsCollection.red,
}

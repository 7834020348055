import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { InputProps } from '@chakra-ui/react'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QStringCore } from '@/components/QForm/QField/QInput/QString/QStringCore'
import { Masks } from '@/constants/Masks'

export const QPhoneInner = (props: InputProps) => {
  const { name, propsFromYup } = useQFieldContext()
  const [field, meta, helpers] = useField(name)
  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QStringCore
        isInvalid={Boolean(meta?.error && meta?.touched)}
        maskProps={{ mask: Masks.phone }}
        placeholder={'+7 (000) 000-00-00'}
        {...{
          ...field,
          meta,
          helpers,
        }}
      />
    </ComponentPropsDecorator>
  )
}

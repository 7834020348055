import * as yup from 'yup'
import { TableQueriesExtraCheckingPropsType } from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import { emptySpace } from '@/types/emptySpace'

export const registersCommonFiltersYup: yup.ObjectSchema<
  Partial<Record<TableQueriesExtraCheckingPropsType, string>>
> = yup
  .object({
    createdDateFrom: yup
      .string()
      .date()
      .label('Создано')
      .meta({
        placeholder: 'С',
      })
      .test(
        '$',
        ({ label, path }) =>
          `Дата "${label || path}" ― не может быть больше даты "Создано по"`,
        (value, { parent }) => {
          if (!value || !parent.createdDateTo) return true
          return new Date(parent.createdDateTo) >= new Date(value)
        },
      ),
    createdDateTo: yup
      .string()
      .date()
      .label(emptySpace)
      .meta({
        placeholder: 'По',
      })
      .test(
        '$',
        ({ label, path }) =>
          `Дата "${label || path}" ― не может быть меньше даты "Создано с"`,
        (value, { parent }) => {
          if (!value || !parent.createdDateFrom) return true
          return new Date(parent.createdDateFrom) <= new Date(value)
        },
      ),
    updatedDateFrom: yup
      .string()
      .date()
      .label('Статус изменен')
      .meta({
        placeholder: 'С',
      })
      .test(
        '$',
        ({ label, path }) =>
          `Дата "${label || path}" ― не может быть больше даты "обновлено по"`,
        (value, { parent }) => {
          if (!value || !parent.updatedDateTo) return true
          return new Date(parent.updatedDateTo) >= new Date(value)
        },
      ),
    updatedDateTo: yup
      .string()
      .date()
      .label(emptySpace)
      .meta({
        placeholder: 'По',
      })
      .test(
        '$',
        ({ label, path }) =>
          `Дата "${label || path}" ― не может быть меньше даты "обновлено с"`,
        (value, { parent }) => {
          if (!value || !parent.updatedDateFrom) return true
          return new Date(parent.updatedDateFrom) <= new Date(value)
        },
      ),
  })
  .required()

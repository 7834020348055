import {
  GetListOfDriversApiArg,
  GetListOfVehiclesApiArg,
  GetV1UsersApiArg,
  Meta,
  VehicleResponse,
} from '@/api/generated/qubyApiJava'
import { FixedGetV1UsersApiResponse } from '@/_app/pages/registers/users/_components/UsersQueryTable.context'
import { qubyApiJava } from '@/api/generated/qubyApiJava'
import { GetEntities3ApiResponse } from './generated/qubyApiDlv'
import { GetShipmentDlvApiArg } from './qubyApiEgoDlv'
import { DriverUpdateRequest } from './generated/qubyApiPhp'

export type FixedDriverResponse = Omit<DriverUpdateRequest, 'id'> & {
  id: string
}

export type FixedGetListOfDriversApiResponse = {
  meta: Meta
  data?: FixedDriverResponse[]
}

export type FixedVehiclesResponse = Omit<VehicleResponse, 'id'> & { id: string }
export type FixedGetListOfVehiclesApiResponse = {
  meta: Meta
  data: FixedVehiclesResponse[]
}
const envVariables = require('@/constants/env')

const convertFilterToArg = (values: GetShipmentDlvApiArg) => {
  const result: ShipmentsDlvFiltersType[] = []
  const { places, syncRecord, courierCode, id, query } = values
  if (places?.barcode) {
    result.push({
      key: 'places.barcode',
      value: places?.barcode,
      operation: '=',
    })
  }
  if (query) {
    result.push({
      key: 'syncRecord.num',
      value: query,
      operation: '=',
    })
  }
  if (syncRecord?.status) {
    result.push({
      key: 'syncRecord.status',
      value: syncRecord?.status,
      operation: '=',
    })
  }
  if (syncRecord?.syncState) {
    result.push({
      key: 'syncRecord.syncState',
      value: syncRecord?.syncState,
      operation: '=',
    })
  }
  if (syncRecord?.courierNum) {
    result.push({
      key: 'syncRecord.courierNum',
      value: syncRecord?.courierNum,
      operation: '=',
    })
  }
  if (syncRecord?.createTsFrom) {
    result.push({
      key: 'syncRecord.createTs',
      value: `${syncRecord?.createTsFrom}T00:00:00`,
      operation: '>',
    })
  }
  if (syncRecord?.createTsTo) {
    result.push({
      key: 'syncRecord.createTs',
      value: `${syncRecord?.createTsTo}T00:00:00`,
      operation: '<',
    })
  }
  if (courierCode) {
    result.push({
      key: 'courierCode',
      value: courierCode,
      operation: '=',
    })
  }
  if (id) {
    result.push({
      key: 'id',
      value: id,
      operation: '=',
    })
  }
  return result
}

export const qubyApiJavaFixedTypes = qubyApiJava.injectEndpoints({
  endpoints: (build) => ({
    getListOfDrivers: build.query<
      FixedGetListOfDriversApiResponse,
      GetListOfDriversApiArg
    >({
      query: (queryArg) => ({
        url: '/v1/drivers',
        params: {
          query: queryArg.query,
          withRoutesAtDate: queryArg.withRoutesAtDate,
          orderBy: queryArg.orderBy,
          order: queryArg.order,
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
        },
      }),
      providesTags: ['Driver'],
    }),
    getV1Users: build.query<FixedGetV1UsersApiResponse, GetV1UsersApiArg>({
      query: (queryArg) => ({
        url: '/v1/users',
        params: {
          partnerId: queryArg.partnerId,
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
        },
      }),
      providesTags: ['Users'],
    }),
    getListOfVehicles: build.query<
      FixedGetListOfVehiclesApiResponse,
      GetListOfVehiclesApiArg
    >({
      query: (queryArg) => ({
        url: '/v1/vehicles',
        params: {
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
          isActive: queryArg.isActive,
        },
      }),
      providesTags: ['Vehicle'],
    }),
    getEntities3: build.query<GetEntities3ApiResponse, GetShipmentDlvApiArg>({
      query: (queryArg) => {
        const originFilter = convertFilterToArg(queryArg)
        return {
          url: `${envVariables.DLV_API_URL}api/v2/shipments`,
          params: {
            page: queryArg.currentPage ? queryArg.currentPage - 1 : 0,
            size: queryArg.perPage,
            sort: queryArg.sort,
            filter: JSON.stringify(originFilter),
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
  }),
  overrideExisting: true,
})
export type ShipmentsDlvFiltersType = {
  key: string
  value: string | null
  operation: string
}

import React from 'react'
import { QFileInner } from '@/components/QForm/QField/QInput/QFile/QFileInner'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'
import { InputProps } from '@chakra-ui/react'

export const QFile = (
  props: Omit<InputProps, 'value'> & Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, withWrapper, isHardName, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QFileInner {...otherProps} />
    </QForm.Field>
  )
}

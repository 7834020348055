import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { runRequestStatusOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { RunRequestStatus } from '@/api/generated/qubyApiJava'

type QSelectRunRequestStatusPropsType = Omit<
  QSelectPropsType<IOption<RunRequestStatus>, RunRequestStatus>,
  'options'
>
export const QSelectRunRequestStatus = (
  props: QSelectRunRequestStatusPropsType,
) => {
  const [t] = useTranslation()
  const options = runRequestStatusOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import { Stack, Switch, SwitchProps } from '@chakra-ui/react'
import omit from 'lodash/omit'
import { colors } from '@/theme/colors'

export const QSwitchCore = (
  props: QFieldCorePopsType<boolean> & SwitchProps & { label?: ReactNode },
) => {
  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined
  const propsValue = props.value
  const innerRef = useRef<NodeJS.Timeout>()

  const [innerValue, setInnerValue] = useState<boolean>(propsValue || false)
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || false)
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const propsOnChange = props.onChange
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setInnerValue(isChecked)
      if (onChangeValue || propsOnChange) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(isChecked)
          propsOnChange && propsOnChange(e)
        }, 300)
      }
    },
    [onChangeValue, propsOnChange],
  )
  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [propsOnFocus],
  )

  useEffect(() => {
    return () => {
      clearInterval(innerRef.current)
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])
  return (
    <Stack direction={'row'} spacing={3} alignItems={'center'}>
      <Switch
        data-type={'control-switch'}
        css={`
          > span {
            box-sizing: content-box;
            ${props.isInvalid ?
              `
                            box-shadow: 0 0 0 1px ${colors.error['900']}99, inset 0 0 0 1px ${colors.error['800']};
                            background: ${colors.error['300']};
                        `
            : ''}
          }
        `}
        isChecked={innerValue}
        {...{
          onChange,
          onFocus,
          onBlur,
        }}
        {...omit(props, [
          'onChangeValue',
          'onChange',
          'onBlur',
          'onFocus',
          'value',
        ])}
        isInvalid={true}
      />
      {props.label}
    </Stack>
  )
}

import React, { FC, useMemo } from 'react'
import { Flex, Icon } from '@chakra-ui/react'
import { EnumOrder } from '@/types/table'
import { colors } from '@/theme/colors'

interface Props {
  sort?: EnumOrder
  onClick: (EnumOrder: EnumOrder | undefined) => void
}

const SortIcon: FC<Props> = ({ sort, onClick }) => {
  const colorASC =
    sort === EnumOrder.ASC ? colors.neutral[1000] : colors.neutral[500]
  const colorDESC =
    sort === EnumOrder.DESC ? colors.neutral[1000] : colors.neutral[500]
  const nextSortState = useMemo(
    () =>
      sort === undefined || sort === EnumOrder.DESC ?
        EnumOrder.ASC
      : EnumOrder.DESC,
    [sort],
  )
  return (
    <Flex
      cursor="pointer"
      onClick={() => onClick(nextSortState)}
      width="16px"
      height="16px"
      flexFlow="column"
      justifyContent="center"
      alignItems="center"
    >
      <Icon
        color={colorDESC}
        mb="4px"
        width="8px"
        height="5px"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 4L4 1.5L6.5 4"
          stroke="currentColor"
          strokeLinecap="square"
        />
      </Icon>
      <Icon
        color={colorASC}
        style={{
          transform: 'rotate(180deg)',
          transformOrigin: 'center center',
        }}
        width="8px"
        height="5px"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 4L4 1.5L6.5 4"
          stroke="currentColor"
          strokeLinecap="square"
        />
      </Icon>
    </Flex>
  )
}

export default SortIcon

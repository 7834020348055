import React from 'react'
import { QSelectAsyncPropsType } from '@/components/QForm/QField/QSelectAsync/QselectAsync.types'
import { SelectAsyncPropsListOptionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import { QForm } from '../..'
import {
  ApiAllGetByIdEndpointsType,
  ApiAllGetListEndpointsType,
  ApiEndpointResultType,
} from '@/types/api.types'
import { CloseButton, Stack } from '@chakra-ui/react'
import { colors } from '@/theme/colors'
import { QGroupListButtons } from '@/components/QForm/QGroupList/QGroupListButtons'
import { QGroupList } from '@/components/QForm/QGroupList/QGroupList'

export const QSelectAsyncGroup = <
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = ApiEndpointResultType<SingleQueryEndpoint>,
>(
  props: QSelectAsyncPropsType<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
) => {
  const { name: groupName } = props

  return (
    <QGroupList
      name={groupName}
      groupListWrapper={(content, value) => {
        const name = `${groupName}[${value?.length}]`
        return (
          <div>
            <Stack pb={2} direction={'row'} wrap={'wrap'}>
              {content}
            </Stack>
            <QForm.Field.SelectAsync
              key={name}
              filterOptions={({ id }) => !value?.includes(id)}
              isWithoutButtons
              {...{
                ...props,
                name,
              }}
              name={`${groupName}[${value?.length}]`}
            />
          </div>
        )
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        background={colors.neutral['300']}
        borderRadius={'var(--chakra-radii-md)'}
        p={1}
        css={`
          line-height: 1.42;
        `}
        pl={'1em'}
        lineHeight={1}
        spacing={0}
      >
        <QForm.Field.SelectAsync isWithoutButtons {...{ ...props }} name={''} />
        <QGroupListButtons.Remove>
          <CloseButton size={'sm'} />
        </QGroupListButtons.Remove>
      </Stack>
    </QGroupList>
  )
}

import React, { FC } from 'react'
import { Grid, Stack } from '@chakra-ui/react'
import { useQGroupContext } from '@/components/QForm/QGroup/useQGroupContext'
import { QForm } from '../..'
import { TimeFrameData } from '@/api/generated/qubyApiPhp'
import { QGroup } from '@/components/QForm/QGroup/QGroup'
import { DatePickerProps } from '@/types/global'

type Props = {
  dateTitle?: string
  timeTitle?: string
  timeFromProps?: DatePickerProps
  timeToProps?: DatePickerProps
  dateProps?: DatePickerProps
}
export const QDateWithTimeRange: FC<Props> = ({
  timeFromProps,
  timeToProps,
  dateProps,
}) => {
  const { value: groupValue } = useQGroupContext<TimeFrameData>()

  return (
    <>
      <QGroup name={'timeFrame'}>
        <Stack spacing={'16px'}>
          <Grid gridTemplateColumns={'2fr 1fr 1fr'} gridGap={'16px'}>
            <QForm.Field.Date withWrapper name={'date'} {...dateProps} />
            <QForm.Field.Time
              withWrapper
              dependFrom={{ timeTo: groupValue?.timeTo }}
              name={'timeFrom'}
              placeholder={'c 00:00'}
              preScrollTo={groupValue?.timeTo}
              {...timeFromProps}
            />
            <QForm.Field.Time
              withWrapper
              name={'timeTo'}
              dependFrom={{ timeTo: groupValue?.timeFrom }}
              preScrollTo={groupValue?.timeFrom}
              placeholder={'по 00:00'}
              {...timeToProps}
            />
          </Grid>
        </Stack>
      </QGroup>
    </>
  )
}

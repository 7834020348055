import { ru } from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
import React, {
  DetailedHTMLProps,
  FC,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { DatePickerProps } from '@/types/global'
import Input from '../Input'
import {
  dateToString,
  stringToDate,
  stringToTime,
  timeToString,
} from '@/utils/dateHelperFns'

registerLocale('ru', ru)
setDefaultLocale('ru')

type ChangeHandler = (
  date: Date | null,
  event: React.SyntheticEvent<HTMLElement, Event> | undefined,
) => void

const _DatePicker: FC<DatePickerProps> = ({
  value,
  handleChange,
  type = 'date',
  ...props
}) => {
  const isTime = type === 'time'
  const isDate = type === 'date'

  const [startDate, setStartDate] = useState<Date | null>(null)

  useEffect(() => {
    if (value) {
      if (isTime) {
        setStartDate(stringToTime(value))
      }
      if (isDate) {
        setStartDate(stringToDate(value))
      }
    } else {
      if (isTime) {
        setStartDate(stringToTime(''))
      }
      if (isDate) {
        setStartDate(stringToDate(''))
      }
    }
  }, [value, isDate, isTime])

  const onChange: ChangeHandler = (date) => {
    setStartDate(date)
    if (handleChange) {
      if (isTime) {
        handleChange(date ? timeToString(date) : '')
      }
      if (isDate) {
        handleChange(date ? dateToString(date) : '')
      }
    }
  }

  return (
    <>
      {/** @ts-ignore */}
      <DatePicker
        portalId="date-picker"
        selected={startDate}
        onChange={onChange}
        // minDate={new Date()}

        {...props}
        customInput={<DatePickerInput isInvalid={props.isInvalid} />}
        showPopperArrow={false}
        {...(isTime ?
          {
            timeCaption: 'Время',
            showTimeSelect: true,
            showTimeSelectOnly: true,
            timeFormat: 'p',
            dateFormat: 'p',
          }
        : {})}
        {...(isDate ?
          {
            dateFormat: 'dd MMMM yyyy',
          }
        : {})}
      />
    </>
  )
}

const DatePickerInput = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    isInvalid?: boolean
  }
>((props, ref) => <Input datePikerInputProps={props} ref={ref} />)

export default _DatePicker

import React, { FC, MouseEventHandler, ReactNode } from 'react'
import { Box, Flex, Menu, MenuButton, MenuList, Portal } from '@chakra-ui/react'
import TableMenuIcon from '@/components/Icons/TableMenu'
import { motion as m } from 'framer-motion'
import variants from './animation'
import { colors } from '@/theme/colors'

interface Props {
  visible: boolean
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>
  children: ReactNode[]
}

const MenuCell: FC<Props> = ({ visible, children, onMouseEnter }) => {
  return (
    <Box
      border="none"
      margin="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Menu isLazy>
        {({ isOpen }) => (
          <>
            <Flex
              as={m.div}
              display={'block'}
              variants={variants.menuButton}
              initial={'hidden'}
              animate={visible || isOpen ? 'visible' : 'hidden'}
              padding={0}
            >
              <MenuButton
                data-type={'menu-button'}
                {...{ onMouseEnter }}
                backgroundColor={'white'}
                borderRadius="4px"
                width="max-content"
                height="max-content"
                border={`1px solid ${colors.neutral[400]}`}
                transitionDelay={'.05s'}
                transition={'.12s ease-in'}
                boxShadow={'sm'}
                _hover={{
                  boxShadow: 'md',
                  transform: 'scale(1.05)',
                }}
                _active={{
                  transform: 'scale(.95)',
                }}
              >
                <Box p={1}>
                  <TableMenuIcon />
                </Box>
              </MenuButton>
            </Flex>
            <Portal>
              <MenuList>{children}</MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </Box>
  )
}

export default MenuCell

import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import Input from '@/components/atoms/Input/Input'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import omit from 'lodash/omit'
import { qubyApiJava } from '@/api/generated/qubyApiJava'
import { Box, InputProps, Spinner } from '@chakra-ui/react'
import { colors } from '@/theme/colors'
import { InputSuggestionsProps } from '@/components/QForm/QField/QInput/QStringSuggestionsAsyc/QStringSuggestionsAsync.types'

export const QStringSuggestionsAsyncCore = (
  props: QFieldCorePopsType & InputProps & InputSuggestionsProps,
) => {
  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined
  const propsValue = props.value
  const innerRef = useRef<NodeJS.Timeout>()

  const [innerValue, setInnerValue] = useState(propsValue || '')

  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || '')
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const onChange = useCallback(
    (v: string) => {
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setInnerValue(v)
      if (onChangeValue) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(v)
        })
      }
    },
    [onChangeValue],
  )
  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus
  const [isFocused, setIsFocused] = useState(false)

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setTimeout(() => {
        setIsFocused(false)
      }, 208)
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [propsOnFocus],
  )

  useEffect(() => {
    return () => {
      clearInterval(innerRef.current)
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])

  const queryAddress = useDeferredValue(innerValue)

  const { data, isFetching } =
    qubyApiJava.endpoints.getListOfAddressSuggestions.useQuery({ queryAddress })

  return (
    <div style={{ position: 'relative' }}>
      <Box
        position={'absolute'}
        right={0}
        top={0}
        transform={'translate(-80%, 5px)'}
      >
        {isFetching && <Spinner size={'xs'} />}
      </Box>
      <Input
        autoComplete={'off'}
        {...{
          onChange: (e) => {
            onChange(e.target.value)
          },
          onFocus,
          onBlur,
          value: innerValue,
          ...omit(props, [
            'meta',
            'helpers',
            'onChangeValue',
            'onChange',
            'onBlur',
            'onFocus',
            'value',
            'onChangeSuggestion',
            'isRequired',
            'required',
            'tooltipText',
          ]),
        }}
      />
      {isFocused && (
        <Box
          shadow={'md'}
          borderRadius={'4px'}
          style={{
            position: 'absolute',
            zIndex: 2,
            background: 'white',
            width: '100%',
            height: 'auto',
            left: 0,
            top: '100%',
          }}
        >
          {data?.suggestions &&
            data.suggestions.map((suggestion, index) => {
              const { value } = suggestion
              return (
                <Box
                  key={index}
                  onClick={() => {
                    onChange(suggestion.value)
                    props.onChangeSuggestion &&
                      props.onChangeSuggestion(suggestion)
                  }}
                  width={'100%'}
                  p={'2px 12px'}
                  as={'button'}
                  textAlign={'left'}
                  display={'block'}
                  transition={'.1s ease-out'}
                  _hover={{
                    background: colors.accent['200'],
                  }}
                  _active={{
                    background: colors.accent['300'],
                  }}
                >
                  {value}
                </Box>
              )
            })}
        </Box>
      )}
    </div>
  )
}

import * as React from 'react'
import { QFormGroupContextValueType } from './QGroup.types'

export const QFormGroupContextDefaultValue: QFormGroupContextValueType<undefined> =
  {
    hasProvider: false,
    name: 'isFakeName',
    value: undefined,
  }
export const QGroupContext = React.createContext<
  QFormGroupContextValueType<unknown>
>(QFormGroupContextDefaultValue)

import React, { FC, useEffect, useRef } from 'react'
import { CalendarContainerProps } from 'react-datepicker'
import escapeStringRegexp from 'escape-string-regexp'

export const DatePickerScrollToTime: FC<
  CalendarContainerProps & {
    preScrollTo?: string
  }
> = ({ children, className, preScrollTo = '10:00' }) => {
  const divRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const list = divRef.current?.querySelector('.react-datepicker__time-list')
    if (list) {
      const timeArray = preScrollTo.split(':')
      const stringForSearch = [timeArray[0], timeArray[1]]
        .join(':')
        .replace(/^0(?=\d)/, '')
      const found = Array.from(list.children).find(
        (element) =>
          element.innerHTML.match(
            new RegExp(`^${escapeStringRegexp(preScrollTo)}`),
          ) || element.innerHTML === stringForSearch,
      )
      if (found) {
        found.scrollIntoView({ block: 'center' })
      }
    }
  }, [preScrollTo])
  return (
    <div {...{ className }} ref={divRef}>
      {/** @ts-ignore */}
      {children}
    </div>
  )
}

import React, { FC, ReactNode } from 'react'
import { Option } from '../style'

interface Props {
  isSelected: boolean
  onSelectOption: () => void
  children: ReactNode
}

const RenderOption: FC<Props> = ({ isSelected, onSelectOption, children }) => {
  return (
    <Option $isSelected={isSelected} onClick={onSelectOption}>
      {children}
    </Option>
  )
}

export default RenderOption

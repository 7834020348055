import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_352_16749)" fill="#98A2B3">
        <path d="M16 16H8.012A8 8 0 1116 7.497V16zM8.012 1.333a6.667 6.667 0 100 13.334h6.654V7.56a6.679 6.679 0 00-6.654-6.228z" />
        <path d="M8.668 4.667h-4V6h4V4.667zM11.335 7.333H4.668v1.334h6.667V7.334zM11.335 10H4.668v1.333h6.667V10z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_352_16749">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

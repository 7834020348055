import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_204_15103)" fill="#475467">
        <path d="M13.333 0H2.667A2.667 2.667 0 000 2.667v8a2.667 2.667 0 002.667 2.666H4.6l2.967 2.509a.667.667 0 00.862 0l2.971-2.509h1.933A2.667 2.667 0 0016 10.667v-8A2.667 2.667 0 0013.333 0zm1.334 10.667A1.333 1.333 0 0113.333 12H11.4c-.315 0-.62.112-.86.315L8 14.46l-2.538-2.145A1.333 1.333 0 004.6 12H2.667a1.334 1.334 0 01-1.334-1.333v-8a1.333 1.333 0 011.334-1.334h10.666a1.333 1.333 0 011.334 1.334v8z" />
        <path d="M4.667 4.667H8a.667.667 0 100-1.333H4.667a.667.667 0 100 1.333zM11.333 6H4.667a.667.667 0 000 1.333h6.666a.667.667 0 100-1.333zM11.333 8.666H4.667a.667.667 0 000 1.334h6.666a.667.667 0 100-1.334z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_204_15103">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

import React from 'react'
import { Text } from '@chakra-ui/react'
import { NotFound } from '../../style'

const _NotFound = (props: { isLoading: boolean }) => {
  return (
    <NotFound>
      <Text textAlign="center" fontSize="10px">
        {props.isLoading ? 'Загрузка...' : 'Ничего не найдено'}
      </Text>
    </NotFound>
  )
}

export default _NotFound

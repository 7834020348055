import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_310_16563)">
        <path
          d="M13.684 2.811L9.666.491a3.343 3.343 0 00-3.333 0l-4.018 2.32A3.343 3.343 0 00.648 5.698v4.64a3.344 3.344 0 001.667 2.887l4.018 2.32a3.342 3.342 0 003.333 0l4.018-2.32a3.343 3.343 0 001.667-2.887v-4.64a3.344 3.344 0 00-1.667-2.887zM7 1.647a2 2 0 012 0l4.018 2.32c.102.063.197.137.286.22L9.175 6.568a2.353 2.353 0 01-2.35 0l-4.13-2.383c.09-.082.185-.156.287-.22L7 1.646zM2.982 12.07a2.006 2.006 0 01-1-1.733V5.698c.004-.12.02-.24.047-.357l4.129 2.384c.364.207.762.35 1.175.423v6.38A1.922 1.922 0 017 14.39l-4.018-2.32zm11.036-1.733a2.005 2.005 0 01-1 1.733L9 14.39a1.922 1.922 0 01-.334.137V8.148a3.66 3.66 0 001.176-.423L13.97 5.34c.027.118.043.237.048.357v4.639z"
          fill="#475467"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_310_16563">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

import React from 'react'
import { FormikErrors } from 'formik'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import { FlatTextFormErrorType, QErrorsRenderPropsType } from './QErrors.types'
import { uniqBy } from 'lodash'
export const QErrorsRender = ({
  filterFn = () => true,
  errors = {},
}: QErrorsRenderPropsType) => {
  const errorsForRender = uniqBy(flatTextErrors(errors), 'text')
  return (
    <UnorderedList>
      {errorsForRender.filter(filterFn).map(({ text, path }) => (
        <ListItem key={path}>{text}</ListItem>
      ))}
    </UnorderedList>
  )
}

function flatTextErrors(errors: FormikErrors<unknown> = {}) {
  const resultErrors: FlatTextFormErrorType[] = []

  function collectError(
    possibleToCollectError: FormikErrors<unknown> = {},
    parentPath = '',
  ) {
    Object.entries(possibleToCollectError).forEach(([key, error]) => {
      const path = parentPath ? `${parentPath}.${key}` : key
      if (typeof error === 'string') {
        resultErrors.push({ text: error, path })
      } else if (error && typeof error === 'object') {
        collectError(error, path)
      }
    })
  }

  collectError(errors)
  return resultErrors
}

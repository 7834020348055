import React, { FC } from 'react'
import { RepeatIcon } from '@chakra-ui/icons'
import { RTKQUseQueryResult } from '@/types/rtkq.types'
import { IconButton, Tooltip } from '@chakra-ui/react'

type Props = {
  query: RTKQUseQueryResult<Record<string, unknown>, Record<string, unknown>>
}
export const RefreshQueryListButton: FC<Props> = ({ query }) => {
  return (
    <Tooltip label={query.isFetching ? undefined : 'Обновить'}>
      <IconButton
        maxH={'32px'}
        disabled={query.isFetching}
        isLoading={query.isFetching}
        variant={'secondary'}
        onClick={query.refetch}
        aria-label={'refresh list'}
        icon={<RepeatIcon />}
      />
    </Tooltip>
  )
}

import { emptySplitDlvApi as api } from '../emptyApi'
export const addTagTypes = [
  'shipment-controller',
  'user-controller',
  'upload-controller-v-2',
  'courier-company-controller-v-2',
  'tariff-import-process-controller-v-2',
  'courier-tariff-controller-v-2',
  'system-setting-controller-v-2',
  'suggestion-controller',
  'shipment-request-controller-v-2',
  'print-template-controller-v-2',
  'place-controller-v-2',
  'import-controller-v-2',
  'shipment-history-controller-v-2',
  'geography-controller-v-2',
  'contract-controller-v-2',
  'drop-tariff-controller',
  'download-controller',
  'courier-product-controller-v-2',
  'cdek-city-code-controller-v-2',
  'account-controller-v-2',
  'returns-controller',
  'excel-report-controller',
  'postal-controller',
  'postal-zone-fias-controller',
  'podrygka-cdek-update-status-controller',
  'pick-point-controller',
  'postamat-controller',
  'courier-settings-controller',
  'contract-controller',
  'statuses-controller',
  'address-validation-controller',
  'account-controller',
  'health-check-controller',
  'shipment-history-controller-v-3',
  'order-list-controller',
  'monitoring-controller',
  'barcode-pool-controller',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getShipmentCustomInfo: build.query<
        GetShipmentCustomInfoApiResponse,
        GetShipmentCustomInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/shipmentCustomInfo`,
          params: { dlvId: queryArg.dlvId },
        }),
        providesTags: ['shipment-controller'],
      }),
      editShipmentCustomInfo: build.mutation<
        EditShipmentCustomInfoApiResponse,
        EditShipmentCustomInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/shipmentCustomInfo`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      createShipmentCustomInfo: build.mutation<
        CreateShipmentCustomInfoApiResponse,
        CreateShipmentCustomInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/shipmentCustomInfo`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      postEntity: build.mutation<PostEntityApiResponse, PostEntityApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/user`,
          method: 'POST',
          body: queryArg.userDto,
        }),
        invalidatesTags: ['user-controller'],
      }),
      uploadShipments: build.mutation<
        UploadShipmentsApiResponse,
        UploadShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/upload/shipments`,
          method: 'POST',
          body: queryArg.shipmentUploadRequest,
        }),
        invalidatesTags: ['upload-controller-v-2'],
      }),
      uploadShipmentsByFile: build.mutation<
        UploadShipmentsByFileApiResponse,
        UploadShipmentsByFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/upload/shipmentsByFile`,
          method: 'POST',
          body: queryArg.shipmentUploadByFileRequest,
        }),
        invalidatesTags: ['upload-controller-v-2'],
      }),
      uploadPostamats: build.mutation<
        UploadPostamatsApiResponse,
        UploadPostamatsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/upload/postamats`,
          method: 'POST',
          body: queryArg.postamatUploadRequest,
        }),
        invalidatesTags: ['upload-controller-v-2'],
      }),
      uploadGeographiesByFile: build.mutation<
        UploadGeographiesByFileApiResponse,
        UploadGeographiesByFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/upload/geographies/by-file`,
          method: 'POST',
          body: queryArg.uploadGeographyByFileRequest,
        }),
        invalidatesTags: ['upload-controller-v-2'],
      }),
      updateStatuses: build.mutation<
        UpdateStatusesApiResponse,
        UpdateStatusesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/updateCourierCompanyStatuses`,
          method: 'POST',
          body: queryArg.updateStatusesDto,
        }),
        invalidatesTags: ['courier-company-controller-v-2'],
      }),
      testTariffImport: build.mutation<
        TestTariffImportApiResponse,
        TestTariffImportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/test-tariff-import-process`,
          method: 'POST',
          body: queryArg.createEditDto,
        }),
        invalidatesTags: ['tariff-import-process-controller-v-2'],
      }),
      postEntity1: build.mutation<PostEntity1ApiResponse, PostEntity1ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/tariff`,
          method: 'POST',
          body: queryArg.courierTariffDto,
        }),
        invalidatesTags: ['courier-tariff-controller-v-2'],
      }),
      updateCourierServices: build.mutation<
        UpdateCourierServicesApiResponse,
        UpdateCourierServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tariff/update-courier-services/${queryArg.courier}`,
          method: 'POST',
        }),
        invalidatesTags: ['courier-tariff-controller-v-2'],
      }),
      postEntity2: build.mutation<PostEntity2ApiResponse, PostEntity2ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/tariff-import-process`,
          method: 'POST',
          body: queryArg.createEditDto,
        }),
        invalidatesTags: ['tariff-import-process-controller-v-2'],
      }),
      postSetting: build.mutation<PostSettingApiResponse, PostSettingApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/system-setting`,
          method: 'POST',
          body: queryArg.systemSetting,
        }),
        invalidatesTags: ['system-setting-controller-v-2'],
      }),
      getGeographyTips: build.mutation<
        GetGeographyTipsApiResponse,
        GetGeographyTipsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/suggestion/geographyTips`,
          method: 'POST',
          body: queryArg.geographyTipsRequest,
        }),
        invalidatesTags: ['suggestion-controller'],
      }),
      postEntity3: build.mutation<PostEntity3ApiResponse, PostEntity3ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/shipment`,
          method: 'POST',
          body: queryArg.shipmentRequestDto,
        }),
        invalidatesTags: ['shipment-request-controller-v-2'],
      }),
      editShipment: build.mutation<EditShipmentApiResponse, EditShipmentApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/shipment-edit`,
            method: 'POST',
            body: queryArg.editShipmentDto,
          }),
          invalidatesTags: ['shipment-request-controller-v-2'],
        },
      ),
      postEntity4: build.mutation<PostEntity4ApiResponse, PostEntity4ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/print-template`,
          method: 'POST',
          body: queryArg.createEditDto,
        }),
        invalidatesTags: ['print-template-controller-v-2'],
      }),
      postEntity5: build.mutation<PostEntity5ApiResponse, PostEntity5ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/place`,
          method: 'POST',
          body: queryArg.placeDto,
        }),
        invalidatesTags: ['place-controller-v-2'],
      }),
      postEntity6: build.mutation<PostEntity6ApiResponse, PostEntity6ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/manual-call-api`,
          method: 'POST',
          body: queryArg.manualCallApiDto,
        }),
        invalidatesTags: ['shipment-request-controller-v-2'],
      }),
      importXlsTariff: build.mutation<
        ImportXlsTariffApiResponse,
        ImportXlsTariffApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/import`,
          method: 'POST',
          body: queryArg.importDto,
        }),
        invalidatesTags: ['import-controller-v-2'],
      }),
      importDeliveryDateChangeReason: build.mutation<
        ImportDeliveryDateChangeReasonApiResponse,
        ImportDeliveryDateChangeReasonApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/import/delivery-date-change-reason`,
          method: 'POST',
          body: queryArg.importDeliveryDateChangeReasonsRequest,
        }),
        invalidatesTags: ['import-controller-v-2'],
      }),
      postEntity7: build.mutation<PostEntity7ApiResponse, PostEntity7ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/history`,
          method: 'POST',
          body: queryArg.createEditDto,
        }),
        invalidatesTags: ['shipment-history-controller-v-2'],
      }),
      getHistoriesByDate2: build.query<
        GetHistoriesByDate2ApiResponse,
        GetHistoriesByDate2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/histories_by_date`,
          params: {
            createdFrom: queryArg.createdFrom,
            dlvLogin: queryArg.dlvLogin,
            contractId: queryArg.contractId,
            dlvId: queryArg.dlvId,
            orderNum: queryArg.orderNum,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: ['shipment-history-controller-v-2'],
      }),
      getHistoriesByDate: build.mutation<
        GetHistoriesByDateApiResponse,
        GetHistoriesByDateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/histories_by_date`,
          method: 'POST',
          body: queryArg.shipmentRequestHistoryRequestDto,
        }),
        invalidatesTags: ['shipment-history-controller-v-2'],
      }),
      postEntity8: build.mutation<PostEntity8ApiResponse, PostEntity8ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/geography`,
          method: 'POST',
          body: queryArg.geographyDto,
        }),
        invalidatesTags: ['geography-controller-v-2'],
      }),
      exportTariffs: build.mutation<
        ExportTariffsApiResponse,
        ExportTariffsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/exportTariffs`,
          method: 'POST',
          body: queryArg.exportTariffDto,
        }),
        invalidatesTags: ['contract-controller-v-2'],
      }),
      uploadGeographyFile: build.mutation<
        UploadGeographyFileApiResponse,
        UploadGeographyFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/dropTariffsByContract/${queryArg.contractId}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['drop-tariff-controller'],
      }),
      downloadReport: build.mutation<
        DownloadReportApiResponse,
        DownloadReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/downloadProblemOrdersReport`,
          method: 'POST',
          body: queryArg.downloadProblemOrderRequest,
        }),
        invalidatesTags: ['download-controller'],
      }),
      postEntity9: build.mutation<PostEntity9ApiResponse, PostEntity9ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/courier-product`,
          method: 'POST',
          body: queryArg.courierProductDto,
        }),
        invalidatesTags: ['courier-product-controller-v-2'],
      }),
      postEntity10: build.mutation<PostEntity10ApiResponse, PostEntity10ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/courier-company`,
            method: 'POST',
            body: queryArg.courierCompanyDto,
          }),
          invalidatesTags: ['courier-company-controller-v-2'],
        },
      ),
      postEntity11: build.mutation<PostEntity11ApiResponse, PostEntity11ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/contract`,
            method: 'POST',
            body: queryArg.contractDto,
          }),
          invalidatesTags: ['contract-controller-v-2'],
        },
      ),
      updateStatuses1: build.mutation<
        UpdateStatuses1ApiResponse,
        UpdateStatuses1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/contractUpdateShipmentStatuses`,
          method: 'POST',
          body: queryArg.updateShipmentStatusDto,
        }),
        invalidatesTags: ['contract-controller-v-2'],
      }),
      console: build.mutation<ConsoleApiResponse, ConsoleApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/console`,
          method: 'POST',
          body: queryArg.consoleDto,
        }),
        invalidatesTags: ['import-controller-v-2'],
      }),
      sendYandexRegister: build.mutation<
        SendYandexRegisterApiResponse,
        SendYandexRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/console/yandex-register`,
          method: 'POST',
          body: queryArg.yandexRegisterDto,
        }),
        invalidatesTags: ['import-controller-v-2'],
      }),
      updateCityCodes: build.mutation<
        UpdateCityCodesApiResponse,
        UpdateCityCodesApiArg
      >({
        query: () => ({
          url: `/api/v2/cdek/updateCityCodes`,
          method: 'POST',
        }),
        invalidatesTags: ['cdek-city-code-controller-v-2'],
      }),
      updateCityCodesV2: build.mutation<
        UpdateCityCodesV2ApiResponse,
        UpdateCityCodesV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/cdek/updateCityCodesV2/${queryArg.country}`,
          method: 'POST',
          params: { merge_geographies: queryArg.mergeGeographies },
        }),
        invalidatesTags: ['cdek-city-code-controller-v-2'],
      }),
      postEntity12: build.mutation<PostEntity12ApiResponse, PostEntity12ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/cdek/cityCode`,
            method: 'POST',
            body: queryArg.createEditDto,
          }),
          invalidatesTags: ['cdek-city-code-controller-v-2'],
        },
      ),
      calculateTariff: build.mutation<
        CalculateTariffApiResponse,
        CalculateTariffApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/calculateContractTariff`,
          method: 'POST',
          body: queryArg.calculateTariffDto,
        }),
        invalidatesTags: ['contract-controller-v-2'],
      }),
      postEntity13: build.mutation<PostEntity13ApiResponse, PostEntity13ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/account`,
            method: 'POST',
            body: queryArg.accountDto,
          }),
          invalidatesTags: ['account-controller-v-2'],
        },
      ),
      sendRegisterOrders: build.mutation<
        SendRegisterOrdersApiResponse,
        SendRegisterOrdersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/sendRegisterOrders`,
          method: 'POST',
          body: queryArg.registerOrdersRequest,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      uploadExcelFile: build.mutation<
        UploadExcelFileApiResponse,
        UploadExcelFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/returns/uploadReturns`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['returns-controller'],
      }),
      getStatReport: build.mutation<
        GetStatReportApiResponse,
        GetStatReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/statistic-courier-report`,
          method: 'POST',
          body: queryArg.statReport,
        }),
        invalidatesTags: ['excel-report-controller'],
      }),
      getEstimatedDeliveryTimeReport: build.mutation<
        GetEstimatedDeliveryTimeReportApiResponse,
        GetEstimatedDeliveryTimeReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/estimated-delivery-time-report`,
          method: 'POST',
          body: queryArg.estimatedDeliveryTimeReportDto,
        }),
        invalidatesTags: ['excel-report-controller'],
      }),
      savePostalTariff: build.mutation<
        SavePostalTariffApiResponse,
        SavePostalTariffApiArg
      >({
        query: (queryArg) => ({
          url: `/api/postal/savePostalTariff`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['postal-controller'],
      }),
      importRestriction: build.mutation<
        ImportRestrictionApiResponse,
        ImportRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/postal/importRestriction`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['postal-controller'],
      }),
      importXlsTariff1: build.mutation<
        ImportXlsTariff1ApiResponse,
        ImportXlsTariff1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/postal/import-fias-zones`,
          method: 'POST',
          body: queryArg.importZoneFiasDto,
        }),
        invalidatesTags: ['postal-zone-fias-controller'],
      }),
      getRestrictions: build.mutation<
        GetRestrictionsApiResponse,
        GetRestrictionsApiArg
      >({
        query: () => ({
          url: `/api/postal/getRestrictions`,
          method: 'POST',
        }),
        invalidatesTags: ['postal-controller'],
      }),
      getPostalTariffs: build.mutation<
        GetPostalTariffsApiResponse,
        GetPostalTariffsApiArg
      >({
        query: () => ({
          url: `/api/postal/getPostalTariffs`,
          method: 'POST',
        }),
        invalidatesTags: ['postal-controller'],
      }),
      postEntity14: build.mutation<PostEntity14ApiResponse, PostEntity14ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/postal/fias-zone`,
            method: 'POST',
            body: queryArg.postalZoneFiasDto,
          }),
          invalidatesTags: ['postal-zone-fias-controller'],
        },
      ),
      postRegister: build.mutation<PostRegisterApiResponse, PostRegisterApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/postRegister`,
            method: 'POST',
            body: queryArg.body,
          }),
          invalidatesTags: ['shipment-controller'],
        },
      ),
      updateStatus: build.mutation<UpdateStatusApiResponse, UpdateStatusApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/podrygka-cdek/updateStatus`,
            method: 'POST',
            body: queryArg.updateStatusRequest,
          }),
          invalidatesTags: ['podrygka-cdek-update-status-controller'],
        },
      ),
      getPlanReturns: build.mutation<
        GetPlanReturnsApiResponse,
        GetPlanReturnsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/pickpoint/base-tariffs`,
          method: 'POST',
          body: queryArg.pickPointTariffRequest,
        }),
        invalidatesTags: ['pick-point-controller'],
      }),
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/api/login`,
          method: 'POST',
          body: queryArg.loginRequestDto,
        }),
        invalidatesTags: ['user-controller'],
      }),
      getPacketShipment: build.mutation<
        GetPacketShipmentApiResponse,
        GetPacketShipmentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lite/packedShipments`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      liteGetRegisterPrintForm: build.mutation<
        LiteGetRegisterPrintFormApiResponse,
        LiteGetRegisterPrintFormApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lite/getRegisterPrintForm`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      liteCreateRegister: build.mutation<
        LiteCreateRegisterApiResponse,
        LiteCreateRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lite/createRegister`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      liteAcceptPlace: build.mutation<
        LiteAcceptPlaceApiResponse,
        LiteAcceptPlaceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lite/acceptPlace`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getTariffs: build.mutation<GetTariffsApiResponse, GetTariffsApiArg>({
        query: (queryArg) => ({
          url: `/api/getTariffs`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getShipmentInfo: build.mutation<
        GetShipmentInfoApiResponse,
        GetShipmentInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getShipmentInfo`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getStickers: build.mutation<GetStickersApiResponse, GetStickersApiArg>({
        query: (queryArg) => ({
          url: `/api/getPrintForm`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      findPostamatByFiasCode: build.mutation<
        FindPostamatByFiasCodeApiResponse,
        FindPostamatByFiasCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getPostamatsByFiasCode`,
          method: 'POST',
          body: queryArg.findPostamatsByFiasCodeRequest,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
          },
        }),
        invalidatesTags: ['postamat-controller'],
      }),
      getPostamat: build.mutation<GetPostamatApiResponse, GetPostamatApiArg>({
        query: (queryArg) => ({
          url: `/api/getPostamat`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getPostamatList: build.mutation<
        GetPostamatListApiResponse,
        GetPostamatListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getPostamatList`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getPostalTariffs1: build.mutation<
        GetPostalTariffs1ApiResponse,
        GetPostalTariffs1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getPostalTariffs`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getPlaceList: build.mutation<GetPlaceListApiResponse, GetPlaceListApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/getPlaceList`,
            method: 'POST',
            body: queryArg.body,
          }),
          invalidatesTags: ['shipment-controller'],
        },
      ),
      getNextCourierWindow: build.mutation<
        GetNextCourierWindowApiResponse,
        GetNextCourierWindowApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getNextCourierWindow`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getMeasurements: build.mutation<
        GetMeasurementsApiResponse,
        GetMeasurementsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getMeasurements`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getHistory: build.mutation<GetHistoryApiResponse, GetHistoryApiArg>({
        query: (queryArg) => ({
          url: `/api/getHistory`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getGatherRequestHistory: build.mutation<
        GetGatherRequestHistoryApiResponse,
        GetGatherRequestHistoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getGatherRequestHistory`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getDeliveredItems: build.mutation<
        GetDeliveredItemsApiResponse,
        GetDeliveredItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getDeliveredItems`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getCourierServices: build.mutation<
        GetCourierServicesApiResponse,
        GetCourierServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getCourierServices`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getChangedShipmentHistory: build.mutation<
        GetChangedShipmentHistoryApiResponse,
        GetChangedShipmentHistoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/getChangedShipmentHistory`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      getActive: build.mutation<GetActiveApiResponse, GetActiveApiArg>({
        query: (queryArg) => ({
          url: `/api/getActive`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      fakeCreatePlace: build.mutation<
        FakeCreatePlaceApiResponse,
        FakeCreatePlaceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/fakeCreatePlace`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      fakeCommitShipmentRequestList: build.mutation<
        FakeCommitShipmentRequestListApiResponse,
        FakeCommitShipmentRequestListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/fakeCommitShipmentRequestList`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      editShipmentRequest: build.mutation<
        EditShipmentRequestApiResponse,
        EditShipmentRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/editShipmentRequest`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      editPlace: build.mutation<EditPlaceApiResponse, EditPlaceApiArg>({
        query: (queryArg) => ({
          url: `/api/editPlace`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      editPallet: build.mutation<EditPalletApiResponse, EditPalletApiArg>({
        query: (queryArg) => ({
          url: `/api/editPallet`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      editGatherRequest: build.mutation<
        EditGatherRequestApiResponse,
        EditGatherRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/editGatherRequest`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      deleteShipmentRequest: build.mutation<
        DeleteShipmentRequestApiResponse,
        DeleteShipmentRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/deleteShipmentRequest`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      deletePlace: build.mutation<DeletePlaceApiResponse, DeletePlaceApiArg>({
        query: (queryArg) => ({
          url: `/api/deletePlace`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      createTariff: build.mutation<CreateTariffApiResponse, CreateTariffApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/createTariff`,
            method: 'POST',
            body: queryArg.body,
          }),
          invalidatesTags: ['shipment-controller'],
        },
      ),
      createShipmentRequest: build.mutation<
        CreateShipmentRequestApiResponse,
        CreateShipmentRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/createShipmentRequest`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      createPlace: build.mutation<CreatePlaceApiResponse, CreatePlaceApiArg>({
        query: (queryArg) => ({
          url: `/api/createPlace`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      createPallet: build.mutation<CreatePalletApiResponse, CreatePalletApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/createPallet`,
            method: 'POST',
            body: queryArg.body,
          }),
          invalidatesTags: ['shipment-controller'],
        },
      ),
      createGatherRequest: build.mutation<
        CreateGatherRequestApiResponse,
        CreateGatherRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/createGatherRequest`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      create: build.mutation<CreateApiResponse, CreateApiArg>({
        query: (queryArg) => ({
          url: `/api/courierSettings`,
          method: 'POST',
          body: queryArg.courierSettingsDto,
        }),
        invalidatesTags: ['courier-settings-controller'],
      }),
      deleteApiCourierSettings: build.mutation<
        DeleteApiCourierSettingsApiResponse,
        DeleteApiCourierSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/courierSettings`,
          method: 'DELETE',
          params: { courierCode: queryArg.courierCode },
        }),
        invalidatesTags: ['courier-settings-controller'],
      }),
      getCourierCalendar: build.query<
        GetCourierCalendarApiResponse,
        GetCourierCalendarApiArg
      >({
        query: (queryArg) => ({
          url: `/api/courierCalendar`,
          params: {
            courierCode: queryArg.courierCode,
            calendarType: queryArg.calendarType,
          },
        }),
        providesTags: ['shipment-controller'],
      }),
      uploadCourierCalendar: build.mutation<
        UploadCourierCalendarApiResponse,
        UploadCourierCalendarApiArg
      >({
        query: (queryArg) => ({
          url: `/api/courierCalendar`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      removeContract: build.mutation<
        RemoveContractApiResponse,
        RemoveContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/remove`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['contract-controller'],
      }),
      getProducts: build.mutation<GetProductsApiResponse, GetProductsApiArg>({
        query: (queryArg) => ({
          url: `/api/contract/getProducts`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['contract-controller'],
      }),
      getContracts: build.mutation<GetContractsApiResponse, GetContractsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/contract/getList`,
            method: 'POST',
            body: queryArg.body,
          }),
          invalidatesTags: ['contract-controller'],
        },
      ),
      getBarcodeCount: build.mutation<
        GetBarcodeCountApiResponse,
        GetBarcodeCountApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/getFreeBarcodeCount`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['contract-controller'],
      }),
      getCourierServiceList: build.mutation<
        GetCourierServiceListApiResponse,
        GetCourierServiceListApiArg
      >({
        query: () => ({
          url: `/api/contract/getCourierServiceList`,
          method: 'POST',
        }),
        invalidatesTags: ['contract-controller'],
      }),
      getAdditionalParams: build.mutation<
        GetAdditionalParamsApiResponse,
        GetAdditionalParamsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/getAdditionalParams`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['contract-controller'],
      }),
      generateBarcode: build.mutation<
        GenerateBarcodeApiResponse,
        GenerateBarcodeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/generateBarcode`,
          method: 'POST',
          params: { request: queryArg.request },
        }),
        invalidatesTags: ['contract-controller'],
      }),
      editContract: build.mutation<EditContractApiResponse, EditContractApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/contract/edit`,
            method: 'POST',
            body: queryArg.body,
          }),
          invalidatesTags: ['contract-controller'],
        },
      ),
      createContract: build.mutation<
        CreateContractApiResponse,
        CreateContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/create`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['contract-controller'],
      }),
      act: build.mutation<ActApiResponse, ActApiArg>({
        query: (queryArg) => ({
          url: `/api/contract/act`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['contract-controller'],
      }),
      commitShipmentRequestList: build.mutation<
        CommitShipmentRequestListApiResponse,
        CommitShipmentRequestListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/commitShipmentRequestList`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      checkShipmentRequest: build.mutation<
        CheckShipmentRequestApiResponse,
        CheckShipmentRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/checkShipment`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      checkPickUpDate: build.mutation<
        CheckPickUpDateApiResponse,
        CheckPickUpDateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/checkPickUpDate`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      changeDimensions: build.mutation<
        ChangeDimensionsApiResponse,
        ChangeDimensionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/changeDimensions`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      cancelShipmentRequest: build.mutation<
        CancelShipmentRequestApiResponse,
        CancelShipmentRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/cancelShipmentRequest`,
          method: 'POST',
          body: queryArg.cancelShipmentRequest,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      cancelGatherRequest: build.mutation<
        CancelGatherRequestApiResponse,
        CancelGatherRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/cancelGatherRequest`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['shipment-controller'],
      }),
      cancelCommit: build.mutation<CancelCommitApiResponse, CancelCommitApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/cancelCommit`,
            method: 'POST',
            body: queryArg.cancelCommitDto,
          }),
          invalidatesTags: ['shipment-controller'],
        },
      ),
      updateStatuses2: build.mutation<
        UpdateStatuses2ApiResponse,
        UpdateStatuses2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/admin/statuses/update`,
          method: 'POST',
          body: queryArg.statusesUpdateRequest,
        }),
        invalidatesTags: ['statuses-controller'],
      }),
      validateAddress: build.mutation<
        ValidateAddressApiResponse,
        ValidateAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/api/address-validation`,
          method: 'POST',
          body: queryArg.addressValidationRequest,
        }),
        invalidatesTags: ['address-validation-controller'],
      }),
      getAccounts: build.mutation<GetAccountsApiResponse, GetAccountsApiArg>({
        query: () => ({
          url: `/api/account/getAccounts`,
          method: 'POST',
        }),
        invalidatesTags: ['account-controller'],
      }),
      deleteAccount: build.mutation<
        DeleteAccountApiResponse,
        DeleteAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/api/account/deleteAccount`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['account-controller'],
      }),
      createAccount: build.mutation<
        CreateAccountApiResponse,
        CreateAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/api/account/createAccount`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['account-controller'],
      }),
      getPlanReturns1: build.query<
        GetPlanReturns1ApiResponse,
        GetPlanReturns1ApiArg
      >({
        query: () => ({ url: `/healthcheck` }),
        providesTags: ['health-check-controller'],
      }),
      getRawHistoriesByDate: build.query<
        GetRawHistoriesByDateApiResponse,
        GetRawHistoriesByDateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v3/raw_histories`,
          params: {
            createdFrom: queryArg.createdFrom,
            contractId: queryArg.contractId,
            dlvId: queryArg.dlvId,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: ['shipment-history-controller-v-3'],
      }),
      getHistoriesByDate1: build.query<
        GetHistoriesByDate1ApiResponse,
        GetHistoriesByDate1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v3/histories`,
          params: {
            createdFrom: queryArg.createdFrom,
            contractId: queryArg.contractId,
            dlvId: queryArg.dlvId,
            orderNum: queryArg.orderNum,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: ['shipment-history-controller-v-3'],
      }),
      getEntities: build.query<GetEntitiesApiResponse, GetEntitiesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/users`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['user-controller'],
      }),
      getEntity: build.query<GetEntityApiResponse, GetEntityApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.id}` }),
        providesTags: ['user-controller'],
      }),
      deleteEntity: build.mutation<DeleteEntityApiResponse, DeleteEntityApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/user/${queryArg.id}`,
            method: 'DELETE',
          }),
          invalidatesTags: ['user-controller'],
        },
      ),
      uploadTariffs: build.query<UploadTariffsApiResponse, UploadTariffsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/upload/tariffs`,
            params: { contractId: queryArg.contractId },
          }),
          providesTags: ['upload-controller-v-2'],
        },
      ),
      uploadGeographies: build.query<
        UploadGeographiesApiResponse,
        UploadGeographiesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/upload/geographies`,
          params: {
            region: queryArg.region,
            city: queryArg.city,
            area: queryArg.area,
            settlement: queryArg.settlement,
            cityFiasCode: queryArg.cityFiasCode,
            settlementFiasCode: queryArg.settlementFiasCode,
            kladrCode: queryArg.kladrCode,
            courierCityCode: queryArg.courierCityCode,
          },
        }),
        providesTags: ['upload-controller-v-2'],
      }),
      getEntities1: build.query<GetEntities1ApiResponse, GetEntities1ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/tariffs`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['courier-tariff-controller-v-2'],
      }),
      getEntitiesByParentId: build.query<
        GetEntitiesByParentIdApiResponse,
        GetEntitiesByParentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tariffs/${queryArg.contractId}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['courier-tariff-controller-v-2'],
      }),
      getEntity1: build.query<GetEntity1ApiResponse, GetEntity1ApiArg>({
        query: (queryArg) => ({ url: `/api/v2/tariff/${queryArg.id}` }),
        providesTags: ['courier-tariff-controller-v-2'],
      }),
      deleteEntity1: build.mutation<
        DeleteEntity1ApiResponse,
        DeleteEntity1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tariff/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['courier-tariff-controller-v-2'],
      }),
      getEntities2: build.query<GetEntities2ApiResponse, GetEntities2ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/tariff-import-processes`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['tariff-import-process-controller-v-2'],
      }),
      getEntitiesByParentId1: build.query<
        GetEntitiesByParentId1ApiResponse,
        GetEntitiesByParentId1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tariff-import-processes/${queryArg.contractId}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['tariff-import-process-controller-v-2'],
      }),
      getEntity2: build.query<GetEntity2ApiResponse, GetEntity2ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/tariff-import-process/${queryArg.id}`,
        }),
        providesTags: ['tariff-import-process-controller-v-2'],
      }),
      deleteEntity2: build.mutation<
        DeleteEntity2ApiResponse,
        DeleteEntity2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tariff-import-process/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['tariff-import-process-controller-v-2'],
      }),
      getAllSettings: build.query<
        GetAllSettingsApiResponse,
        GetAllSettingsApiArg
      >({
        query: () => ({ url: `/api/v2/system-settings` }),
        providesTags: ['system-setting-controller-v-2'],
      }),
      getGeographySuggestion: build.query<
        GetGeographySuggestionApiResponse,
        GetGeographySuggestionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/suggestion/geography`,
          params: { query: queryArg.query },
        }),
        providesTags: ['suggestion-controller'],
      }),
      getString: build.query<GetStringApiResponse, GetStringApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/statusesByDate`,
          params: {
            dateFrom: queryArg.dateFrom,
            dlvLogin: queryArg.dlvLogin,
          },
        }),
        providesTags: ['shipment-history-controller-v-2'],
      }),
      getEntities3: build.query<GetEntities3ApiResponse, GetEntities3ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/shipments`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['shipment-request-controller-v-2'],
      }),
      getEntity3: build.query<GetEntity3ApiResponse, GetEntity3ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/shipment/${queryArg.id}`,
        }),
        providesTags: ['shipment-request-controller-v-2'],
      }),
      deleteEntity3: build.mutation<
        DeleteEntity3ApiResponse,
        DeleteEntity3ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/shipment/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['shipment-request-controller-v-2'],
      }),
      saveDeletedTariffsLogs: build.query<
        SaveDeletedTariffsLogsApiResponse,
        SaveDeletedTariffsLogsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/saveDeletedTariffsLogs/${queryArg.logId}`,
        }),
        providesTags: ['drop-tariff-controller'],
      }),
      getRawHistoriesByDate1: build.query<
        GetRawHistoriesByDate1ApiResponse,
        GetRawHistoriesByDate1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/raw_histories_by_date`,
          params: {
            createdFrom: queryArg.createdFrom,
            dlvLogin: queryArg.dlvLogin,
            contractId: queryArg.contractId,
            dlvId: queryArg.dlvId,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: ['shipment-history-controller-v-2'],
      }),
      getEntities4: build.query<GetEntities4ApiResponse, GetEntities4ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/print-templates`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['print-template-controller-v-2'],
      }),
      getEntity4: build.query<GetEntity4ApiResponse, GetEntity4ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/print-template/${queryArg.id}`,
        }),
        providesTags: ['print-template-controller-v-2'],
      }),
      deleteEntity4: build.mutation<
        DeleteEntity4ApiResponse,
        DeleteEntity4ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/print-template/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['print-template-controller-v-2'],
      }),
      getEntitiesByParentId2: build.query<
        GetEntitiesByParentId2ApiResponse,
        GetEntitiesByParentId2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/places/${queryArg.srId}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['place-controller-v-2'],
      }),
      getEntity5: build.query<GetEntity5ApiResponse, GetEntity5ApiArg>({
        query: (queryArg) => ({ url: `/api/v2/place/${queryArg.id}` }),
        providesTags: ['place-controller-v-2'],
      }),
      deleteEntity5: build.mutation<
        DeleteEntity5ApiResponse,
        DeleteEntity5ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/place/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['place-controller-v-2'],
      }),
      getEntity6: build.query<GetEntity6ApiResponse, GetEntity6ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/history/${queryArg.id}`,
        }),
        providesTags: ['shipment-history-controller-v-2'],
      }),
      deleteEntity6: build.mutation<
        DeleteEntity6ApiResponse,
        DeleteEntity6ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/history/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['shipment-history-controller-v-2'],
      }),
      getEntities5: build.query<GetEntities5ApiResponse, GetEntities5ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/histories`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            direction: queryArg.direction,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['shipment-history-controller-v-2'],
      }),
      getEntitiesByParentId3: build.query<
        GetEntitiesByParentId3ApiResponse,
        GetEntitiesByParentId3ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/histories/${queryArg.srId}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['shipment-history-controller-v-2'],
      }),
      getEntity7: build.query<GetEntity7ApiResponse, GetEntity7ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/geography/${queryArg.id}`,
        }),
        providesTags: ['geography-controller-v-2'],
      }),
      deleteEntity7: build.mutation<
        DeleteEntity7ApiResponse,
        DeleteEntity7ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/geography/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['geography-controller-v-2'],
      }),
      getEntities6: build.query<GetEntities6ApiResponse, GetEntities6ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/geographies`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['geography-controller-v-2'],
      }),
      getFailedTariffImportProcess: build.query<
        GetFailedTariffImportProcessApiResponse,
        GetFailedTariffImportProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/failedTariffImportProcess/${queryArg.id}`,
        }),
        providesTags: ['tariff-import-process-controller-v-2'],
      }),
      dropTariffsLogs: build.query<
        DropTariffsLogsApiResponse,
        DropTariffsLogsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/dropTariffsLogs/${queryArg.contractId}`,
        }),
        providesTags: ['drop-tariff-controller'],
      }),
      getEntities7: build.query<GetEntities7ApiResponse, GetEntities7ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/courier-products`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['courier-product-controller-v-2'],
      }),
      getEntitiesByParentId4: build.query<
        GetEntitiesByParentId4ApiResponse,
        GetEntitiesByParentId4ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/courier-products/${queryArg.courierCompanyId}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['courier-product-controller-v-2'],
      }),
      getEntity8: build.query<GetEntity8ApiResponse, GetEntity8ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/courier-product/${queryArg.id}`,
        }),
        providesTags: ['courier-product-controller-v-2'],
      }),
      deleteEntity8: build.mutation<
        DeleteEntity8ApiResponse,
        DeleteEntity8ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/courier-product/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['courier-product-controller-v-2'],
      }),
      getEntity9: build.query<GetEntity9ApiResponse, GetEntity9ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/courier-company/${queryArg.id}`,
        }),
        providesTags: ['courier-company-controller-v-2'],
      }),
      deleteEntity9: build.mutation<
        DeleteEntity9ApiResponse,
        DeleteEntity9ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/courier-company/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['courier-company-controller-v-2'],
      }),
      getEntities8: build.query<GetEntities8ApiResponse, GetEntities8ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/courier-companies`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['courier-company-controller-v-2'],
      }),
      getEntities9: build.query<GetEntities9ApiResponse, GetEntities9ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/contracts`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['contract-controller-v-2'],
      }),
      getEntitiesByParentId5: build.query<
        GetEntitiesByParentId5ApiResponse,
        GetEntitiesByParentId5ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/contracts/${queryArg.accountId}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['contract-controller-v-2'],
      }),
      getEntity10: build.query<GetEntity10ApiResponse, GetEntity10ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/contract/${queryArg.id}`,
        }),
        providesTags: ['contract-controller-v-2'],
      }),
      deleteEntity10: build.mutation<
        DeleteEntity10ApiResponse,
        DeleteEntity10ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/contract/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['contract-controller-v-2'],
      }),
      getEntities10: build.query<GetEntities10ApiResponse, GetEntities10ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/cdek/cityCodes`,
            params: {
              page: queryArg.page,
              size: queryArg.size,
              sort: queryArg.sort,
              filter: queryArg.filter,
            },
          }),
          providesTags: ['cdek-city-code-controller-v-2'],
        },
      ),
      getEntity11: build.query<GetEntity11ApiResponse, GetEntity11ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/cdek/cityCode/${queryArg.id}`,
        }),
        providesTags: ['cdek-city-code-controller-v-2'],
      }),
      deleteEntity11: build.mutation<
        DeleteEntity11ApiResponse,
        DeleteEntity11ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/cdek/cityCode/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['cdek-city-code-controller-v-2'],
      }),
      getEntities11: build.query<GetEntities11ApiResponse, GetEntities11ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/accounts`,
            params: {
              page: queryArg.page,
              size: queryArg.size,
              sort: queryArg.sort,
              filter: queryArg.filter,
            },
          }),
          providesTags: ['account-controller-v-2'],
        },
      ),
      getEntity12: build.query<GetEntity12ApiResponse, GetEntity12ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/account/${queryArg.id}`,
        }),
        providesTags: ['account-controller-v-2'],
      }),
      deleteEntity12: build.mutation<
        DeleteEntity12ApiResponse,
        DeleteEntity12ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/account/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['account-controller-v-2'],
      }),
      getPlanReturns2: build.query<
        GetPlanReturns2ApiResponse,
        GetPlanReturns2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/returns/planReturns`,
          params: {
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            docNumber: queryArg.docNumber,
            warehouse: queryArg.warehouse,
          },
        }),
        providesTags: ['returns-controller'],
      }),
      exportStatusExcel: build.query<
        ExportStatusExcelApiResponse,
        ExportStatusExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/status-report`,
          params: {
            dlvLogin: queryArg.dlvLogin,
            courierCode: queryArg.courierCode,
            createDate: queryArg.createDate,
          },
        }),
        providesTags: ['excel-report-controller'],
      }),
      exportStatusCountExcel: build.query<
        ExportStatusCountExcelApiResponse,
        ExportStatusCountExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/status-count-report`,
          params: {
            courierCode: queryArg.courierCode,
            dlvLogin: queryArg.dlvLogin,
            createDate: queryArg.createDate,
          },
        }),
        providesTags: ['excel-report-controller'],
      }),
      exportStatisticCountExcel: build.query<
        ExportStatisticCountExcelApiResponse,
        ExportStatisticCountExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/statistic-region-count-report`,
          params: {
            courierCode: queryArg.courierCode,
            dlvLogin: queryArg.dlvLogin,
            region: queryArg.region,
            createDateFrom: queryArg.createDateFrom,
            createDateTo: queryArg.createDateTo,
          },
        }),
        providesTags: ['excel-report-controller'],
      }),
      exportStatisticCountExcel1: build.query<
        ExportStatisticCountExcel1ApiResponse,
        ExportStatisticCountExcel1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/statistic-date-count-report`,
          params: {
            courierCode: queryArg.courierCode,
            dlvLogin: queryArg.dlvLogin,
            groupType: queryArg.groupType,
            createDateFrom: queryArg.createDateFrom,
            createDateTo: queryArg.createDateTo,
          },
        }),
        providesTags: ['excel-report-controller'],
      }),
      exportPostalBaseTariffExcel: build.query<
        ExportPostalBaseTariffExcelApiResponse,
        ExportPostalBaseTariffExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/postal-base-tariff-report`,
          params: {
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
          },
        }),
        providesTags: ['excel-report-controller'],
      }),
      exportOverdueExcel: build.query<
        ExportOverdueExcelApiResponse,
        ExportOverdueExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/excel/overdue-report`,
          params: {
            dlvLogin: queryArg.dlvLogin,
            courierCode: queryArg.courierCode,
            overDays: queryArg.overDays,
            createDate: queryArg.createDate,
          },
        }),
        providesTags: ['excel-report-controller'],
      }),
      getEntities12: build.query<GetEntities12ApiResponse, GetEntities12ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/postamats`,
            params: {
              page: queryArg.page,
              size: queryArg.size,
              sort: queryArg.sort,
              filter: queryArg.filter,
            },
          }),
          providesTags: ['postamat-controller'],
        },
      ),
      updatePostamats: build.query<
        UpdatePostamatsApiResponse,
        UpdatePostamatsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/postamats-update`,
          params: {
            courierCode: queryArg.courierCode,
            strictGeography: queryArg.strictGeography,
          },
        }),
        providesTags: ['postamat-controller'],
      }),
      getEntities13: build.query<GetEntities13ApiResponse, GetEntities13ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/postal/fias-zones`,
            params: {
              page: queryArg.page,
              size: queryArg.size,
              sort: queryArg.sort,
              filter: queryArg.filter,
            },
          }),
          providesTags: ['postal-zone-fias-controller'],
        },
      ),
      getEntity13: build.query<GetEntity13ApiResponse, GetEntity13ApiArg>({
        query: (queryArg) => ({
          url: `/api/postal/fias-zone/${queryArg.id}`,
        }),
        providesTags: ['postal-zone-fias-controller'],
      }),
      deleteEntity13: build.mutation<
        DeleteEntity13ApiResponse,
        DeleteEntity13ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/postal/fias-zone/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['postal-zone-fias-controller'],
      }),
      orderList: build.query<OrderListApiResponse, OrderListApiArg>({
        query: (queryArg) => ({
          url: `/api/order-list`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            client: queryArg.client,
            courierCode: queryArg.courierCode,
            pageable: queryArg.pageable,
          },
        }),
        providesTags: ['order-list-controller'],
      }),
      getOrdersInfo: build.query<GetOrdersInfoApiResponse, GetOrdersInfoApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/monitoring/orders-info`,
            params: {
              dateFrom: queryArg.dateFrom,
              dateTo: queryArg.dateTo,
              statusDuration: queryArg.statusDuration,
              warehouse: queryArg.warehouse,
              courierStatuses: queryArg.courierStatuses,
              dlvStatuses: queryArg.dlvStatuses,
              filterType: queryArg.filterType,
              pageable: queryArg.pageable,
            },
          }),
          providesTags: ['monitoring-controller'],
        },
      ),
      getOrdersInfo1: build.query<
        GetOrdersInfo1ApiResponse,
        GetOrdersInfo1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/monitoring/order-info`,
          params: { num: queryArg.num, dlvId: queryArg.dlvId },
        }),
        providesTags: ['monitoring-controller'],
      }),
      getExchange: build.query<GetExchangeApiResponse, GetExchangeApiArg>({
        query: (queryArg) => ({
          url: `/api/monitoring/exchange`,
          params: { id: queryArg.id, type: queryArg['type'] },
        }),
        providesTags: ['monitoring-controller'],
      }),
      getPlanReturns3: build.query<
        GetPlanReturns3ApiResponse,
        GetPlanReturns3ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/monitoring/delivered-statuses`,
          params: {
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
          },
        }),
        providesTags: ['monitoring-controller'],
      }),
      literegisteredShipments: build.query<
        LiteregisteredShipmentsApiResponse,
        LiteregisteredShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lite/registeredShipments`,
          params: { fromDate: queryArg.fromDate },
        }),
        providesTags: ['shipment-controller'],
      }),
      findByFias: build.query<FindByFiasApiResponse, FindByFiasApiArg>({
        query: (queryArg) => ({
          url: `/api/contract/find-by-fias`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            fiasCode: queryArg.fiasCode,
          },
        }),
        providesTags: ['contract-controller'],
      }),
      getBarcodePools: build.query<
        GetBarcodePoolsApiResponse,
        GetBarcodePoolsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/barcode-pools`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['barcode-pool-controller'],
      }),
      getBarcodePoolContracts: build.query<
        GetBarcodePoolContractsApiResponse,
        GetBarcodePoolContractsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/barcode-pool-contracts/${queryArg.id}`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['barcode-pool-controller'],
      }),
      tariffRemoveProcess: build.mutation<
        TariffRemoveProcessApiResponse,
        TariffRemoveProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/dropTariffsDeleteLog/${queryArg.logId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['drop-tariff-controller'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as qubyApiDlv }
export type GetShipmentCustomInfoApiResponse =
  /** status 200 Успешный ответ с информацией о местах */ GetShipmentCustomInfoResult
export type GetShipmentCustomInfoApiArg = {
  dlvId: string
}
export type EditShipmentCustomInfoApiResponse = /** status 200 OK */ string
export type EditShipmentCustomInfoApiArg = {
  body: string
}
export type CreateShipmentCustomInfoApiResponse = /** status 200 OK */ string
export type CreateShipmentCustomInfoApiArg = {
  body: string
}
export type PostEntityApiResponse = /** status 200 OK */ Result
export type PostEntityApiArg = {
  userDto: UserDto
}
export type UploadShipmentsApiResponse = /** status 200 OK */ Result
export type UploadShipmentsApiArg = {
  shipmentUploadRequest: ShipmentUploadRequest
}
export type UploadShipmentsByFileApiResponse = /** status 200 OK */ Result
export type UploadShipmentsByFileApiArg = {
  shipmentUploadByFileRequest: ShipmentUploadByFileRequest
}
export type UploadPostamatsApiResponse = /** status 200 OK */ Result
export type UploadPostamatsApiArg = {
  postamatUploadRequest: PostamatUploadRequest
}
export type UploadGeographiesByFileApiResponse = /** status 200 OK */ object
export type UploadGeographiesByFileApiArg = {
  uploadGeographyByFileRequest: UploadGeographyByFileRequest
}
export type UpdateStatusesApiResponse = /** status 200 OK */ Result
export type UpdateStatusesApiArg = {
  updateStatusesDto: UpdateStatusesDto
}
export type TestTariffImportApiResponse = /** status 200 OK */ Result
export type TestTariffImportApiArg = {
  createEditDto: CreateEditDto
}
export type PostEntity1ApiResponse = /** status 200 OK */ Result
export type PostEntity1ApiArg = {
  courierTariffDto: CourierTariffDto
}
export type UpdateCourierServicesApiResponse = /** status 200 OK */ Result
export type UpdateCourierServicesApiArg = {
  courier:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
}
export type PostEntity2ApiResponse = /** status 200 OK */ Result
export type PostEntity2ApiArg = {
  createEditDto: CreateEditDto
}
export type PostSettingApiResponse = /** status 200 OK */ Result
export type PostSettingApiArg = {
  systemSetting: SystemSetting
}
export type GetGeographyTipsApiResponse = /** status 200 OK */ Result
export type GetGeographyTipsApiArg = {
  geographyTipsRequest: GeographyTipsRequest
}
export type PostEntity3ApiResponse = /** status 200 OK */ Result
export type PostEntity3ApiArg = {
  shipmentRequestDto: ShipmentRequestDto
}
export type EditShipmentApiResponse = /** status 200 OK */ Result
export type EditShipmentApiArg = {
  editShipmentDto: EditShipmentDto
}
export type PostEntity4ApiResponse = /** status 200 OK */ Result
export type PostEntity4ApiArg = {
  createEditDto: CreateEditDto
}
export type PostEntity5ApiResponse = /** status 200 OK */ Result
export type PostEntity5ApiArg = {
  placeDto: PlaceDto
}
export type PostEntity6ApiResponse = /** status 200 OK */ Result
export type PostEntity6ApiArg = {
  manualCallApiDto: ManualCallApiDto
}
export type ImportXlsTariffApiResponse = /** status 200 OK */ Result
export type ImportXlsTariffApiArg = {
  importDto: ImportDto
}
export type ImportDeliveryDateChangeReasonApiResponse =
  /** status 200 OK */ Result
export type ImportDeliveryDateChangeReasonApiArg = {
  importDeliveryDateChangeReasonsRequest: ImportDeliveryDateChangeReasonsRequest
}
export type PostEntity7ApiResponse = /** status 200 OK */ Result
export type PostEntity7ApiArg = {
  createEditDto: CreateEditDto
}
export type GetHistoriesByDate2ApiResponse =
  /** status 200 OK */ PageResultShipmentRequestHistoryDto
export type GetHistoriesByDate2ApiArg = {
  createdFrom: string
  dlvLogin?: string
  contractId?: string
  dlvId?: string
  orderNum?: string
  page?: number
  size?: number
}
export type GetHistoriesByDateApiResponse =
  /** status 200 OK */ PageResultShipmentRequestHistoryDto
export type GetHistoriesByDateApiArg = {
  shipmentRequestHistoryRequestDto: ShipmentRequestHistoryRequestDto
}
export type PostEntity8ApiResponse = /** status 200 OK */ Result
export type PostEntity8ApiArg = {
  geographyDto: GeographyDto
}
export type ExportTariffsApiResponse =
  /** status 200 OK */ StreamingResponseBody
export type ExportTariffsApiArg = {
  exportTariffDto: ExportTariffDtoWrite
}
export type UploadGeographyFileApiResponse = /** status 200 OK */ Result
export type UploadGeographyFileApiArg = {
  contractId: string
  body: {
    file: Blob
  }
}
export type DownloadReportApiResponse = unknown
export type DownloadReportApiArg = {
  downloadProblemOrderRequest: DownloadProblemOrderRequest
}
export type PostEntity9ApiResponse = /** status 200 OK */ Result
export type PostEntity9ApiArg = {
  courierProductDto: CourierProductDto
}
export type PostEntity10ApiResponse = /** status 200 OK */ Result
export type PostEntity10ApiArg = {
  courierCompanyDto: CourierCompanyDto
}
export type PostEntity11ApiResponse = /** status 200 OK */ Result
export type PostEntity11ApiArg = {
  contractDto: ContractDto
}
export type UpdateStatuses1ApiResponse = /** status 200 OK */ Result
export type UpdateStatuses1ApiArg = {
  updateShipmentStatusDto: UpdateShipmentStatusDto
}
export type ConsoleApiResponse = /** status 200 OK */ Result
export type ConsoleApiArg = {
  consoleDto: ConsoleDto
}
export type SendYandexRegisterApiResponse = /** status 200 OK */ Result
export type SendYandexRegisterApiArg = {
  yandexRegisterDto: YandexRegisterDto
}
export type UpdateCityCodesApiResponse = /** status 200 OK */ Result
export type UpdateCityCodesApiArg = void
export type UpdateCityCodesV2ApiResponse = /** status 200 OK */ Result
export type UpdateCityCodesV2ApiArg = {
  country: 'RU' | 'BY' | 'KZ' | 'AM' | 'KG'
  mergeGeographies?: boolean
}
export type PostEntity12ApiResponse = /** status 200 OK */ Result
export type PostEntity12ApiArg = {
  createEditDto: CreateEditDto
}
export type CalculateTariffApiResponse =
  /** status 200 OK */ PageResultCalculatedTariff
export type CalculateTariffApiArg = {
  calculateTariffDto: CalculateTariffDtoWrite
}
export type PostEntity13ApiResponse = /** status 200 OK */ Result
export type PostEntity13ApiArg = {
  accountDto: AccountDto
}
export type SendRegisterOrdersApiResponse = /** status 200 OK */ string
export type SendRegisterOrdersApiArg = {
  registerOrdersRequest: RegisterOrdersRequest
}
export type UploadExcelFileApiResponse = /** status 200 OK */ object
export type UploadExcelFileApiArg = {
  body: {
    file: Blob
    body: UploadReturnsRequest
  }
}
export type GetStatReportApiResponse = /** status 200 OK */ string
export type GetStatReportApiArg = {
  statReport: StatReport
}
export type GetEstimatedDeliveryTimeReportApiResponse =
  /** status 200 OK */ string
export type GetEstimatedDeliveryTimeReportApiArg = {
  estimatedDeliveryTimeReportDto: EstimatedDeliveryTimeReportDto
}
export type SavePostalTariffApiResponse = /** status 200 OK */ string
export type SavePostalTariffApiArg = {
  body: string
}
export type ImportRestrictionApiResponse = /** status 200 OK */ string
export type ImportRestrictionApiArg = {
  body: string
}
export type ImportXlsTariff1ApiResponse = /** status 200 OK */ Result
export type ImportXlsTariff1ApiArg = {
  importZoneFiasDto: ImportZoneFiasDto
}
export type GetRestrictionsApiResponse = /** status 200 OK */ string
export type GetRestrictionsApiArg = void
export type GetPostalTariffsApiResponse = /** status 200 OK */ string
export type GetPostalTariffsApiArg = void
export type PostEntity14ApiResponse = /** status 200 OK */ Result
export type PostEntity14ApiArg = {
  postalZoneFiasDto: PostalZoneFiasDto
}
export type PostRegisterApiResponse = /** status 200 OK */ string
export type PostRegisterApiArg = {
  body: string
}
export type UpdateStatusApiResponse = /** status 200 OK */ UpdateStatusResponse
export type UpdateStatusApiArg = {
  updateStatusRequest: UpdateStatusRequest
}
export type GetPlanReturnsApiResponse = /** status 200 OK */ string
export type GetPlanReturnsApiArg = {
  pickPointTariffRequest: PickPointTariffRequest
}
export type LoginApiResponse = /** status 200 OK */ LoginResult
export type LoginApiArg = {
  loginRequestDto: LoginRequestDto
}
export type GetPacketShipmentApiResponse = /** status 200 OK */ string
export type GetPacketShipmentApiArg = {
  body: string
}
export type LiteGetRegisterPrintFormApiResponse = /** status 200 OK */ string
export type LiteGetRegisterPrintFormApiArg = {
  body: string
}
export type LiteCreateRegisterApiResponse = /** status 200 OK */ string
export type LiteCreateRegisterApiArg = {
  body: string
}
export type LiteAcceptPlaceApiResponse = /** status 200 OK */ string
export type LiteAcceptPlaceApiArg = {
  body: string
}
export type GetTariffsApiResponse = /** status 200 OK */ string
export type GetTariffsApiArg = {
  body: string
}
export type GetShipmentInfoApiResponse = /** status 200 OK */ string
export type GetShipmentInfoApiArg = {
  body: string
}
export type GetStickersApiResponse = /** status 200 OK */ string
export type GetStickersApiArg = {
  body: string
}
export type FindPostamatByFiasCodeApiResponse = /** status 200 OK */ Result
export type FindPostamatByFiasCodeApiArg = {
  page?: number
  size?: number
  sort?: string
  findPostamatsByFiasCodeRequest: FindPostamatsByFiasCodeRequest
}
export type GetPostamatApiResponse = /** status 200 OK */ string
export type GetPostamatApiArg = {
  body: string
}
export type GetPostamatListApiResponse = /** status 200 OK */ string
export type GetPostamatListApiArg = {
  body: string
}
export type GetPostalTariffs1ApiResponse = /** status 200 OK */ string
export type GetPostalTariffs1ApiArg = {
  body: string
}
export type GetPlaceListApiResponse = /** status 200 OK */ string
export type GetPlaceListApiArg = {
  body: string
}
export type GetNextCourierWindowApiResponse = /** status 200 OK */ string
export type GetNextCourierWindowApiArg = {
  body: string
}
export type GetMeasurementsApiResponse = /** status 200 OK */ string
export type GetMeasurementsApiArg = {
  body: string
}
export type GetHistoryApiResponse = /** status 200 OK */ string
export type GetHistoryApiArg = {
  body: string
}
export type GetGatherRequestHistoryApiResponse = /** status 200 OK */ string
export type GetGatherRequestHistoryApiArg = {
  body: string
}
export type GetDeliveredItemsApiResponse = /** status 200 OK */ string
export type GetDeliveredItemsApiArg = {
  body: string
}
export type GetCourierServicesApiResponse = /** status 200 OK */ string
export type GetCourierServicesApiArg = {
  body: string
}
export type GetChangedShipmentHistoryApiResponse = /** status 200 OK */ string
export type GetChangedShipmentHistoryApiArg = {
  body: string
}
export type GetActiveApiResponse = /** status 200 OK */ string
export type GetActiveApiArg = {
  body: string
}
export type FakeCreatePlaceApiResponse = /** status 200 OK */ string
export type FakeCreatePlaceApiArg = {
  body: string
}
export type FakeCommitShipmentRequestListApiResponse =
  /** status 200 OK */ string
export type FakeCommitShipmentRequestListApiArg = {
  body: string
}
export type EditShipmentRequestApiResponse = /** status 200 OK */ string
export type EditShipmentRequestApiArg = {
  body: string
}
export type EditPlaceApiResponse = /** status 200 OK */ string
export type EditPlaceApiArg = {
  body: string
}
export type EditPalletApiResponse = /** status 200 OK */ string
export type EditPalletApiArg = {
  body: string
}
export type EditGatherRequestApiResponse = /** status 200 OK */ string
export type EditGatherRequestApiArg = {
  body: string
}
export type DeleteShipmentRequestApiResponse = /** status 200 OK */ string
export type DeleteShipmentRequestApiArg = {
  body: string
}
export type DeletePlaceApiResponse = /** status 200 OK */ string
export type DeletePlaceApiArg = {
  body: string
}
export type CreateTariffApiResponse = /** status 200 OK */ string
export type CreateTariffApiArg = {
  body: string
}
export type CreateShipmentRequestApiResponse = /** status 200 OK */ string
export type CreateShipmentRequestApiArg = {
  body: string
}
export type CreatePlaceApiResponse = /** status 200 OK */ string
export type CreatePlaceApiArg = {
  body: string
}
export type CreatePalletApiResponse = /** status 200 OK */ string
export type CreatePalletApiArg = {
  body: string
}
export type CreateGatherRequestApiResponse = /** status 200 OK */ string
export type CreateGatherRequestApiArg = {
  body: string
}
export type CreateApiResponse = /** status 200 OK */ string
export type CreateApiArg = {
  courierSettingsDto: CourierSettingsDto
}
export type DeleteApiCourierSettingsApiResponse = /** status 200 OK */ string
export type DeleteApiCourierSettingsApiArg = {
  courierCode: string
}
export type GetCourierCalendarApiResponse = /** status 200 OK */ string
export type GetCourierCalendarApiArg = {
  courierCode?: string
  calendarType?: string
}
export type UploadCourierCalendarApiResponse = /** status 200 OK */ string
export type UploadCourierCalendarApiArg = {
  body: string
}
export type RemoveContractApiResponse = /** status 200 OK */ string
export type RemoveContractApiArg = {
  body: string
}
export type GetProductsApiResponse = /** status 200 OK */ string
export type GetProductsApiArg = {
  body: string
}
export type GetContractsApiResponse = /** status 200 OK */ string
export type GetContractsApiArg = {
  body: string
}
export type GetBarcodeCountApiResponse = /** status 200 OK */ string
export type GetBarcodeCountApiArg = {
  body: string
}
export type GetCourierServiceListApiResponse = /** status 200 OK */ string
export type GetCourierServiceListApiArg = void
export type GetAdditionalParamsApiResponse = /** status 200 OK */ string
export type GetAdditionalParamsApiArg = {
  body: string
}
export type GenerateBarcodeApiResponse = /** status 200 OK */ string
export type GenerateBarcodeApiArg = {
  request: GenerateBarcodeRequest
}
export type EditContractApiResponse = /** status 200 OK */ string
export type EditContractApiArg = {
  body: string
}
export type CreateContractApiResponse = /** status 200 OK */ string
export type CreateContractApiArg = {
  body: string
}
export type ActApiResponse = /** status 200 OK */ string
export type ActApiArg = {
  body: string
}
export type CommitShipmentRequestListApiResponse = /** status 200 OK */ string
export type CommitShipmentRequestListApiArg = {
  body: string
}
export type CheckShipmentRequestApiResponse = /** status 200 OK */ string
export type CheckShipmentRequestApiArg = {
  body: string
}
export type CheckPickUpDateApiResponse = /** status 200 OK */ string
export type CheckPickUpDateApiArg = {
  body: string
}
export type ChangeDimensionsApiResponse = /** status 200 OK */ string
export type ChangeDimensionsApiArg = {
  body: string
}
export type CancelShipmentRequestApiResponse = /** status 200 OK */ string
export type CancelShipmentRequestApiArg = {
  cancelShipmentRequest: CancelShipmentRequest
}
export type CancelGatherRequestApiResponse = /** status 200 OK */ string
export type CancelGatherRequestApiArg = {
  body: string
}
export type CancelCommitApiResponse = /** status 200 OK */ string
export type CancelCommitApiArg = {
  cancelCommitDto: CancelCommitDto
}
export type UpdateStatuses2ApiResponse = /** status 200 OK */ Result
export type UpdateStatuses2ApiArg = {
  statusesUpdateRequest: StatusesUpdateRequest
}
export type ValidateAddressApiResponse = /** status 200 OK */ object
export type ValidateAddressApiArg = {
  addressValidationRequest: AddressValidationRequest
}
export type GetAccountsApiResponse = /** status 200 OK */ string
export type GetAccountsApiArg = void
export type DeleteAccountApiResponse = /** status 200 OK */ string
export type DeleteAccountApiArg = {
  body: string
}
export type CreateAccountApiResponse = /** status 200 OK */ string
export type CreateAccountApiArg = {
  body: string
}
export type GetPlanReturns1ApiResponse = /** status 200 OK */ Result
export type GetPlanReturns1ApiArg = void
export type GetRawHistoriesByDateApiResponse =
  /** status 200 OK */ PageResultRawHistoryDto
export type GetRawHistoriesByDateApiArg = {
  createdFrom: string
  contractId?: string
  dlvId?: string
  page?: number
  size?: number
}
export type GetHistoriesByDate1ApiResponse =
  /** status 200 OK */ PageResultShipmentRequestHistoryDto
export type GetHistoriesByDate1ApiArg = {
  createdFrom: string
  contractId?: string
  dlvId?: string
  orderNum?: string
  page?: number
  size?: number
}
export type GetEntitiesApiResponse = /** status 200 OK */ PageResultUser
export type GetEntitiesApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntityApiResponse = /** status 200 OK */ EntityResultUser
export type GetEntityApiArg = {
  id: string
}
export type DeleteEntityApiResponse = /** status 200 OK */ Result
export type DeleteEntityApiArg = {
  id: string
}
export type UploadTariffsApiResponse = /** status 200 OK */ Result
export type UploadTariffsApiArg = {
  contractId: string
}
export type UploadGeographiesApiResponse = /** status 200 OK */ object
export type UploadGeographiesApiArg = {
  region?: string
  city?: string
  area?: string
  settlement?: string
  cityFiasCode?: string
  settlementFiasCode?: string
  kladrCode?: string
  courierCityCode?: string
}
export type GetEntities1ApiResponse =
  /** status 200 OK */ PageResultCourierTariff
export type GetEntities1ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntitiesByParentIdApiResponse =
  /** status 200 OK */ PageResultCourierTariff
export type GetEntitiesByParentIdApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  contractId: string
}
export type GetEntity1ApiResponse =
  /** status 200 OK */ EntityResultCourierTariff
export type GetEntity1ApiArg = {
  id: string
}
export type DeleteEntity1ApiResponse = /** status 200 OK */ Result
export type DeleteEntity1ApiArg = {
  id: string
}
export type GetEntities2ApiResponse =
  /** status 200 OK */ PageResultTariffImportProcess
export type GetEntities2ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntitiesByParentId1ApiResponse =
  /** status 200 OK */ PageResultTariffImportProcess
export type GetEntitiesByParentId1ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  contractId: string
}
export type GetEntity2ApiResponse =
  /** status 200 OK */ EntityResultTariffImportProcess
export type GetEntity2ApiArg = {
  id: string
}
export type DeleteEntity2ApiResponse = /** status 200 OK */ Result
export type DeleteEntity2ApiArg = {
  id: string
}
export type GetAllSettingsApiResponse =
  /** status 200 OK */ PageResultSystemSetting
export type GetAllSettingsApiArg = void
export type GetGeographySuggestionApiResponse =
  /** status 200 OK */ GeographySuggestionResult
export type GetGeographySuggestionApiArg = {
  query: string
}
export type GetStringApiResponse =
  /** status 200 OK */ GetHistoryResultShipmentRequestHistory
export type GetStringApiArg = {
  dateFrom: string
  dlvLogin: string
}
export type GetEntities3ApiResponse =
  /** status 200 OK */ PageResultShipmentRequest
export type GetEntities3ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntity3ApiResponse =
  /** status 200 Успешный ответ с информацией об отправлении */ EntityResultShipmentRequest
export type GetEntity3ApiArg = {
  id: string
}
export type DeleteEntity3ApiResponse = /** status 200 OK */ Result
export type DeleteEntity3ApiArg = {
  id: string
}
export type SaveDeletedTariffsLogsApiResponse = /** status 200 OK */ Result
export type SaveDeletedTariffsLogsApiArg = {
  logId: string
}
export type GetRawHistoriesByDate1ApiResponse =
  /** status 200 OK */ PageResultRawHistoryDto
export type GetRawHistoriesByDate1ApiArg = {
  createdFrom: string
  dlvLogin?: string
  contractId?: string
  dlvId?: string
  page?: number
  size?: number
}
export type GetEntities4ApiResponse =
  /** status 200 OK */ PageResultPrintTemplate
export type GetEntities4ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntity4ApiResponse =
  /** status 200 OK */ EntityResultPrintTemplate
export type GetEntity4ApiArg = {
  id: string
}
export type DeleteEntity4ApiResponse = /** status 200 OK */ Result
export type DeleteEntity4ApiArg = {
  id: string
}
export type GetEntitiesByParentId2ApiResponse =
  /** status 200 Успешный ответ с информацией о местах */ PageResultPlace
export type GetEntitiesByParentId2ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  srId: string
}
export type GetEntity5ApiResponse = /** status 200 OK */ EntityResultPlace
export type GetEntity5ApiArg = {
  id: string
}
export type DeleteEntity5ApiResponse = /** status 200 OK */ Result
export type DeleteEntity5ApiArg = {
  id: string
}
export type GetEntity6ApiResponse =
  /** status 200 OK */ EntityResultShipmentRequestHistory
export type GetEntity6ApiArg = {
  id: string
}
export type DeleteEntity6ApiResponse = /** status 200 OK */ Result
export type DeleteEntity6ApiArg = {
  id: string
}
export type GetEntities5ApiResponse =
  /** status 200 OK */ PageResultShipmentRequestHistory
export type GetEntities5ApiArg = {
  page?: number
  size?: number
  sort?: string
  direction?: 'ASC' | 'DESC'
  filter?: string
}
export type GetEntitiesByParentId3ApiResponse =
  /** status 200 Успешный ответ с информацией об отправлении */ PageResultShipmentRequestHistory
export type GetEntitiesByParentId3ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  srId: string
}
export type GetEntity7ApiResponse = /** status 200 OK */ EntityResultGeography
export type GetEntity7ApiArg = {
  id: string
}
export type DeleteEntity7ApiResponse = /** status 200 OK */ Result
export type DeleteEntity7ApiArg = {
  id: string
}
export type GetEntities6ApiResponse = /** status 200 OK */ PageResultGeography
export type GetEntities6ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetFailedTariffImportProcessApiResponse =
  /** status 200 OK */ Result
export type GetFailedTariffImportProcessApiArg = {
  id: string
}
export type DropTariffsLogsApiResponse = /** status 200 OK */ Result
export type DropTariffsLogsApiArg = {
  contractId: string
}
export type GetEntities7ApiResponse =
  /** status 200 OK */ PageResultCourierProduct
export type GetEntities7ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntitiesByParentId4ApiResponse =
  /** status 200 OK */ PageResultCourierProduct
export type GetEntitiesByParentId4ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  courierCompanyId: string
}
export type GetEntity8ApiResponse =
  /** status 200 OK */ EntityResultCourierProduct
export type GetEntity8ApiArg = {
  id: string
}
export type DeleteEntity8ApiResponse = /** status 200 OK */ Result
export type DeleteEntity8ApiArg = {
  id: string
}
export type GetEntity9ApiResponse =
  /** status 200 OK */ EntityResultCourierCompany
export type GetEntity9ApiArg = {
  id: string
}
export type DeleteEntity9ApiResponse = /** status 200 OK */ Result
export type DeleteEntity9ApiArg = {
  id: string
}
export type GetEntities8ApiResponse =
  /** status 200 OK */ PageResultCourierCompany
export type GetEntities8ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntities9ApiResponse = /** status 200 OK */ PageResultContract
export type GetEntities9ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntitiesByParentId5ApiResponse =
  /** status 200 OK */ PageResultContract
export type GetEntitiesByParentId5ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  accountId: string
}
export type GetEntity10ApiResponse = /** status 200 OK */ EntityResultContract
export type GetEntity10ApiArg = {
  id: string
}
export type DeleteEntity10ApiResponse = /** status 200 OK */ Result
export type DeleteEntity10ApiArg = {
  id: string
}
export type GetEntities10ApiResponse =
  /** status 200 OK */ PageResultCdekCityCode
export type GetEntities10ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntity11ApiResponse =
  /** status 200 OK */ EntityResultCdekCityCode
export type GetEntity11ApiArg = {
  id: string
}
export type DeleteEntity11ApiResponse = /** status 200 OK */ Result
export type DeleteEntity11ApiArg = {
  id: string
}
export type GetEntities11ApiResponse = /** status 200 OK */ PageResultAccount
export type GetEntities11ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntity12ApiResponse = /** status 200 OK */ EntityResultAccount
export type GetEntity12ApiArg = {
  id: string
}
export type DeleteEntity12ApiResponse = /** status 200 OK */ Result
export type DeleteEntity12ApiArg = {
  id: string
}
export type GetPlanReturns2ApiResponse =
  /** status 200 Успешный ответ с информацией о возвратах */ ReturnsDocsDto
export type GetPlanReturns2ApiArg = {
  dateFrom: string
  dateTo?: string
  docNumber?: string
  warehouse?: string
}
export type ExportStatusExcelApiResponse = unknown
export type ExportStatusExcelApiArg = {
  dlvLogin?: string
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  createDate?: string
}
export type ExportStatusCountExcelApiResponse = unknown
export type ExportStatusCountExcelApiArg = {
  courierCode:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  dlvLogin?: string
  createDate?: string
}
export type ExportStatisticCountExcelApiResponse = unknown
export type ExportStatisticCountExcelApiArg = {
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  dlvLogin?: string
  region?: string
  createDateFrom: string
  createDateTo?: string
}
export type ExportStatisticCountExcel1ApiResponse = unknown
export type ExportStatisticCountExcel1ApiArg = {
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  dlvLogin?: string
  groupType: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR'
  createDateFrom: string
  createDateTo?: string
}
export type ExportPostalBaseTariffExcelApiResponse = unknown
export type ExportPostalBaseTariffExcelApiArg = {
  dateFrom: string
  dateTo?: string
}
export type ExportOverdueExcelApiResponse = unknown
export type ExportOverdueExcelApiArg = {
  dlvLogin?: string
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  overDays?: number
  createDate?: string
}
export type GetEntities12ApiResponse = /** status 200 OK */ PageResultPostamat
export type GetEntities12ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type UpdatePostamatsApiResponse = /** status 200 OK */ Result
export type UpdatePostamatsApiArg = {
  courierCode:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  strictGeography?: boolean
}
export type GetEntities13ApiResponse =
  /** status 200 OK */ PageResultPostalZoneFias
export type GetEntities13ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetEntity13ApiResponse =
  /** status 200 OK */ EntityResultPostalZoneFias
export type GetEntity13ApiArg = {
  id: string
}
export type DeleteEntity13ApiResponse = /** status 200 OK */ Result
export type DeleteEntity13ApiArg = {
  id: string
}
export type OrderListApiResponse = /** status 200 OK */ PageOrderInfo
export type OrderListApiArg = {
  startDate?: string
  endDate?: string
  client?: string
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  pageable: Pageable
}
export type GetOrdersInfoApiResponse =
  /** status 200 OK */ PageShipmentInfoResponse
export type GetOrdersInfoApiArg = {
  dateFrom?: string
  dateTo?: string
  statusDuration?: string
  warehouse?: 'KOLEDINO' | 'VNUKOVO'
  courierStatuses?: (
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  )[]
  dlvStatuses?: (
    | 'ACCEPTED'
    | 'UPDATING'
    | 'CANCELING'
    | 'ABORTED'
    | 'UPDATING_SUSPENDED'
  )[]
  filterType?:
    | 'COMMIT_ERROR'
    | 'INACTION'
    | 'DEFAULT'
    | 'ALL_ERRORS'
    | 'WITHOUT_COMMIT'
    | 'FILTER_OFF'
  pageable: Pageable
}
export type GetOrdersInfo1ApiResponse = /** status 200 OK */ InfoResponse
export type GetOrdersInfo1ApiArg = {
  num?: string
  dlvId?: string
}
export type GetExchangeApiResponse = /** status 200 OK */ ExchangeLogLine
export type GetExchangeApiArg = {
  id: string
  type: 'COURIER' | 'SHIPMENT'
}
export type GetPlanReturns3ApiResponse = /** status 200 OK */ string
export type GetPlanReturns3ApiArg = {
  dateFrom: string
  dateTo: string
}
export type LiteregisteredShipmentsApiResponse = /** status 200 OK */ string
export type LiteregisteredShipmentsApiArg = {
  fromDate?: string
}
export type FindByFiasApiResponse =
  /** status 200 OK */ PageResultContractResponse
export type FindByFiasApiArg = {
  page?: number
  size?: number
  fiasCode?: string
}
export type GetBarcodePoolsApiResponse =
  /** status 200 OK */ PageResultBarcodePoolInfo
export type GetBarcodePoolsApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type GetBarcodePoolContractsApiResponse =
  /** status 200 OK */ PageResultContract
export type GetBarcodePoolContractsApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
  id: string
}
export type TariffRemoveProcessApiResponse = /** status 200 OK */ Result
export type TariffRemoveProcessApiArg = {
  logId: string
}
export type DlvError = {
  code?:
    | 'WRONG_JSON_FORMAT'
    | 'WRONG_PARAMETER'
    | 'NO_SERVICE'
    | 'UNKNOWN_ERROR'
    | 'DUPLICATE'
    | 'PERMISSION_DENIED'
    | 'PICK_UP_TIME'
    | 'AUTHENTICATION_ERROR'
    | 'COURIER_ERROR'
    | 'TIMEOUT'
    | 'NOT_FOUND'
    | 'BUSINESS_ERROR'
  message?: string
}
export type ShipmentRequestSync = {
  id?: string
  createTs?: string
  updateTs?: string
  num?: string
  courierNum?: string
  tempCourierNum?: string
  postalApiId?: string
  parcelNum?: string
  returningCourierNum?: string
  /** Внутреннее состояние сущности ДЛВ. Возможные значения:
    
    ACCEPTED - Принято,
    
    UPDATING - Выгружено в КС. Обновляется по статусам,
    
    CANCELING - Отменяется,
    
    ABORTED - Обработка отправления закончена. Финальный статус,
    
    UPDATING_SUSPENDED - Обновление приостановлено
     */
  syncState?:
    | 'ACCEPTED'
    | 'UPDATING'
    | 'CANCELING'
    | 'ABORTED'
    | 'UPDATING_SUSPENDED'
  syncMessage?: string
  cancelReason?: string
  /** Статус заказа. Движение заказа у курьерской службы */
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  /** Статус отмены заказа. Возможные значения:
    
    ERROR - Произошла ошибка отмены,
    
    IN_PROCESS - Происходит обработка в КС,
    
    CANCELLED - Успешно оменено,
    
    NOT_CANCELLED - Стандартный статус. Запроса на отмену не было
     */
  cancellationStatus?: 'ERROR' | 'IN_PROCESS' | 'CANCELLED' | 'NOT_CANCELLED'
  cancellationStatusTs?: string
  cancellationStateError?: string
  currentCity?: string
  lastRequestTs?: string
  registeredTs?: string
  docReturn?: boolean
  statusDate?: string
  dlvLogin?: string
  actualDeliveryDate?: string
  courierData?: string
  registerActId?: string
  registeredActTs?: string
  commitTry?: boolean
  idAsString?: string
}
export type ShipmentCustomInfo = {
  id?: string
  shipmentRequestSync?: ShipmentRequestSync
  createTs?: string
  comment?: string
  author?: string
}
export type GetShipmentCustomInfoResult = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  shipmentCustomInfoList?: ShipmentCustomInfo[]
}
export type Result = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
}
export type UserDto = {
  id?: string
  username: string
  password?: string
  name?: string
}
export type ShipmentUploadRequest = {
  dataType?:
    | 'SHIPMENT_REQUEST'
    | 'SHIPMENT_REQUEST_BY_FILE'
    | 'TARIFF'
    | 'POSTAMAT'
    | 'GEOGRAPHY'
  withPlaces?: boolean
  ownerCompanyName?: string
  createTsFrom?: string
  createTsTo?: string
  statusDateFrom?: string
  statusDateTo?: string
  factShipmentDateFrom?: string
  factShipmentDateTo?: string
  statuses?: (
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  )[]
  courierCodes?: (
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  )[]
}
export type ShipmentUploadByFileRequest = {
  dataType?:
    | 'SHIPMENT_REQUEST'
    | 'SHIPMENT_REQUEST_BY_FILE'
    | 'TARIFF'
    | 'POSTAMAT'
    | 'GEOGRAPHY'
  withPlaces?: boolean
  file: string[]
}
export type PostamatUploadRequest = {
  dataType?:
    | 'SHIPMENT_REQUEST'
    | 'SHIPMENT_REQUEST_BY_FILE'
    | 'TARIFF'
    | 'POSTAMAT'
    | 'GEOGRAPHY'
  fiasCode?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  code?: string
  postCode?: string
  withClosed?: boolean
}
export type UploadGeographyByFileRequest = {
  dataType?:
    | 'SHIPMENT_REQUEST'
    | 'SHIPMENT_REQUEST_BY_FILE'
    | 'TARIFF'
    | 'POSTAMAT'
    | 'GEOGRAPHY'
  file: string[]
}
export type UpdateStatusesDto = {
  /** Возможные коды курьерских служб */
  courierCode:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
}
export type CreateEditDto = {
  id?: string
  contractId: string
  country: string
  file: string[]
  fileName: string
  priority: 'HIGH' | 'MEDIUM' | 'LOW'
}
export type CourierTariffDto = {
  id?: string
  icn?: string
  login?: string
  password?: string
  ownerCompanyName?: string
  showTariffSource?: 'PUBLIC_API' | 'PRIVATE_API' | 'DATABASE'
  barcodePoolId?: string
  courierProductId?: string
  courierCompanyId?: string
}
export type SystemSetting = {
  id?: string
  createTs?: string
  updateTs?: string
  key?:
    | 'DADATA_ENABLED'
    | 'DADATA_TOKEN'
    | 'DADATA_SECRET_KEY'
    | 'DADATA_URL'
    | 'DADATA_FIAS_URL'
    | 'DADA_STANDARDIZATION_URL'
    | 'DADATA_GEO_LOCATE_URL'
    | 'GOOGLE_API_KEY'
    | 'INTEGRATION_ENABLED'
    | 'TEST_INTEGRATION'
    | 'DISABLE_COURIER_SERVICE'
    | 'MEGAMARKET_BARCODE_COUNT'
    | 'NOT_ALLOWED_CALCULATE_TARIFF'
    | 'DISABLE_FIAS_LOWLEVEL'
    | 'PODRYGKA_CDEK_CLIENT_ID'
    | 'PODRYGKA_CDEK_CLIENT_SECRET'
  value?: string
}
export type GeographyTipsRequest = {
  query?: string
  fromBoundValue?:
    | 'COUNTRY'
    | 'REGION'
    | 'AREA'
    | 'CITY'
    | 'SETTLEMENT'
    | 'STREET'
    | 'HOUSE'
    | 'FLAT'
  toBoundValue?:
    | 'COUNTRY'
    | 'REGION'
    | 'AREA'
    | 'CITY'
    | 'SETTLEMENT'
    | 'STREET'
    | 'HOUSE'
    | 'FLAT'
  limit?: number
}
export type SyncRecordDto = {
  id?: string
  /** Внутреннее состояние сущности ДЛВ. Возможные значения:
    
    ACCEPTED - Принято,
    
    UPDATING - Выгружено в КС. Обновляется по статусам,
    
    CANCELING - Отменяется,
    
    ABORTED - Обработка отправления закончена. Финальный статус,
    
    UPDATING_SUSPENDED - Обновление приостановлено
     */
  syncState?:
    | 'ACCEPTED'
    | 'UPDATING'
    | 'CANCELING'
    | 'ABORTED'
    | 'UPDATING_SUSPENDED'
  courierNum?: string
}
export type ShipmentRequestDto = {
  id?: string
  syncRecord?: SyncRecordDto
}
export type CustomerInfo = {
  name?: string
  company?: string
  phone?: string
  phone2?: string
  email?: string
  inn?: string
  warehouse?: string
  zipcode?: string
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  street?: string
  house?: string
  block?: string
  building?: string
  flat?: string
  replacement?: string
  fiasCode?: string
  kladrCode?: string
  cityFiasCode?: string
  regionFiasCode?: string
  areaFiasCode?: string
  settlementFiasCode?: string
}
export type CodParams = {
  name?: string
  accountNumber?: string
  bik?: string
  correspondAccount?: string
  inn?: string
  kpp?: string
  bankName?: string
}
export type Params = {
  dlvLogin: string
  floor?: number
  cashOnDeliveryType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  payerType?: 'SENDER' | 'RECEIVER'
  description?: string
  comment?: string
  docReturn?: boolean
  byHand?: boolean
  deliveryVATType?:
    | 'VAT18'
    | 'VAT20'
    | 'VAT10'
    | 'VAT_CALC_20_120'
    | 'VAT_CALC_18_118'
    | 'VAT_CALC_10_110'
    | 'VAT0'
    | 'NO_VAT'
    | 'VATX'
  startPickUp?: string
  endPickUp?: string
  planDeliveryTimeStart?: string
  planDeliveryTimeEnd?: string
  planDeliveryDate?: string
  cargoPickUpDate?: string
  num: string
  insuranceSum: number
  deliveryPrice?: number
  cashOnDelivery: number
  freeDeliveryFromSum?: number
  contractId: string
  tariffType: 'FAST' | 'CHEAP' | 'CONTRACT' | 'COURIER'
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  /** Возможные коды курьерских служб */
  courierCode2?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  litePacked?: boolean
  courierProduct?: string
  postamatNum?: string
  codParams?: CodParams
  registerNum?: string
  additionalInfo?: string
  supplierInn?: string
  supplierPhone?: string
}
export type Service = {
  serviceName:
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  serviceValue: string
}
export type CustomData = {
  paramName: string
  paramValue: string
}
export type EditShipmentDto = {
  dlvId: string
  sender: CustomerInfo
  receiver: CustomerInfo
  params: Params
  services?: Service[]
  customData?: CustomData[]
  id: string
}
export type ReturnSubPieceDto = {
  VendorCode?: string
  ExternalId?: string
  Barcode?: string
  Qty?: number
  Name?: string
}
export type PlaceDto = {
  /** Идентификатор места в формате UUID */
  PlaceId?: string
  /** Идентификатор заказа в формате UUID */
  ShipmentId?: string
  Barcode?: string
  SubPieces?: ReturnSubPieceDto[]
}
export type ManualCallApiDto = {
  type?:
    | 'UPDATE_STATUS'
    | 'GET_TRACKING_NUM'
    | 'COMMIT_SHIPMENT'
    | 'PARTIALLY_DELIVERY_UPDATE'
    | 'CANCEL_SHIPMENT'
    | 'CHECK_RECEIVER_PHONE'
    | 'RELOAD_SHIPMENT_HISTORY'
  influenceOnShipment?: boolean
  shipmentRequestId?: string
}
export type ImportDto = {
  file: string[]
  type:
    | '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u043A\u043E\u0434\u043E\u0432 \u0433\u043E\u0440\u043E\u0434\u043E\u0432 CDEK'
    | '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u043A\u043E\u0434\u043E\u0432 \u0433\u043E\u0440\u043E\u0434\u043E\u0432 CDEK \u043F\u043E \u0444\u0438\u0430\u0441 \u043A\u043E\u0434\u0443'
    | '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u0438\u043D\u0434\u0435\u043A\u0441\u043E\u0432 c \u043F\u043E\u043D\u0438\u0436\u0435\u043D\u043D\u044B\u0438 insurance'
    | '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u0438\u043D\u0434\u0435\u043A\u0441\u043E\u0432 \u041F\u043E\u0441\u044B\u043B\u043A\u0430-\u041E\u043D\u043B\u0430\u0439\u043D'
    | '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u0438\u043D\u0434\u0435\u043A\u0441\u043E\u0432 \u041A\u0443\u0440\u044C\u0435\u0440-\u041E\u043D\u043B\u0430\u0439\u043D'
    | '\u0418\u043C\u043E\u0440\u0442 \u0438\u043D\u0434\u0435\u043A\u0441\u043E\u0432'
    | '\u041E\u041F\u0421 \u043A\u0440\u0443\u0433\u043B\u043E\u0433\u043E\u0434\u0438\u0447\u043D\u043E\u0439 \u0442\u0440\u0443\u0434\u043D\u043E\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E\u0441\u0442\u0438'
    | '\u041E\u041F\u0421 \u0432\u0440\u0435\u043C\u0435\u043D\u043D\u043E\u0439 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E\u0441\u0442\u0438'
    | '\u0418\u043C\u043F\u043E\u0440\u0442 \u0433\u0435\u043E\u0433\u0440\u0430\u0444\u0438\u0439 Kazpost'
    | '\u0418\u043C\u043F\u043E\u0440\u0442 \u043F\u0440\u0438\u0447\u0438\u043D\u044B \u043D\u0430\u0440\u0443\u0448\u0435\u043D\u0438\u044F \u0441\u0440\u043E\u043A\u043E\u0432 \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438'
  useDadata?: boolean
}
export type ImportDeliveryDateChangeReasonsRequest = {
  file: string[]
}
export type Pagination = {
  totalElements?: number
  currentPage?: number
  totalPages?: number
}
export type ShipmentRequestHistoryDto = {
  dlvId?: string
  orderNum?: string
  dateTime?: string
  /** Статус заказа. Движение заказа у курьерской службы */
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  city?: string
  index?: number
  createTs?: string
  courierPlanDeliveryDate?: string
  courierPlanDeliveryDateChangeReason?: string
  message?: string
  cancelReason?: string
}
export type PageResultShipmentRequestHistoryDto = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: ShipmentRequestHistoryDto[]
}
export type ShipmentRequestHistoryRequestDto = {
  createdFrom?: string
  dlvLogins?: string[]
  page?: number
  size?: number
}
export type GeographyDto = {
  id?: string
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  fiasCode?: string
  kladrCode?: string
  cityFiasCode?: string
  regionFiasCode?: string
  areaFiasCode?: string
  settlementFiasCode?: string
  latitude?: string
  longitude?: string
  distance?: number
  sourceAddressListAsStringList?: string[]
}
export type StreamingResponseBody = object
export type CourierCityCode = {
  id?: string
  createTs?: string
  updateTs?: string
  cityCode?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
}
export type Geography = {
  id?: string
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  fiasCode?: string
  kladrCode?: string
  cityFiasCode?: string
  regionFiasCode?: string
  areaFiasCode?: string
  settlementFiasCode?: string
  fiasLevel?: string
  latitude?: string
  longitude?: string
  distance?: number
  courierCityCodes?: CourierCityCode[]
  postamatNum?: string
  sourceAddressListAsStringList?: string[]
  settlementInsideCity?: boolean
  zipCodes?: string[]
}
export type SourceAddress = {
  id?: string
  sourceAddress?: string
}
export type GeographyWrite = {
  id?: string
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  fiasCode?: string
  kladrCode?: string
  cityFiasCode?: string
  regionFiasCode?: string
  areaFiasCode?: string
  settlementFiasCode?: string
  fiasLevel?: string
  latitude?: string
  longitude?: string
  distance?: number
  sourceAddressList?: SourceAddress[]
  courierCityCodes?: CourierCityCode[]
  postamatNum?: string
  sourceAddress?: string
  sourceAddressListAsStringList?: string[]
  settlementInsideCity?: boolean
  zipCodes?: string[]
}
export type ExtendedGeography = {
  geography?: Geography
  zipcode?: string
  postamatNumber?: string
}
export type ExtendedGeographyWrite = {
  geography?: GeographyWrite
  zipcode?: string
  postamatNumber?: string
}
export type ExportTariffDto = {
  contractId: string
  senderGeo: ExtendedGeography
  reload?: boolean
}
export type ExportTariffDtoWrite = {
  contractId: string
  senderGeo: ExtendedGeographyWrite
  reload?: boolean
}
export type DownloadProblemOrderRequest = {
  courierCodes?: (
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  )[]
  startTime?: string
  finishTime?: string
}
export type CourierProductDto = {
  id?: string
  courierCompanyId?: string
  name?: string
  code?: string
  international?: boolean
  docReturn?: boolean
  pvzProduct?: boolean
  maxWeight?: number
}
export type CourierCompanyDto = {
  id?: string
  name?: string
  apiUrl?: string
  testApiUrl?: string
  /** Возможные коды курьерских служб */
  code?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
}
export type ContractParamDto = {
  paramKey?:
    | 'INN'
    | 'KPP'
    | 'ADDITIONAL_POSTAL_ABONENT_BOX_NUM'
    | 'ADDITIONAL_POSTAL_TECH_ZIPCODE'
    | 'ADDITIONAL_POSTAL_TOP_COMPANY'
    | 'ADDITIONAL_POSTAL_BOTTOM_COMPANY'
    | 'ADDITIONAL_POSTAL_SENDER_ADDRESS'
    | 'ADDITIONAL_POSTAL_SENDER_COMPANY'
    | 'STOCK'
    | 'PRODUCT_TYPE'
    | 'SERVICE_CODE'
    | 'SELLER_NAME'
    | 'LEGAL_NAME'
    | 'ESPP_NUM'
    | 'codName'
    | 'codBankName'
    | 'codBik'
    | 'codCorrespondAccount'
    | 'codInn'
    | 'codKpp'
    | 'codAccountNumber'
    | 'SYNC_DATE'
    | 'SEND_ON_COMMIT'
    | 'YVES_ROCHER'
    | 'postalEmail'
    | 'postalEmailCC'
    | 'simplePostalTariff'
    | 'postalEmailOrganization'
    | 'SHIPMENT_SORT'
    | 'YANDEX_WAREHOUSE_TO_ID'
    | 'YANDEX_API_KEYS'
    | 'YANDEX_CLIENT_ID'
    | 'YANDEX_TEST_SENDER_ID'
    | 'COURIER_NAME'
    | 'CAR_MODEL'
    | 'CAR_NUMBER'
    | 'DPD_REGULAR_NUM'
    | 'DPD_PICK_POINT_NUM'
    | 'DPD_LATVIA_API_TOKEN'
    | 'DPD_LATVIA_PARCELSHOP_ID'
    | 'DPD_LATVIA_STATUS_URL'
    | 'SENDER_POSTAMAT_NUM'
    | 'FORCE_ORDER_PREFIX'
    | 'YANDEX_AUTO_PARCEL_CONFIRM'
    | 'YANDEX_PARCEL_RECEIVER_EMAILS'
    | 'YANDEX_PARCEL_ERROR_EMAILS'
    | 'OZON_DELIVERY_METHODS'
    | 'OZON_REGISTER_EMAILS'
    | 'OZON_PICKUP'
    | 'SMS_NOTIFICATION'
    | 'BACK_PAYMENT_DETAILS'
    | 'INSURANCE_TARIFF_PERCENT'
    | 'FIX_RAISE_INSURANCE'
    | 'COD_TARIFF_PERCENT'
    | 'FIX_RAISE_COD'
    | 'EXTERNAL_CONTRACT_NUMBER'
    | 'COURIER_CONTRACT_NUMBER'
    | 'COURIER_CONTRACT_DATE'
    | 'STANDART_RAISE_PRICE_PROCENT'
    | 'FIX_RAISE_PRICE'
    | 'PICK_POINT_AGGREGATOR_NAME'
    | 'GRASTIN_DELIVERY_BARCODE'
    | 'KAK2C_LITE_CONTRACT'
    | 'STRIZH_SETTING_ID'
    | 'BB_PICKUP_ID'
    | 'BASE_TARIFF'
    | 'BASE_TARIFF_PRICE_PERCENT'
    | 'BASE_TARIFF_PRICE_FIX'
    | 'CALL_NOTIFICATION'
    | 'TO_DOOR'
    | 'CHECK_COD'
    | 'CHECK_INSURANCE'
    | 'NON_CHECK_CDEK_CITY'
    | 'NON_CHECK_DIMENSION'
    | 'ONLY_CARD'
    | 'SUMM_FOR_FREE_DELIVERY'
    | 'PLUS_MIN_DELIVERY_DAY'
    | 'PLUS_MAX_DELIVERY_DAY'
    | 'USER_NAME'
    | 'USER_PASSWORD'
    | 'EDRPOU_CODE'
    | 'SENDER_COUNTER_PARTY_REF'
    | 'SENDER_CITY_REF'
    | 'SENDER_CONTACT_PERSON'
    | 'SENDER_ADDRESS_REF'
    | 'SENDER_DEFAULT_COST'
    | 'SENDER_PHONE'
    | 'SENDER_NAME'
    | 'SENDER_WAREHOUSE_ID'
    | 'DISABLED_TARIFFS'
    | 'SENDER_ADDRESS_CITY'
    | 'SENDER_ADDRESS_STREET'
    | 'SENDER_ADDRESS_HOUSE'
    | 'INTERNATIONAL'
    | 'TAX_IDENTIFICATION_NUMBER'
    | 'UPS_AUTH_TOKEN'
    | 'UPS_ACCESS_LICENSE_NUMBER'
    | 'UPS_API_VERSION'
    | 'ONLY_CYRILLIC_SYMBOLS_IN_FIO'
    | 'X5_POST_PARTNER_LOCATION_ID'
    | 'X5_POST_PARTNER_CARGO_ID'
    | 'TIME_CUTOFF'
    | 'LOGSIS_BARCODE_PREFIX'
    | 'YANDEXV2_TRY_DIMENSIONS'
    | 'NEW_WMS'
    | 'RETURNS_LOAD_DATE'
    | 'WEBSITE'
    | 'CSE_DEPARTMENTS'
    | 'CSE_PROJECTS'
    | 'FIRST_MILE_WAREHOUSE_ID'
    | 'SHIPPER_ACCOUNT_NUMBER'
    | 'DIRECT_CONTRACT'
    | 'EXPORT_SKU_CODE'
    | 'CLIENT_NUMBER'
    | 'CSE_LOGIN_PARTIAL'
    | 'CSE_PASS_PARTIAL'
    | 'YANDEX_PLATFORM_STATION_ID'
    | 'YANDEX_MARKET_CAMPAIGN_ID'
    | 'TARIFF_GROUP'
    | 'TARIFF_DISABLED_COD'
    | 'BOX_NEEDED_BY_VOLUME'
    | 'BOX_NEEDED_BY_PERCENT'
    | 'OVERSIZED_CARGO_BY_PERCENT'
    | 'OVERSIZED_CARGO_FIXED'
    | 'DISABLE_INSURANCE_SUM'
    | 'AUTH_TOKEN'
    | 'MEGAMARKET_BARCODE_PREFIX'
    | 'QUBY_WAREHOUSE_LOCATION_ID'
    | 'WAREHOUSE_CODE_TO'
  paramValue?: string
}
export type ContractDto = {
  id?: string
  icn?: string
  login?: string
  password?: string
  ownerCompanyName?: string
  sellerPrefix?: string
  imitateCourierCompany?: boolean
  showTariffSource: 'PUBLIC_API' | 'PRIVATE_API' | 'DATABASE'
  barcodePoolId?: string
  courierProductId?: string
  courierCompanyId: string
  accountId?: string
  contractParams?: ContractParamDto[]
}
export type UpdateShipmentStatusDto = {
  contractId: string
}
export type ConsoleDto = {
  type?:
    | 'POSTAMAT_UPDATE'
    | 'UPDATE_PRODUCTION_CALENDAR'
    | 'GEOGRAPHY_UPDATE'
    | 'GEOGRAPHY_UPDATE_STANDARDIZATION'
    | 'GEOGRAPHY_UPDATE_BY_FIAS'
    | 'SHIPMENT_REQUEST_STATUS_UPDATE'
    | 'GATHER_REQUEST_STATUS_UPDATE'
    | 'YANDEX_SEND_ALL_REGISTER'
    | 'YANDEX_CONFIRM_PARCELS'
    | 'YANDEX_CREATE_PARCELS'
    | 'DPD_PENDING_UPDATE'
    | 'MONMIO_CHECK_TARIFF'
    | 'CDEK_CITY_CODE_UPDATE'
    | 'CDEK_CITY_CODE_UPDATE_WITHOUT_FIAS'
    | 'OZON_DELIVERY_VARIANT_UPDATE'
    | 'IMPORT_SOME_TARIFFS'
    | 'ITEM_DELIVERY_STATUS'
    | 'RELOAD_DPD_SHIPMENT_HISTORY'
    | 'UPDATE_PVZ_TARIFF_LIMITS'
}
export type YandexRegisterDto = {
  email?: string
  parcelId?: string
}
export type CalculatedTariff = {
  contractId?: string
  /** Возможные коды курьерских служб */
  courierCompany?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  courierProduct?: string
  price?: number
  minDeliveryTime?: number
  maxDeliveryTime?: number
  insurancePrice?: number
  postalZone?: string
  weightCategory?: number
  includeVAT?: number
  extra?: boolean
  errorMessage?: string
  deliveryPrice?: number
  codPrice?: number
  selectedServicesPrice?: number
  externalContractNumber?: string
  receiverGeography?: Geography
  senderGeography?: Geography
  avia?: boolean
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  courierServicePrices?: {
    [key: string]: number
  }
  restriction?: boolean
  onlyCyrillicSymbolInFio?: boolean
  pvz?: boolean
}
export type CalculatedTariffWrite = {
  contractId?: string
  /** Возможные коды курьерских служб */
  courierCompany?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  courierProduct?: string
  price?: number
  minDeliveryTime?: number
  maxDeliveryTime?: number
  insurancePrice?: number
  postalZone?: string
  weightCategory?: number
  includeVAT?: number
  extra?: boolean
  errorMessage?: string
  deliveryPrice?: number
  codPrice?: number
  selectedServicesPrice?: number
  externalContractNumber?: string
  receiverGeography?: GeographyWrite
  senderGeography?: GeographyWrite
  isPVZ?: boolean
  avia?: boolean
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  courierServicePrices?: {
    [key: string]: number
  }
  restriction?: boolean
  onlyCyrillicSymbolInFio?: boolean
  pvz?: boolean
}
export type PageResultCalculatedTariff = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CalculatedTariff[]
}
export type PageResultCalculatedTariffWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CalculatedTariffWrite[]
}
export type Dimension = {
  weight?: number
  width?: number
  height?: number
  depth?: number
}
export type TariffRequest = {
  dlvLogin?: string
  postalProduct?: string
  senderGeo?: ExtendedGeography
  receiverGeo?: ExtendedGeography
  dimension?: Dimension
  floor?: number
  docReturn?: boolean
  byHand?: boolean
  insuranceSum?: number
  insuranceSumPost?: number
  codSum?: number
  courierServices?: (
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  )[]
  postalDogovor?: string
  postalDate?: string
}
export type TariffRequestWrite = {
  dlvLogin?: string
  postalProduct?: string
  senderGeo?: ExtendedGeographyWrite
  receiverGeo?: ExtendedGeographyWrite
  dimension?: Dimension
  floor?: number
  docReturn?: boolean
  byHand?: boolean
  insuranceSum?: number
  insuranceSumPost?: number
  codSum?: number
  courierServices?: (
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  )[]
  postalDogovor?: string
  postalDate?: string
}
export type CalculateTariffDto = {
  contractId: string
  tariffRequest: TariffRequest
}
export type CalculateTariffDtoWrite = {
  contractId: string
  tariffRequest: TariffRequestWrite
}
export type AccountDto = {
  id?: string
  dlvLogin: string
  userId: string
  unchangeable: boolean
}
export type Order = {
  dlvId?: string
  factShipmentDate?: string
}
export type RegisterOrdersRequest = {
  warehouse?: string
  date?: string
  orders?: Order[]
  /** Возможные коды курьерских служб */
  courier_code?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
}
export type UploadReturnsRequest = {
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
}
export type StatReport = {
  dateFrom?: string
  dateTo?: string
  courierCodes?: (
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  )[]
  deliveryTypes?: (
    | '\u041A\u0443\u0440\u044C\u0435\u0440\u0441\u043A\u0430\u044F'
    | '\u041F\u0443\u043D\u043A\u0442\u044B \u0432\u044B\u0434\u0430\u0447\u0438'
  )[]
  countries?: string[]
  regions?: string[]
  cities?: string[]
}
export type EstimatedDeliveryTimeReportDto = {
  courierCodes?: (
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  )[]
  createTsFrom?: string
  createTsTo?: string
  actualDeliveryDateFrom?: string
  actualDeliveryDateTo?: string
  factShipmentDateFrom?: string
  factShipmentDateTo?: string
  estimatedDeliveryDateFrom?: string
  estimatedDeliveryDateTo?: string
  dlvLogin?: string
}
export type ImportZoneFiasDto = {
  file: string[]
}
export type PostalZoneFiasDto = {
  id?: string
  regionFias: string
  zipcode: string
  minDeliveryTime: number
  maxDeliveryTime: number
}
export type UpdateStatusResponse = {
  integrationId?: string
  deliveryServiceCode?: string
  sysType?: string
  order?: Order
  errorCode?: string
  errorText?: string
}
export type UpdateStatusRequest = {
  integrationId?: string
  deliveryServiceCode?: string
  sysType?: string
  order?: Order
}
export type PickPointTariffRequest = {
  dlvIdList?: string[]
}
export type LoginResult = {
  access_token?: string
  token_type?: string
  expires_in?: number
}
export type LoginRequestDto = {
  username: string
  password: string
  eternal?: boolean
}
export type FindPostamatsByFiasCodeRequest = {
  fiasCode?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  code?: string
  postCode?: string
  withClosed?: boolean
}
export type CourierSettingsDto = {
  courierCode: string
  oversizedMaxWeight?: number
  oversizedMaxDimensionSum?: number
  oversizedMaxLengthSide?: number
}
export type GenerateBarcodeRequest = {
  contractId?: string
  prefix?: string
  from?: number
  to?: number
  rpMonthNum?: number
  rpEndDate?: string
  params?: {
    [key: string]: object
  }
}
export type CancelShipmentRequest = {
  dlvId?: string
}
export type CancelCommitDto = {
  dlvIdList: string[]
}
export type StatusesUpdateRequest = {
  orderNums?: string[]
  orderIds?: string[]
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  /** Статус заказа. Движение заказа у курьерской службы */
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  message?: string
}
export type Address = {
  zipcode?: string
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  street?: string
  house?: string
  block?: string
  building?: string
  flat?: string
  replacement?: string
  fiasCode?: string
  kladrCode?: string
  cityFiasCode?: string
  regionFiasCode?: string
  areaFiasCode?: string
  settlementFiasCode?: string
}
export type AddressValidationRequest = {
  rawAddress?: string
  courierCodes: (
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  )[]
  dimension?: Dimension
  addressFrom?: Address
  addressTo?: Address
  deliveryType?: 'COURIER' | 'PVZ'
}
export type RawHistoryDto = {
  dlvId?: string
  dateTime?: string
  status?: string
  message?: string
  city?: string
  createTs?: string
}
export type PageResultRawHistoryDto = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: RawHistoryDto[]
}
export type GrantedAuthority = {
  authority?: string
}
export type User = {
  id?: string
  createTs?: string
  updateTs?: string
  name?: string
  checkTariffOnPost?: boolean
  username?: string
  authorities?: GrantedAuthority[]
}
export type PageResultUser = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: User[]
}
export type EntityResultUser = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: User
}
export type FixedAndNextPrice = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
}
export type TariffCourierServices = {
  priceForTrying?: number
  priceForPartialDelivery?: number
  priceForCheckContent?: number
  priceForPersonallyInHands?: number
  priceForVerify?: number
  priceForDangerousCargo?: number
  priceForReverse?: number
  priceForContactlessDelivery?: number
  priceForDayOffDelivery?: number
  priceForWaitingOnAddress?: number
  priceForTemperatureRegime?: number
  priceForLiftToDoorMap?: string
  priceForLiftToDoorManualMap?: string
  priceForDeliveryDateApprove?: number
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  priceLiftToDoorMap?: {
    [key: string]: FixedAndNextPrice
  }
  priceLiftToDoorManualMap?: {
    [key: string]: FixedAndNextPrice
  }
}
export type PricesForKg = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
}
export type TariffOptions = {
  priceForHalfKg?: number
  priceForFirstKg?: number
  priceForSecondKg?: number
  priceForThirdKg?: number
  priceForFourthKg?: number
  priceForFifthKg?: number
  priceForNextKg?: number
  deliveryTimeMin?: number
  deliveryTimeMax?: number
  returnPriceForHalfKg?: number
  returnPriceForFirstKg?: number
  returnPriceForNextKg?: number
  avia?: boolean
  courierServices?: TariffCourierServices
  pricesForKGMap?: {
    [key: string]: PricesForKg
  }
  pricesForKgMapString?: string
}
export type TariffOptionsWrite = {
  priceForHalfKg?: number
  priceForFirstKg?: number
  priceForSecondKg?: number
  priceForThirdKg?: number
  priceForFourthKg?: number
  priceForFifthKg?: number
  pricesForKgMap?: string
  priceForNextKg?: number
  deliveryTimeMin?: number
  deliveryTimeMax?: number
  returnPriceForHalfKg?: number
  returnPriceForFirstKg?: number
  returnPriceForNextKg?: number
  avia?: boolean
  courierServices?: TariffCourierServices
  pricesForKGMap?: {
    [key: string]: PricesForKg
  }
  pricesKgMap?: {
    [key: string]: PricesForKg
  }
  pricesForKgMapString?: string
}
export type CourierTariff = {
  id?: string
  createTs?: string
  updateTs?: string
  senderGeography?: Geography
  receiverGeography?: Geography
  options?: TariffOptions
}
export type CourierTariffWrite = {
  id?: string
  createTs?: string
  updateTs?: string
  senderGeography?: GeographyWrite
  receiverGeography?: GeographyWrite
  options?: TariffOptionsWrite
}
export type PageResultCourierTariff = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CourierTariff[]
}
export type PageResultCourierTariffWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CourierTariffWrite[]
}
export type EntityResultCourierTariff = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: CourierTariff
}
export type EntityResultCourierTariffWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: CourierTariffWrite
}
export type TariffImportProcess = {
  id?: string
  country?: string
  file?: string[]
  fileName?: string
  totalRows?: number
  importedRows?: number
  failedRows?: number
  lastProcessedRow?: number
  log?: string
  priority?: 'HIGH' | 'MEDIUM' | 'LOW'
  completed?: boolean
  createTs?: string
  completeTs?: string
}
export type PageResultTariffImportProcess = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: TariffImportProcess[]
}
export type EntityResultTariffImportProcess = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: TariffImportProcess
}
export type PageResultSystemSetting = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: SystemSetting[]
}
export type GeographySuggestionResult = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  list?: Geography[]
}
export type GeographySuggestionResultWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  list?: GeographyWrite[]
}
export type ShipmentRequestHistory = {
  dateTime?: string
  /** Статус заказа. Движение заказа у курьерской службы */
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  city?: string
  index?: number
  createTs?: string
  dlvId?: string
  message?: string
  cancelReason?: string
}
export type RawHistory = {
  statusDate?: string
  statusCode?: string
  statusMessage?: string
  statusCity?: string
  createTs?: string
}
export type RecordShipmentRequestHistory = {
  dlvId?: string
  history?: ShipmentRequestHistory[]
  rawHistory?: RawHistory[]
}
export type GetHistoryResultShipmentRequestHistory = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  list?: RecordShipmentRequestHistory[]
}
export type Customer = {
  name?: string
  company?: string
  phone?: string
  phone2?: string
  email?: string
  inn?: string
  warehouse?: string
  firstName?: string
  lastName?: string
  middleName?: string
}
export type SupplierInfo = {
  supplierINN?: string
  supplierPhone?: string
}
export type YandexParams = {
  warehouseFromId?: string
  requisiteId?: string
  senderId?: string
  intervalId?: string
  deliveryName?: string
  direction?: string
  tariffId?: string
  pickuppoint?: string
  ydSystem?: string
  deliveryUniqueName?: string
  cargoIntervalId?: string
}
export type SubPiece = {
  id?: string
  createTs?: string
  updateTs?: string
  description?: string
  internationalDescription?: string
  price?: number
  quantity?: number
  vatSum?: number
  vatType?:
    | 'VAT18'
    | 'VAT20'
    | 'VAT10'
    | 'VAT_CALC_20_120'
    | 'VAT_CALC_18_118'
    | 'VAT_CALC_10_110'
    | 'VAT0'
    | 'NO_VAT'
    | 'VATX'
  tnvedCode?: string
  vendorCode?: string
  marketPlaceCode?: string
  received?: boolean
  receivedQuantity?: number
  barcode?: string
  boxNeeded?: boolean
  countryOfOrigin?: string
  weight?: number
  marketplaceSkuId?: string
  controlIdMarks?: string
  controlIdMarksMask?: string
  jwUin?: string
  customData?: CustomData[]
  index?: number
  priceNoVat?: number
  summaryPrice?: number
  summaryPriceNoVat?: number
  vatPrice?: number
  skuCode?: string
  summaryVatPrice?: number
  externalId?: string
}
export type Place = {
  id?: string
  description?: string
  barcode?: string
  fboBarcode?: string
  dimension?: Dimension
  index?: string
  giveToCourierDate?: string
  stickerValue?: string
  stickerPdf?: string[]
  stickerZpl?: string
  litePacked?: boolean
  externalId?: string
  lpNumber?: string
  solutionCode?: string
  subPieces?: SubPiece[]
}
export type Pallet = {
  id?: string
  description?: string
  barcode?: string
  fboBarcode?: string
  dimension?: Dimension
  index?: string
  giveToCourierDate?: string
  stickerValue?: string
  stickerPdf?: string[]
  stickerZpl?: string
  placeList?: Place[]
}
export type ServiceParam = {
  courierServices?:
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  courierServiceCode?: string
}
export type Receiver = {
  name?: string
  company?: string
  phone?: string
  phone2?: string
  email?: string
  inn?: string
  warehouse?: string
  documentSeries?: string
  documentNumber?: string
  documentDate?: string
  firstName?: string
  lastName?: string
  middleName?: string
}
export type MegamarketDataDto = {
  merchantName?: string
  merchantId?: string
  warehouseId?: string
  deliveryId?: string
  fullData?: boolean
}
export type ShipmentRequest = {
  id?: string
  syncRecord?: ShipmentRequestSync
  sender?: Customer
  senderAddress?: Address
  receiverAddress?: Address
  supplierInfo?: SupplierInfo
  yandexParams?: YandexParams
  cashOnDeliveryType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  description?: string
  comment?: string
  docReturn?: boolean
  byHand?: boolean
  deliveryVATType?:
    | 'VAT18'
    | 'VAT20'
    | 'VAT10'
    | 'VAT_CALC_20_120'
    | 'VAT_CALC_18_118'
    | 'VAT_CALC_10_110'
    | 'VAT0'
    | 'NO_VAT'
    | 'VATX'
  deliveryType?: 'FAST' | 'CHEAP' | 'CONTRACT' | 'COURIER'
  startPickUp?: string
  endPickUp?: string
  factShipmentDate?: string
  estimatedDeliveryDate?: string
  insuranceSum?: number
  deliveryPrice?: number
  cashOnDelivery?: number
  freeDeliveryFromSum?: number
  baseTariffPrice?: number
  baseTariffDiscountPrice?: number
  contractId?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  /** Возможные коды курьерских служб */
  courierCode2?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  litePacked?: boolean
  courierProduct?: string
  courierProductChangeHistory?: string
  postamatNum?: string
  postamatNumChangeHistory?: string
  codParams?: CodParams
  registerNum?: string
  additionalInfo?: string
  places?: Place[]
  pallets?: Pallet[]
  serviceParams?: ServiceParam[]
  planDeliveryDate?: string
  planDeliveryDateChangeHistory?: string
  planDeliveryTimeStart?: string
  planDeliveryTimeEnd?: string
  planDeliveryDateChangeReason?: string
  cargoPickUpDate?: string
  primaryContractId?: string
  liteShipment?: 'LITE_SHIPMENT_1' | 'LITE_SHIPMENT_2'
  prevShipment?: string
  payerType?: 'SENDER' | 'RECEIVER'
  floor?: number
  customData?: {
    [key: string]: string
  }
  hourOfDeliveryTimeStart?: string
  hourOfDeliveryTimeEnd?: string
  servicesWithPrices?: {
    [key: string]: number
  }
  receiverAll?: Receiver
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  megamarketData?: MegamarketDataDto
  placesDimension?: Dimension[]
}
export type PageResultShipmentRequest = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: ShipmentRequest[]
}
export type EntityResultShipmentRequest = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: ShipmentRequest
}
export type EntityResult = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  /** Сущность, содержащая данные ответа */
  entity?: object
}
export type PrintTemplate = {
  id?: string
  code?: string
  name?: string
  content?: string[]
}
export type PageResultPrintTemplate = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: PrintTemplate[]
}
export type EntityResultPrintTemplate = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: PrintTemplate
}
export type PageResultPlace = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: Place[]
}
export type PageResult = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: object[]
}
export type EntityResultPlace = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: Place
}
export type EntityResultShipmentRequestHistory = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: ShipmentRequestHistory
}
export type PageResultShipmentRequestHistory = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: ShipmentRequestHistory[]
}
export type EntityResultGeography = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: Geography
}
export type EntityResultGeographyWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: GeographyWrite
}
export type PageResultGeography = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: Geography[]
}
export type PageResultGeographyWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: GeographyWrite[]
}
export type CourierSettings = {
  id?: string
  oversizedMaxWeight?: number
  oversizedMaxDimensionSum?: number
  oversizedMaxLengthSide?: number
}
export type CourierCompany = {
  id?: string
  name?: string
  apiUrl?: string
  testApiUrl?: string
  /** Возможные коды курьерских служб */
  code?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  courierSettings?: CourierSettings
  statusUpdateTs?: string
  statusUpdateTimeSec?: number
  returnsUpdateTs?: string
  useProductionCalendar?: boolean
  legalName?: string
}
export type CourierProduct = {
  id?: string
  courierCompany?: CourierCompany
  name?: string
  code?: string
  international?: boolean
  docReturn?: boolean
  pvzProduct?: boolean
  maxWeight?: number
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
}
export type PageResultCourierProduct = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CourierProduct[]
}
export type EntityResultCourierProduct = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: CourierProduct
}
export type EntityResultCourierCompany = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: CourierCompany
}
export type PageResultCourierCompany = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CourierCompany[]
}
export type BarcodePool = {
  id?: string
  name?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  active?: boolean
}
export type ContractParam = {
  id?: string
  paramKey?:
    | 'INN'
    | 'KPP'
    | 'ADDITIONAL_POSTAL_ABONENT_BOX_NUM'
    | 'ADDITIONAL_POSTAL_TECH_ZIPCODE'
    | 'ADDITIONAL_POSTAL_TOP_COMPANY'
    | 'ADDITIONAL_POSTAL_BOTTOM_COMPANY'
    | 'ADDITIONAL_POSTAL_SENDER_ADDRESS'
    | 'ADDITIONAL_POSTAL_SENDER_COMPANY'
    | 'STOCK'
    | 'PRODUCT_TYPE'
    | 'SERVICE_CODE'
    | 'SELLER_NAME'
    | 'LEGAL_NAME'
    | 'ESPP_NUM'
    | 'codName'
    | 'codBankName'
    | 'codBik'
    | 'codCorrespondAccount'
    | 'codInn'
    | 'codKpp'
    | 'codAccountNumber'
    | 'SYNC_DATE'
    | 'SEND_ON_COMMIT'
    | 'YVES_ROCHER'
    | 'postalEmail'
    | 'postalEmailCC'
    | 'simplePostalTariff'
    | 'postalEmailOrganization'
    | 'SHIPMENT_SORT'
    | 'YANDEX_WAREHOUSE_TO_ID'
    | 'YANDEX_API_KEYS'
    | 'YANDEX_CLIENT_ID'
    | 'YANDEX_TEST_SENDER_ID'
    | 'COURIER_NAME'
    | 'CAR_MODEL'
    | 'CAR_NUMBER'
    | 'DPD_REGULAR_NUM'
    | 'DPD_PICK_POINT_NUM'
    | 'DPD_LATVIA_API_TOKEN'
    | 'DPD_LATVIA_PARCELSHOP_ID'
    | 'DPD_LATVIA_STATUS_URL'
    | 'SENDER_POSTAMAT_NUM'
    | 'FORCE_ORDER_PREFIX'
    | 'YANDEX_AUTO_PARCEL_CONFIRM'
    | 'YANDEX_PARCEL_RECEIVER_EMAILS'
    | 'YANDEX_PARCEL_ERROR_EMAILS'
    | 'OZON_DELIVERY_METHODS'
    | 'OZON_REGISTER_EMAILS'
    | 'OZON_PICKUP'
    | 'SMS_NOTIFICATION'
    | 'BACK_PAYMENT_DETAILS'
    | 'INSURANCE_TARIFF_PERCENT'
    | 'FIX_RAISE_INSURANCE'
    | 'COD_TARIFF_PERCENT'
    | 'FIX_RAISE_COD'
    | 'EXTERNAL_CONTRACT_NUMBER'
    | 'COURIER_CONTRACT_NUMBER'
    | 'COURIER_CONTRACT_DATE'
    | 'STANDART_RAISE_PRICE_PROCENT'
    | 'FIX_RAISE_PRICE'
    | 'PICK_POINT_AGGREGATOR_NAME'
    | 'GRASTIN_DELIVERY_BARCODE'
    | 'KAK2C_LITE_CONTRACT'
    | 'STRIZH_SETTING_ID'
    | 'BB_PICKUP_ID'
    | 'BASE_TARIFF'
    | 'BASE_TARIFF_PRICE_PERCENT'
    | 'BASE_TARIFF_PRICE_FIX'
    | 'CALL_NOTIFICATION'
    | 'TO_DOOR'
    | 'CHECK_COD'
    | 'CHECK_INSURANCE'
    | 'NON_CHECK_CDEK_CITY'
    | 'NON_CHECK_DIMENSION'
    | 'ONLY_CARD'
    | 'SUMM_FOR_FREE_DELIVERY'
    | 'PLUS_MIN_DELIVERY_DAY'
    | 'PLUS_MAX_DELIVERY_DAY'
    | 'USER_NAME'
    | 'USER_PASSWORD'
    | 'EDRPOU_CODE'
    | 'SENDER_COUNTER_PARTY_REF'
    | 'SENDER_CITY_REF'
    | 'SENDER_CONTACT_PERSON'
    | 'SENDER_ADDRESS_REF'
    | 'SENDER_DEFAULT_COST'
    | 'SENDER_PHONE'
    | 'SENDER_NAME'
    | 'SENDER_WAREHOUSE_ID'
    | 'DISABLED_TARIFFS'
    | 'SENDER_ADDRESS_CITY'
    | 'SENDER_ADDRESS_STREET'
    | 'SENDER_ADDRESS_HOUSE'
    | 'INTERNATIONAL'
    | 'TAX_IDENTIFICATION_NUMBER'
    | 'UPS_AUTH_TOKEN'
    | 'UPS_ACCESS_LICENSE_NUMBER'
    | 'UPS_API_VERSION'
    | 'ONLY_CYRILLIC_SYMBOLS_IN_FIO'
    | 'X5_POST_PARTNER_LOCATION_ID'
    | 'X5_POST_PARTNER_CARGO_ID'
    | 'TIME_CUTOFF'
    | 'LOGSIS_BARCODE_PREFIX'
    | 'YANDEXV2_TRY_DIMENSIONS'
    | 'NEW_WMS'
    | 'RETURNS_LOAD_DATE'
    | 'WEBSITE'
    | 'CSE_DEPARTMENTS'
    | 'CSE_PROJECTS'
    | 'FIRST_MILE_WAREHOUSE_ID'
    | 'SHIPPER_ACCOUNT_NUMBER'
    | 'DIRECT_CONTRACT'
    | 'EXPORT_SKU_CODE'
    | 'CLIENT_NUMBER'
    | 'CSE_LOGIN_PARTIAL'
    | 'CSE_PASS_PARTIAL'
    | 'YANDEX_PLATFORM_STATION_ID'
    | 'YANDEX_MARKET_CAMPAIGN_ID'
    | 'TARIFF_GROUP'
    | 'TARIFF_DISABLED_COD'
    | 'BOX_NEEDED_BY_VOLUME'
    | 'BOX_NEEDED_BY_PERCENT'
    | 'OVERSIZED_CARGO_BY_PERCENT'
    | 'OVERSIZED_CARGO_FIXED'
    | 'DISABLE_INSURANCE_SUM'
    | 'AUTH_TOKEN'
    | 'MEGAMARKET_BARCODE_PREFIX'
    | 'QUBY_WAREHOUSE_LOCATION_ID'
    | 'WAREHOUSE_CODE_TO'
  paramValue?: string
}
export type Contract = {
  id?: string
  createTs?: string
  updateTs?: string
  courierCompany?: CourierCompany
  icn?: string
  login?: string
  password?: string
  ownerCompanyName?: string
  sellerPrefix?: string
  courierProduct?: CourierProduct
  showTariffSource?: 'PUBLIC_API' | 'PRIVATE_API' | 'DATABASE'
  barcodePool?: BarcodePool
  imitateCourierCompany?: boolean
  active?: boolean
  contractParams?: ContractParam[]
  exportSkuCodeType?: 'EXTERNAL_ID' | 'VENDOR_CODE'
}
export type PageResultContract = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: Contract[]
}
export type EntityResultContract = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: Contract
}
export type CdekCityCode = {
  id?: string
  cityCode?: number
  nalSumLimit?: string
  postCodeList?: string
  geography?: Geography
  updateDate?: string
}
export type CdekCityCodeWrite = {
  id?: string
  cityCode?: number
  nalSumLimit?: string
  postCodeList?: string
  geography?: GeographyWrite
  updateDate?: string
}
export type PageResultCdekCityCode = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CdekCityCode[]
}
export type PageResultCdekCityCodeWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: CdekCityCodeWrite[]
}
export type EntityResultCdekCityCode = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: CdekCityCode
}
export type EntityResultCdekCityCodeWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: CdekCityCodeWrite
}
export type Account = {
  id?: string
  createTs?: string
  updateTs?: string
  dlvLogin?: string
  unchangeable?: boolean
  user?: User
  enabled?: boolean
  username?: string
  authorities?: GrantedAuthority[]
  credentialsNonExpired?: boolean
  accountNonExpired?: boolean
  accountNonLocked?: boolean
}
export type PageResultAccount = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: Account[]
}
export type EntityResultAccount = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: Account
}
export type ShipmentDto = {
  /** Идентификатор заказа в формате UUID */
  DlvId?: string
  /** Номер заказа */
  Num?: string
  /** Номер заказа в курьерской системе (трек) */
  CourierNum?: string
  Client?: string
  Warehouse?: string
  Places?: PlaceDto[]
}
export type ReturnDocDto = {
  /** Возможные коды курьерских служб */
  CourierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  ConsDocNum?: string
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  Shipments?: ShipmentDto[]
}
export type ReturnsDocsDto = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  ReturnsDocs?: ReturnDocDto[]
}
export type Postamat = {
  geography?: Geography
  address?: string
  postCode?: string
  metroStation?: string
  latitude?: string
  longitude?: string
  code?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  description?: string
  maxSize?: string
  maxWeight?: number
  minWeight?: number
  workTime?: string
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  updateTimestamp?: string
  closed?: boolean
  courierRef?: string
  type?: 'Postamat' | 'PVZ' | 'CASHIER_ISSUE_POINT'
  courierPostamatType?: string
  postOfficeNumber?: string
  maxCodSum?: number
  additionalCourierName?: string
  courierServices?: (
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  )[]
  weightCoef?: number
}
export type PostamatWrite = {
  geography?: GeographyWrite
  address?: string
  postCode?: string
  metroStation?: string
  latitude?: string
  longitude?: string
  code?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  description?: string
  maxSize?: string
  maxWeight?: number
  minWeight?: number
  workTime?: string
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  updateTimestamp?: string
  closed?: boolean
  courierRef?: string
  type?: 'Postamat' | 'PVZ' | 'CASHIER_ISSUE_POINT'
  courierPostamatType?: string
  postOfficeNumber?: string
  maxCodSum?: number
  additionalCourierName?: string
  courierServices?: (
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  )[]
  weightCoef?: number
}
export type PageResultPostamat = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: Postamat[]
}
export type PageResultPostamatWrite = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: PostamatWrite[]
}
export type PostalZoneFias = {
  id?: string
  createTs?: string
  updateTs?: string
  regionFias?: string
  zipcode?: string
  minDeliveryTime?: number
  maxDeliveryTime?: number
}
export type PageResultPostalZoneFias = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: PostalZoneFias[]
}
export type EntityResultPostalZoneFias = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  entity?: PostalZoneFias
}
export type SortObject = {
  empty?: boolean
  sorted?: boolean
  unsorted?: boolean
}
export type OrderInfo = {
  num?: string
  date?: string
  deliveryDate?: string
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  courierNum?: string
  weight?: number
  receiverAddress?: string
  deliveryPrice?: number
  insuranceSum?: number
  agentPrice?: number
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  cod?: boolean
}
export type PageableObject = {
  sort?: SortObject
  offset?: number
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  unpaged?: boolean
}
export type PageOrderInfo = {
  totalElements?: number
  totalPages?: number
  sort?: SortObject
  first?: boolean
  last?: boolean
  size?: number
  content?: OrderInfo[]
  number?: number
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type Pageable = {
  page?: number
  size?: number
  sort?: string[]
}
export type ExchangeLogLine = {
  id?: string
  url?: string
  requestDate?: string
  responseCode?: string
  methodType?: string
  method?: string
  login?: string
  request?: string
  response?: string
}
export type ExchangeLog = {
  exchangeLogs?: ExchangeLogLine[]
}
export type ShipmentInfoResponse = {
  dlvId?: string
  num?: string
  courierNum?: string
  commitError?: string
  warehouseName?: string
  createTs?: string
  /** Статус заказа. Движение заказа у курьерской службы */
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  /** Внутреннее состояние сущности ДЛВ. Возможные значения:
    
    ACCEPTED - Принято,
    
    UPDATING - Выгружено в КС. Обновляется по статусам,
    
    CANCELING - Отменяется,
    
    ABORTED - Обработка отправления закончена. Финальный статус,
    
    UPDATING_SUSPENDED - Обновление приостановлено
     */
  dlvStatus?:
    | 'ACCEPTED'
    | 'UPDATING'
    | 'CANCELING'
    | 'ABORTED'
    | 'UPDATING_SUSPENDED'
  /** Возможные коды курьерских служб */
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  dlvExchangeLog?: ExchangeLog
  courierExchangeLog?: ExchangeLog
}
export type PageShipmentInfoResponse = {
  totalElements?: number
  totalPages?: number
  sort?: SortObject
  first?: boolean
  last?: boolean
  size?: number
  content?: ShipmentInfoResponse[]
  number?: number
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type InfoResponse = {
  content?: ShipmentInfoResponse[]
}
export type ContractResponse = {
  contractId?: string
  dlvLogin?: string
  courierCode?: string
  courierProduct?: string
}
export type PageResultContractResponse = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: ContractResponse[]
}
export type BarcodePoolInfo = {
  barcodePool?: BarcodePool
  barcodePrefix?: string
  freeBarcodesCount?: number
}
export type PageResultBarcodePoolInfo = {
  /** Флаг успешности операции */
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
  pagination?: Pagination
  list?: BarcodePoolInfo[]
}

import React, { ReactNode } from 'react'
import { QNumberInner } from '@/components/QForm/QField/QInput/QNumber/QNumberInner'
import { omit } from 'lodash'
import { QNumberPropsType } from '@/components/QForm/QField/QInput/QNumber/QNumber.types'
import { QForm } from '@/components/QForm/QForm'

type QMoneyProps = QNumberPropsType & {
  unit: ReactNode
}
export const QUnit = (props: QMoneyProps) => {
  const { name, withWrapper, isHardName, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        withWrapper,
        isHardName,
      }}
    >
      <QNumberInner
        isHideStepper
        precision={0}
        rightElement={{
          content: props.unit,
        }}
        {...omit(otherProps, ['onChange', 'onInvalid', 'name'])}
      />
    </QForm.Field>
  )
}

import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { receiverDestinationTypeOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { ReceiverDestinationType } from '@/api/generated/qubyApiJava'

type QSelectReceiverDestinationTypePropsType = Omit<
  QSelectPropsType<IOption<ReceiverDestinationType>, ReceiverDestinationType>,
  'options'
>
export const QSelectReceiverDestinationType = (
  props: QSelectReceiverDestinationTypePropsType,
) => {
  const [t] = useTranslation()
  const options = receiverDestinationTypeOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

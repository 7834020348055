import React from 'react'
import { JSONTree } from 'react-json-tree'

type PrintRProps = {
  /**
   * Объект для рекурсивного вывода на экран
   */
  object: Record<string, unknown> | Record<string, unknown>[] | null | undefined
  /**
   * Заголовок
   */
  title?: React.ReactNode
  /**
   * Дублирует вывод в консоль
   */
  isLogInConsole?: boolean
  /**
   * Сворачивается в икону для показа из под спойлера
   */
  isSpoiler?: boolean
  /**
   * Скрыть `root` уровень
   */
  hideRoot?: boolean
  /**
   * Схлопнуть вложенность объекта при открытии.
   *
   * По умолчанию всё дерево раскрыто.
   */
  isDefaultCollapsedTree?: boolean
}

export const PrintR: React.FunctionComponent<PrintRProps> = ({
  isSpoiler = false,
  title,
  object,
  isLogInConsole = false,
  hideRoot = true,
  isDefaultCollapsedTree = false,
}) => {
  const [isHidden, setIsHidden] = React.useState(true)

  if (!object) return <>Вместо объекта пришёл {typeof object}</>

  if (isSpoiler && isHidden) {
    return (
      <button
        type="button"
        onClick={() => {
          setIsHidden(false)
        }}
      >
        {title} ↓
      </button>
    )
  }

  if (isLogInConsole) {
    // eslint-disable-next-line no-console
    console.groupCollapsed(`PrintR ${title}`)

    // eslint-disable-next-line no-console
    console.trace()

    // eslint-disable-next-line no-console
    console.groupEnd()
  }

  return (
    <div>
      {(title || isSpoiler) && (
        <h2>
          {title ?? 'Вывод объекта'}:{' '}
          {isSpoiler && !isHidden && (
            <button
              type="button"
              onClick={() => {
                setIsHidden(true)
              }}
            >
              скрыть ↑
            </button>
          )}
        </h2>
      )}
      {typeof object === 'undefined' ?
        <div>Пустовато…</div>
      : <JSONTree
          shouldExpandNodeInitially={() => !isDefaultCollapsedTree}
          data={object}
          {...{ hideRoot }}
          invertTheme={false}
        />
      }
    </div>
  )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import {
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { omit } from 'lodash'
import { QNumberPropsType } from './QNumber.types'
import { colors } from '@/theme/colors'
import NP from 'number-precision'

export const QNumberCore = (props: QNumberPropsType) => {
  const {
    isHideStepper = false,
    leftAddon,
    rightAddon,
    leftElement,
    rightElement,
    multiplier = 1,
    value,
    isDisabled,
    isInvalid,
  } = props

  const isShowStepper = !isHideStepper

  const withMultiplier = useCallback(
    (v: string | number) => {
      return NP.times(Number(v), multiplier)
    },
    [multiplier],
  )
  const withoutMultiplier = useCallback(
    (v: string | number) => {
      return NP.divide(Number(v), multiplier)
    },
    [multiplier],
  )

  const innerRef = useRef<NodeJS.Timeout>()

  const [innerValue, setInnerValue] = useState(
    typeof value === 'undefined' ? '' : String(withMultiplier(value)),
  )
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (Number(value || 0) !== Number(withoutMultiplier(innerValue) || 0)) {
        setInnerValue(
          typeof value === 'undefined' ? '' : (
            String(Number(withMultiplier(value)))
          ),
        )
      }
    }, 1000)
  }, [value, innerValue, multiplier, withMultiplier, withoutMultiplier])

  const { onChangeValue } = useQFieldContext()

  const changeRef = useRef<NodeJS.Timeout>()
  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus

  useEffect(() => {
    return () => {
      clearInterval(innerRef.current)
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (!innerValue && innerValue === '') {
        onChangeValue(undefined)
        setInnerValue('')
      }
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [innerValue, onChangeValue, propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (!Number(innerValue)) {
        setTimeout(() => {
          e.target.select()
        }, 80)
      } else {
        setInnerValue(String(Number(innerValue)))
      }
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [innerValue, propsOnFocus],
  )
  const onChange = useCallback(
    (stringValue: string, numberValue: number) => {
      clearTimeout(changeRef.current)
      clearTimeout(innerRef.current)
      setInnerValue(stringValue)
      changeRef.current = setTimeout(() => {
        const n = Number(numberValue)
        const v = withoutMultiplier(n)
        onChangeValue(v || 0)
      }, 300)
    },
    [onChangeValue, withoutMultiplier],
  )

  return (
    <InputGroup>
      {leftAddon?.content && (
        <InputLeftAddon color={isDisabled ? colors.neutral['500'] : undefined}>
          {leftAddon.content}
        </InputLeftAddon>
      )}
      {leftElement?.content && (
        <InputLeftElement
          color={isDisabled ? colors.neutral['500'] : undefined}
        >
          {leftElement.content}
        </InputLeftElement>
      )}

      <ComponentPropsDecorator onBlur={onBlur} onFocus={onFocus}>
        <NumberInput
          w={'100%'}
          {...{
            onChange,
            onFocus,
            onBlur,
          }}
          {...omit(props, [
            'value',
            'isHideStepper',
            'rightAddon',
            'leftAddon',
            'rightElement',
            'leftElement',
            'onChange',
            'onFocus',
            'onBlur',
            'onChangeValue',
            'isRequired',
            'required',
            'tooltipText',
          ])}
          {...{
            value: innerValue,
          }}
        >
          <NumberInputField
            style={
              isInvalid ?
                {
                  borderColor: colors.error[700],
                  boxShadow: `0 0 0 1px ${colors.error[700]}`,
                }
              : undefined
            }
            placeholder={props.placeholder}
            borderLeftRadius={leftAddon ? 0 : undefined}
            borderRightRadius={rightAddon ? 'none' : undefined}
          />
          {isShowStepper && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </NumberInput>
      </ComponentPropsDecorator>
      {rightElement?.content && (
        <InputRightElement
          color={isDisabled ? colors.neutral['500'] : colors.neutral['500']}
        >
          {rightElement.content}
        </InputRightElement>
      )}
      {rightAddon?.content && (
        <InputRightAddon color={isDisabled ? colors.neutral['500'] : undefined}>
          {rightAddon.content}
        </InputRightAddon>
      )}
    </InputGroup>
  )
}

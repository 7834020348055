import React, { useCallback, useEffect, useRef, useState } from 'react'
import Input from '@/components/atoms/Input/Input'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import { InputProps } from '@chakra-ui/react'
import omit from 'lodash/omit'

export const QStringCore = (props: QFieldCorePopsType & InputProps) => {
  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined
  const propsValue = props.value
  const innerRef = useRef<NodeJS.Timeout>()
  const [innerValue, setInnerValue] = useState(propsValue || '')
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || '')
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const propsOnChange = props.onChange
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setInnerValue(v)
      if (onChangeValue || propsOnChange) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(v)
          propsOnChange && propsOnChange(e)
        }, 300)
      }
    },
    [onChangeValue, propsOnChange],
  )
  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [propsOnFocus],
  )

  useEffect(() => {
    return () => {
      clearInterval(innerRef.current)
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])
  return (
    <Input
      {...{
        onChange,
        onFocus,
        onBlur,
        ...(props.mask ?
          {
            maskProps: {
              mask: props.mask,
            },
          }
        : {}),
      }}
      {...{
        value: innerValue,
      }}
      {...omit(props, [
        'mask',
        'onChangeValue',
        'onChange',
        'onBlur',
        'onFocus',
        'value',
        'isRequired',
        'required',
        'tooltipText',
      ])}
    />
  )
}

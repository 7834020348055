import { getIn } from 'formik'
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { QGroupContext } from './QGroup.context'
import { QFormGroupPropsType } from './QGroup.types'
import { useQFormContext } from '@/components/QForm/useQFormContext'
import { useInView } from 'framer-motion'

export const QGroup: React.FC<QFormGroupPropsType> = ({
  children,
  name,
  isHardName,
  index,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const isInView = useInView(ref)

  const parentGroup = useContext(QGroupContext)
  const { values } = useQFormContext()
  const resultName =
    isHardName || !parentGroup.hasProvider ?
      name
    : [parentGroup.name, name].join('.')

  const value = getIn(values, resultName)

  const [lastSize, setLastSize] = useState<{ height: number; width: number }>({
    width: 100,
    height: 100,
  })
  useEffect(() => {
    setLastSize({
      height: ref.current?.getBoundingClientRect()?.height || 100,
      width: ref.current?.getBoundingClientRect()?.width || 100,
    })
  }, [isInView])

  return (
    <div
      data-test-class={'form_group'}
      data-test-group-name={name}
      data-test-full-group-name={resultName}
      ref={ref}
      style={{
        opacity: isInView ? 1 : 0.1,
        transition: '.142s ease-in',
      }}
    >
      {isInView ?
        <QGroupContext.Provider
          value={{
            hasProvider: true,
            name: resultName,
            isHardName,
            value,
            parent: parentGroup.hasProvider ? parentGroup : undefined,
            index,
          }}
        >
          {children}
        </QGroupContext.Provider>
      : <div
          style={{
            height: `${Math.round(lastSize.height || 0)}px`,
            width: `${Math.round(lastSize.width || 0)}px`,
          }}
        />
      }
    </div>
  )
}

/**
 * Это архитектурный файл.
 * В нём перечислены сущности и их связи с эндпоинтами
 *
 * Эндпоинты – следует использовать, через него, а не напрямую из сгенерированной апихи.
 * Это упрощает копирование компонентов и рефакторинг.
 */

import { qubyApiPhp } from '@/api/generated/qubyApiPhp'
import { AbstractEntities, Endpoints, Entity } from '@/_entities/Entities.types'
import { qubyApiPhpEgo } from '@/api/qubyApiPhpEgo'
import { qubyApiEgo } from '@/api/qubyApiEgo'
import { qubyApiEgoDlv } from '@/api/qubyApiEgoDlv'
import { qubyApiJavaFixedTypes } from '@/api/qubyApiJavaFixedTypes'

/**
 * Сейчас здесь единственный список энпеоинтов.
 * На данный момент следует использовать только его и сосредоточиться на выпиливании Java
 * Позже апихи могут быть поделены на internal, external и public (админ, юзер и без авторизации – соответственно)
 */
const endpointsList = qubyApiPhp.endpoints
const endpointsListEgo = qubyApiEgo.endpoints
const endpointsListDlv = qubyApiEgoDlv.endpoints

const endpointsListJava = qubyApiJavaFixedTypes.endpoints

/**
 * Класс сущностей нашего проекта
 *
 */
export class Entities implements AbstractEntities {
  static Location = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getById = endpointsList.getInternalLocationsById
      static getList = endpointsList.getInternalLocationsList
      static create = endpointsList.postInternalLocations
      static update = qubyApiPhpEgo.endpoints.putInternalLocations
      static getCanHaveQubyWarehouse =
        endpointsList.getInternalCanHaveQubyWarehouse
    }
  }
  static Client = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getById = endpointsList.getClientsById
      static getList = endpointsList.getInternalClientsList
      static getSettingsById = endpointsList.getInternalClientSettingsById
    }
  }
  static Shipment = class implements Entity {
    static Endpoints = class implements Endpoints {
      static create = endpointsList.postInternalShipments
      static update = endpointsList.patchInternalShipments
      static getById = endpointsList.getInternalShipmentsById
      static getList = endpointsList.getInternalShipmentsList
    }
  }
  static RunRequest = class implements Entities {
    static Endpoints = class implements Endpoints {
      static getList = endpointsList.getInternalRunRequestsList
      static getById = endpointsList.getInternalRunRequestsById
      static update = endpointsListEgo.patchInternalRunRequest
      static create = endpointsListEgo.createInternalRunRequestsList
    }
  }
  static ShipmentDlv = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsListJava.getEntities3
    }
  }
  static ReturnsDlv = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsListDlv.getPlanReturnsList
    }
  }
  static Goods = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getById = endpointsList.getInternalGoodsById
      static getList = endpointsList.getInternalGoodsList
      static create = endpointsList.postInternalGoods
    }
  }
  static Invoices = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getById = endpointsList.postInternalInvoices
      static getList = endpointsList.getInternalInvoicesList
    }
  }
  static Vehicle = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getById = endpointsList.getInternalVehiclesById
      static getList = endpointsList.getInternalVehiclesList
      static create = endpointsList.postInternalVehicles
      static update = endpointsList.putInternalVehiclesById
    }
  }
  static Driver = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getById = endpointsList.getInternalDriversById
      static getList = endpointsList.getInternalDriversList
      static create = endpointsList.postInternalDrivers
      static update = endpointsList.putInternalDriversById
    }
  }
  static Monitoring = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsListDlv.getMonitoringList
      static getById = endpointsListDlv.getMonitoringById
    }
  }
  static PlanningRoutes = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsList.getInternalRoutesList
      static getById = endpointsList.getInternalRouteById
    }
  }
  static DeliveryServices = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsList.getInternalDeliveryServicesList
      static getById = endpointsList.getInternalDeliveryServicesById
      static create = endpointsList.postInternalDeliveryServices
      static update = endpointsList.putInternalDeliveryServicesById
      static delete = endpointsList.deleteInternalDeliveryServicesById
    }
  }
  static PartnerWarehouses = class implements Entity {
    static Endpoints = class implements Endpoints {
      static create = endpointsListEgo.createPartnerWarehouse
      static update = endpointsListEgo.updatePartnerWarehouse
    }
  }
  static StatisticsOrderRevenue = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsList.getInternalStatisticsRevenue
    }
  }
  static StatisticsSD = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsList.getInternalStatisticsDeliveryServices
    }
  }

  static Routes = class implements Entity {
    static Endpoints = class implements Endpoints {
      static getList = endpointsList.getInternalRoutesList
    }
  }
}

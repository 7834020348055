import styled from 'styled-components'
import { colors } from '@/theme/colors'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 39, 62, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1399;
`

export const ContentModal = styled.div`
  display: grid;
  /* min-width: 500px; */
  background-color: #fff;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 60px rgba(154, 171, 215, 0.12);

  .plank {
    width: 100%;
    height: 4px;

    background-color: ${colors.accent[600]};
  }
`

export const ModalHeader = styled.div`
  display: grid;
  padding: 16px 24px;
  grid-gap: 16px;
  align-items: center;
  grid-template-columns: 1fr max-content;
`

export const ModalContent = styled.div`
  padding: 24px;
`

export const ModalButtons = styled.div`
  padding: 16px 24px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: end;
  grid-gap: 16px;
`

import { createContext } from 'react'
import { SelectAsyncComponentContextValueType } from '@/components/atoms/SelectAsync/SelectAsync.types'

const defaultValue: SelectAsyncComponentContextValueType = {
  isHasProvider: false,
  onClickChange: () => undefined,
  onClickClear: () => undefined,
}
export const SelectAsyncComponentContext =
  createContext<SelectAsyncComponentContextValueType>(defaultValue)

import React, { ReactNode } from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { InputProps } from '@chakra-ui/react'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QSwitchCore } from '@/components/QForm/QField/QSwitch/QSwitchCore'

export const QSwitchInner = (props: InputProps & { children?: ReactNode }) => {
  const { name, propsFromYup } = useQFieldContext()
  const [field, meta] = useField(name)

  return (
    <ComponentPropsDecorator {...propsFromYup} {...props}>
      <QSwitchCore
        {...{
          ...field,
          isInvalid: Boolean(meta.error),
        }}
      />
    </ComponentPropsDecorator>
  )
}

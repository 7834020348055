import * as React from 'react'
import { FC, ReactElement, useContext } from 'react'
import { QGroupListContext } from './QGroupList.context'
import { QGroupContext } from '@/components/QForm/QGroup/QGroup.context'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { Button } from '@chakra-ui/react'

const AddButton: FC<{
  children?: ReactElement
  onAdd?: (value: unknown) => unknown
  addValue?: unknown
}> = ({
  children = <Button variant={'secondary'}>Добавить</Button>,
  onAdd = (value) => value,
  addValue = {},
}) => {
  const { arrayHelpers, hasProvider } = useContext(QGroupListContext)
  if (!hasProvider) {
    return <>кнопка работает только внутри контекста списков групп</>
  }

  return (
    <ComponentPropsDecorator
      onClick={() => {
        arrayHelpers?.push(onAdd(addValue))
      }}
    >
      {children}
    </ComponentPropsDecorator>
  )
}
const RemoveButton: FC<{
  children?: ReactElement
  onRemove?: (information: { index: number; value: unknown }) => void
}> = ({
  children = <Button variant={'secondary'}>Удалить</Button>,
  onRemove = () => undefined,
}) => {
  const { arrayHelpers } = useContext(QGroupListContext)
  const { index, hasProvider, value } = useContext(QGroupContext)
  if (typeof index !== 'number' || !hasProvider) {
    return <>кнопка работает только внутри контекста списков групп</>
  }
  return (
    <ComponentPropsDecorator
      onClick={() => {
        onRemove({
          index,
          value,
        })
        arrayHelpers?.remove(index)
      }}
    >
      {children}
    </ComponentPropsDecorator>
  )
}
const CopyButton: FC<{
  children?: ReactElement
  onCopy?: (value: unknown) => unknown
}> = ({
  children = <Button variant={'secondary'}>копировать</Button>,
  onCopy = (v) => v,
}) => {
  const { arrayHelpers, hasProvider: hasGroupListProvider } =
    useContext(QGroupListContext)
  const { value, hasProvider } = useContext(QGroupContext)
  if (!hasGroupListProvider || !hasProvider) {
    return <>кнопка работает только внутри контекста списков групп</>
  }

  return (
    <ComponentPropsDecorator
      onClick={() => {
        arrayHelpers?.push(onCopy(value))
      }}
    >
      {children}
    </ComponentPropsDecorator>
  )
}
export const QGroupListButtons = {
  Add: AddButton,
  Copy: CopyButton,
  Remove: RemoveButton,
}

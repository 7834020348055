import { Variants } from 'framer-motion'

export const variants: { ['modal']: Variants } = {
  modal: {
    open: {
      opacity: 1,
      display: 'flex',
    },
    close: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  },
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { QFieldCorePopsType } from '@/components/QForm/QField/QField.types'
import DatePicker from '@/components/atoms/DatePicker'
import omit from 'lodash/omit'
import { DatePickerProps } from '@/types/global'

export const QDateCore = (
  props: QFieldCorePopsType & DatePickerProps & { placeholder?: string },
) => {
  const onChangeValue =
    'onChangeValue' in props ? props.onChangeValue : undefined
  const propsValue = props.value
  const innerRef = useRef<NodeJS.Timeout>()

  const [innerValue, setInnerValue] = useState(propsValue || '')
  useEffect(() => {
    clearTimeout(innerRef.current)
    innerRef.current = setTimeout(() => {
      if (propsValue !== innerValue) {
        setInnerValue(propsValue || '')
      }
    }, 1000)
    return () => {
      clearTimeout(innerRef.current)
    }
  }, [propsValue, innerValue])
  const changeRef = useRef<NodeJS.Timeout>()

  const handleChange = useCallback(
    (v: string) => {
      clearTimeout(innerRef.current)
      clearTimeout(changeRef.current)
      setInnerValue(v)
      if (onChangeValue) {
        changeRef.current = setTimeout(() => {
          onChangeValue && onChangeValue(v)
        }, 300)
      }
    },
    [onChangeValue],
  )
  const focusRef = useRef<NodeJS.Timeout>()
  const blurRef = useRef<NodeJS.Timeout>()
  const propsOnBlur = props.onBlur
  const propsOnFocus = props.onFocus

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnBlur) {
        blurRef.current = setTimeout(() => {
          propsOnBlur(e)
        }, 300)
      }
    },
    [propsOnBlur],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      clearTimeout(focusRef.current)
      clearTimeout(blurRef.current)
      if (propsOnFocus) {
        focusRef.current = setInterval(() => {
          propsOnFocus(e)
        }, 300)
      }
    },
    [propsOnFocus],
  )

  useEffect(() => {
    return () => {
      clearInterval(innerRef.current)
      clearInterval(changeRef.current)
      clearInterval(focusRef.current)
      clearInterval(blurRef.current)
    }
  }, [])
  return (
    <DatePicker
      autoComplete={'off'}
      {...{
        onFocus,
        onBlur,
        value: innerValue,
        handleChange,
      }}
      placeholderText={props.placeholder ?? 'Выберите дату...'}
      {...omit(props, [
        'onChangeValue',
        'onChange',
        'onBlur',
        'onFocus',
        'value',
        'tooltipText',
      ])}
    />
  )
}

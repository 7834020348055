import styled, { css } from 'styled-components'
import { colors } from '@/theme/colors'

type SelectProps = { $isDisabled?: boolean; error?: boolean }

export const SelectContainer = styled.div<SelectProps>`
  padding: 8px 12px;
  border: 1px solid ${colors.neutral[400]};
  border-radius: 10px;
  box-sizing: border-box;
  height: min-content;
  cursor: pointer;
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;
  position: relative;
  transition: 0.3s ease;
  border-color: ${({ $isDisabled }) =>
    $isDisabled ? colors.neutral[400] : ''};

  & p {
    color: ${({ $isDisabled }) => ($isDisabled ? colors.neutral[500] : '')};
    color: ${({ error }) => error && colors.neutral[500]};
  }
`

export const OptionsList = styled.div`
  position: absolute;

  left: -1px;
  right: -1px;
  border: 1px solid ${colors.neutral[400]};
  display: grid;
  max-height: 400px;
  overflow-y: scroll;
  grid-template-columns: 1fr;
  border-radius: 10px;
  overflow-x: hidden;
  background: #fff;
  z-index: 9000;

  &[data-open-direction='top'] {
    bottom: calc(100% + 4px);
  }

  &[data-open-direction='bottom'] {
    top: calc(100% + 4px);
  }
`
type IOptionProps = { $isSelected?: boolean }
export const Option = styled.div<IOptionProps>`
  padding: 8px 12px;
  transition: 0.1s ease;

  &:hover {
    background-color: ${colors.accent[200]};
    color: ${colors.accent[600]};
  }
  ${({ $isSelected = false }) =>
    $isSelected &&
    css`
      background-color: ${colors.accent[200]};
      color: ${colors.accent[600]};
    `}
`

export const NotFound = styled.div`
  padding: 12px 12px;
  color: ${colors.neutral[500]};
`

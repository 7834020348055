import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { shipmentDlvStatusOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { DlvStatusEnum } from '@/api/qubyApiEgoDlv'

type QSelectCargoTypePropsType = Omit<
  QSelectPropsType<IOption<DlvStatusEnum>, DlvStatusEnum>,
  'options'
>
export const QSelectDlvStatus = (props: QSelectCargoTypePropsType) => {
  const [t] = useTranslation()
  const options = shipmentDlvStatusOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

import * as yup from 'yup'
import {
  AddressOutputData,
  AddressRequest,
  MetroData,
} from '@/api/generated/qubyApiJava'
import { LocationModalFormTypes } from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModalForm.types'

const metroDataScheme: yup.ObjectSchema<MetroData> = yup.object({
  name: yup.string(),
  line: yup.string(),
  distance: yup.number(),
})

function checkFias(data: AddressOutputData): boolean {
  return Boolean(
    data?.settlementFiasId ||
      data?.cityFiasId ||
      data?.streetFiasId ||
      data?.houseFiasId,
  )
}

const checkIsManual = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _: any[],
  schema: any,
  options: any,
) => {
  if (options.onlyForContext.values.isManual) {
    return schema
  }

  return schema.meta({
    isDisabled: true,
  })
}

const requiredFiasText = 'Как минимум один из ФИАС кодов – нужно заполнить'

export const addressOutputDataYupSchema: yup.ObjectSchema<AddressOutputData> =
  yup.object({
    postalCode: yup
      .string()
      .meta({ placeholder: '000000' })
      .label('Индекс')
      .when(checkIsManual),
    country: yup.string(),
    countryIsoCode: yup.string(),
    federalDistrict: yup.string(),
    regionFiasId: yup.string(),
    regionKladrId: yup.string(),
    regionIsoCode: yup.string(),
    regionWithType: yup.string(),
    regionType: yup.string(),
    regionTypeFull: yup.string(),
    region: yup.string(),
    areaFiasId: yup.string(),
    areaKladrId: yup.string(),
    areaWithType: yup.string(),
    areaType: yup.string(),
    areaTypeFull: yup.string(),
    area: yup.string(),
    cityFiasId: yup
      .string()
      .id()
      .default('')
      .label('ФИАС города')
      .meta({ placeholder: '-' })
      .when(checkIsManual)
      .when('$', (_, schema, { parent }) => {
        if (checkFias(parent)) {
          return schema
        }
        return schema.required(requiredFiasText)
      }),
    cityKladrId: yup.string(),
    cityWithType: yup.string(),
    cityType: yup.string(),
    cityTypeFull: yup.string(),
    city: yup.string(),
    cityArea: yup.string(),
    cityDistrictFiasId: yup.string(),
    cityDistrictKladrId: yup.string(),
    cityDistrictWithType: yup.string(),
    cityDistrictType: yup.string(),
    cityDistrictTypeFull: yup.string(),
    cityDistrict: yup.string(),
    settlementFiasId: yup
      .string()
      .id()
      .label('ФИАС населенного пункта')
      .meta({ placeholder: '-' })
      .when(checkIsManual)
      .default('')

      .when('$', (_, schema, { parent }) => {
        if (checkFias(parent)) {
          return schema
        }
        return schema.required(requiredFiasText)
      }),

    settlementKladrId: yup.string(),
    settlementWithType: yup.string(),
    settlementType: yup.string(),
    settlementTypeFull: yup.string(),
    settlement: yup.string(),
    streetFiasId: yup
      .string()
      .id()
      .default('')
      .label('ФИАС улицы')
      .meta({ placeholder: '-' })
      .when(checkIsManual)
      .when('$', (_, schema, { parent }) => {
        if (checkFias(parent)) {
          return schema
        }
        return schema.required(requiredFiasText)
      }),
    streetKladrId: yup.string(),
    streetWithType: yup.string(),
    streetType: yup.string(),
    streetTypeFull: yup.string(),
    street: yup.string(),
    steadFiasId: yup.string(),
    steadCadnum: yup.string(),
    steadType: yup.string(),
    steadTypeFull: yup.string(),
    stead: yup.string(),
    houseFiasId: yup
      .string()
      .default('')
      .id()
      .label('ФИАС дома')
      .when(checkIsManual)
      .when('$', (_, schema, { parent }) => {
        if (checkFias(parent)) {
          return schema
        }
        return schema.required(requiredFiasText)
      }),
    houseKladrId: yup.string(),
    houseCadnum: yup.string(),
    houseType: yup.string(),
    houseTypeFull: yup.string(),
    house: yup.string(),
    blockType: yup.string(),
    blockTypeFull: yup.string(),
    block: yup.string(),
    entrance: yup.string(),
    floor: yup.string(),
    flatFiasId: yup.string(),
    flatCadnum: yup.string(),
    flatType: yup.string(),
    flatTypeFull: yup.string(),
    flat: yup.string(),
    flatArea: yup.string(),
    squareMeterPrice: yup.string(),
    flatPrice: yup.string(),
    roomFiasId: yup.string(),
    roomCadnum: yup.string(),
    roomType: yup.string(),
    roomTypeFull: yup.string(),
    room: yup.string(),
    postalBox: yup.string(),
    fiasId: yup.string(),
    fiasCode: yup.string(),
    fiasLevel: yup.string(),
    fiasActualityState: yup.string(),
    kladrId: yup.string(),
    geonameId: yup.string(),
    capitalMarker: yup.string(),
    okato: yup.string(),
    oktmo: yup.string(),
    taxOffice: yup.string(),
    taxOfficeLegal: yup.string(),
    timezone: yup.string(),
    latitude: yup
      .string()
      .meta({ placeholder: '-' })
      .when(checkIsManual)
      .label('Широта'),
    longitude: yup
      .string()
      .meta({ placeholder: '-' })
      .when(checkIsManual)
      .label('Долгота'),
    beltwayHit: yup.string(),
    beltwayDistance: yup.string(),
    metro: yup.array().of(metroDataScheme),
    divisions: yup.string(),
    qcGeo: yup.string(),
    qcComplete: yup.string(),
    qcHouse: yup.string(),
    history_values: yup.array().of(yup.object({})),
    unparsedParts: yup.array().of(yup.object({})),
    source: yup.string(),
    qc: yup.string(),
  })

export const addressYupScheme: yup.ObjectSchema<AddressRequest> = yup.object({
  id: yup.string(),
  value: yup
    .string()
    .label('Адрес')
    .meta({ placeholder: 'Введите адрес' })
    .required(),
  unrestrictedValue: yup.string().required(),
  data: addressOutputDataYupSchema,
})

const locationStoreRequest: yup.ObjectSchema<
  LocationModalFormTypes['CreateRequest']['locationStoreRequest']
> = yup.object({
  fullName: yup
    .string()
    .label('Наименование получателя/отправителя')
    .meta({ placeholder: 'Введите наименование' })
    .required(),
  phoneNumber: yup.string().default('').label('Телефон').phone().required(),
  additionalPhoneNumber: yup.string().label('Дополнительный телефон').phone(),
  comment: yup
    .string()
    .label('Комментарий')
    .meta({ placeholder: 'Добавьте информацию при необходимости' }),
  addressString: yup
    .string()
    .label('Адрес')
    .meta({ placeholder: 'Введите адрес' })
    .default('')
    .required(),
  floor: yup
    .number()
    .label('Этаж')
    .default(1)
    .transform((v) => parseInt(v))
    .required()
    .min(1)
    .meta({
      placeholder: '-',
      tooltipText: `Количество этажей. Целое положительное число. Участвует в расчётах. Используй поле ввода комментария – если нужно указать особый номер этажа – вроде -2 или 1Б.`,
    }),
})

export const locationModalCreateYupValidationSchema: yup.ObjectSchema<
  LocationModalFormTypes['CreateRequest']
> = yup.object({
  partnerId: yup.string().id().required(),
  locationStoreRequest: locationStoreRequest.required(),
})
export const locationModalUpdateYupValidationSchema: yup.ObjectSchema<
  LocationModalFormTypes['UpdateRequest']
> = yup.object({
  id: yup.string().id().required(),
  locationStoreRequest: locationStoreRequest.required(),
})

import React from 'react'
import { InputProps } from '@chakra-ui/react'
import { QForm } from '@/components/QForm/QForm'
import { QFieldPropsType } from '@/components/QForm/QField/QField.types'
import { QPhoneInner } from '@/components/QForm/QField/QInput/QPhone/QPhoneInner'

export const QPhone = (
  props: InputProps & Omit<QFieldPropsType<string>, 'children'>,
) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        isHardName,
        withWrapper,
      }}
    >
      <QPhoneInner {...otherProps} />
    </QForm.Field>
  )
}

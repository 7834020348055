import React from 'react'
import { ComponentPropsDecorator } from '@/components/Decorator/Decorator'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { QSelectAsyncCore } from '@/components/QForm/QField/QSelectAsync/QSelectAsyncCore'
import { SelectAsyncPropsListOptionType } from '@/components/atoms/SelectAsync/SelectAsync.types'
import { QSelectAsyncInnerPropsType } from '@/components/QForm/QField/QSelectAsync/QselectAsync.types'
import {
  ApiAllGetByIdEndpointsType,
  ApiAllGetListEndpointsType,
  ApiEndpointResultType,
} from '@/types/api.types'

export const QSelectAsyncInner = <
  SingleQueryEndpoint extends ApiAllGetByIdEndpointsType,
  ListQueryEndpoint extends ApiAllGetListEndpointsType,
  ListOptionType extends SelectAsyncPropsListOptionType,
  SelectedOptionType extends Record<
    string,
    unknown
  > = ApiEndpointResultType<SingleQueryEndpoint>,
>(
  props: QSelectAsyncInnerPropsType<
    SingleQueryEndpoint,
    ListQueryEndpoint,
    ListOptionType,
    SelectedOptionType
  >,
) => {
  const { onChangeValue, value, meta, propsFromYup } = useQFieldContext()
  const isInvalid = Boolean(meta.error && meta.touched)
  // @ts-ignore
  if (props.name === 'partnerIds[0]') {
    console.log({ propsFromYup, props })
  }
  return (
    <ComponentPropsDecorator {...props}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <QSelectAsyncCore
        {...{
          onChangeValue,
          value,
          isInvalid,
          ...propsFromYup,
        }}
      />
    </ComponentPropsDecorator>
  )
}

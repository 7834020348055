import { useContext } from 'react'
import { QFormContext } from '@/components/QForm/QForm.context'
import { QFormContextValueType } from '@/components/QForm/QForm.types'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useQFormContext = <
  ValueType extends Record<string, unknown> = any,
  ValidationContextValueType extends Record<string, unknown> = Record<
    string,
    unknown
  >,
>() => {
  const context = useContext(QFormContext)
  if (!context) {
    throw new Error('Не обёрнут контекст QFormContext')
  }
  return context as QFormContextValueType<ValueType, ValidationContextValueType>
}

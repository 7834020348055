import { Variants, Transition } from 'framer-motion'
const transition: Transition = {
  ease: 'easeIn',
  duration: 0.1,
}
const selectAnimationConf: { [s: string]: Variants } = {
  optionList: {
    visible: {
      opacity: 1,
      display: 'grid',
      transition,
    },
    hidden: {
      opacity: 0,
      transition,
      transitionEnd: {
        display: 'none',
      },
    },
  },
}

export default selectAnimationConf

import { Dispatch, SetStateAction, useMemo, useState } from 'react'

export type FilterMenuController = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}
export function useFilter(): FilterMenuController {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return useMemo(() => {
    return {
      isOpen,
      setIsOpen,
    }
  }, [isOpen])
}

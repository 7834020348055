import React from 'react'
import { QNumberInner } from '@/components/QForm/QField/QInput/QNumber/QNumberInner'
import { omit } from 'lodash'
import { QNumberPropsType } from '@/components/QForm/QField/QInput/QNumber/QNumber.types'
import { QForm } from '@/components/QForm/QForm'

export const QNumber = (props: QNumberPropsType) => {
  const { name, isHardName, withWrapper, ...otherProps } = props
  return (
    <QForm.Field
      {...{
        name,
        isHardName,
        withWrapper,
      }}
    >
      <QNumberInner {...omit(otherProps, ['onChange', 'onInvalid', 'name'])} />
    </QForm.Field>
  )
}

import React from 'react'
import { InputProps } from '@chakra-ui/react'
import { useQFieldContext } from '@/components/QForm/QField/useQFieldContext'
import { useField } from 'formik'
import { QSearchCore } from '@/components/QForm/QField/QInput/QSearch/QSearchCore'

export const QSearchInner = (props: InputProps) => {
  const { name, propsFromYup } = useQFieldContext()
  const [field, meta, helpers] = useField(name)

  return (
    <QSearchCore
      {...{
        ...propsFromYup,
        ...props,
        ...field,
        isInvalid: Boolean(meta.error && meta.touched),
        meta,
        helpers,
      }}
    />
  )
}

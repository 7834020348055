const formatParts = (parts: Intl.NumberFormatPart[]) => {
  return parts
    .map(({ type, value }) => {
      if (type === 'group') {
        return ' '
      } else if (type === 'literal') {
        return ' '
      }
      return value
    })
    .join('')
}

export const formatRoubles = (
  value: number,
  maximumFractionDigits?: number,
) => {
  const parts = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits,
    useGrouping: true,
  }).formatToParts(value)

  return formatParts(parts)
}

export const formatRoublesCeil = (value: number) => {
  return formatRoubles(value, 0)
}
export const formatRoublesWithFactorial = (
  value: number,
  maximumFractionDigits = 2,
) => {
  return formatRoubles(value, maximumFractionDigits)
}

export const formatNumber = (value: number, maximumFractionDigits?: number) => {
  const parts = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits,
  }).formatToParts(value)
  return formatParts(parts)
}

/**
 * @see https://unicode.org/reports/tr35/tr35-general.html#Unit_Elements
 */
type NumberFormatUnit =
  | 'acre'
  | 'bit'
  | 'byte'
  | 'celsius'
  | 'centimeter'
  | 'day'
  | 'degree'
  | 'fahrenheit'
  | 'fluid-ounce'
  | 'foot'
  | 'gallon'
  | 'gigabit'
  | 'gigabyte'
  | 'gram'
  | 'hectare'
  | 'hour'
  | 'inch'
  | 'kilobit'
  | 'kilobyte'
  | 'kilogram'
  | 'kilometer'
  | 'liter'
  | 'megabit'
  | 'megabyte'
  | 'meter'
  | 'mile'
  | 'mile-scandinavian'
  | 'milliliter'
  | 'millimeter'
  | 'millisecond'
  | 'minute'
  | 'month'
  | 'ounce'
  | 'percent'
  | 'petabyte'
  | 'pound'
  | 'second'
  | 'stone'
  | 'terabit'
  | 'terabyte'
  | 'week'
  | 'yard'
  | 'year'
export const formatUnit = (
  value: number,
  unit: NumberFormatUnit,
  maximumFractionDigits?: number,
) => {
  const parts = new Intl.NumberFormat('ru-RU', {
    style: 'unit',
    maximumFractionDigits,
    unit,
  }).formatToParts(value)
  return formatParts(parts)
}

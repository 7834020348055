import React, { useEffect, useRef, useState } from 'react'
import { motion as m } from 'framer-motion'
import { createPortal } from 'react-dom'
import {
  ContentModal,
  ModalButtons,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from './style'
import { variants } from './animation'
import { useClickAway } from 'react-use'
import { CloseButton, Text } from '@chakra-ui/react'
import { ModalPropsType } from '@/components/Modal/Modal.types'
import { ModalContext } from '@/components/Modal/Modal.context'
import { FullWidthSpinner } from '@/components/FullWidthSpiner'

const datePickerRegex = /react-datepicker/

const Modal = <T,>({
  children,
  isOpen,
  closeModal,
  header,
  buttons,
  isPreventCloseByClickOutside,
  isLoading,
  onCloseModal,
}: ModalPropsType<T>) => {
  const [node, setNode] = useState<HTMLElement>()
  const refContent = useRef<HTMLDivElement>(null)
  useClickAway(refContent, (e) => {
    if (!isPreventCloseByClickOutside) {
      const element = e.target as HTMLElement
      if (element && !datePickerRegex.test(element.classList[0])) {
        onCloseModal && onCloseModal()
        closeModal()
      }
    }
  })
  useEffect(() => {
    const modal = document.getElementById('modal')
    if (modal) {
      setNode(modal as HTMLElement)
    }
  }, [])

  if (node) {
    return (
      <ModalContext.Provider value={null}>
        <>
          {createPortal(
            <ModalOverlay
              className="overlayClickCalss"
              as={m.div}
              animate={isOpen ? 'open' : 'close'}
              initial="close"
              variants={variants.modal}
            >
              <ContentModal ref={refContent}>
                <div className="plank"></div>
                <ModalHeader>
                  {typeof header === 'string' ?
                    <Text textStyle="h4">{header}</Text>
                  : header || <div></div>}
                  <CloseButton
                    data-test-id={'close-modal-button'}
                    size={'sm'}
                    onClick={() => {
                      onCloseModal && onCloseModal()
                      closeModal()
                    }}
                  />
                </ModalHeader>
                <ModalContent>
                  {isLoading ?
                    <FullWidthSpinner label={'Загрузка...'} />
                  : children}
                </ModalContent>
                {buttons && <ModalButtons>{buttons}</ModalButtons>}
              </ContentModal>
            </ModalOverlay>,
            node as Element,
          )}
        </>
      </ModalContext.Provider>
    )
  }

  return null
}

export default Modal

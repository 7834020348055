import React from 'react'
import { QSelectPropsType } from '@/components/QForm/QField/QSelect/Qselect.types'
import { useTranslation } from 'react-i18next'
import { statusOptions } from '@/constants/options'
import { IOption } from '@/types/global'
import { QForm } from '@/components/QForm/QForm'
import { ClientStatus } from '@/_app/pages/partners/modals/partner/ClientModalForm.types'

type QSelectClientStatusPropsType = Omit<
  QSelectPropsType<IOption<ClientStatus>, ClientStatus>,
  'options'
>
export const QSelectClientStatus = (props: QSelectClientStatusPropsType) => {
  const [t] = useTranslation()
  const options = statusOptions(t)

  return (
    <QForm.Field.Select
      {...{
        ...props,
        options,
      }}
    />
  )
}

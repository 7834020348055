import { Box, Grid, Tooltip } from '@chakra-ui/react'
import React, { FC } from 'react'
import { colors } from '@/theme/colors'
import DocumentIcon from '@/components/Icons/Document'
import { AttachmentResponse } from '@/api/generated/qubyApiJava'

interface Props {
  atachments?: AttachmentResponse[]
}

const AttachmentsCell: FC<Props> = ({ atachments = [] }) => {
  return (
    <Grid
      gridAutoFlow="column"
      height="100%"
      alignContent="center"
      alignItems="center"
      gridAutoColumns="max-content"
      gridGap="10px"
    >
      {atachments.map(({ filePath, fileName }, i) => (
        <Tooltip borderRadius="4px" size="s" label={fileName} key={i}>
          <Box as="a" target="_blank" href={filePath} cursor="pointer">
            <DocumentIcon
              color={colors.neutral[500]}
              _hover={{ color: colors.accent[600] }}
              width="16px"
              height="16px"
            />
          </Box>
        </Tooltip>
      ))}
    </Grid>
  )
}

export default AttachmentsCell

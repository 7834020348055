import React, { useState } from 'react'
import { QForm } from '@/components/QForm'
import { SelectAsyncQueriesConfig } from '@/components/atoms/SelectAsync/SelectAsync.types'
import {
  GoodsModalStateType,
  QSelectAsyncPropsType,
  QSelectEndpointTypes,
} from './QSelectAsync.types'
import { QSelectAsyncEndpoints } from './QSelectAsync.endpoints'
import { useClientContext } from '@/providers/Client/useClientContext'
import useModalState from '@/hooks/useModalState'
import { QSelectAsyncGoodsNewGoodModal } from '@/components/QForm/QField/QSelectAsync/Goods/Modal/QSelectAsyncGoodsNewGoodModal'
import { v4 as uuidv4 } from 'uuid'

export const QSelectAsyncGoods = (props: QSelectAsyncPropsType) => {
  const {
    singleEndpointAdditionalArgs,
    listEndpointAdditionalArgs,
    ...otherProps
  } = props

  const { clientId } = useClientContext()

  const queriesConfig: SelectAsyncQueriesConfig<
    QSelectEndpointTypes['SingleEndpoint'],
    QSelectEndpointTypes['ListEndpoint']
  > = {
    single: {
      query: QSelectAsyncEndpoints.getById,
      args: (value: typeof props.value) => {
        return {
          id: String(value),
          ...(singleEndpointAdditionalArgs ?
            singleEndpointAdditionalArgs()
          : {}),
        }
      },
    },
    list: {
      query: QSelectAsyncEndpoints.getList,
      args: () => {
        return {
          partnerId: clientId || '',
          perPage: 200,
          ...(listEndpointAdditionalArgs ? listEndpointAdditionalArgs() : {}),
        }
      },
    },
  }
  const { clientId: partnerId } = useClientContext()
  const modalProps = useModalState<GoodsModalStateType>()
  const [uuid, setUuid] = useState(uuidv4())

  if (!clientId) {
    return (
      <>
        Этот компонент зависит от clientId в контексте и не будет работать без
        него
      </>
    )
  }
  return (
    <>
      <QSelectAsyncGoodsNewGoodModal key={uuid} {...{ modalProps }} />
      <QForm.Field.SelectAsync<
        QSelectEndpointTypes['SingleEndpoint'],
        QSelectEndpointTypes['ListEndpoint'],
        QSelectEndpointTypes['Item']
      >
        onCreateNew={(name, afterSave) => {
          setUuid(uuidv4())
          modalProps.openModal({
            goods: {
              partnerId: partnerId || 'unknown',
              goodStoreRequest: { name: String(name) },
            },
            afterSave,
          })
        }}
        {...{
          queriesConfig,
          ...otherProps,
        }}
      />
    </>
  )
}

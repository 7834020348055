import { Stack } from '@chakra-ui/react'
import { UniversalLocationTypeSelectButton } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/UniversalLocationTypeSelectButton'
import { LocationTypes } from '@/api/generated/qubyApiPhp'
import React, { FC } from 'react'
import { qSelectAsyncLocationTypes } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/qSelectAsyncUniversalLocations.constants'
import { QSelectAsyncLocationPropsType } from '@/components/QForm/QField/QSelectAsync/UniversalLocations/QSelectAsync.types'

type QSelectAsyncLocationTopAddonContentPopsType = {
  locationType: LocationTypes
  setLocationType: (type: LocationTypes) => void
} & QSelectAsyncLocationPropsType

export const QSelectAsyncUniversalLocationTopAddonContent: FC<
  QSelectAsyncLocationTopAddonContentPopsType
> = ({
  locationType,
  setLocationType,
  disabledLocationTypes = [],
  isDisableLocationChange,
}) => {
  return (
    <Stack direction={'row'} spacing={2} pt={1}>
      {Object.entries(qSelectAsyncLocationTypes).map(([type, title]) => {
        const isActive = locationType === type
        const isDisabled =
          isActive ? false : (
            isDisableLocationChange ||
            disabledLocationTypes.includes(type as LocationTypes)
          )
        return (
          <UniversalLocationTypeSelectButton
            $isActive={isActive}
            $isDisabled={isDisabled}
            key={type}
            onClick={() => {
              setLocationType(type as LocationTypes)
            }}
          >
            {title}
          </UniversalLocationTypeSelectButton>
        )
      })}
    </Stack>
  )
}

import { Variants } from 'framer-motion'

const menuCellAnimationConf: { [s: string]: Variants } = {
  menuList: {
    open: {
      opacity: 1,
      x: 0,
    },
    close: {
      opacity: 0,
      x: '-16px',
    },
  },
  menuButton: {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  },
}

export default menuCellAnimationConf

import React, { FC, PropsWithChildren, useCallback } from 'react'
import { QForm } from '@/components/QForm'
import { LocationModalInner } from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModalInner'
import { RTKMutationResult } from '@/types/rtkq.types'
import {
  locationModalCreateYupValidationSchema,
  locationModalUpdateYupValidationSchema,
} from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModal.yup'
import {
  LocationModalFormPropsType,
  LocationModalFormTypes,
} from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModalForm.types'
import { useClientContext } from '@/providers/Client/useClientContext'
import { LocationModalFormEndpoints } from '@/components/QForm/QField/QSelectAsync/Locations/Modal/LocationModalForm.endpoints'
import { qFormCollectErrors } from '@/components/QForm/qFormCollectErrors'

export const LocationModal: FC<
  PropsWithChildren<LocationModalFormPropsType>
> = ({ modalProps }) => {
  const { clientId: partnerId } = useClientContext()
  const [createLocation, { isLoading: isLoadingCreate }] =
    LocationModalFormEndpoints.create.useMutation()
  const [updateLocation, { isLoading: isLoadingUpdate }] =
    LocationModalFormEndpoints.update.useMutation()
  const isLoading = isLoadingUpdate || isLoadingCreate

  const modalData = modalProps.getData()
  const onSubmit = useCallback(
    async (location: LocationModalFormTypes['ConsumedRequest']) => {
      function handle(
        response: RTKMutationResult<LocationModalFormTypes['ConsumedResponse']>,
      ) {
        const errors = qFormCollectErrors(response)
        if ('data' in response) {
          if (response.data) {
            modalData?.afterSave && modalData?.afterSave(response.data)
            modalProps.closeModal()
          }
        }
        return errors
      }

      if ('id' in location) {
        const response = await updateLocation(location)
        return handle(response)
      } else {
        const response = await createLocation(location)
        return handle(response)
      }
    },
    [createLocation, updateLocation, modalProps, modalData],
  )

  return (
    <QForm<LocationModalFormTypes['ConsumedRequest']>
      requestKey={'locationStoreRequest'}
      initialValues={modalData?.location}
      validationSchema={
        modalData?.location && 'id' in modalData?.location ?
          locationModalUpdateYupValidationSchema
        : locationModalCreateYupValidationSchema
      }
      {...{ onSubmit }}
    >
      <LocationModalInner
        {...{
          partnerId,
          modalProps,
          isLoading,
        }}
      />
    </QForm>
  )
}

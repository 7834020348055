import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_204_15519)">
        <path
          d="M15.805 14.862l-3.98-3.979a6.677 6.677 0 10-.942.943l3.979 3.979a.667.667 0 00.943-.943zM6.667 12A5.334 5.334 0 1112 6.667 5.34 5.34 0 016.667 12z"
          fill={props.fill ? props.fill : '#98A2B3'}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_204_15519">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

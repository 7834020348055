import { useContext } from 'react'
import { QGroupContext } from '@/components/QForm/QGroup/QGroup.context'
import { QFormGroupContextValueType } from '@/components/QForm/QGroup/QGroup.types'

export const useQGroupContext = <ValueType = unknown>() => {
  const context = useContext(QGroupContext)
  if (!context) {
    throw new Error('Не обёрнут контекст QGroupContext')
  }
  return context as QFormGroupContextValueType<ValueType | undefined>
}
